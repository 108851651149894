import React from "react";
import styles from '../styles/Error.module.css';

function ErrorFallbackUI({ error, resetErrorBoundary }) {
  return (
    <div className="container">
        <div className="row">
            <div className="col-md-4">
                <h2 className="text-center">500</h2>
                <h5 className="text-center">Something Went Wrong</h5>
                <pre style={{ color: "red" }}>{error.message}</pre>
            </div>
        </div>
    </div>
  );
}

export default ErrorFallbackUI;
