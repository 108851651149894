import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import styles from '../styles/Documents.module.css'
import styles2 from '../styles/DIS.module.css'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faTrash, faEye } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { formatDate } from '../utilities/format'
import { msalFetch } from '../utilities/msalFetch'

export const Documents = props => {
  const [file, setFile] = useState(undefined)
  const [docType, setDocType] = useState('Other')
  const [filesList, setFilesList] = useState([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [fileDeleteKey, setFileDeleteKey] = useState('')
  const formref = useRef(null)
  const [invalidFile, setInvalidFile] = useState(false)
  const [invalidFileMessage, setInvalidFileMessage] = useState('')

  const fileChange = e => {
    const file = e.target.files[0]
    const type = file.type
    if (
      type === 'image/jpg' ||
      type === 'image/jpeg' ||
      type === 'application/pdf'
    ) {
      setInvalidFile(false)
      setFile(file)
    } else {
      setInvalidFile(true)
      setInvalidFileMessage(
        'Invalid file type. Only pdf and image files are allowed'
      )
    }
  }

  const getFilesList = () => {
    props.actions.setLoading()
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: props.token
      }
    }
    msalFetch(
      `/api/donor/getFilesList/${props.donorInfo.donor_id}`,
      options
    ).then(async res => {
      props.actions.clearLoading()
      if (res.ok) {
        const data = await res.json()
        setFilesList(data)
      } else {
        toast.error('Get files failed')
      }
    })
  }

  useEffect(() => {
    if (props.donorInfo?.donor_id) {
      getFilesList()
    }
  }, [props.donor_info])

  const fileUpload = e => {
    e.preventDefault()
    if (!invalidFile) {
      props.actions.setLoading()
      var formData = new FormData()
      formData.append('doc_type', docType)
      formData.append('donor_id', props.donorInfo.donor_id)
      formData.append('file', file)
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: props.token
        },
        body: formData
      }
      msalFetch('/api/donor/fileUpload', options)
        .then(async res => {
          props.actions.clearLoading()

          if (res.ok) {
            toast.success('File uploaded successfully')
            getFilesList()
            formref.current.reset()
          } else {
            toast.error('Failed to upload file')
          }
        })
        .catch(error => {
          toast.error('Failed to upload file')
        })
    }
  }

  const fileDownload = key => {
    //props.actions.setLoading();
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: props.token
      }
    }
    msalFetch(`/api/donor/downloadFile/${key}`, options).then(
      async response => {
        const blob = await response.blob()
        props.actions.clearLoading()
        const header = response.headers.get('Content-Disposition')
        const parts = header?.split(';')
        let filename = parts[1].split('=')[1]
        filename = filename.replaceAll(`"`, '')
        var url = window.URL.createObjectURL(blob)
        var a = document.createElement('a')
        a.href = url
        a.download = filename
        document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click()
        a.remove() //afterwards we remove the element again
      }
    )
  }

  const filePreview = key => {
    //props.actions.setLoading();
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: props.token
      }
    }
    msalFetch(`/api/donor/downloadFile/${key}`, options).then(
      async response => {
        const blob = await response.blob()
        props.actions.clearLoading()
        var url = window.URL.createObjectURL(blob)
        window.open(url)
      }
    )
  }

  const deleteFile = (e, key = fileDeleteKey) => {
    setShowDeleteModal(false)
    props.actions.setLoading()
    const options = {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        Authorization: props.token
      }
    }
    msalFetch(`/api/donor/deleteFile/${key}`, options).then(async response => {
      props.actions.clearLoading()
      if (response.ok) {
        toast.success('File deleted successfully')
        getFilesList()
      } else {
        let data = await response.json()

        toast.warning('Deletion failed')
      }
    })
  }

  if (props.donorInfo.donor_id) {
    return (
      <React.Fragment>
        <div className={`row p-2 mt-1 ${styles2.drdRibbon}`}>
          <p className={`col-sm-3 ${styles2.drdRibbonTitle}`}>
            Documents Management
          </p>
          <p className={`col-sm-3 ${styles2.drdRibbonDonorName}`}>
            <span>
              {props.donorInfo.first_name} {props.donorInfo.last_name}
            </span>
          </p>
          <div className={`col-sm-3 ${styles2.drdRibbonID}`}>
            <span>XXX-XX-{props.donorInfo.donor_id}</span>
          </div>
        </div>
        <div className='row mt-2 py-4 px-4 border-bottom'>
          {props.roleid == 8 || props.roleid == 7 || props.roleid == 3 ? (
            <div className='col-md-10'>
              <form
                onSubmit={e => fileUpload(e)}
                encType='multipart/form-data'
                ref={formref}
              >
                <div className='form-group'>
                  <label
                    htmlFor='bodyart'
                    className='py-2'
                    style={{ fontSize: '18px' }}
                  >
                    Upload Document:
                  </label>
                  <br></br>
                  <div className='row'>
                    <div className='col-md-4 pt-1 form-group'>
                      <input
                        type='file'
                        className='form-control-file'
                        id='bodyart'
                        onChange={fileChange}
                        required={true}
                      />
                      <br></br>
                      {invalidFile && (
                        <span className={styles.fileWarning}>
                          {invalidFileMessage}
                        </span>
                      )}
                    </div>
                    <div className='col-md-4 d-flex justify-content-between form-group p-0'>
                      <label htmlFor='docType'>Document Type:</label>
                      <select
                        className='form-select form-select-sm'
                        style={{ height: '67%' }}
                        id='docType'
                        onChange={e => setDocType(e.target.value)}
                        required={true}
                      >
                        <option selected disabled value=''>
                          Select
                        </option>
                        <option value='Medical Incident Report'>
                          Medical Incident Report
                        </option>
                        <option value='Physician Approval To Donate'>
                          Physician Approval To Donate
                        </option>
                        <option value='Others'>Others</option>
                      </select>
                    </div>
                    <div className='col-md-2'>
                      <button
                        className='btn btn-primary'
                        disabled={invalidFile}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          ) : null}
        </div>
        <div className='row mt-2 mb-4 px-4'>
          <div className='col-md-10'>
            <h4 className={styles.documentsTitle}>Documents</h4>
            <table className='table table-sm table-bordered'>
              <thead>
                <tr>
                  <th scope='col'>Doc Type</th>
                  <th scope='col'>Name</th>
                  <th scope='col'>Uploaded By</th>
                  <th scope='col'>Uploaded On</th>
                  <th scope='col'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filesList.length
                  ? filesList.map(file => {
                      return (
                        <tr key={file.document_id}>
                          <td>{file.doc_type}</td>
                          <td>{file.name}</td>
                          <td>{file.uploaded_by}</td>
                          <td>
                            {file.uploaded_at && formatDate(file.uploaded_at)}
                          </td>
                          <td className='d-flex justify-content-around'>
                            <FontAwesomeIcon
                              icon={faDownload}
                              className={styles.downloadIcon}
                              onClick={() => fileDownload(file.aws_key)}
                            ></FontAwesomeIcon>
                            <FontAwesomeIcon
                              icon={faEye}
                              className={styles.PreviewIcon}
                              onClick={() => filePreview(file.aws_key)}
                            ></FontAwesomeIcon>
                            <FontAwesomeIcon
                              icon={faTrash}
                              className={styles.deleteIcon}
                              onClick={() => {
                                setFileDeleteKey(file.aws_key)
                                setShowDeleteModal(true)
                              }}
                            ></FontAwesomeIcon>
                          </td>
                        </tr>
                      )
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
        <Modal
          show={showDeleteModal}
          backdrop='static'
          keyboard={false}
          size='md'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header>
            <Modal.Title>Please Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            The file will be deleted permanently. Do you still want to continue?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='danger'
              size='sm'
              style={{ width: '50px' }}
              onClick={deleteFile}
            >
              Yes
            </Button>
            <Button
              variant='primary'
              size='sm'
              style={{ width: '50px' }}
              onClick={() => setShowDeleteModal(false)}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    )
  }
  return 'Loading...'
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token,
    isAuthenticated: state.isAuthenticated,
    roleid: state.roleid
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Documents)
