import React from 'react'
import logo from '../images/life-plasma-logo.webp'
import moment from 'moment'
import { ReactBarcode as Barcode } from 'react-jsbarcode';

class PrintLabOrder extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return (
      <div
        className='row d-flex justify-content-center border p-4 py-4'
        style={{ height: '50vh', fontSize:'14px' }}
      >
        <div className='col-sm-12'>
          <div className='row mb-4'>
            <div className='col-sm-12'>
              <img alt='logo' width={200} src={logo}></img>
              <h3 className='mt-3 mb-2 text-center'>Lab Order Report</h3>
              <p>
                Donation Date :{' '}
                {this.props.donationRange
                  ? moment(this.props.donationRange.fromDate).format(
                      'DD-MMM-YYYY'
                    ) +
                    ' - ' +
                    moment(this.props.donationRange.toDate).format(
                      'DD-MMM-YYYY'
                    )
                  : ''}
              </p>
              <p>UCN : {this.props.ucn}</p>
            </div>
          </div>
          <div className='row mt-4'>
            {this.props.labOrderReport ? (
              <table className='table table-bordered'>
                <thead>
                  <tr>
                    <th scope='col'>UCN</th>
                    <th scope='col'>Donation Date</th>
                    <th scope='col'>Donor Type</th>
                    <th scope='col'>NAT/VMT</th>
                    <th scope='col'>ATYA</th>
                    <th scope='col'>SPE/RPR</th>
                    <th scope='col'>Plasma Processor</th>
                    <th scope='col'>Lab Result</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.labOrderReport.map(record => {
                    return (
                      <tr>
                        <td>
                          <Barcode
                            value={record.UCN}
                            options={{ format: 'code128', height: 40 }}
                          ></Barcode>
                        </td>
                        <td>{record.DONATION_DATE}</td>
                        <td>{record.DONOR_TYPE}</td>
                        <td>{record.NAT_VMT}</td>
                        <td>{record.ATYA}</td>
                        <td>{record.SPE_RPR}</td>
                        <td>{record.PLASMA_PROCESSOR}</td>
                        <td>{record.LAB_RESULT}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

export default PrintLabOrder
