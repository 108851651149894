import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import * as actions from "../redux/actions";
import { bindActionCreators } from "redux";
import styles from "../styles/DIS.module.css";
import DRD from "./DRD";
import DI from "./DI";
import DonationHistory from "./DonationHistory";
import Documents from "./Documents";
import moment from "moment";
import DHQ from "./DHQ";
import HealthAssessment from "../modules/HealthAssessment/HealthAssessment";
import { msalFetch } from "../utilities/msalFetch";
import DonorAlerts from "./DonorAlerts";
import { Badge } from "antd";
import { Menu } from "primereact/menu";
import MIRForm from "../modules/MIRFrom/MIRForm";
import MIRReports from "../modules/MIRFrom/MIRReports";

function DIS(props) {
  const [dp, setDp] = useState("");
  const [donorInfo, setDonorInfo] = useState({});
  const [page, setPage] = useState("DI");
  const [b64Image, setB64Image] = useState("");
  const [speDue, setSPEDue] = useState(false);
  const [drdData, setDrdData] = useState(undefined);
  const [hardStop, setHardStop] = useState({ result: true });
  const [toggleResMenuItems, setToggleResMenuItems] = useState(false);
  const [alertCount, setAlertCount] = useState(0);

  useEffect(() => {
    sessionStorage.getItem("selectedDonorId");
    getDonorInfo(true);
  }, [sessionStorage.getItem("selectedDonorId")]);

  useEffect(() => {
    if (sessionStorage.getItem("selectedUCN")) {
      if (props.roleid == 3 || props.roleid == 1 || props.roleid == 2) {
        setPage("DRD");
      } else if (props.roleid == 7) {
        setPage("HA");
      }
    } else if (sessionStorage.getItem("redirectPage")) {
      setPage(sessionStorage.getItem("redirectPage"));
    }
    return () => {
      sessionStorage.removeItem("selectedUCN");
      sessionStorage.removeItem("redirectPage");
    };
  }, []);

  useEffect(() => {
    if (donorInfo.donor_id) {
      getData();
      !sessionStorage.getItem("selectedUCN") && validate3X7rule();
    }
  }, [donorInfo]);

  const fetchPendingAlertCount = async (id) => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: props?.token,
      },
    };
    const res = await msalFetch(`/api/donor//allPendingAlerts/${id}`, options);
    const data = await res.json();
    if (data.success) {
      setAlertCount(data?.pendingCount);
    }
  };

  const checkIfDonorisSPEDue = (spedate) => {
    if (spedate == null || spedate == "") {
      setSPEDue(true);
    } else {
      let currentDate = new Date();
      var a = moment(currentDate);
      var b = moment(spedate);
      let monthsDiff = a.diff(b, "months");
      if (monthsDiff && monthsDiff >= 4) {
        setSPEDue(true);
      } else {
        setSPEDue(false);
      }
    }
  };

  const getDonorInfo = (mountCall = false) => {
    console.log("callng");

    props.actions.setLoading();
    const options = {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: props.token,
      },
    };
    const donorId = sessionStorage.getItem("selectedDonorId");
    msalFetch(`/api/donor/donorinfo/${donorId}`, options)
      .then(async (res) => {
        const result = await res.json();
        props.actions.clearLoading();
        result.pic && setB64Image(result.pic.data);
        result.pic && base64toImg(result.pic.data);

        setDonorInfo(result);
        if (mountCall && sessionStorage.getItem("selectedUCN")) {
          if (props.roleid == 3 || props.roleid == 1 || props.roleid == 2) {
            setPage("DRD");
          }
        }
        checkIfDonorisSPEDue(result.spe_sample_date);
        fetchPendingAlertCount(donorId);
      })
      .catch((err) => {
        props.actions.clearLoading();
      });
  };

  const getData = () => {
    const options = {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: props.token,
      },
    };
    const URL = sessionStorage.getItem("selectedUCN")
      ? `api/donor/getDRDbyUCN/${sessionStorage.getItem("selectedUCN")}`
      : `api/donor/getDRD/${donorInfo.donor_id}`;
    msalFetch(URL, options)
      .then(async (res) => {
        const data = await res.json();
        if (data.length > 0) {
          setDrdData(data[0]);
        } else {
          setDrdData({});
        }
      })
      .catch((err) => {});
  };

  const validate3X7rule = () => {
    props.actions.setLoading();
    const options = {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    };
    msalFetch(`/api/donor/validate3x7rule/${donorInfo.donor_id}`, options)
      .then(async (res) => {
        props.actions.clearLoading();
        if (res.ok) {
          const result = await res.json();
          setHardStop(result);
        } else {
          setHardStop({
            result: false,
            message: "Failed to validate 3X7 rule",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        props.actions.clearLoading();
      });
  };

  const base64toImg = (result) => {
    var arrayBufferView = new Uint8Array(result);
    var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
    var urlCreator = window.URL || window.webkitURL;
    var imageUrl = urlCreator.createObjectURL(blob);
    setDp(imageUrl);
  };

  const pageDisplay = () => {
    switch (page) {
      case "DI":
        return (
          <DI
            donorInfo={donorInfo}
            image={dp}
            b64={b64Image}
            getDonorInfo={getDonorInfo}
          ></DI>
        );
      case "DRD":
        return (
          <DRD
            donorInfo={donorInfo}
            dp={dp}
            speDue={speDue}
            hardStop={hardStop}
          ></DRD>
        );
      case "DH":
        return (
          <DonationHistory
            donorInfo={donorInfo}
            token={props.token}
            roleid={props.roleid}
          ></DonationHistory>
        );
      case "docs":
        return <Documents donorInfo={donorInfo}></Documents>;
      case "DHQ":
        return (
          <DHQ
            donorInfo={donorInfo}
            drdData={drdData}
            getData={getData}
            setPage={setPage}
            hardStop={hardStop}
            getDonorInfo={getDonorInfo}
          ></DHQ>
        );
      case "HA":
        return (
          <HealthAssessment
            donorInfo={donorInfo}
            drdData={drdData}
            getData={getData}
            setPage={setPage}
            getDonorInfo={getDonorInfo}
          ></HealthAssessment>
        );
      case "DonorAlerts":
        return (
          <DonorAlerts
            donorId={donorInfo?.donor_id}
            setAlertCount={setAlertCount}
          />
        );
      case "MIRForm":
        return <MIRForm donorInfo={donorInfo} />;
      case "MIRReports":
        return <MIRReports donorId={donorInfo.donor_id} />;
      default:
        return <>null</>;
    }
  };

  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-lg navbar-light bg-light m-0 p-0">
        <button
          className="navbar-toggler my-1"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={(e) => {
            setToggleResMenuItems(!toggleResMenuItems);
          }}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={
            toggleResMenuItems ? "navbar-collapse" : "collapse navbar-collapse"
          }
          id="navbarSupportedContent"
        >
          <ul className={`d-lg-flex ${styles.donorTab}`}>
            <li className={`nav-item `}>
              <a
                className={`nav-link ${styles.donorTabItem} ${
                  page == "DI" ? `${styles.disMenuActive}` : ""
                }`}
                onClick={() => setPage("DI")}
              >
                Donor Information
              </a>
            </li>
            {props.roleid == 1 ||
            props.roleid == 2 ||
            props.roleid == 3 ||
            props.roleid == 7 ? (
              <li className="nav-item">
                <a
                  className={`nav-link ${styles.donorTabItem} ${
                    page == "DHQ" ? `${styles.disMenuActive}` : null
                  }`}
                  onClick={() => setPage("DHQ")}
                >
                  Donor History Questionnaire(s)
                </a>
              </li>
            ) : null}
            {props.roleid == 1 || props.roleid == 2 || props.roleid == 3 ? (
              <li className="nav-item">
                <a
                  className={`nav-link ${styles.donorTabItem} ${
                    page == "DRD" ? `${styles.disMenuActive}` : null
                  }`}
                  onClick={() => setPage("DRD")}
                >
                  Donor Record of Donation
                </a>
              </li>
            ) : null}
            {props.roleid == 1 ||
            props.roleid == 2 ||
            props.roleid == 3 ||
            props.roleid == 7 ||
            props.roleid == 8 ? (
              <li className="nav-item">
                <a
                  className={`nav-link ${styles.donorTabItem} ${
                    page == "docs" ? `${styles.disMenuActive}` : null
                  }`}
                  onClick={() => setPage("docs")}
                >
                  Documents
                </a>
              </li>
            ) : null}
            {props.roleid == 7 || props.roleid == 8 || props.roleid == 2 ? (
              <li className="nav-item">
                <a
                  className={`nav-link ${styles.donorTabItem} ${
                    page == "HA" ? `${styles.disMenuActive}` : null
                  }`}
                  onClick={() => setPage("HA")}
                >
                  Health Assessment
                </a>
              </li>
            ) : null}
            <li className="nav-item">
              <a
                className={`nav-link ${styles.donorTabItem} ${
                  page == "DH" ? `${styles.disMenuActive}` : null
                }`}
                onClick={() => setPage("DH")}
              >
                Donation History
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${styles.donorTabItem} ${
                  page == "DH" ? `${styles.disMenuActive}` : null
                }`}
                onClick={() => setPage("DonorAlerts")}
              >
                <span>
                  Alerts <Badge count={alertCount} className="m-0 p-0" />
                </span>
              </a>
            </li>
            {(props.roleid === 7 || props.roleid === 8) &&
              donorInfo?.ucn?.ucn && (
                <>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${styles.donorTabItem} ${
                        page == "DH" ? `${styles.disMenuActive}` : null
                      }`}
                      onClick={() => setPage("MIRForm")}
                    >
                      <span>MIR Form</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${styles.donorTabItem} ${
                        page == "DH" ? `${styles.disMenuActive}` : null
                      }`}
                      onClick={() => setPage("MIRReports")}
                    >
                      <span>MIR Reports</span>
                    </a>
                  </li>
                </>
              )}
          </ul>
        </div>
      </nav>
      {pageDisplay()}
      <Menu />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    username: state.username,
    token: state.token,
    isAuthenticated: state.isAuthenticated,
    roleid: state.roleid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DIS);
