import React from "react";
import logo from "../images/life-plasma-logo.webp";
import { formatDate } from "../utilities/format";

class PrintCasePacking extends React.Component {
  // getVolume = () => {
  //   return this.props.casingData.reduce((acc, caseunit) => {
  //     return acc + caseunit.volume;
  //   }, 0);
  // };

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  render() {
    return (
      <div className="row d-flex justify-content-center border p-4">
        <div className="col-sm-12">
          <div className="row mb-4">
            <div className="col-sm-12">
              <img alt="logo" width={200} src={logo}></img>
              <h2 className="mt-3 mb-4 text-center">Packing Slip</h2>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Case No</td>
                    <th>{this.props.caseno}</th>
                    <td>Newest Donation</td>
                    <th>{this.props.dates.maxDate}</th>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <th>{this.props.status}</th>
                    <td>Oldest Donation</td>
                    <th>{this.props.dates.minDate}</th>
                  </tr>
                  <tr>
                    <td>US License#</td>
                    <th>2311</th>
                    <td>FEI Number:</td>
                    <th>3021916912</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-4 p-2">
            <table className="w-100 table table-bordered table-sm">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">UCN</th>
                  <th scope="col">Donor Id</th>
                  <th scope="col">Total Volume</th>
                  <th scope="col">Donation Date</th>
                </tr>
              </thead>
              <tbody>
                {this.props.casingData.map((x, i) => {
                  return (
                    <tr key={i}>
                      <th scope="row">{x.seq}</th>
                      <td>{x.ucn}</td>
                      <td>{x.donor_id}</td>
                      <td>{x.volume}</td>
                      <td>{x.donationDate && formatDate(x.donationDate)}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td>Total Volume</td>
                  <th colSpan={4}>
                    {this.numberWithCommas(this.props.volume) + " mL"}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <p>
          Shipping Address: Life Plasma, Inc., 5438 Perkiomen Ave, Reading, PA 19606 USA
        </p>
        <p>Phone: 484-772-8687</p>
        <p>
          Email us at contactus@lifeplasma.com with Case Packing Number and UCN
          for any questions.
        </p>
      </div>
    );
  }
}

export default PrintCasePacking;
