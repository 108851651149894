import React,{ useState } from 'react';
import { formatDate } from '../../utilities/format';

const DHQ = props => {

  return (
    <div className='row mt-2 d-flex'>
    {console.log(props)}
      <div className='col-md-12'>
        {props.dhqDetails ? (
          Object.keys(props.dhqDetails).length ? (
            <React.Fragment>
              <table className='table table-sm table-bordered'>
                <tbody>
                  {props.questions.map((x, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <th scope='row' colSpan='3'>
                            {x.questionHead}
                          </th>
                        </tr>
                        {x.questions.map((question, index) => {
                          return (
                            <tr
                              key={index}
                              id={question.id}
                              className={
                                question.expectedAnswer !==
                                props.answers[question?.id]
                                  ? 'table-danger'
                                  : ''
                              }
                            >
                              <td>{question.question}</td>
                              <td>
                                Yes{' '}
                                <input
                                  type='radio'
                                  value='Yes'
                                  checked={props.answers[question?.id] == 'Yes'}
                                  name={question.id}
                                ></input>
                              </td>
                              <td>
                                No{' '}
                                <input
                                  type='radio'
                                  value='No'
                                  checked={props.answers[question?.id] == 'No'}
                                  name={question.id}
                                ></input>
                              </td>
                            </tr>
                          )
                        })}
                      </>
                    )
                  })}
                </tbody>
              </table>
              <div className='row p-2'>
                <table className='table table-bordered'>
                  <tr>
                    <th>Donor Name</th>
                    <td>{props.dhqDetails.DONOR_NAME}</td>
                  </tr>
                  <tr>
                    <th>
                      Donor Signature<sup>*</sup>
                    </th>
                    <td>{props.dhqDetails.DONOR_SIGN}</td>
                  </tr>
                  <tr>
                    <th>
                      Medical Screener Name<sup>*</sup>
                    </th>
                    <td>
                      <input
                        type='text'
                        value={props.dhqDetails.MEDICAL_SCREENER}
                        style={{
                          backGroundColor: '#FFFFF',
                          margin: '2px',
                          border: '1px solid'
                        }}
                        disabled
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <th className='pt-2 pb-2'>
                      DHQ Result<sup>*</sup>
                    </th>
                    <td className='pt-2 pb-2'>
                      <select
                        aria-label='Default select example'
                        style={{
                          backGroundColor: '#FFFFF',
                          margin: '2px',
                          border: '1px solid',
                          width: '10rem'
                        }}
                        value={props.dhqDetails.RESULT}
                        disabled
                      >
                        <option selected disabled>
                          Select
                        </option>
                        <option value='PASS'>Pass</option>
                        <option value='FAIL'>Fail</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>Comments</th>
                    <td>{props.dhqDetails.COMMENTS}</td>
                  </tr>
                  <tr>
                    <th>Date</th>
                    <td>
                      {props.dhqDetails.DATE_OF_DHQ
                        ? formatDate(props.dhqDetails.DATE_OF_DHQ)
                        : Date().slice(0, 15)}
                    </td>
                  </tr>
                  {props.dhqDetails.PHYSICIAN_SUB ? (
                    <>
                      <tr>
                        <th>Physician Sub Approval</th>
                        <td>{props.dhqDetails.PHYSICIAN_SUB}</td>
                      </tr>
                      <tr>
                        <th>Approved On</th>
                        <td>
                          {formatDate(props.dhqDetails.PHYSUB_APPROVAL_DATE)}
                        </td>
                      </tr>
                    </>
                  ) : null}
                </table>
              </div>
            </React.Fragment>
          ) : (
            <h5>No Record found</h5>
          )
        ) : (
          <h5>Loading...</h5>
        )}
      </div>
    </div>
  )
}

export default DHQ
