import React, { useEffect, useState, useRef } from 'react'
import styles from '../../../styles/ManageUsers.module.css'
import { connect } from 'react-redux'
import * as actions from '../../../redux/actions'
import { bindActionCreators } from 'redux'
import {
  faEllipsisH,
  faLock,
  faUnlock,
  faTrash,
  faEdit,
  faEllipsis
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { msalFetch } from '../../../utilities/msalFetch'
import { Button, Select, Table, Tag } from 'antd'

function UserManagement (props) {
  const [users, setUsers] = useState([])
  const [addUserPopup, setAddUserPopUp] = useState(false)
  const [rolesOptions, setRolesOptions] = useState({})
  const [newUserData, setNewUserData] = useState({})
  const [newUserErrors, setNewUserErrors] = useState({})
  const [selectedUserId, setSelectedUserId] = useState(null)
  const [showMenu, setShowMenu] = useState(false)
  const [editUserPopup, setEditUserPopUp] = useState(false)
  const [editUserData, setEditUserData] = useState({})
  const [editUserErrors, setEditUserErrors] = useState({})
  const [resetPasswordPopup, setResetPasswordPopup] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [centers, setCenters] = useState([])
  const [centerFilter, setCenterFilter] = useState('All')
  const resetPasswordRef = useRef(null)
  const menuRef = useRef(null)

  useEffect(() => {
    getAllUsers()
    fetchCenters()
  }, [])

  useEffect(() => {
    if (props.allRoles) {
      const roles = props.allRoles.map(x => {
        return { label: x.role_name, value: x.role_id }
      })
      setRolesOptions(roles)
    }
  }, [props.allRoles])

  useEffect(() => {
    function handleClickOutside (event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuRef])

  const getAllUsers = () => {
    props.actions.setLoading()
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      }
    }
    msalFetch(`/api/admin/getAllUsers/${centerFilter}`, options)
      .then(async res => {
        props.actions.clearLoading()
        if (res.status === 200) {
          const users = await res.json()
          setUsers(users)
        } else {
        }
      })
      .catch(err => {
        console.log(err)
        props.actions.clearLoading()
      })
  }

  const fetchCenters = () => {
    const options = {
      method: 'GET',
      headers: {
        Authorization: props.token,
        'Content-Type': 'application/json'
      }
    }
    msalFetch('/api/centerManagement/getCenters', options)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          setCenters(data)
        } else {
          toast.error('Failed to get all centers')
        }
      })
      .catch(err => {
        toast.error('Failed to get all centers')
        console.log(err)
      })
  }

  const generateRoleChips = roles => {
    let rolesArray = roles.split(';')
    let filteredRoles = props.allRoles.filter(x =>
      rolesArray.includes(String(x.role_id))
    )
    let content = filteredRoles.map(x => {
      return (
        <Tag key={x.role_name} className='my-1'>
          {x.role_name}
        </Tag>
      )
    })
    return content
  }

  const handleAddUserChange = e => {
    setNewUserData({ ...newUserData, [e.target.name]: e.target.value })
    setNewUserErrors({})
    if (e.target.name == 'username') {
      if (newUserErrors.username) {
        delete newUserErrors.username
      }
    }
  }

  const newUserValidation = () => {
    let errors = {}
    if (newUserData.cpassword !== newUserData.password) {
      errors.password = 'Passwords do not match'
    } else {
      if (errors.password) {
        delete errors.password
      }
    }

    if (newUserData.roles == undefined || newUserData.roles == '') {
      errors.roles = 'Please select a role'
    } else {
      if (errors.roles) {
        delete errors.roles
      }
    }
    setNewUserErrors({ ...errors })
    if (Object.keys(errors).length > 0) {
      return false
    } else {
      return true
    }
  }

  const handleNewUserRoleMultiSelect = (selectedList, selectedItem) => {
    const roles = selectedList.join(';')
    setNewUserErrors({})
    setNewUserData({ ...newUserData, roles })
  }

  const handleNewUserSubmit = e => {
    e.preventDefault()
    if (newUserValidation()) {
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.token
        },
        body: JSON.stringify(newUserData)
      }
      msalFetch('/api/admin/createUser', options)
        .then(async res => {
          if (res.ok) {
            setNewUserErrors({})
            setAddUserPopUp(false)
            toast.success('User created successfully')
            getAllUsers()
          } else if (res.status == 403) {
            setNewUserErrors({
              duplicateUsername:
                'Username already in use, please use a different username'
            })
          }
        })
        .catch(err => {})
    }
  }

  const deleteUser = e => {
    let selectedId = selectedUserId
    setSelectedUserId(null)
    props.actions.setLoading()
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      },
      body: JSON.stringify({ selectedId })
    }
    msalFetch('/api/admin/deleteUser', options)
      .then(async res => {
        props.actions.clearLoading()
        if (res.ok) {
          toast.success('User deleted successfully')
          getAllUsers()
        } else {
          toast.error('User deletion failed')
        }
      })
      .catch(err => {
        toast.error(err.message)
      })
  }

  const changeUserStatus = status => {
    props.actions.setLoading()
    let selectedId = selectedUserId
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      },
      body: JSON.stringify({ status, selectedId })
    }
    msalFetch('/api/admin/changeUserStatus', options)
      .then(async res => {
        props.actions.clearLoading()
        if (res.ok) {
          toast.success(`User ${status} successfully`)
          setSelectedUserId(null)
          getAllUsers()
        } else {
          toast.error('Unable to change user status')
          props.actions.clearLoading()
        }
      })
      .catch(err => {
        toast.error(err.message)
      })
  }

  const handleClickEdit = e => {
    setEditUserPopUp(true)
    const editUser = users.filter(x => x.userid == selectedUserId)[0]
    setEditUserData({
      first_name: editUser.first_name,
      last_name: editUser.last_name,
      userid: selectedUserId,
      roles: editUser.roles,
      center_code: editUser.center_code
    })
  }

  const editUserRoleChange = selectedList => {
    const roles = selectedList.join(';')
    setEditUserErrors({})
    setEditUserData({ ...editUserData, roles })
  }

  const getEditUserRoles = () => {
    const selectedUserRoles =
      editUserData.roles && editUserData.roles.split(';')
    const filteredRoles = rolesOptions.filter(x =>
      selectedUserRoles.includes(x.value.toString())
    )
    return filteredRoles
  }

  const handleEditUserChange = e => {
    setEditUserData({ ...editUserData, [e.target.name]: e.target.value })
    setEditUserErrors({})
  }

  const userEditValidation = () => {
    const errors = {}
    if (editUserData.roles == undefined || editUserData.roles == '') {
      errors.roles = 'Please select a role'
    } else {
      if (errors.roles) {
        delete errors.roles
      }
    }
    setEditUserErrors({ ...errors })
    if (Object.keys(errors).length > 0) {
      return false
    } else {
      return true
    }
  }

  const editUserSubmit = e => {
    e.preventDefault()
    if (userEditValidation) {
      console.log(editUserData)
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.token
        },
        body: JSON.stringify(editUserData)
      }
      msalFetch('/api/admin/editUser', options)
        .then(async res => {
          if (res.ok) {
            setEditUserErrors({})
            setEditUserPopUp(false)
            toast.success('User updated successfully')
            getAllUsers()
          }
        })
        .catch(err => {})
    }
  }

  const handleResetPassword = e => {
    props.actions.setLoading()
    e.preventDefault()
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      },
      body: JSON.stringify({ userId: selectedUserId, newPassword })
    }
    msalFetch('/api/admin/resetPassword', options)
      .then(async res => {
        props.actions.clearLoading()
        if (res.ok) {
          toast.success('Password reset successfully')
          setResetPasswordPopup(false)
          setNewPassword('')
        } else {
          toast.error('Password reset failed')
        }
      })
      .catch(err => {
        props.actions.clearLoading()
        toast.error(err)
      })
  }

  const getCenterDropDownOptions = () => {
    if (centers.length) {
      const a = centers.map(x => {
        return {
          label: x.center_code,
          value: x.center_code
        }
      })
      a.unshift({ label: 'All', value: 'All' })
      return a
    }
    return []
  }

  const userTableColumns = [
    {
      title: 'Center Code',
      key: 'center_code',
      dataIndex: 'center_code'
    },
    {
      title: 'UserName',
      key: 'username',
      dataIndex: 'username'
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email'
    },
    {
      title: 'First Name',
      key: 'first_name',
      dataIndex: 'first_name'
    },
    {
      title: 'Last Name',
      key: 'last_name',
      dataIndex: 'last_name'
    },
    {
      title: 'Roles',
      render: (_, record) => {
        return generateRoleChips(record.roles)
      }
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status'
    },
    {
      title: 'Actions',
      render: (_, record) => {
        return (
          <button
            className='btn'
            onClick={() => {
              setSelectedUserId(record.userid)
              setShowMenu(!showMenu)
            }}
            style={{ position: 'relative' }}
          >
            <FontAwesomeIcon icon={faEllipsisH}></FontAwesomeIcon>
            {showMenu && selectedUserId == record.userid ? (
              <div
                className={styles.dropDownMenu}
                ref={e => {
                  menuRef.current = e
                }}
              >
                <p
                  onClick={() => {
                    record.status == 'ACTIVE'
                      ? changeUserStatus('LOCKED')
                      : changeUserStatus('ACTIVE')
                  }}
                >
                  <FontAwesomeIcon
                    icon={record.status == 'ACTIVE' ? faLock : faUnlock}
                  ></FontAwesomeIcon>{' '}
                  {record.status == 'ACTIVE' ? 'Lock User' : 'Unlock User'}
                </p>
                <p onClick={handleClickEdit}>
                  <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon> Edit
                </p>
                <p onClick={deleteUser}>
                  <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon> Delete User
                </p>
              </div>
            ) : null}
          </button>
        )
      }
    }
  ]

  return (
    <div className='row justify-content-center'>
      <div className='col-md-11 my-4'>
        <h2 className='title2'>User Management</h2>
        <div className='row justify-content-between'>
          <div className='col-md-4 col-sm-12 d-flex justify-content-between align-items-center'>
            <label className='w-25'>Center Code:</label>
            <Select
              className='w-50'
              options={getCenterDropDownOptions()}
              value={centerFilter}
              onChange={(value)=>setCenterFilter(value)}
            ></Select>
            <Button type='primary' onClick={getAllUsers}>Apply</Button>
          </div>
          <div className='col-md-4 col-sm-12 d-flex justify-content-end'>
            <Button type='primary' onClick={() => setAddUserPopUp(true)}>
              Add User
            </Button>
          </div>
        </div>
        <Table
          dataSource={users}
          columns={userTableColumns}
          className='my-3'
          size='small'
        ></Table>
      </div>
      {addUserPopup ? (
        <Modal
          size='lg'
          show={addUserPopup}
          onHide={() => {
            setAddUserPopUp(false)
          }}
        >
          <Modal.Header closeButton>
            <h4 className='text-secondary p-0 m-0'>Add User</h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleNewUserSubmit}>
              <div className='form-group row justify-content-center mb-2'>
                <label for='firstname' className='col-sm-3 col-form-label'>
                  First Name :{' '}
                </label>
                <div className='col-sm-4'>
                  <input
                    type='text'
                    name='first_name'
                    onChange={handleAddUserChange}
                    className='form-control'
                    id='firstname'
                    required
                    placeholder='First Name'
                  />
                </div>
              </div>
              <div className='form-group row justify-content-center mb-2'>
                <label for='lasttname' className='col-sm-3 col-form-label'>
                  Last Name :{' '}
                </label>
                <div className='col-sm-4'>
                  <input
                    type='text'
                    name='last_name'
                    onChange={handleAddUserChange}
                    className='form-control'
                    id='lasttname'
                    required
                    placeholder='Last Name'
                  />
                </div>
              </div>
              <div className='form-group row justify-content-center mb-2'>
                <label for='email' className='col-sm-3 col-form-label'>
                  Email :{' '}
                </label>
                <div className='col-sm-4'>
                  <input
                    type='email'
                    name='email'
                    placeholder='Email'
                    onChange={handleAddUserChange}
                    className='form-control'
                    id='email'
                    required
                  />
                  {newUserErrors.duplicateUsername ? (
                    <p className='text-danger' style={{ fontSize: '14px' }}>
                      {newUserErrors.duplicateUsername}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className='form-group row justify-content-center mb-2'>
                <label for='center' className='col-sm-3 col-form-label'>
                  Center Code :{' '}
                </label>
                <div className='col-sm-4'>
                  <select
                    className='form-select'
                    aria-label='Default select example'
                    placeholder='Select Center'
                    name='center_code'
                    onChange={handleAddUserChange}
                  >
                    <option selected disabled>
                      select
                    </option>
                    {centers.length &&
                      centers.map(x => {
                        return (
                          <option value={x.center_code}>{x.center_code}</option>
                        )
                      })}
                  </select>
                  {newUserErrors.duplicateUsername ? (
                    <p className='text-danger' style={{ fontSize: '14px' }}>
                      {newUserErrors.duplicateUsername}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className='form-group row justify-content-center mb-2'>
                <label for='roles' className='col-sm-3 col-form-label'>
                  Roles :{' '}
                </label>
                <div className='col-sm-4'>
                  <Select
                    mode='multiple'
                    options={rolesOptions}
                    dropdownStyle={{ zIndex: '2000' }}
                    className='w-100'
                    size='large'
                    onChange={e => handleNewUserRoleMultiSelect(e)}
                  ></Select>
                  {newUserErrors.roles ? (
                    <p className='text-danger' style={{ fontSize: '14px' }}>
                      {newUserErrors.roles}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className='form-group row mb-2 mt-4'>
                <div className='col-sm-6 offset-3'>
                  <button
                    type='submit'
                    className='btn btn-outline-success'
                    style={{ width: '100%', height: '35px', padding: '0' }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      ) : null}
      {editUserPopup ? (
        <Modal
          size='lg'
          show={editUserPopup}
          onHide={() => {
            setEditUserPopUp(false)
          }}
        >
          <Modal.Header closeButton>
            <h4 className='text-secondary p-0 m-0'>Edit User</h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={editUserSubmit}>
              <div className='form-group row justify-content-center mb-2'>
                <label for='firstname' className='col-sm-3 col-form-label'>
                  First Name :{' '}
                </label>
                <div className='col-sm-4'>
                  <input
                    type='text'
                    name='first_name'
                    onChange={handleEditUserChange}
                    value={editUserData.first_name}
                    className='form-control'
                    id='firstname'
                    placeholder='First Name'
                    required
                  />
                </div>
              </div>
              <div className='form-group row justify-content-center mb-2'>
                <label for='lasttname' className='col-sm-3 col-form-label'>
                  Last Name :{' '}
                </label>
                <div className='col-sm-4'>
                  <input
                    type='text'
                    name='last_name'
                    onChange={handleEditUserChange}
                    value={editUserData.last_name}
                    className='form-control'
                    id='lasttname'
                    placeholder='Last Name'
                    required
                  />
                </div>
              </div>
              <div className='form-group row justify-content-center mb-2'>
                <label for='center' className='col-sm-3 col-form-label'>
                  Center Code :{' '}
                </label>
                <div className='col-sm-4'>
                  <select
                    className='form-select'
                    aria-label='Default select example'
                    placeholder='Select Center'
                    name='center_code'
                    onChange={handleEditUserChange}
                    value={editUserData.center_code}
                  >
                    <option disabled selected={!editUserData.center_code}>Select</option>
                    {centers.length &&
                      centers.map(x => {
                        return (
                          <option
                            value={x.center_code}
                            selected={
                              x.center_code === editUserData.center_code
                            }
                            key={x.center_code}
                          >
                            {x.center_code}
                          </option>
                        )
                      })}
                  </select>
                  {newUserErrors.duplicateUsername ? (
                    <p className='text-danger' style={{ fontSize: '14px' }}>
                      {newUserErrors.duplicateUsername}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className='form-group row justify-content-center mb-2'>
                <label for='roles' className='col-sm-3 col-form-label'>
                  Roles :{' '}
                </label>
                <div className='col-sm-4'>
                  <Select
                    mode='multiple'
                    options={rolesOptions}
                    dropdownStyle={{ zIndex: '2000' }}
                    className='w-100'
                    size='large'
                    defaultValue={() => getEditUserRoles()}
                    onChange={e => editUserRoleChange(e)}
                  ></Select>
                  {editUserErrors.roles ? (
                    <p className='text-danger' style={{ fontSize: '14px' }}>
                      {editUserErrors.roles}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className='form-group row mb-2 mt-4'>
                <div className='col-sm-6 offset-3'>
                  <button
                    type='submit'
                    className='btn btn-outline-success'
                    style={{ width: '100%', height: '35px', padding: '0' }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      ) : null}
      <Modal
        size='md'
        show={resetPasswordPopup}
        onHide={() => {
          setResetPasswordPopup(false)
          setNewPassword('')
          setSelectedUserId(null)
        }}
        centered
        ref={e => (resetPasswordRef.current = e)}
      >
        <Modal.Header closeButton>
          <h4 className='text-secondary p-0 m-0'>Reset Password</h4>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleResetPassword}>
            <div className='form-group row justify-content-center mb-2'>
              <label htmlFor='newPassword' className='col-sm-4 col-form-label'>
                New Password :{' '}
              </label>
              <div className='col-sm-6'>
                <input
                  type='text'
                  name='newPassword'
                  id='newPassword'
                  onChange={e => setNewPassword(e.target.value)}
                  value={newPassword}
                  className='form-control'
                  required
                />
              </div>
            </div>
            <div className='form-group row mb-2 mt-4'>
              <div className='col-sm-6 offset-3'>
                <button
                  type='submit'
                  className='btn btn-outline-success'
                  style={{ width: '100%', height: '35px', padding: '0' }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    userid: state.userid,
    username: state.username,
    token: state.token,
    isAuthenticated: state.isAuthenticated,
    roleid: state.roleid,
    rolename: state.rolename,
    allRoles: state.allRoles
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement)
