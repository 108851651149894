import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import SearchIcon from '../images/search.png'
import RegisterIcon from '../images/registration-complete.png'
import DonationsIcon from '../images/website-donation.png'
import PackingIcon from '../images/packing.png'
import ShippingIcon from '../images/shipping.png'
import adminIcon from '../images/administrator.png'
import LabtestIcon from '../images/lab-test.png'
import UploadIcon from '../images/upload.png'
import ReportIcon from '../images/report.png'
import BarcodeIcon from '../images/barcode.png'
import HealthCheck from '../images/health-check.png'
import { NavLink } from 'react-router-dom'
import LabResult from '../images/lab-test3.png'
import DonorReport from '../images/donor-report.png'
import KPIReport from '../images/kpi.png'
import Queue from '../images/queue.png'
import DeviationForm from '../images/deviation-form.png'
import CenterManagement from '../images/centermanagement.png'

const dashboardStyles = {
  content: {
    fontFamily: 'Font-Medium'
  },
  cardStyles: {
    cursor: 'pointer'
  }
}

const routes = {
  search: {
    title: 'Donor Search',
    icon: SearchIcon,
    url: '/advancedSearch',
    page: 'advancedSearch'
  },
  register: {
    title: 'Register new Donor',
    icon: RegisterIcon,
    url: '/donor/register',
    page: 'donorRegistration'
  },
  scanUCN: {
    title: 'Scan UCN',
    icon: BarcodeIcon,
    url: '/scanUCN',
    page: 'scanUCN'
  },
  donations: {
    title: 'Donations',
    icon: DonationsIcon,
    url: '/donations',
    page: 'donations'
  },
  cases: {
    title: 'Cases Report',
    icon: ReportIcon,
    url: '/cases',
    page: 'cases'
  },
  casePacking: {
    title: 'Case Packing',
    icon: PackingIcon,
    url: '/casePacking/undefined',
    page: 'casePacking'
  },
  ucnLocationUpdate: {
    title: 'UCN Location Update',
    icon: ShippingIcon,
    url: '/ucnLocationUpdate',
    page: 'ucnLocationUpdate'
  },
  laborder: {
    title: 'Lab Order Entry',
    icon: LabtestIcon,
    url: '/labOrder',
    page: 'labOrder'
  },
  cdcsReport: {
    title: 'CDCS Report',
    icon: ReportIcon,
    url: '/cdcsReport',
    page: 'cdcsReport'
  },
  labOrderReport: {
    title: 'Lab Order Report',
    icon: ReportIcon,
    url: '/labOrderReport',
    page: 'labOrderReport'
  },
  uploadLabResults: {
    title: 'Upload Lab Results',
    icon: UploadIcon,
    url: '/uploadLabResults',
    page: 'uploadLabResults'
  },
  userManagement: {
    title: 'User Management',
    icon: adminIcon,
    url: '/admin/userManagement',
    page: 'userManagement'
  },
  healthAssessments: {
    title: 'Health Assessments',
    icon: HealthCheck,
    url: '/healthAssessments',
    page: 'healthAssessments'
  },
  labResultsReport: {
    title: 'Lab Results',
    icon: LabResult,
    url: '/labResultsReport',
    page: 'labResultsReport'
  },
  donorsReport: {
    title: 'Donor Report',
    icon: DonorReport,
    url: '/donorReport',
    page: 'donorReport'
  },
  donorEntry: {
    title: 'Donor Entry',
    icon: RegisterIcon,
    url: '/donorEntry',
    page: 'donorEntry'
  },
  donorUpload: {
    title: 'Donor Upload',
    icon: UploadIcon,
    url: '/donorUpload',
    page: 'donorUpload'
  },
  shippingSummaryReport: {
    title: 'Shipping Summary Report',
    icon: ReportIcon,
    url: '/shippingSummaryReport',
    page: 'shippingSummaryReport'
  },
  shippingDetailsReport: {
    title: 'Shipping Details Report',
    icon: ReportIcon,
    url: '/shippingDetailsReport',
    page: 'shippingDetailsReport'
  },
  kpiReport: {
    title: 'Center Performance Report',
    icon: KPIReport,
    url: '/kpiReport',
    page: 'kpiReport'
  },
  donorCenterQueue: {
    title: 'Donor Center Queue',
    icon: Queue,
    url: '/donorCenterQueue',
    page: 'donorCenterQueue'
  },
  deviationForm: {
    title: 'Deviation Form',
    icon: DeviationForm,
    url: '/deviationForm',
    page: 'deviationForm'
  },
  DeviationReports: {
    title: 'Deviation Reports',
    icon: ReportIcon,
    url: '/deviationReports',
    page: 'deviationReports'
  },
  centerManagement: {
    title: 'Center Management',
    icon: CenterManagement,
    url: '/centerManagement',
    page: 'centerMangement'
  }
}

function Dashboard (props) {
  const [links, setLinks] = useState([])

  useEffect(() => {
    if (props.roleid) {
      const {
        search,
        register,
        scanUCN,
        donations,
        cases,
        casePacking,
        ucnLocationUpdate,
        laborder,
        cdcsReport,
        labOrderReport,
        uploadLabResults,
        userManagement,
        healthAssessments,
        labResultsReport,
        donorsReport,
        donorUpload,
        shippingSummaryReport,
        shippingDetailsReport,
        kpiReport,
        donorCenterQueue,
        deviationForm,
        DeviationReports,
        centerManagement
      } = routes
      let links = []
      if (props.roleid == 1) {
        links = [
          search,
          register,
          scanUCN,
          cdcsReport,
          donorsReport,
          donorUpload,
          kpiReport,
          donorCenterQueue
        ]
      } else if (props.roleid == 2) {
        links = [
          search,
          scanUCN,
          laborder,
          labOrderReport,
          donorsReport,
          kpiReport
        ]
      } else if (props.roleid == 3) {
        links = [
          search,
          register,
          scanUCN,
          donations,
          cases,
          casePacking,
          ucnLocationUpdate,
          cdcsReport,
          laborder,
          labOrderReport,
          labResultsReport,
          donorsReport,
          shippingSummaryReport,
          shippingDetailsReport,
          kpiReport,
          donorCenterQueue,
          deviationForm,
          DeviationReports,
        ]
      } else if (props.roleid == 6) {
        links = [uploadLabResults]
      } else if (props.roleid == 4) {
        links = [userManagement, kpiReport, centerManagement]
      } else if (props.roleid == 5) {
        links = [cases, casePacking, kpiReport]
      } else if (props.roleid == 7) {
        links = [
          search,
          scanUCN,
          cdcsReport,
          healthAssessments,
          labResultsReport,
          laborder,
          labOrderReport,
          donorsReport,
          kpiReport
        ]
      } else if (props.roleid === 8) {
        links = [
          search,
          scanUCN,
          healthAssessments,
          labResultsReport,
          donorsReport,
          kpiReport
        ]
      } else {
        links = [search, scanUCN, donorsReport, kpiReport]
      }
      setLinks([...links])
    }
  }, [props.roleid])

  const handleClick = page => {
    props.actions.setPage(page)
  }

  return (
    <div className='row d-flex justify-content-center mt-3'>
      <div className='col-md-10 col-sm-12 p-4' style={dashboardStyles.content}>
        <div className='row '>
          {links.length != 0 &&
            links.map(x => {
              return (
                <div className='col-sm-2 m-md-3 m-lg-3 mx-sm-0 my-3'>
                  <NavLink to={x.url} style={{ color: '#24292f' }}>
                    <div
                      className='card p-2'
                      style={dashboardStyles.cardStyles}
                      onClick={handleClick}
                    >
                      <div className='card-body text-center'>
                        <img src={x.icon}></img>
                      </div>
                      <div className='card-footer'>
                        <h5 className='card-text text-center'>{x.title}</h5>
                      </div>
                    </div>
                  </NavLink>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token,
    roleid: state.roleid,
    rolename: state.rolename
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
