import {
  useIsAuthenticated,
  useMsal,
  MsalAuthenticationTemplate,
  withMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from '@azure/msal-react'
import { InteractionStatus, InteractionType } from '@azure/msal-browser'
import React, { useEffect } from 'react'
import App from './App'
import { loginRequest } from './config/authConfig'
import { connect } from 'react-redux'
import * as actions from './redux/actions'
import { bindActionCreators } from 'redux'
import Loader from './components/Loader'
import { Switch, Route } from 'react-router-dom'
import DonorDHQ from './modules/DonorDHQ'

const AuthWrapper = props => {
  const isAuthenticated = useIsAuthenticated()
  const { instance, accounts, inProgress } = useMsal()

  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      const activeAccount = instance.getActiveAccount()
      const displayName = activeAccount.name
      const email = activeAccount.username
      const username = email.split('@')[0]
      props.actions.setLogin({ displayName, email, username })
    }
  }, [isAuthenticated, inProgress])

  function ErrorComponent ({ error }) {
    return <p>An Error Occurred: {error}</p>
  }

  function LoadingComponent () {
    return <Loader />
  }

  const WithAuth = withMsal(App)

  const AuthWrappedComponent = () => {
    return (
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}
      >
        <WithAuth />
      </MsalAuthenticationTemplate>
    )
  }

  return (
    <>
      <Switch>
        <Route exact path='/DHQ' component={DonorDHQ}></Route>
        <Route path='/*' component={AuthWrappedComponent}></Route>
      </Switch>
    </>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(null, mapDispatchToProps)(AuthWrapper)
