import React, { useState } from "react";
import DynamicForm from "../../../shared/FormInputs/DynamicInput";
import { request } from "../../../utilities/msalFetch";
import { toast } from "react-toastify";
import useEmpNames from "../../../hooks/useEmpNames";

const FollowUpForm = ({ mirId, onClose, setFollowUp, followUps = [] }) => {
  const { empNames } = useEmpNames();
  const fields = [
    { name: "date", label: "Date", type: "date" },
    { name: "time", label: "Time", type: "time" },
    { name: "personContacted", label: "Person Contacted", type: "text" },
    {
      name: "informationReceived",
      label: "Information Received",
      type: "text",
    },
    {
      name: "donorCondition",
      label: "Donor Condition",
      type: "select",
      options: ["Improved", "Same", "Worsened", "Unknown"],
    },
    {
      name: "donorInstructed",
      label: "Donor instructed to call center?",
      type: "select",
      options: ["Yes", "No", "NA"],
    },
    { name: "performedBy", label: "Performed By", type: "select" },
  ];

  const initialState = {
    date: "",
    time: "",
    personContacted: "",
    informationReceived: "",
    donorCondition: "",
    donorInstructed: "",
    performedBy: "",
  };

  const [formData, setFormData] = useState([initialState]);

  const handleFormChange = (updatedFormData) => {
    setFormData(updatedFormData);
  };

  const validateForm = () => {
    for (const row of formData) {
      for (const key in row) {
        if (row[key].toString()?.trim() === "") {
          toast.error("Follow Up information required.");
          return false;
        }
      }
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }
    const allFOllowUps = followUps ? [...formData, ...followUps] : formData;
    try {
      await request.put(`/api/mir/${mirId}`, {
        followUp: allFOllowUps,
      });
      toast.success("Follow up added.");
      setFollowUp(JSON.stringify(allFOllowUps));
      onClose();
    } catch (error) {
      toast.error("Failed to add Follow up");
    }
  };

  return (
    <>
      <DynamicForm
        formData={formData}
        onChange={handleFormChange}
        fields={fields}
        initialState={initialState}
        maxRows={3}
        options={{ performedBy: empNames }}
      />

      <div className="mt-2">
        <button className="btn btn-primary" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </>
  );
};

export default FollowUpForm;
