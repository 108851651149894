import { loginRequest } from "../config/authConfig";
import { msalInstance as instance } from "../../index";
import { store } from "../../index";
import axios from 'axios';

export const msalFetch = async (url, options) => {
  try {
    if (instance && instance.getActiveAccount()) {
      let tokenResponse = await instance
        .acquireTokenSilent(loginRequest)
        .catch((err) => {
          if (err.name === "InteractionRequiredAuthError") {
            return instance.acquireTokenRedirect(loginRequest)
              .then(response => {
                tokenResponse = response;
              })
              .catch(err => {
                throw new Error('Token request failed')
              });
          }
        });
      const token = tokenResponse?.accessToken;
      const headers = options.headers || {};
      //headers["Content-Type"] = "application/json";
      const reduxState = store.getState();
      const userRole = reduxState.roleid;
      const centerCode = reduxState.centerCode;
      headers["Authorization"] = `Bearer ${token}`;
      headers["User-Role"] = userRole;
      headers["Center-Code"] = centerCode;
      options.headers = headers;
      const res = await fetch(url, options);
      return res;
    } else {
      instance.loginRedirect(loginRequest);
    }
  } catch (err) {
    console.log(err)
    return new Error(err.message);
  }
};

export const request = axios.create();

request.interceptors.request.use(
  async (config) => {
    try {
      if (instance && instance.getActiveAccount()) {
        let tokenResponse = await instance
          .acquireTokenSilent(loginRequest)
          .catch(async (err) => {
            if (err.name === "InteractionRequiredAuthError") {
              return instance.acquireTokenRedirect(loginRequest)
                .then(response => response)
                .catch(err => {
                  throw new Error('Token request failed');
                });
            }
          });

        const token = tokenResponse?.accessToken;
        const reduxState = store.getState();
        const userRole = reduxState.roleid;
        const centerCode = reduxState.centerCode;

        // Attach Authorization and User-Role headers to the request
        config.headers["Authorization"] = `Bearer ${token}`;
        config.headers["User-Role"] = userRole;
        config.headers["Center-Code"] = centerCode;
      } else {
        instance.loginRedirect(loginRequest);
      }
      return config;
    } catch (err) {
      console.log(err);
      return Promise.reject(new Error(err.message));
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);
