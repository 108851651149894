import React, { useState, useEffect } from 'react'
import FDHQ from './FDHQ'
import RDHQ from './RDHQ'
import { useHistory } from 'react-router-dom'
import styles from '../styles/DIS.module.css'
import {
  checkDonorEligibility,
  checkValidUCN
} from '../utilities/validations.js'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import { Button, Input, Select, Modal } from 'antd'
import { toast } from 'react-toastify'
import { msalFetch } from '../utilities/msalFetch'
import { Alert } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons'

const { TextArea } = Input

function DHQ (props) {
  const [dhqType, setDhqType] = useState('')
  const [loading, setLoading] = useState(true)
  const [ucn, setUCN] = useState('')
  const [CDCS, setCDCS] = useState('')
  const [cdcs_check, setCDCSpass] = useState(null)
  const [NDDR, setNDDR] = useState(null)
  const [nddrCheck, setNDDRCheck] = useState(null)
  const [screening_comments, setScreeningComments] = useState('')
  const [errors, setErrors] = useState({})
  const [noDonationConfirmModalOpen, setNoDonationConfirmModalOpen] =
    useState(false)
  const [nddrFailSubmitModalOpen, setNDDRFailSubmitModalOpen] = useState(false)
  const [disableNDDRfields, setDisableNDDRfields] = useState(false)
  const [disableCDCSfields, setDisableCDCSfields] = useState(false)
  const [duplicateUCN, setDuplicateUCN] = useState(false)
  const [formDisabled, setFormDisabled] = useState(false)

  const history = useHistory()

  const requireNDDR = ['NEW', 'APPLICANT 1']

  useEffect(() => {
    if (props.donorInfo.donor_id) {
      const { eligible, reason } = checkDonorEligibility(
        props.donorInfo.dob,
        props.donorInfo.vein_grade_left,
        props.donorInfo.vein_grade_right,
        props.donorInfo.nddr_pass,
        props.donorInfo.address_verified,
        props.donorInfo.donor_status
      )
      if (eligible || sessionStorage.getItem('selectedUCN')) {
        props.actions.setLoading()
        if (sessionStorage.getItem('selectedUCN')) {
          const ucn = sessionStorage.getItem('selectedUCN')
          const options = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: props.token
            }
          }
          msalFetch(`/api/donor/getDHQByUCN/${ucn}`, options).then(
            async response => {
              props.actions.clearLoading()
              if (response.status === 200) {
                const data = await response.json()
                if (data.length) {
                  setDhqType(data[0].DHQ_TYPE)
                }
              } else {
                let donorType = props.donorInfo.donor_type
                if (
                  donorType == 'APPLICANT 1' ||
                  donorType == 'APPLICANT 2' ||
                  donorType == 'NEW'
                ) {
                  setDhqType('FDHQ')
                } else {
                  setDhqType('RDHQ')
                }
              }
            }
          )
        } else {
          const options = {
            method: 'GET',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: props.token
            }
          }
          const url = `/api/donor/getLatestDHQStatus/${props.donorInfo.donor_id}`
          msalFetch(url, options)
            .then(async res => {
              props.actions.clearLoading()
              if (res.ok) {
                const data = await res.json()
                if (data.length) {
                  setDhqType(data[0].DHQ_TYPE)
                } else {
                  let donorType = props.donorInfo.donor_type
                  if (
                    donorType == 'APPLICANT 1' ||
                    donorType == 'APPLICANT 2' ||
                    donorType == 'NEW'
                  ) {
                    setDhqType('FDHQ')
                  } else {
                    setDhqType('RDHQ')
                  }
                }
              } else {
                let donorType = props.donorInfo.donor_type
                if (
                  donorType == 'APPLICANT 1' ||
                  donorType == 'APPLICANT 2' ||
                  donorType == 'NEW'
                ) {
                  setDhqType('FDHQ')
                } else {
                  setDhqType('RDHQ')
                }
              }
            })
            .catch(async err => {
              props.clearLoading()
              let donorType = props.donorInfo.donor_type
              if (
                donorType == 'APPLICANT 1' ||
                donorType == 'APPLICANT 2' ||
                donorType == 'NEW'
              ) {
                setDhqType('FDHQ')
              } else {
                setDhqType('RDHQ')
              }
              console.error(err)
            })
        }
      }
    }
  }, [props.donorInfo])

  /*   useEffect(() => {
    props.getData();
  }, []); */

  useEffect(() => {
    if (props.drdData != undefined) {
      setLoading(false)
      if (props.drdData.ucn) {
        setUCN(props.drdData.ucn)
        setCDCS(props.drdData.CDCS)
        setCDCSpass(props.drdData.cdcs_check)
        setScreeningComments(props.drdData.screening_comments)
        setNDDR(props.drdData.nddr)
        setNDDRCheck(props.drdData.nddr_check)
      }
      if (props.roleid == 7) {
        if (props.drdData && props.drdData.status == 'Pending QA Review') {
          setFormDisabled(true)
        } else {
          setFormDisabled(false)
        }
      } else {
        if (
          (props.drdData &&
            props.drdData.weight &&
            props.drdData.screening_time) ||
          (props.drdData.status && props.drdData.status !== 'Pending')
        ) {
          setFormDisabled(true)
        } else {
          setFormDisabled(false)
        }
      }
    }
  }, [props.drdData])

  const checkDuplicateUCN = ucn => {
    const promise = new Promise((resolve, reject) => {
      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.token
        }
      }
      msalFetch(`api/donor/checkDuplicateUCN/${ucn}`, options)
        .then(async res => {
          if (res.ok) {
            resolve(res.json())
          } else {
            console.error(res)
            reject(true)
          }
        })
        .catch(err => {
          console.error(err)
        })
    })
    return promise
  }

  const handleChange = async e => {
    const name = e.target.name
    if (name === 'ucn') {
      setUCN(e.target.value.trim())
      if (e.target.value !== '') {
        if (checkValidUCN(e.target.value.trim())) {
          if (await checkDuplicateUCN(e.target.value)) {
            setErrors({ ...errors, ucn: 'Duplicate UCN' })
            setDuplicateUCN(true)
          } else {
            let temp = { ...errors }
            delete temp.ucn
            setErrors(temp)
            setDuplicateUCN(false)
          }
        } else {
          setErrors({ ...errors, ucn: 'Invalid UCN' })
        }
      } else {
        let temp = { ...errors }
        delete temp.ucn
        setErrors(temp)
      }
    }
    if (name === 'CDCS') {
      setCDCS(e.target.value)
      let temp = { ...errors }
      delete temp.cdcs
      setErrors(temp)
    }
    if (name === 'NDDR') {
      setNDDR(e.target.value)
      let temp = { ...errors }
      delete temp.nddr
      setErrors(temp)
      if ((e.target.value = '')) {
        setCDCS(null)
        setCDCSpass(null)
      }
    }
    if (name == 'screening_comments') {
      setScreeningComments(e.target.value)
      let temp = { ...errors }
      delete temp.screening_comments
      setErrors(temp)
    }
  }

  const setError = (parameter, message) => {
    setErrors({ ...errors, [parameter]: message })
  }

  const validateInputs = () => {
    let formErrors = {}
    if (requireNDDR.includes(props.donorInfo.donor_type)) {
      if (!ucn || ucn === '') {
        formErrors = { ...formErrors, ucn: 'UCN is required' }
      } else if (!NDDR || NDDR === '') {
        formErrors = { ...formErrors, nddr: 'NDDR is required' }
      } else if (!nddrCheck || nddrCheck == '') {
        formErrors = { ...formErrors, nddrCheck: 'NDDR check is required' }
      } else {
        if (nddrCheck === 'Pass' && NDDR) {
          if (!CDCS) {
            formErrors = { ...formErrors, cdcs: 'CDCS Inquiry ID is required' }
          } else if (!cdcs_check) {
            formErrors = {
              ...formErrors,
              cdcs_check: 'CDCS check must be done'
            }
          } else if (
            CDCS &&
            cdcs_check &&
            cdcs_check === 'No' &&
            !screening_comments
          ) {
            formErrors = {
              ...formErrors,
              screening_comments: 'Please input comments'
            }
          }
          if (Object.keys(formErrors).length) {
            setErrors(formErrors)
          } else {
            if (cdcs_check === 'No') {
              setNoDonationConfirmModalOpen(true)
            }
          }
        } else if (NDDR && nddrCheck && nddrCheck === 'Fail') {
          if (!screening_comments) {
            formErrors = {
              ...formErrors,
              screening_comments: 'Please enter comments'
            }
          }
          if (Object.keys(formErrors).length) {
            setErrors(formErrors)
            return
          } else {
            setNDDRFailSubmitModalOpen(true)
          }
        }
      }
      if (Object.keys(formErrors).length) {
        setErrors(formErrors)
      }
    } else {
      if (!ucn) {
        formErrors = { ...formErrors, ucn: 'UCN is required' }
      } else if (!CDCS) {
        formErrors = { ...formErrors, cdcs: 'CDCS Inquiry ID is required' }
      } else if (!cdcs_check) {
        formErrors = { ...formErrors, cdcs_check: 'CDCS check must be done' }
      } else if (
        CDCS &&
        cdcs_check &&
        cdcs_check === 'No' &&
        !screening_comments
      ) {
        formErrors = {
          ...formErrors,
          screening_comments: 'Please input comments'
        }
      }
      if (Object.keys(formErrors).length) {
        setErrors(formErrors)
      } else {
        if (cdcs_check === 'No') {
          setNoDonationConfirmModalOpen(true)
        }
      }
    }
  }

  const handleNoDonation = () => {
    props.actions.setLoading()
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      },
      body: JSON.stringify({
        ucn: ucn,
        donor_id: props.donorInfo.donor_id,
        cdcs_check,
        CDCS,
        screening_comments,
        NDDR,
        nddrCheck
      })
    }
    msalFetch(`api/donor/noDonation/DHQ`, options)
      .then(async response => {
        props.actions.clearLoading()
        const data = await response.json()
        if (response.ok) {
          toast.success(data.message)
          setNoDonationConfirmModalOpen(false)
          history.push('/dashboard')
        } else {
          toast.error(data.message)
        }
      })
      .catch(err => {
        toast.error(err.message)
        props.actions.clearLoading()
      })
  }

  const performCDCScheck = () => {
    props.actions.setLoading()
    let donorId = props.donorInfo.donor_id
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      },
      body: JSON.stringify({
        donorId
      })
    }
    msalFetch('/api/donor/cdcsCheck', options)
      .then(async res => {
        props.actions.clearLoading()
        if (!res.ok) {
          toast.error('Error while performing CDCS check')
        } else {
          setDisableCDCSfields(true)
          const data = await res.json()
          setCDCS(data.inquiryReferenceNumber)
          if (data.donations.length == 0) {
            setCDCSpass('Yes')
          } else {
            const matches = data.donations.find(x => {
              return x.match == 'EXACT' || x.match == 'PARTIAL'
            })
            if (matches) {
              setCDCSpass('No')
            } else {
              setCDCSpass('Yes')
            }
          }
        }
      })
      .catch(e => {
        console.log(e)
        props.actions.clearLoading()
        toast.error('Error while performing CDCS check')
      })
  }

  const handleNDDRfail = () => {
    if (NDDR && nddrCheck == 'Fail' && screening_comments) {
      const body = {
        donor_id: props.donorInfo.donor_id,
        ucn,
        nddr: NDDR,
        nddr_check: nddrCheck,
        screening_comments
      }
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.token
        },
        body: JSON.stringify(body)
      }
      props.actions.setLoading()
      msalFetch('api/donor/nddrFail', options)
        .then(async res => {
          props.actions.clearLoading()
          if (res.ok) {
            const data = await res.json()
            toast.success(data.msg)
            props.getDonorInfo()
            props.setPage('DI')
          } else {
            toast.error('Failed to update')
          }
        })
        .catch(err => {
          props.actions.clearLoading()
          toast.error(err)
        })
    } else {
      toast.error('Please fill all the details')
    }
  }

  const performNDDRCheck = e => {
    e.preventDefault()
    e.stopPropagation()
    props.actions.setLoading()
    if (props.donorInfo.donor_id) {
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.token
        },
        body: JSON.stringify({ donorId: props.donorInfo.donor_id })
      }
      msalFetch('/api/donor/nddrCheck', options)
        .then(async response => {
          props.actions.clearLoading()
          const data = await response.json()
          if (!response.ok) {
            toast.error('Error while performing NDDR check')
          } else {
            setDisableNDDRfields(true)
            const { inquiryReferenceNumber, inquiryResult, timeStamp } = data
            setNDDR(inquiryReferenceNumber)
            if (inquiryResult === 'MAT') {
              setNDDRCheck('Fail')
            } else if (inquiryResult === 'NOT') {
              setNDDRCheck('Pass')
            } else {
              toast.error(
                'Error while performing NDDR check, retry again later'
              )
            }
          }
        })
        .catch(e => {
          console.log(e)
          props.actions.clearLoading()
          toast.error('Error while performing NDDR check')
        })
    } else {
      toast.warning('Donor SSN is required to perform NDDR check')
    }
  }

  const content = () => {
    if (props.donorInfo?.donor_id) {
      if (!props.hardStop.result) {
        return (
          <div className='row justify-content-center mt-5'>
            <div className='col-sm-10'>
              <div className='row justify-content-sm-center'>
                <div className='col-sm-12 col-md-6'>
                  <Alert
                    message='3X7 rule validation Failed'
                    type='error'
                    showIcon
                    description={props.hardStop?.message}
                  ></Alert>
                </div>
              </div>
            </div>
          </div>
        )
      } else {
        const { eligible, reason } = checkDonorEligibility(
          props.donorInfo.dob,
          props.donorInfo.vein_grade_left,
          props.donorInfo.vein_grade_right,
          props.donorInfo.nddr_pass,
          props.donorInfo.address_verified,
          props.donorInfo.donor_status
        )
        if (eligible || sessionStorage.getItem('selectedUCN')) {
          return (
            <div className='row justify-content-center'>
              <div className='col-sm-10'>
                <div className='row justify-content-sm-center'>
                  <div className='col-sm-12 col-md-6'>
                    <div className='row d-flex mb-2 mt-3'>
                      <div className='col-sm-4 d-flex align-items-center'>
                        <label htmlFor='ucn'>
                          UCN<span style={{ color: 'red' }}>&#42;</span> :{' '}
                        </label>
                      </div>
                      <div className='col-sm-8'>
                        <Input
                          size='default'
                          id='ucn'
                          placeholder='Scan UCN'
                          name='ucn'
                          onChange={handleChange}
                          value={ucn}
                          disabled={formDisabled || props.drdData.ucn}
                        />
                        {errors.ucn && (
                          <p className='invalidInput'>{errors.ucn}</p>
                        )}
                      </div>
                    </div>
                    {requireNDDR.includes(props.donorInfo.donor_type) ? (
                      <>
                        <div className='row d-flex my-2'>
                          <div className='col-sm-4 d-flex align-items-center'>
                            <label htmlFor='cdcs_inquiry_id'>
                              NDDR Inquiry ID
                              <span style={{ color: 'red' }}>&#42;</span> :{' '}
                            </label>
                          </div>
                          <div className='col-sm-5'>
                            <Input
                              size='default'
                              id='cdcs_inquiry_id'
                              placeholder='NDDR Inquiry ID'
                              onChange={handleChange}
                              name='NDDR'
                              value={NDDR}
                              disabled={disableNDDRfields || formDisabled}
                            />
                            {errors.nddr && (
                              <p className='invalidInput'>{errors.nddr}</p>
                            )}
                          </div>
                          <div className='col-sm-3'>
                            {/* {props.donorInfo?.donor_type === 'NEW' ||
                            props.donorInfo.donor_type === 'APPLICANT 1' ? (
                              <Button
                                type='primary'
                                className='w-100 px-0'
                                onClick={performNDDRCheck}
                                disabled={disableNDDRfields || formDisabled}
                              >
                                <FontAwesomeIcon
                                  icon={faArrowAltCircleDown}
                                  className='mx-1'
                                />
                                NDDR Check
                              </Button>
                            ) : null} */}
                          </div>
                        </div>
                        <div className='row d-flex my-2'>
                          <div className='col-sm-4 d-flex align-items-center'>
                            <label htmlFor='cdcs_check'>
                              NDDR Check
                              <span style={{ color: 'red' }}>&#42;</span> :{' '}
                            </label>
                          </div>
                          <div className='col-sm-6'>
                            <Select
                              options={[
                                {
                                  value: 'Pass',
                                  label: 'Pass'
                                },
                                {
                                  value: 'Fail',
                                  label: 'Fail'
                                }
                              ]}
                              onChange={value => {
                                setNDDRCheck(value)
                                if (value == 'Fail') {
                                  setCDCS(null)
                                  setCDCSpass(null)
                                }
                              }}
                              placeholder='Select CDCS Check Result'
                              className='w-50'
                              value={nddrCheck}
                              disabled={disableNDDRfields || formDisabled}
                            ></Select>
                            {errors.nddrCheck && (
                              <p className='invalidInput'>{errors._check}</p>
                            )}
                          </div>
                        </div>
                      </>
                    ) : null}
                    {requireNDDR.includes(props.donorInfo.donor_type) ? (
                      NDDR && nddrCheck == 'Pass' ? (
                        <>
                          <div className='row d-flex my-2'>
                            <div className='col-sm-4 d-flex align-items-center'>
                              <label htmlFor='cdcs_inquiry_id'>
                                CDCS Inquiry ID
                                <span style={{ color: 'red' }}>
                                  &#42;
                                </span> :{' '}
                              </label>
                            </div>
                            <div className='col-sm-5'>
                              <Input
                                size='default'
                                id='cdcs_inquiry_id'
                                placeholder='CDCS Inquiry ID'
                                onChange={handleChange}
                                name='CDCS'
                                value={CDCS}
                                disabled={formDisabled}
                              />
                              {errors.cdcs && (
                                <p className='invalidInput'>{errors.cdcs}</p>
                              )}
                            </div>
                            <div className='col-sm-3'>
                              {/* {props.donorInfo?.donor_type === 'NEW' ||
                              props.donorInfo.donor_type === 'APPLICANT 1' ? (
                                <Button
                                  type='primary'
                                  className='w-100 px-0'
                                  onClick={performCDCScheck}
                                  disabled={formDisabled}
                                >
                                  <FontAwesomeIcon
                                    icon={faArrowAltCircleDown}
                                    className='mx-1'
                                  />
                                  CDCS Check
                                </Button>
                              ) : null} */}
                            </div>
                          </div>
                          <div className='row d-flex my-2'>
                            <div className='col-sm-4 d-flex align-items-center'>
                              <label htmlFor='cdcs_check'>
                                CDCS Pass
                                <span style={{ color: 'red' }}>
                                  &#42;
                                </span> :{' '}
                              </label>
                            </div>
                            <div className='col-sm-6'>
                              <Select
                                options={[
                                  {
                                    value: 'Yes',
                                    label: 'Yes'
                                  },
                                  {
                                    value: 'No',
                                    label: 'No'
                                  }
                                ]}
                                onChange={value => {
                                  setCDCSpass(value)
                                }}
                                placeholder='Select CDCS Check Result'
                                className='w-50'
                                value={cdcs_check}
                                disabled={formDisabled}
                              ></Select>
                              {errors.cdcs_check && (
                                <p className='invalidInput'>
                                  {errors.cdcs_check}
                                </p>
                              )}
                            </div>
                          </div>
                        </>
                      ) : NDDR && nddrCheck == 'Fail' ? (
                        <>
                          <div className='row d-flex my-2'>
                            <div className='col-sm-4'>
                              <label htmlFor='screening_comments'>
                                Comments
                                <span style={{ color: 'red' }}>&#42;</span> :
                              </label>
                            </div>
                            <div className='col-sm-8'>
                              <TextArea
                                className='w-100'
                                rows={3}
                                onChange={handleChange}
                                name='screening_comments'
                                value={screening_comments}
                              ></TextArea>
                              {errors.screening_comments && (
                                <p className='invalidInput'>
                                  {errors.screening_comments}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className='row d-flex my-2'>
                            <div className='col-sm-3'>
                              <Button
                                type='primary'
                                className='w-100'
                                danger
                                onClick={validateInputs}
                              >
                                Submit
                              </Button>
                            </div>
                          </div>
                        </>
                      ) : null
                    ) : (
                      <>
                        <div className='row d-flex my-2'>
                          <div className='col-sm-4 d-flex align-items-center'>
                            <label htmlFor='cdcs_inquiry_id'>
                              CDCS Inquiry ID
                              <span style={{ color: 'red' }}>&#42;</span> :{' '}
                            </label>
                          </div>
                          <div className='col-sm-5'>
                            <Input
                              size='default'
                              id='cdcs_inquiry_id'
                              placeholder='CDCS Inquiry ID'
                              onChange={handleChange}
                              name='CDCS'
                              value={CDCS}
                              disabled={disableCDCSfields || formDisabled}
                            />
                            {errors.cdcs && (
                              <p className='invalidInput'>{errors.cdcs}</p>
                            )}
                          </div>
                          <div className='col-sm-3'>
                            {/* <Button
                            type="primary"
                            className="w-100 px-0"
                            onClick={performCDCScheck}
                            disabled={disableCDCSfields}
                          >
                            <FontAwesomeIcon
                              icon={faArrowAltCircleDown}
                              className="mx-1"
                            />
                            CDCS Check
                          </Button> */}
                          </div>
                        </div>
                        <div className='row d-flex my-2'>
                          <div className='col-sm-4 d-flex align-items-center'>
                            <label htmlFor='cdcs_check'>
                              CDCS Pass
                              <span style={{ color: 'red' }}>&#42;</span> :{' '}
                            </label>
                          </div>
                          <div className='col-sm-6'>
                            <Select
                              options={[
                                {
                                  value: 'Yes',
                                  label: 'Yes'
                                },
                                {
                                  value: 'No',
                                  label: 'No'
                                }
                              ]}
                              onChange={value => {
                                setCDCSpass(value)
                              }}
                              placeholder='Select CDCS Check Result'
                              className='w-50'
                              value={cdcs_check}
                              disabled={disableCDCSfields || formDisabled}
                            ></Select>
                            {errors.cdcs_check && (
                              <p className='invalidInput'>
                                {errors.cdcs_check}
                              </p>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {CDCS && cdcs_check && cdcs_check == 'No' ? (
                      <>
                        <div className='row d-flex my-2'>
                          <div className='col-sm-4'>
                            <label htmlFor='screening_comments'>
                              Comments
                              <span style={{ color: 'red' }}>&#42;</span> :
                            </label>
                          </div>
                          <div className='col-sm-8'>
                            <TextArea
                              className='w-100'
                              rows={3}
                              onChange={handleChange}
                              name='screening_comments'
                              value={screening_comments}
                              disabled={formDisabled}
                            ></TextArea>
                            {errors.screening_comments && (
                              <p className='invalidInput'>
                                {errors.screening_comments}
                              </p>
                            )}
                          </div>
                        </div>
                        {props.drdData?.no_donation != 1 && (
                          <div className='row d-flex my-2'>
                            <div className='col-sm-3'>
                              <Button
                                type='primary'
                                className='w-100'
                                danger
                                onClick={validateInputs}
                              >
                                No Donation
                              </Button>
                            </div>
                          </div>
                        )}
                      </>
                    ) : null}
                    {CDCS && cdcs_check && cdcs_check == 'Yes' ? (
                      <div className='row d-flex my-2'>
                        <div className='col-sm-4 d-flex align-items-center'>
                          <label htmlFor='cdcs_check'>
                            DHQ Type<span style={{ color: 'red' }}>&#42;</span>{' '}
                            :{' '}
                          </label>
                        </div>
                        <div className='col-sm-8'>
                          <Select
                            options={[
                              {
                                value: 'FDHQ',
                                label: 'FDHQ'
                              },
                              {
                                value: 'RDHQ',
                                label: 'RDHQ'
                              }
                            ]}
                            onChange={value => {
                              setDhqType(value)
                            }}
                            value={dhqType}
                            placeholder='Select DHQ type'
                            className='w-50'
                            disabled={formDisabled}
                          ></Select>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                {CDCS &&
                cdcs_check &&
                cdcs_check == 'Yes' &&
                dhqType != '' &&
                dhqType == 'FDHQ' ? (
                  <FDHQ
                    donorInfo={props.donorInfo}
                    drdData={props.drdData}
                    setPage={props.setPage}
                    setError={setError}
                    ucn={ucn}
                    cdcs_check={cdcs_check}
                    CDCS={CDCS}
                    screening_comments={screening_comments}
                    nddr={NDDR}
                    nddr_check={nddrCheck}
                    duplicateUCN={duplicateUCN}
                  ></FDHQ>
                ) : null}
                {CDCS &&
                cdcs_check &&
                cdcs_check == 'Yes' &&
                dhqType != '' &&
                dhqType == 'RDHQ' ? (
                  <RDHQ
                    donorInfo={props.donorInfo}
                    drdData={props.drdData}
                    setPage={props.setPage}
                    setError={setError}
                    ucn={ucn}
                    cdcs_check={cdcs_check}
                    CDCS={CDCS}
                    screening_comments={screening_comments}
                    nddr={NDDR}
                    nddr_check={nddrCheck}
                    duplicateUCN={duplicateUCN}
                  ></RDHQ>
                ) : null}
              </div>
            </div>
          )
        } else {
          return (
            <div className='row justify-content-center mt-4'>
              <div className='col-md-6 card'>
                <div className='card-body'>
                  <h5 className='card-title'>
                    Does not meet basic eligibility criteria to Donate
                  </h5>
                  <p className='card-text text-danger'>{reason}</p>
                  <button
                    style={{
                      marginRight: '16px',
                      width: '80px',
                      height: '30px',
                      verticalAlign: 'center',
                      padding: '0',
                      borderRadius: '10px'
                    }}
                    className='btn btn-outline-danger mr-3'
                    onClick={() => history.push('/dashboard')}
                  >
                    Exit
                  </button>
                </div>
              </div>
            </div>
          )
        }
      }
    } else {
      return 'Loading...'
    }
  }

  return (
    <React.Fragment>
      {props.donorInfo.donor_id ? (
        <div className={`row p-2 mt-1 ${styles.drdRibbon}`}>
          <p className={`col-sm-3 ${styles.drdRibbonTitle}`}>
            Donor History Questionnaire
          </p>
          <p className={`col-sm-3 ${styles.drdRibbonDonorName}`}>
            <span>
              {props.donorInfo.first_name} {props.donorInfo.last_name}
            </span>
          </p>
          <div className={`col-sm-3 ${styles.drdRibbonID}`}>
            <span>Donor ID : {props.donorInfo.donor_id}</span>
          </div>
          <div className={`col-sm-3 ${styles.drdRibbonID}`}>
            <span>Donor Type : {props.donorInfo.donor_type}</span>
          </div>
        </div>
      ) : (
        'Loading'
      )}
      {!loading ? (
        <div className='row justify-content-center d-flex'>{content()}</div>
      ) : (
        <div className='mt-4 row d-flex justify-content-center'>Loading...</div>
      )}
      <Modal
        open={noDonationConfirmModalOpen}
        onOk={handleNoDonation}
        onCancel={() => setNoDonationConfirmModalOpen(false)}
        centered
        closable={false}
      >
        Please confirm to mark this as no donation
      </Modal>
      <Modal
        open={nddrFailSubmitModalOpen}
        onOk={handleNDDRfail}
        onCancel={() => setNDDRFailSubmitModalOpen(false)}
        centered
        closable={false}
      >
        The donor will be permanently deferred and the donation will be
        cancelled.
      </Modal>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token,
    isAuthenticated: state.isAuthenticated,
    roleid: state.roleid
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DHQ)
