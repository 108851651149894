import React, { useState, useEffect } from 'react'
import styles from '../styles/DIS.module.css'
import Loader from './Loader'
import { formatDate } from '../utilities/format'
import DonationModal from '.././modules/DonationDetails'
import { msalFetch } from '../utilities/msalFetch'
import { Table, Tag } from 'antd'
import moment from 'moment'

const columns = [
  {
    title: 'UCN',
    dataIndex: 'ucn',
    key: 'ucn'
  },
  {
    title: 'Donation Date',
    dataIndex: 'donation_date',
    key: 'donation_date',
    sorter: (a, b) =>
      moment(b.donation_date).isValid
        ? moment(a.donation_date, 'MM/DD/YYYY').valueOf() -
          moment(b.donation_date, 'MM/DD/YYYY').valueOf()
        : moment(a.donation_date).valueOf() - moment('12/12/9999', 'MM/DD/YYYY')
  },
  {
    title: 'Plasma Amount',
    dataIndex: 'plasma_amt_machine',
    key: 'plasma_amt_machine',
    sorter: (a, b) => a.plasma_amt_machine - b.plasma_amt_machine
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    filters: [
      {
        text: 'Pending',
        value: 'Pending'
      },
      {
        text: 'Pending QA Review',
        value: 'Pending QA Review'
      },
      {
        text: 'QA Reviewed',
        value: 'QA Reviewed'
      },
      {
        text: 'Quarantined',
        value: 'Quarantined'
      }
    ],
    onFilter: (value, record) => record.status.indexOf(value) === 0,
    render: (_, { status }) => {
      switch (status) {
        case 'Pending':
          return <Tag color='geekblue'>{status}</Tag>
        case 'Pending QA Review':
          return <Tag color='purple'>{status}</Tag>
        case 'QA Reviewed':
          return <Tag color='green'>{status}</Tag>
        case 'Quarantined':
          return <Tag color='red'>{status}</Tag>
      }
    }
  },
  {
    title: 'Lab Result',
    dataIndex: 'labresult',
    key: 'labresult',
    render: (_, { labresult }) => {
      switch (labresult) {
        case 'Partial':
          return <Tag color='geekblue'>{labresult}</Tag>
        case 'Pass':
          return <Tag color='green'>{labresult}</Tag>
        case 'Reactive':
          return <Tag color='red'>{labresult}</Tag>
        case 'Abnormal SPE':
          return <Tag color='yellow'>{labresult}</Tag>
        case 'RPR Positive':
          return <Tag color='yellow'>{labresult}</Tag>
        case 'ATYA Positive':
          return <Tag color='red'>{labresult}</Tag>
        case 'No Records':
          return <p>{labresult}</p>
      }
    },
    filters: [
      {
        text: 'Partial',
        value: 'Partal'
      },
      {
        text: 'Pass',
        value: 'Pass'
      },
      {
        text: 'Reactive',
        value: 'Reactive'
      },
      {
        text: 'Abnormal SPE',
        value: 'Abnormal SPE'
      },
      {
        text: 'ATYA Positive',
        value: 'ATYA Positive'
      },
      {
        text: 'RPR Positive',
        value: 'RPR Positive'
      },
      {
        text: 'No Records',
        value: 'No Records'
      }
    ],
    onFilter: (value, record) => record.labresult.indexOf(value) === 0
  }
]

function DonationHistory ({ donorInfo, token, roleid }) {
  const [records, setRecords] = useState(undefined)
  const [selectedDonation, setSelectedDonation] = useState({})
  const [donationModal, setDonationModel] = useState(undefined)
  const [filteredRecords, setFilteredRecords] = useState()
  const [selectedUCNs, setSelectedUCNs] = useState([])

  useEffect(() => {
    if (records && records.length) {
      const data = records.filter(
        record => selectedUCNs.indexOf(record.ucn) != -1
      )
      setFilteredRecords(data)
    }
  }, [selectedUCNs, records])

  const fetchDonationHistory = () => {
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    }
    msalFetch(`api/donor/getDonationHistory/${donorInfo.donor_id}`, options)
      .then(async res => {
        let data = await res.json()
        data = data.map(x => {
          x.donation_date = moment(x.donation_date).isValid()
            ? formatDate(x.donation_date)
            : ''

          if (x.no_donation === 1) {
            x.plasma_amt_machine = 'No Donation'
          }
          if (x.spe_only === 1) {
            x.plasma_amt_machine = 'SPE Donation'
          }
          return x
        })
        setRecords(data)
      })
      .catch(err => {
        setRecords([])
      })
  }

  useEffect(() => {
    fetchDonationHistory()
  }, [])

  const data =
    filteredRecords && filteredRecords.length ? filteredRecords : records

  return (
    <React.Fragment>
      {donorInfo.donor_id ? (
        <div className={`row p-2 mt-1 ${styles.drdRibbon}`}>
          <p className={`col-sm-3 ${styles.drdRibbonTitle}`}>
            Donation History
          </p>
          <p className={`col-sm-3 ${styles.drdRibbonDonorName}`}>
            <span>
              {donorInfo.first_name} {donorInfo.last_name}
            </span>
          </p>
          <div className={`col-sm-3 ${styles.drdRibbonID}`}>
            <span>Donor ID : {donorInfo.donor_id}</span>
          </div>
        </div>
      ) : (
        'Loading'
      )}
      {records !== undefined ? (
        records.length ? (
          <div className='row mt-4 justify-content-center'>
            <div className='col-md-10'>
              <Table
                dataSource={records}
                columns={columns}
                size='middle'
                onRow={record => {
                  return {
                    onClick: e => {
                      setDonationModel(true)
                      setSelectedDonation(record)
                    }
                  }
                }}
                rowClassName={styles.antdTableRow}
              />
            </div>
          </div>
        ) : (
          'No Records Found'
        )
      ) : (
        <Loader />
      )}
      {donationModal ? (
        <DonationModal
          token={token}
          donationDetails={selectedDonation}
          setVisible={setDonationModel}
          roleid={roleid}
          fetchDonationHistory={fetchDonationHistory}
          donorInfo = {donorInfo}
        ></DonationModal>
      ) : null}
    </React.Fragment>
  )
}

export default DonationHistory
