import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: `${process.env.REACT_APP_CLIENT_ID}`, // This is the ONLY mandatory field that you need to supply.
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`, // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: `${process.env.REACT_APP_REDIRECT_URL}`, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: `${process.env.REACT_APP_LOGOUT_URL}`, // Indicates the page to navigate after logout.
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
    }
};

export const loginRequest = {
    scopes: ['openid', 'profile', 'email', `api://${process.env.REACT_APP_API_CLIENT_ID}/${process.env.REACT_APP_API_SCOPE}`]
};