import React from "react";

const TextField = ({
  id,
  type = "text",
  name,
  label,
  value,
  onChange,
  error,
  placeholder,
  multiline = false,
  rows = 3,
  required,
  ...props
}) => {
  return (
    <>
      {label && (
        <label htmlFor={id}>
          {label} {required && <span style={{ color: "red" }}>&#42;</span>}
        </label>
      )}
      {multiline ? (
        <textarea
          id={id}
          name={name}
          className="form-control"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          rows={rows}
          {...props}
        />
      ) : (
        <input
          type={type}
          id={id}
          name={name}
          className="form-control"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        {...props}
        />
      )}
      {error && <div className="text-danger">{error}</div>}
    </>
  );
};

export default TextField;
