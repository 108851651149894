import React from "react";

const Select = ({value, name, id,label, options=[], error, disabled, placeholder, onChange, required}) => {
  return (
    <>
      {label && <label htmlFor={id}>{label} {required && <span style={{ color: 'red' }}>&#42;</span>}</label>}
      <select
        id={id}
        name={name}
        className="form-control"
        value={value}
        onChange={onChange}
        disabled={disabled}
        defaultValue={""}
      >
        {placeholder && <option value="" disabled>
          {placeholder}
        </option>}
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      {error && (
        <div className="text-danger">{error}</div>
      )}
    </>
  );
};

export default Select;
