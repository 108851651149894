import React, { useEffect, useState } from 'react'
import styles from '../styles/DIS.module.css'
import Stepper from 'react-stepper-horizontal'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronCircleRight,
  faChevronCircleLeft
} from '@fortawesome/free-solid-svg-icons'
import MedicalScreener from './MedicalScreener'
import Phlebotomist from './Phlebotomist'
import QAPage from './QA'
import { formatDate } from '../utilities/format'
import { checkDonorEligibility } from '../utilities/validations'
import { useHistory } from 'react-router-dom'
import { Input, Modal } from 'antd'
import { checkValidUCN } from '../utilities/validations'
import { toast } from 'react-toastify'
import { Message } from 'primereact/message'
import { msalFetch } from '../utilities/msalFetch'

function DRD ({ donorInfo, token, dp, actions, roleid, speDue, hardStop }) {
  const [page, setPage] = useState(0)
  const [drdData, setDrdData] = useState(undefined)
  const [nextButtonDisable, setNextButtonDisable] = useState(false)
  const [previousButtonDisable, setPreviousButtonDisable] = useState(false)
  const [dhqData, setDhqData] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [donorEligible, setDonorEligible] = useState(true)
  const [donorInEligibleReason, setDonorInEligibleReason] = useState('')
  const [noDonationModal, setNoDonationModal] = useState(false)
  const [noDonationUCN, setNoDonationUCN] = useState('')
  const [healthAssessment, setHealthAssessment] = useState({})

  const history = useHistory()

  useEffect(() => {
    if (roleid) {
      if (roleid == 1) {
        setPage(1)
      } else if (roleid == 2) {
        setPage(2)
      } else if (roleid == 3) {
        setPage(3)
      } else {
        setPage(1)
      }
    }
  }, [roleid])

  useEffect(() => {
    if (page == 0) {
      setNextButtonDisable(false)
      setPreviousButtonDisable(true)
    }
    if (page == 1) {
      if (roleid == 1) {
        setNextButtonDisable(true)
        setPreviousButtonDisable(false)
      } else if (roleid == 2) {
        setNextButtonDisable(true)
        setPreviousButtonDisable(true)
      } else if (roleid == 3) {
        setNextButtonDisable(false)
        setPreviousButtonDisable(false)
      }
    }
    if (page == 2) {
      if (roleid == 2) {
        setNextButtonDisable(true)
        setPreviousButtonDisable(true)
      }
      if (roleid == 3) {
        setNextButtonDisable(false)
        setPreviousButtonDisable(false)
      }
    }
    if (page == 3) {
      setNextButtonDisable(true)
      setPreviousButtonDisable(false)
    }
  }, [page])

  const getData = () => {
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    }
    const URL = sessionStorage.getItem('selectedUCN')
      ? `api/donor/getDRDbyUCN/${sessionStorage.getItem('selectedUCN')}`
      : `api/donor/getDRD/${donorInfo.donor_id}`
    msalFetch(URL, options)
      .then(async res => {
        const data = await res.json()
        if (data.length > 0) {
          getHealthAssessmentByUCN(data[0].ucn)
          setDrdData(data[0])
        } else {
          setDrdData({})
        }
      })
      .catch(err => {})
  }

  useEffect(() => {
    setLoading(true)
    if (donorInfo.donor_id) {
      getData()
      const { eligible, reason } = checkDonorEligibility(
        donorInfo.dob,
        donorInfo.vein_grade_left,
        donorInfo.vein_grade_right,
        donorInfo.check,
        donorInfo.address_verified,
        donorInfo.donor_status
      )
      setDonorEligible(eligible)
      setDonorInEligibleReason(reason)
      if (eligible || sessionStorage.getItem('selectedUCN')) {
        if (sessionStorage.getItem('selectedUCN')) {
          const ucn = sessionStorage.getItem('selectedUCN')
          const options = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
          msalFetch(`/api/donor/getDHQByUCN/${ucn}`, options).then(
            async response => {
              setLoading(false)
              if (response.status === 200) {
                const data = await response.json()
                setDhqData(data)
                if (data.length == 0) setNextButtonDisable(true)
              } else {
              }
            }
          )
        } else {
          const options = {
            method: 'GET',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
          msalFetch(
            `/api/donor/getLatestDHQStatus/${donorInfo.donor_id}`,
            options
          )
            .then(async res => {
              setLoading(false)
              const data = await res.json()
              setDhqData(data)
              if (data.length == 0) setNextButtonDisable(true)
            })
            .catch(err => {
              console.error(err)
              setLoading(false)
            })
        }
      }
    }
  }, [donorInfo])

  const getHealthAssessmentByUCN = ucn => {
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      }
    }
    msalFetch(`/api/donor/getHealthAssessmentByUCN/${ucn}`, options).then(
      async res => {
        if (res.ok) {
          const data = await res.json()
          if (data.length) {
            const record = data[0]
            setHealthAssessment(record)
          }
        } else {
          setHealthAssessment({})
        }
      }
    )
  }

  const pageDisplay = page => {
    switch (page) {
      case 0:
        return renderDHQDetails()
      case 1:
        return (
          <MedicalScreener
            token={token}
            getData={getData}
            roleid={roleid}
            donorId={donorInfo.donor_id}
            donorInfo={donorInfo}
            drd={drdData}
            handleNavigation={x => setPage(x)}
            dp={dp}
            actions={actions}
            speDue={speDue}
            dhqResult={dhqData && dhqData.length ? dhqData[0].RESULT : 'PASS'}
            healthAssessment={healthAssessment}
            hardStop={hardStop}
            dhqData={dhqData}
          />
        )
      case 2:
        return (
          <Phlebotomist
            getData={getData}
            donorId={donorInfo.donor_id}
            donorInfo={donorInfo}
            drd={drdData}
            handleNavigation={x => setPage(x)}
            page={page}
            speDue={speDue}
          />
        )
      case 3:
        return (
          <QAPage
            token={token}
            getData={getData}
            roleid={roleid}
            drd={drdData}
            donorInfo={donorInfo}
            donorId={donorInfo.donor_id}
            handleNavigation={x => setPage(x)}
            page={page}
            actions={actions}
            speDue={speDue}
            dhqData={dhqData}
            healthAssessment={healthAssessment}
          ></QAPage>
        )
      default:
        return <h4>Error</h4>
    }
  }

  const clickPrevButton = () => {
    if (page > 0) {
      setPage(page => page - 1)
    }
  }

  const clickNextButton = () => {
    if (page < 3) {
      setPage(page => page + 1)
    }
  }

  const handleNoDonation = () => {
    actions.setLoading()
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      },
      body: JSON.stringify({
        ucn: noDonationUCN,
        donor_id: donorInfo.donor_id
      })
    }
    msalFetch(`api/donor/noDonation`, options)
      .then(async response => {
        actions.clearLoading()
        const data = await response.json()
        if (response.ok) {
          toast.success(data.message)
          setNoDonationModal(false)
          setNoDonationUCN('')
          history.push('/dashboard')
        } else {
          toast.error(data.message)
        }
      })
      .catch(err => {
        toast.error(err.message)
        actions.clearLoading()
      })
  }

  const renderDHQDetails = () => {
    return (
      <React.Fragment>
        {donorEligible || sessionStorage.getItem('selectedUCN') ? (
          <div className='row mt-4 justify-content-center d-flex'>
            {!loading ? (
              dhqData && dhqData.length > 0 ? (
                <div className='col-md-4'>
                  <div className='card'>
                    <div className='card-header'>
                      <h6 style={{ fontFamily: 'Font-Bold' }}>
                        Last DHQ Details
                      </h6>
                    </div>
                    <div className='card-body'>
                      <table className='table table table-bordered table-sm'>
                        <tbody>
                          <tr>
                            <td>DONOR ID</td>
                            <th>{dhqData[0].DONOR_ID}</th>
                          </tr>
                          <tr>
                            <td>DHQ Type</td>
                            <th>{dhqData[0].DHQ_TYPE}</th>
                          </tr>
                          <tr>
                            <td>DHQ Date</td>
                            <th>{formatDate(dhqData[0].DATE_OF_DHQ)}</th>
                          </tr>
                          <tr>
                            <td>DHQ Result</td>
                            <th
                              className={
                                dhqData[0].RESULT == 'PASS'
                                  ? 'text-success'
                                  : 'text-danger'
                              }
                            >
                              {dhqData[0].RESULT}
                            </th>
                          </tr>
                          {dhqData[0].UCN && (
                            <tr>
                              <td>UCN</td>
                              <th>{dhqData[0].UCN}</th>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className='card-footer'>
                      <button
                        className='btn btn-outline-danger btn-sm mx-2'
                        style={{ width: '60px' }}
                        onClick={() => history.push('/dashboard')}
                      >
                        Exit
                      </button>
                      {dhqData[0].UCN === 'Pending' && (
                        <button
                          className='btn btn-primary btn-sm mx-2'
                          onClick={() => setNoDonationModal(true)}
                        >
                          Mark this DHQ as No Donation
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className='col-md-4'>
                  <div className='card'>
                    <div className='card-header'>
                      <h6 style={{ fontFamily: 'Font-Bold' }}>DHQ Details</h6>
                    </div>
                    <div className='card-body'>
                      No Records Found
                      <p style={{ color: 'red', fontSize: '14px' }}>
                        Please complete DHQ before Donation
                      </p>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div className='col-md-6'>
                <p>Loading...</p>
              </div>
            )}
          </div>
        ) : (
          <div className='row mt-4 d-flex justify-content-center'>
            <div className='col-md-6 card'>
              <div className='card-body'>
                <h5 className='card-title'>
                  Donor does not meet basic eligibility criteria to Donate
                </h5>
                <p className='card-text text-danger'>{donorInEligibleReason}</p>
                <button
                  style={{
                    marginRight: '16px',
                    width: '80px',
                    height: '30px',
                    verticalAlign: 'center',
                    padding: '0',
                    borderRadius: '10px'
                  }}
                  className='btn btn-outline-danger mr-3'
                  onClick={() => history.push('/dashboard')}
                >
                  Exit
                </button>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {donorInfo.donor_id ? (
        <div className={`row p-2 mt-1 ${styles.drdRibbon}`}>
          <p className={`col-sm-3 ${styles.drdRibbonTitle}`}>
            Donor Record of Donation
          </p>
          <p className={`col-sm-3 ${styles.drdRibbonDonorName}`}>
            <span>
              {donorInfo.first_name} {donorInfo.last_name}
            </span>
          </p>
          <div className={`col-sm-3 ${styles.drdRibbonID}`}>
            <span>Donor ID : {donorInfo.donor_id}</span>
          </div>
          <div className={`col-sm-3 ${styles.drdRibbonID}`}>
            <span>Status :</span>
            <span>
              {' '}
              {drdData && drdData.donor_id ? drdData.status : 'New Donation'}
            </span>
          </div>
        </div>
      ) : (
        'Loading'
      )}
      {donorInfo?.allergic_to ? (
        <div className='row'>
          <Message
            text={'Donor is allergic to ' + `${donorInfo.allergic_to}`}
            severity='warn'
            className='col-sm-12 text-danger'
          ></Message>
        </div>
      ) : null}
      <div className='row justify-content-between'>
        <div className='col-md-3 d-flex align-items-center justify-content-end'>
          <button
            className='btn'
            onClick={clickPrevButton}
            disabled={previousButtonDisable}
          >
            <FontAwesomeIcon
              icon={faChevronCircleLeft}
              style={{ fontSize: '25px', color: '#0d59bc' }}
            ></FontAwesomeIcon>
          </button>
        </div>
        <div className={`col-md-6 d-flex ${styles.stepperFonts}`}>
          <Stepper
            steps={[
              {
                title: 'DHQ'
                //onClick: () => setPage(0)
              },
              {
                title: 'Medical Screener'
                //onClick: () => setPage(1)
              },
              { title: 'Phlebotomist' },
              { title: 'QA Review' }
            ]}
            activeStep={page}
            activeColor='#046DAF'
            completeColor='#046DAF'
            activeTitleColor='#6B87A4'
            completeTitleColor='#6B87A4'
            circleFontColor='#FFF'
            defaultBorderColor='#046DAF'
            defaultBorderStyle='#046DAF'
            completeBarColor='#046DAF'
            titleFontSize={14}
          />
        </div>
        <div className='col-md-3 d-flex align-items-center justify-content-start'>
          <button
            className='btn'
            onClick={clickNextButton}
            disabled={nextButtonDisable}
          >
            <FontAwesomeIcon
              icon={faChevronCircleRight}
              style={{ fontSize: '25px', color: '#0d59bc' }}
            ></FontAwesomeIcon>
          </button>
        </div>
      </div>
      {pageDisplay(page)}
      <Modal
        title=''
        open={noDonationModal}
        onOk={handleNoDonation}
        onCancel={() => setNoDonationModal(false)}
        okButtonProps={{
          disabled: noDonationUCN == '' || !checkValidUCN(noDonationUCN)
        }}
        centered
      >
        <p>Scan UCN to mark this as no donation</p>
        <Input
          placeholder='UCN'
          onChange={e => setNoDonationUCN(e.target.value)}
        ></Input>
      </Modal>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token,
    isAuthenticated: state.isAuthenticated,
    roleid: state.roleid
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DRD)
