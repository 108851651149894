import React, { useEffect, useState } from 'react'
import { request } from '../utilities/msalFetch';

const useCenterCodes = () => {
    const [centerCodes, setCenterCodes] = useState([]);

    useEffect(() => {
        const getCenterCode = async () => {
            const res = await request.get("/api/deviation/centercodes");
            if (res.data.success) {
                setCenterCodes(res.data.centerCodes);
            }
        };

        getCenterCode();
    }, []);

    return centerCodes;
}

export default useCenterCodes