import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import { toast } from 'react-toastify'
import styles from '../styles/DHQ.module.css'
import Modal from 'react-bootstrap/Modal'
import { formatDate } from '../utilities/format'
import { msalFetch } from '../utilities/msalFetch'
import { Button, notification, Modal as AntModal } from 'antd'
import { checkValidUCN } from '../utilities/validations'

function FDHQ (props) {
  const [questions, setQuestions] = useState([])
  const [answers, setAnswers] = useState({})
  const [questionIds, setQuestionIds] = useState([])
  const [Acknowledgment, setAcknowledgment] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [donorSignaturePadOpen, setDonorSignaturePadOpen] = useState(false)
  const [donorSignDataUrl, setDonorSignDataUrl] = useState(null)
  const [dhqDate, setDhqDate] = useState(null)
  const [physicianSubstituteSign, setPhysicianSubstituteSign] = useState(null)
  const [physicianSignOpen, setPhysicianSignOpen] = useState(false)
  const [physicianSubName, setPhysicianSubName] = useState(null)
  const [validationErrors, setValidationErrors] = useState('')
  const [loaderCounter, setLoaderCounter] = useState(0)
  const [dhqresult, setdhqresult] = useState(undefined)
  const [comments, setComments] = useState('')
  const [formDisabled, setFormDisabled] = useState(false)
  const [questionNumbers, setQuestionNumbers] = useState({})
  const [missingQuestionIds, setMissingQuestionIds] = useState([])
  const [donorSign, setDonorSign] = useState('')
  const [showPhySubConsellingModal, setShowPhySubConsellingModal] =
    useState(false)
  const [phySubReqComments, setPhySubReqComments] = useState('')

  const [api, contextHolder] = notification.useNotification()
  const [enablePhySubApproval, setEnablePhySubApproval] = useState(false)

  let sigPad = useRef(null)
  let sigPad2 = useRef(null)

  useEffect(() => {
    props.actions.setLoading()
    setLoaderCounter(loaderCounter => loaderCounter + 1)
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      }
    }
    const url = '/api/donor/getDHQQuestions/FDHQ'
    msalFetch(url, options).then(async res => {
      setLoaderCounter(loaderCounter => loaderCounter - 1)
      if (loaderCounter === 0) props.actions.clearLoading()
      if (res.ok) {
        const data = await res.json()
        let questionHeads = []
        let questionIds = []
        data.forEach(x => {
          if (props.donorInfo.gender == 'M') {
            if (x.GENDER != 'F') {
              questionIds.push(x.QUESTION_ID)
              if (questionHeads.indexOf(x.QUESTION_HEAD) == -1) {
                questionHeads.push(x.QUESTION_HEAD)
              }
            }
          }
          if (props.donorInfo.gender == 'F') {
            if (x.GENDER != 'M') {
              questionIds.push(x.QUESTION_ID)
              if (questionHeads.indexOf(x.QUESTION_HEAD) == -1) {
                questionHeads.push(x.QUESTION_HEAD)
              }
            }
          }
        })
        let finalArray = []
        let allQuestions = {}
        questionHeads.forEach(x => {
          let obj = {}
          let questions = []
          obj.questionHead = x
          data.forEach(y => {
            let questionObj = {}
            if (y.QUESTION_HEAD == x && questionIds.includes(y.QUESTION_ID)) {
              questionObj.id = y.QUESTION_ID
              questionObj.question = y.QUESTION
              allQuestions[y.QUESTION_ID] = y.QUESTION
              questionObj.usualAnswer = y.USUAL_ANSWER
              questions.push(questionObj)
            }
          })
          obj.questions = questions
          finalArray.push(obj)
        })
        setQuestions(finalArray)
        setQuestionIds(questionIds)
        setQuestionNumbers(allQuestions)
      }
    })
  }, [])

  //get DHQ Answers
  useEffect(() => {
    if (props.drdData) {
      let ucn = sessionStorage.getItem('selectedUCN')
        ? sessionStorage.getItem('selectedUCN')
        : props.drdData?.ucn
        ? props.drdData?.ucn
        : null
      let donorId = props.donorInfo.donor_id
      let type = 'FDHQ'
      props.actions.setLoading()
      setLoaderCounter(loaderCounter => loaderCounter + 1)
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.token
        },
        body: JSON.stringify({ ucn, donorId, type })
      }
      msalFetch('/api/donor/getDHQAnswers', options).then(async response => {
        setLoaderCounter(loaderCounter => loaderCounter - 1)
        if (loaderCounter == 0) props.actions.clearLoading()
        const data = await response.json()
        if (data.length > 0) {
          let answers = JSON.parse(data[0].ANSWERS)
          setAnswers(answers)
          setAcknowledgment(true)
          let sign = data[0].DONOR_SIGNATURE
          setDonorSignDataUrl(sign)
          setdhqresult(data[0].RESULT)
          setDhqDate(data[0].DATE_OF_DHQ.slice(0, 10))
          setComments(data[0].COMMENTS)
          setDonorSign(data[0].DONOR_SIGN)
          props.drdData && props.drdData.ucn && setEnablePhySubApproval(true)
          if (props.roleid == 7) {
            if (
              props.drdData &&
              Object.keys(props.drdData).length &&
              props.drdData.status == 'Pending QA Review'
            ) {
              setFormDisabled(true)
              setSubmitDisabled(true)
            } else {
              setFormDisabled(false)
              setSubmitDisabled(false)
            }
          } else {
            if (
              props.drdData &&
              Object.keys(props.drdData).length &&
              ((props.drdData.weight && props.drdData.screening_time) ||
                props.drdData.status !== 'Pending')
            ) {
              setFormDisabled(true)
              setSubmitDisabled(true)
            } else {
              setFormDisabled(false)
              setSubmitDisabled(false)
            }
          }
        } else {
          setEnablePhySubApproval(false)
        }
      })
    }
  }, [props.drdData])

  const openMissedQuestionsNotification = missedQuestions => {
    api['warning']({
      message: 'Please answer following questions',
      description: (
        <>
          {missedQuestions.map(q => (
            <p style={{ padding: 0, margin: 0 }}>{q}</p>
          ))}
        </>
      ),
      className: 'custom-class',
      style: {
        width: 600
      }
    })
  }

  const handleChange = e => {
    setAnswers({ ...answers, [e.target.name]: e.target.value })
    const missingQuestionsIds = missingQuestionIds.filter(
      x => x != e.target.name
    )
    setMissingQuestionIds(missingQuestionsIds)
  }

  const handleCheckbox = e => {
    if (e.target.checked) {
      setAcknowledgment(true)
    } else {
      setAcknowledgment(false)
    }
  }

  const clearSigPad = () => {
    sigPad.clear()
  }
  const trimSignature = () => {
    setDonorSignDataUrl(sigPad.getTrimmedCanvas().toDataURL('image/png'))
    setDonorSignaturePadOpen(false)
  }

  const clearPhysicianSubstituteSigPad = () => {
    sigPad2.clear()
  }
  const trimPhysicianSubSignature = () => {
    setPhysicianSubstituteSign(
      sigPad2.getTrimmedCanvas().toDataURL('image/png')
    )
    setPhysicianSignOpen(false)
  }

  const handleSignPadClick = e => {
    if (!formDisabled) {
      if (!Acknowledgment) {
        setValidationErrors('Please accept acknowledgement')
        toast.error('Please accept acknowledgement')
      } else {
        setValidationErrors('')
        setDonorSignaturePadOpen(true)
      }
    }
  }

  const validation = () => {
    let missingQuestionNumbers = []
    let missingQuestionIds = []
    let answeredIds = Object.keys(answers)
    questionIds.forEach(x => {
      if (answeredIds.indexOf(String(x)) == -1) {
        missingQuestionNumbers.push(questionNumbers[x])
        missingQuestionIds.push(x)
      }
    })
    if (missingQuestionNumbers.length) {
      setMissingQuestionIds(missingQuestionIds)
      openMissedQuestionsNotification(missingQuestionNumbers)
      return false
    } else {
      if (!Acknowledgment) {
        setValidationErrors('Please accept acknowledgement')
        toast.error('Please accept acknowledgement')
      } else if (donorSign == null || donorSign == '') {
        setValidationErrors('Donor signature is required')
        toast.error('Donor signature is required')
      } else if (dhqresult == undefined) {
        /*  else if(physicianSubName==""||physicianSubName==null){
                setValidationErrors("Physician Substitute Name is required");
                toast.error("Physician Substitute Name is required");
            }
            else if(physicianSubstituteSign==null || physicianSubstituteSign == ""){
                setValidationErrors("Physician Substitute signature is required");
                toast.error("Physician Substitute signature is required");
            } */
        setValidationErrors('Please choose DHQ result')
        toast.error('Please choose DHQ result')
      } else if (props.ucn == undefined || props.ucn === '') {
        toast.error('Please enter ucn')
        setValidationErrors('Please enter valid UCN')
        props.setError('ucn', 'UCN is required')
        return false
      } else if (props.CDCS == undefined || props.CDCS === '') {
        toast.error('CDCS inquiry id is required')
        setValidationErrors('Please enter CDCS')
        props.setError('cdcs', 'CDCS inquiry id is required')
        return false
      } else if (props.duplicateUCN) {
        toast.error('Invalid UCN')
        setValidationErrors('Duplicate UCN')
        props.setError('ucn', 'Duplicate UCN')
        return false
      } else {
        setValidationErrors('')
        return true
      }
    }
  }

  const surveySubmit = (e, phySubApproval = false) => {
    e.preventDefault()
    if (validation()) {
      const body = {
        answers,
        type: 'FDHQ',
        donorid: props.donorInfo.donor_id,
        donor_sign: donorSign,
        donor_name: `${props.donorInfo.first_name} ${props.donorInfo.last_name}`,
        physicianSubSign: physicianSubstituteSign,
        physicianSubName: physicianSubName,
        dhqresult,
        comments,
        cdcs: props.CDCS,
        ucn: props.ucn,
        cdcs_check: props.cdcs_check,
        nddr: props.nddr,
        nddr_check: props.nddr_check,
        phySubApproval
      }
      props.actions.setLoading()
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.token
        },
        body: JSON.stringify(body)
      }
      msalFetch('/api/donor/postDHQAnswers', options)
        .then(async res => {
          props.actions.clearLoading()
          if (res.ok) {
            const data = await res.json()
            toast.success(data.message)
            setSubmitDisabled(true)
            if (props.roleid === 7) {
              props.setPage('HA')
            } else {
              props.setPage('DRD')
            }
          }
        })
        .catch(err => {
          props.actions.clearLoading()
        })
    }
  }

  const handlePhysubConsellingRequired = () => {
    if (props.ucn && checkValidUCN(props.ucn) && enablePhySubApproval) {
      setShowPhySubConsellingModal(true)
    } else {
      toast.error('DHQ must be saved before initiating PhySub Assessment')
    }
  }

  const submitPhySubCousellingRequest = () => {
    const body = {
      donorid: props.donorInfo.donor_id,
      ucn: props.ucn,
      requestReason: phySubReqComments,
      assessmentType: 'Counselling'
    }
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      },
      body: JSON.stringify(body)
    }
    props.actions.setLoading()
    msalFetch('/api/donor/requestHealthAssessment', options)
      .then(async res => {
        props.actions.clearLoading()
        if (res.ok) {
          const data = await res.json()
          if (data.success) {
            toast.success(data.message)
            setPhySubReqComments('')
            setShowPhySubConsellingModal(false)
          } else {
            toast.error(data.message)
          }
        }
      })
      .catch(err => {
        props.actions.clearLoading()
      })
  }

  return (
    <div className='my-4'>
      {contextHolder}
      <div className='row d-flex justify-content-end'>
      </div>
      <form className onSubmit={e => e.preventDefault()}>
        <table className='table table-sm table-bordered'>
          <tbody>
            {questions.map((x, index) => {
              return (
                <>
                  <tr key={index}>
                    <th scope='row' colSpan='3'>
                      {x.questionHead}
                    </th>
                  </tr>
                  {x.questions.map((question, index) => {
                    return (
                      <tr
                        key={index}
                        id={question.id}
                        className={
                          missingQuestionIds.includes(question.id)
                            ? 'table-danger'
                            : ''
                        }
                      >
                        <td
                          className={
                            answers[question?.id] &&
                            (question.usualAnswer == answers[question?.id]
                              ? ''
                              : 'text-danger')
                          }
                        >
                          {question.question}
                        </td>
                        <td>
                          <span
                            className={
                              answers[question?.id] &&
                              (question.usualAnswer == answers[question?.id]
                                ? ''
                                : 'text-danger')
                            }
                          >
                            Yes{' '}
                          </span>
                          <input
                            type='radio'
                            value='Yes'
                            checked={answers[question?.id] == 'Yes'}
                            name={question.id}
                            onChange={handleChange}
                            disabled={formDisabled}
                          ></input>
                        </td>
                        <td>
                          <span
                            className={
                              answers[question?.id] &&
                              (question.usualAnswer == answers[question?.id]
                                ? ''
                                : 'text-danger')
                            }
                          >
                            No{' '}
                          </span>
                          <input
                            type='radio'
                            value='No'
                            checked={answers[question?.id] == 'No'}
                            name={question.id}
                            onChange={handleChange}
                            disabled={formDisabled}
                          ></input>
                        </td>
                      </tr>
                    )
                  })}
                </>
              )
            })}
          </tbody>
        </table>
        <p>
          <input
            type='checkbox'
            onChange={handleCheckbox}
            checked={Acknowledgment}
            disabled={formDisabled}
          ></input>{' '}
          <strong>Acknowledgment</strong>:
          <ol>
            <li>
              I have reviewed the educational materials regarding infections
              that can be transmitted by my donation, such as, Syphilis, HIV,
              Hepatitis B and C.{' '}
            </li>
            <li>
              I agree not to donate if my donation could result in a potential
              risk to people who receive plasma products.{' '}
            </li>
            <li>
              A sample of my blood will be tested for infections that can be
              transmitted by my donation, such as, Syphilis, HIV, Hepatitis B
              and C.{' '}
            </li>
            <li>
              I understand you will attempt to notify me if for any reason I
              cannot donate, and records will be maintained indicating the
              reason for the deferral and the deferral time period.{' '}
            </li>
            <li>
              I have reviewed the information regarding the potential risks and
              hazards of donating Source Plasma.{' '}
            </li>
            <li>
              I have been given the opportunity to ask questions and understand
              that I may withdraw from the donation procedure at any time.
            </li>
          </ol>
        </p>
        <div className='row p-2'>
          <table className='table table-borderless'>
            <tr>
              <th>Donor Name</th>
              <td>{`${props.donorInfo.first_name} ${props.donorInfo.last_name}`}</td>
            </tr>
            <tr>
              <th>
                Donor Signature<sup>*</sup>
              </th>
              <td>
                <input
                  type='text'
                  style={{
                    backGroundColor: '#FFFFFF',
                    margin: '2px',
                    border: '1px solid',
                    width: '15rem'
                  }}
                  onChange={e => setDonorSign(e.target.value)}
                  value={donorSign}
                  disabled={formDisabled}
                  className={styles.inputs}
                ></input>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <h5>
                  I am signing this document electronically. I agree that my
                  electronic signature is the legal equivalent of my
                  manual/handwritten signature on this document.
                </h5>
              </td>
            </tr>
            <tr>
              <th className='pt-2 pb-2'>
                DHQ Result<sup>*</sup>
              </th>
              <td className='pt-2 pb-2'>
                <select
                  aria-label='Default select example'
                  style={{
                    backGroundColor: '#FFFFF',
                    margin: '2px',
                    border: '1px solid',
                    width: '15rem'
                  }}
                  className={styles.inputs}
                  onChange={e => setdhqresult(e.target.value)}
                  value={dhqresult}
                  disabled={formDisabled}
                >
                  <option selected disabled>
                    Select
                  </option>
                  <option value='PASS'>Pass</option>
                  <option value='FAIL'>Fail</option>
                </select>
              </td>
            </tr>
            <tr>
              <th className='pt-2 pb-2'>
                Comments<sup>*</sup>
              </th>
              <td className='pt-2 pb-2'>
                <textarea
                  style={{
                    backGroundColor: '#FFFFF',
                    margin: '2px',
                    border: '1px solid',
                    width: '15rem'
                  }}
                  rows={3}
                  onChange={e => setComments(e.target.value)}
                  value={comments}
                  disabled={formDisabled}
                ></textarea>
              </td>
            </tr>
            <tr>
              <th>Date</th>
              <td>
                {dhqDate
                  ? formatDate(dhqDate)
                  : formatDate(new Date().toLocaleDateString())}
              </td>
            </tr>
          </table>
        </div>
        <p className='text-danger' style={{ fontSize: '14px' }}>
          {validationErrors}
        </p>
        <div className='col-md-12'>
          {props.roleid === 1 || props.roleid === 2 || props.roleid === 3 ? (
            <>
              <Button
                type='primary'
                disabled={submitDisabled}
                onClick={surveySubmit}
              >
                Submit
              </Button>
              <Button
                onClick={e => {
                  e.preventDefault()
                  handlePhysubConsellingRequired()
                }}
                type='primary'
                disabled={submitDisabled || formDisabled}
                className='mx-4'
              >
                Counselling Required by Phy.Sub
              </Button>
            </>
          ) : null}
          {props.roleid === 7 ? (
            <Button
              type='primary'
              disabled={submitDisabled}
              onClick={e => surveySubmit(e, true)}
            >
              PhysicianSub Approval
            </Button>
          ) : null}
        </div>
      </form>
      <Modal
        show={donorSignaturePadOpen}
        backdrop='static'
        keyboard={false}
        size='sm'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        onHide={() => setDonorSignaturePadOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Please Sign Below</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='signaturePadDiv'>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-dark' onClick={clearSigPad}>
            Clear
          </button>
          <button className='btn btn-primary' onClick={trimSignature}>
            Ok
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={physicianSignOpen}
        backdrop='static'
        keyboard={false}
        size='sm'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        onHide={() => setPhysicianSignOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Please Sign Below</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='signaturePadDiv'>
            {/* <SignaturePad
              ref={ref => {
                sigPad2 = ref
              }}
            ></SignaturePad> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-dark'
            onClick={clearPhysicianSubstituteSigPad}
          >
            Clear
          </button>
          <button
            className='btn btn-primary'
            onClick={trimPhysicianSubSignature}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>
      <AntModal
        title='Request for Physican Sub Counselling'
        open={showPhySubConsellingModal}
        onOk={submitPhySubCousellingRequest}
        onCancel={() => {
          setShowPhySubConsellingModal(false)
          setPhySubReqComments('')
        }}
        centered
        okButtonProps={{ disabled: !phySubReqComments }}
      >
        <div class='form-group'>
          <label for='counsellingcomments'>Comments *</label>
          <textarea
            class='form-control'
            id='counsellingcomments'
            rows='3'
            maxLength={200}
            onChange={e => setPhySubReqComments(e.target.value)}
            value={phySubReqComments}
          ></textarea>
        </div>
      </AntModal>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token,
    isAuthenticated: state.isAuthenticated,
    roleid: state.roleid,
    loading: state.loading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FDHQ)
