import React, { useState } from 'react'
import { Button, message, Upload } from 'antd'
import readXlsxFile from 'read-excel-file'
import { Table } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import { msalFetch } from '../utilities/msalFetch'

const validateSSN = ssn => {
  let ssnWithLeadingZeros = ssn.toString()
  if (ssnWithLeadingZeros.includes('-')) {
    if (ssnWithLeadingZeros.length < 11) {
      let n = 11 - ssnWithLeadingZeros.length
      for (let i = 0; i < n; i++) {
        ssnWithLeadingZeros = '0' + ssnWithLeadingZeros
      }
    }
    var re1 = /^\d{3}-?\d{2}-?\d{4}$/
    return re1.test(ssnWithLeadingZeros)
  } else {
    if (ssnWithLeadingZeros.length < 9) {
      let n = 9 - ssnWithLeadingZeros.length
      for (let i = 0; i < n; i++) {
        ssnWithLeadingZeros = '0' + ssnWithLeadingZeros
      }
    }
    var re2 = /^\d{9}/
    return re2.test(ssnWithLeadingZeros)
  }
}

const validatePhoneNumber = phone => {
  const re = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
  return re.test(phone)
}

const validateZipcode = zipcode => {
  if (zipcode.length < 5) {
    let c = 5 - zipcode.length
    console.log(c)
    for (let i = 0; i < c; i++) {
      zipcode = '0' + zipcode
    }
  }
  const re = /^[0-9]{5}(?:-[0-9]{4})?$/
  return re.test(zipcode)
}

const schema = {
  SSN: {
    prop: 'ssn',
    required: true,
    type: value => {
      const valid = validateSSN(value)
      if (!valid) {
        throw new Error('Invalid SSN')
      }
      return value
    }
  },
  'First Name': {
    prop: 'first_name',
    required: true,
    type: String
  },
  'Middle Name': {
    prop: 'middle_name',
    required: false,
    type: String
  },
  'Last Name': {
    prop: 'last_name',
    required: true,
    type: String
  },
  Gender: {
    prop: 'gender',
    required: true,
    oneOf: ['Male', 'Female']
  },
  'Date of Birth': {
    prop: 'dob',
    required: true,
    type: Date
  },
  "Donor's Phone Number": {
    prop: 'phone',
    required: true,
    type: value => {
      const valid = validatePhoneNumber(value)
      if (!valid) {
        throw new Error('Invalid Phone Number')
      }
      return value
    }
  },
  'Allergic To': {
    prop: 'allergic_to',
    required: false,
    oneOf: ['Iodine', 'Environmental', 'Medication', 'Latex', 'Others']
  },
  'Allergy Comments': {
    prop: 'allergy_comments',
    required: false,
    type: String
  },
  'Emergency Contact Name': {
    prop: 'emergency_contact_name',
    required: true,
    type: String
  },
  'Emergency Contact Number': {
    prop: 'emergency_contact_number',
    required: true,
    type: value => {
      const valid = validatePhoneNumber(value)
      if (!valid) {
        throw new Error('Invalid Phone Number')
      }
      return value
    }
  },
  'Vein Grade L': {
    prop: 'vein_grade_left',
    required: true,
    oneOf: ['Orange', 'Green', 'Unsuitable']
  },
  'Vein Grade R': {
    prop: 'vein_grade_right',
    required: true,
    oneOf: ['Orange', 'Green', 'Unsuitable']
  },
  'Address 1': {
    prop: 'addr1',
    required: true,
    type: String
  },
  'Address 2': {
    prop: 'addr2',
    required: false,
    type: String
  },
  City: {
    prop: 'city',
    required: true,
    type: String
  },
  State: {
    prop: 'state',
    required: true,
    oneOf: [
      'Pennsylvania',
      'New Jersey',
      'Delaware',
      'Maryland',
      'New York',
      'California',
      'Illinois',
      'Texas',
      'Arizona',
      'Florida',
      'Indiana',
      'Ohio',
      'North Carolina',
      'Michigan',
      'Tennessee',
      'Massachusetts',
      'Washington',
      'Colorado',
      'District of Columbia',
      'Kentucky',
      'Oregon',
      'Oklahoma',
      'Wisconsin',
      'Nevada',
      'New Mexico',
      'Missouri',
      'Virginia',
      'Georgia',
      'Nebraska',
      'Minnesota',
      'Kansas',
      'Louisiana',
      'Hawaii',
      'Alaska',
      'Idaho',
      'Alabama',
      'Iowa',
      'Arkansas',
      'Utah',
      'Rhode Island',
      'Mississippi',
      'South Dakota',
      'Connecticut',
      'South Carolina',
      'New Hampshire',
      'North Dakota',
      'Montana',
      'Maine',
      'Wyoming',
      'West Virginia',
      'Vermont'
    ]
  },
  'Zip Code': {
    prop: 'zipcode',
    required: true,
    type: value => {
      const valid = validateZipcode(value.toString())
      if (!valid) {
        throw new Error('Invalid Zipcode')
      }
      return value
    }
  },
  'Address Verified': {
    prop: 'address_verified',
    required: true,
    oneOf: ['Yes', 'No']
  },
  'Donor Status': {
    prop: 'donor_status',
    required: true,
    oneOf: [
      'ACTIVE',
      'TEMPORARILY DEFERRED',
      'TEMPORARILY DEFERRED (Abnormal SPE)',
      'PERMANENTLY DEFERRED'
    ]
  },
  'Donor Type': {
    prop: 'donor_type',
    required: true,
    oneOf: ['QUALIFIED', 'APPLICANT 1', 'APPLICANT 2']
  },
  'NDDR Transaction ID': {
    prop: 'nddr',
    required: true,
    type: String
  },
  'NDDR Check': {
    prop: 'nddr_check',
    required: true,
    oneOf: ['Pass', 'Fail']
  },
  'NDDR Transaction Date': {
    prop: 'nddr_date',
    required: true,
    type: Date
  },
  'Last SPE Sample Date': {
    prop: 'spe_sample_date',
    required: false,
    type: Date
  },
  'Last Full Physical Date': {
    prop: 'last_full_physical_date',
    required: false,
    type: Date
  }
}

const errorColumns = [
  { title: 'Column', dataIndex: 'column', key: 'column' },
  { title: 'Row', dataIndex: 'row', key: 'row' },
  { title: 'Error', dataIndex: 'error', key: 'error' },
  { title: 'Value', dataIndex: 'value', key: 'value' }
]

const resultColumns = [
  { title: 'Donor ID', dataIndex: 'DonorId', key: 'DonorId' },
  { title: 'Status', dataIndex: 'Status', key: 'Status' },
  { title: 'Comments', dataIndex: 'comments', key: 'comments' },
  {
    title: 'SSN',
    dataIndex: 'ssn',
    key: 'ssn'
  },
  {
    title: 'First Name',
    dataIndex: 'first_name',
    key: 'first_name'
  },
  {
    title: 'Middle Name',
    dataIndex: 'middle_name',
    key: 'middle_name'
  },
  {
    title: 'Last Name',
    dataIndex: 'last_name',
    key: 'last_name'
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
    key: 'gender'
  },
  {
    title: 'Date of Birth',
    dataIndex: 'dob',
    key: 'dob'
  },
  {
    title: "Donor's Phone Number",
    dataIndex: 'phone',
    key: 'phone'
  },
  {
    title: 'Allergic To',
    dataIndex: 'allergic_to',
    key: 'allergic_to'
  },
  {
    title: 'Allergy Comments',
    dataIndex: 'allergy_comments',
    key: 'allergy_comments'
  },
  {
    title: 'Emergency Contact Name',
    dataIndex: 'emergency_contact_name',
    key: 'emergency_contact_name'
  },
  {
    title: 'Emergency Contact Number',
    dataIndex: 'emergency_contact_number',
    key: 'emergency_contact_number'
  },
  {
    title: 'Vein Grade L',
    dataIndex: 'vein_grade_left',
    key: 'vein_grade_left'
  },
  {
    title: 'Vein Grade R',
    dataIndex: 'vein_grade_right',
    key: 'vein_grade_right'
  },
  {
    title: 'Address 1',
    dataIndex: 'addr1',
    key: 'addr1'
  },
  {
    title: 'Address 2',
    dataIndex: 'addr2',
    key: 'addr2'
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city'
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state'
  },
  {
    title: 'Zip Code',
    dataIndex: 'zipcode',
    key: 'zipcode'
  }
  /* {
    title: 'Address Verified',
    dataIndex: 'address_verified',
    key: 'address_verified'
  },
  {
    title: 'Donor Status',
    dataIndex: 'donor_status',
    key: 'donor_status'
  },
  {
    title: 'Donor Type',
    dataIndex: 'donor_type',
    key: 'donor_type'
  },
  {
    title: 'NDDR Transaction ID',
    dataIndex: 'nddr',
    key: 'nddr'
  },
  {
    title: 'NDDR Check',
    dataIndex: 'nddr_check',
    key: 'nddr_check'
  },
  {
    title: 'NDDR Transaction Date',
    dataIndex: 'nddr_date',
    key: 'nddr_date'
  },
  {
    title: 'Last SPE Sample Date',
    dataIndex: 'spe_sample_date',
    key: 'spe_sample_date'
  },
  {
    title: 'Last Full Physical Date',
    dataIndex: 'last_full_physical_date',
    key: 'last_full_physical_date'
  } */
]

function DonorUpload (props) {
  const [rows, setRows] = useState([])
  const [invalidFile, setInvalidFile] = useState(false)
  const [errorsList, setErrorsList] = useState([])
  const [result, setResult] = useState([])

  const handleFileChange = e => {
    setResult([])
    const file = e.target.files[0]
    if (
      file &&
      file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      setInvalidFile(false)
      readXlsxFile(file, {
        dateFormat: 'yyyy-mm-dd',
        schema
      }).then(data => {
        const { rows, errors } = data
        if (errors.length) {
          setErrorsList(errors)
          setRows(rows)
        } else {
          setErrorsList([])
          setRows(rows)
        }
      })
    } else {
      setInvalidFile(true)
    }
  }

  const resetFile = () => {
    document.getElementById('donorfile').value = ''
    setErrorsList([])
    setRows([])
    setResult([])
  }

  const formatPhoneNumber = phone => {
    if (phone.includes('(') && phone.includes(')')) {
      return phone;
    } else {
      let string1 = phone.slice(0, 3)
      let string2 = phone.slice(3, 6)
      let string3 = phone.slice(6)
      return '(' + string1 + ') ' + string2 + '-' + string3
    }
  }

  const handleUpload = () => {
    if (!errorsList.length) {
      const newRows =
        rows.length &&
        rows.map(x => {
          x.dob = x.dob ? x.dob.toISOString().slice(0, 10) : null
          x.nddr_date = x.nddr_date
            ? x.nddr_date.toISOString().slice(0, 10)
            : null
          x.spe_sample_date = x.spe_sample_date
            ? x.spe_sample_date.toISOString().slice(0, 10)
            : null
          x.last_full_physical_date = x.last_full_physical_date
            ? x.last_full_physical_date.toISOString().slice(0, 10)
            : null
          x.phone = formatPhoneNumber(x.phone.toString())
          x.emergency_contact_number = formatPhoneNumber(
            x.emergency_contact_number.toString()
          )
          let zip = x.zipcode?.toString()
          let c = 5 - zip.length
          if (c > 0) {
            for (let i = 0; i < c; i++) {
              zip = '0' + zip
            }
          }
          let ssnWithLeadingZeros = x.ssn.toString()
          if (ssnWithLeadingZeros.includes('-')) {
            if (ssnWithLeadingZeros.length < 11) {
              let n = 11 - ssnWithLeadingZeros.length
              for (let i = 0; i < n; i++) {
                ssnWithLeadingZeros = '0' + ssnWithLeadingZeros
              }
            }
          } else {
            if (ssnWithLeadingZeros.length < 9) {
              let n = 9 - ssnWithLeadingZeros.length
              for (let i = 0; i < n; i++) {
                ssnWithLeadingZeros = '0' + ssnWithLeadingZeros
              }
            }
            ssnWithLeadingZeros =
              ssnWithLeadingZeros?.slice(0, 3) +
              '-' +
              ssnWithLeadingZeros?.slice(3, 5) +
              '-' +
              ssnWithLeadingZeros?.slice(5)
          }

          x.ssn = ssnWithLeadingZeros
          x.zipcode = zip
          x.country = 'USA'
          x.gender = x.gender == 'Male' ? 'M' : x.gender === 'Female' ? 'F' : ''
          return x
        })
      props.actions.setLoading()
      const options = {
        method: 'POST',
        headers: {
          Authorization: props.token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newRows)
      }
      msalFetch('/api/reports/uploadDonorFile', options)
        .then(async res => {
          props.actions.clearLoading()
          if (res.ok) {
            resetFile()
            const data = await res.json()
            setResult(data)
          } else {
          }
        })
        .catch(err => {
          props.actions.clearLoading()
        })
    } else {
      toast.error('Please fix errors before uploading')
    }
  }

  return (
    <div className='row mt-4 justify-content-center'>
      <div className='col-md-10'>
        <p className='title'>Donor Upload Utility</p>
        <div className='row'>
          <div className='col-md-4 mb-3'>
            <label htmlFor='donorfile' className='form-label'>
              Select File :
            </label>
            <input
              className='form-control'
              type='file'
              id='donorfile'
              onChange={handleFileChange}
            />
          </div>
          <div className='col-md-2 d-flex align-items-center'>
            <Button
              type='primary'
              danger
              className='mt-2 d-flex align-items-center'
              onClick={resetFile}
            >
              <DeleteOutlined />
            </Button>
          </div>
        </div>
        {invalidFile && (
          <p style={{ fontSize: '12px' }} className='text-danger'>
            Invalid file. Only excel files are allowed
          </p>
        )}
        <Button
          type='primary'
          disabled={errorsList.length || !rows.length}
          onClick={handleUpload}
        >
          Upload
        </Button>
        <div className='row mt-2'>
          {errorsList.length ? (
            <div className='col-md-12 mt-4'>
              <p className='text-danger'>
                Please fix the following errors before uploading
              </p>
              <Table
                columns={errorColumns}
                dataSource={errorsList}
                size='small'
              ></Table>
            </div>
          ) : null}
          {result.length ? (
            <>
              <div className='d-flex justify-content-end'>
               {/*  <Button
                  type='primary'
                  onClick={() => exportToExcel(result, 'UploadReport')}
                  className='mb-1'
                >
                  Download to Excel
                </Button> */}
              </div>
              <Table
                columns={resultColumns}
                dataSource={result}
                size='small'
              ></Table>
            </>
          ) : null}
        </div>
      </div>
      <div className='col-md-12 mt-2'></div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DonorUpload)
