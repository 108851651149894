import styles from '../styles/Search.module.css'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import { formatDate } from '../utilities/format'
import { useHistory } from 'react-router-dom'
import { msalFetch } from '../utilities/msalFetch'
import { Table, Tag, Input, Button } from 'antd'
import { checkValidUCN } from '../utilities/validations'

const columns = [
  {
    title: 'Case Number',
    dataIndex: 'CASE_NO',
    key: 'CASE_NO'
  },
  {
    title: 'Total Volume (Litres)',
    key: 'TOTAL_VOLUME',
    dataIndex: 'TOTAL_VOLUME',
    sorter: (a, b) => a.TOTAL_VOLUME - b.TOTAL_VOLUME
  },
  {
    title: 'Status',
    key: 'STATUS',
    dataIndex: 'STATUS',
    filters: [
      {
        text: 'IN PROGRESS',
        value: 'IN PROGRESS'
      },
      {
        text: 'PENDING QA REVIEW',
        value: 'PENDING QA REVIEW'
      },
      {
        text: 'QA RELEASED',
        value: 'QA RELEASED'
      },
      {
        text: 'SHIPPED',
        value: 'SHIPPED'
      }
    ],
    onFilter: (value, record) => record.STATUS === value,
    render: (_, { STATUS }) => {
      switch (STATUS) {
        case 'IN PROGRESS':
          return <Tag color='geekblue'>{STATUS}</Tag>
        case 'PENDING QA REVIEW':
          return <Tag color='purple'>{STATUS}</Tag>
        case 'QA RELEASED':
          return <Tag color='green'>{STATUS}</Tag>
        case 'SHIPPED':
          return <Tag color='green'>{STATUS}</Tag>
      }
    }
  },
  {
    title: 'QA Release Date',
    key: 'QA_RELEASED_ON',
    dataIndex: 'QA_RELEASED_ON',
    render: (_, { QA_RELEASED_ON }) => {
      return <span>{formatDate(QA_RELEASED_ON)}</span>
    }
  },
  {
    title: 'Shipped',
    key: 'SHIPPED',
    dataIndex: 'SHIPPED',
    render: (_, { SHIPPED }) => {
      return <h5>{SHIPPED?<Tag color='green'>Yes</Tag>:<Tag color='yellow'>No</Tag>}</h5>
    },
    filters: [
      {
        text: 'Shipped',
        value: true
      },
      {
        text: 'Not Shipped',
        value: false
      },
    ],
    onFilter: (value, record) => Boolean(record.SHIPPED)===value,
  },
  {
    title: 'BOL',
    key: 'BOL',
    dataIndex: 'BOL',
    render: (_, { BOL }) => {
      return <span>{BOL}</span>
    }
  },
  {
    title: 'Ship Date',
    key: 'SHIP_DATE',
    dataIndex: 'SHIP_DATE',
    render: (_, { SHIP_DATE }) => {
      return <span>{formatDate(SHIP_DATE)}</span>
    }
  },
]

function CasePackReport (props) {
  const [cases, setCases] = useState([])
  const [loading, setLoading] = useState(true)
  const statuses = [
    'IN PROGRESS',
    'QA RELEASED',
    'PENDING QA REVIEW',
    'SHIPPED'
  ]
  const [searchCase, setSearchCase] = useState(null)
  const [searchUCN, setSearchUCN] = useState(null)
  const [filteredCases, setFilteredCases] = useState([])

  const history = useHistory()

  const convertToLitres = ml => {
    return ml / 1000
  }

  const getCases = () => {
    props.actions.setLoading()
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      }
    }
    msalFetch(`/api/casePacking/getCasePackingReport`, options)
      .then(async res => {
        props.actions.clearLoading()
        let data = await res.json()
        data = data.map(x => {
          x.COMPLETED_ON = x.COMPLETED_ON && formatDate(x.COMPLETED_ON)
          x.TOTAL_VOLUME = convertToLitres(x.TOTAL_VOLUME)
          return x
        })
        setCases(data)
        setFilteredCases(data)
        setLoading(false)
      })
      .catch(err => {
        props.actions.clearLoading()
        toast.error(err)
      })
  }

  useEffect(() => {
    getCases()
  }, [])

  const handleSelectCase = item => {
    const url = `/casePacking/${item.CASE_NO}`
    history.push(url)
  }

  const handleSearch = async () => {
    if (searchCase) {
      const newRows = cases.filter(x => x.CASE_NO === searchCase)
      setFilteredCases(newRows)
    } else if (searchUCN && checkValidUCN(searchUCN)) {
      props.actions.setLoading()
      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.token
        }
      }
      msalFetch(`/api/reports/getCaseNumberByUCN/${searchUCN}`, options)
        .then(async res => {
          props.actions.clearLoading()
          let data = await res.json()
          if (data.case_no) {
            const newRows = cases.filter(x => x.CASE_NO === data.case_no)
            setFilteredCases(newRows)
          } else {
            toast.error('UCN is not found or case packed')
          }
        })
        .catch(err => {
          props.actions.clearLoading()
          toast.error(err)
        })
    } else {
      getCases()
    }
  }

  return (
    <React.Fragment>
      <div className='row mt-2 justify-content-center'>
        <div className='col-md-10 mt-4'>
          <div className='row'>
            <h3 className={`col-sm-4 ${styles.donationsTitle}`}>
              Case Packing Report
            </h3>
          </div>
          <div className='row mt-4 d-flex '>
            <div className='col-md-6 d-flex align-items-center justify-content-between'>
              <div>
                <label className='mx-2'>Case Number :</label>
                <Input
                  style={{ width: '200px' }}
                  disabled={!!searchUCN}
                  allowClear
                  onChange={e => setSearchCase(e.target.value)}
                />
              </div>
              <div>
                <label className='mx-2'>UCN :</label>
                <Input
                  style={{ width: '200px' }}
                  disabled={!!searchCase}
                  allowClear
                  onChange={e => setSearchUCN(e.target.value)}
                />
              </div>
              <Button type='primary' onClick={handleSearch}>
                Apply
              </Button>
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-sm-10'>
              <Table
                columns={columns}
                dataSource={filteredCases}
                onRow={record => {
                  return {
                    onClick: e => {
                      handleSelectCase(record)
                    }
                  }
                }}
              ></Table>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token,
    isAuthenticated: state.isAuthenticated
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CasePackReport)
