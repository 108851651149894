import React, { useState } from 'react'
import BodyMap from '../../images/Body-map.png'
import { Calendar } from 'primereact/calendar'
import moment from 'moment'
import { Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

const locations = []
for (let i = 0; i < 48; i++) {
  locations.push(i + 1)
}

function BodyArt (props) {
  const [temp, setTemp] = useState({})
  const [error, setError] = useState('')

  const handleChange = e => {
    setError('')
    if (e.target.name === 'date') {
      e.target.value = moment(e.target.value).format('MM/DD/YYYY')
    }
    setTemp({ ...temp, [e.target.name]: e.target.value })
  }

  const handleAddMarking = e => {
    e.preventDefault()
    if (temp.markingType && temp.date && temp.location) {
      const lastThreeMonths = moment().diff(
        moment(temp.date, 'MM/DD/YYYY'),
        'days'
      )
      if (lastThreeMonths <= 90) {
        temp.last_three_months = true
      } else {
        temp.last_three_months = false
      }
      props.setMarkings([...props.markings, temp])
      setTemp({
        markingType: '',
        comments: '',
        date: '',
        location: ''
      })
      setError('')
      e.target.reset()
    } else {
      setError('Please fill all the mandatory fields')
    }
  }

  const handleDeleteMarking = index => {
    const newMarkings = props.markings.filter((x, i) => {
      return index !== i
    })
    props?.setMarkings(newMarkings)
  }

  return (
    <div className='row'>
      <div className='col-md-7'>
        {props.readOnly ? null : (
          <form onSubmit={handleAddMarking}>
            <div className='row mb-2'>
              <label for='type' className='col-sm-3 col-form-label text-end'>
                Type<span style={{ color: 'red' }}>&#42;</span>:
              </label>
              <div className='col-sm-6'>
                <select
                  className='form-select form-select-sm'
                  name='markingType'
                  value={temp.markingType}
                  onChange={handleChange}
                  disabled={props.formDisabled}
                >
                  <option value={''} disabled selected>
                    Select
                  </option>
                  <option value={'Tattoo'}>Tattoo</option>
                  <option value={'Piercing'}>Piercing</option>
                </select>
              </div>
            </div>
            <div className='row mb-1'>
              <label
                for='location'
                className='col-sm-3 col-form-label text-end'
              >
                Location<span style={{ color: 'red' }}>&#42;</span>:
              </label>
              <div className='col-sm-6'>
                <select
                  className='form-select form-select-sm'
                  id='location'
                  name='location'
                  value={temp.location}
                  onChange={handleChange}
                  disabled={props.formDisabled}
                >
                  <option value={''} disabled selected>
                    Select
                  </option>
                  {locations?.map(x => {
                    return (
                      <option key={x} value={x}>
                        {x}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className='row mb-1'>
              <label for='date' className='col-sm-3 col-form-label text-end'>
                Date (Approx)<span style={{ color: 'red' }}>&#42;</span>:
              </label>
              <div className='col-sm-6'>
                <Calendar
                  style={{ height: '35px' }}
                  dateFormat='mm/dd/yy'
                  name='date'
                  onChange={handleChange}
                  disabled={props.formDisabled}
                ></Calendar>
              </div>
            </div>
            <div className='row mb-2'>
              <label
                for='comments'
                className='col-sm-3 col-form-label text-end'
              >
                Comments<span style={{ color: 'red' }}>&#42;</span>:
              </label>
              <div className='col-sm-6'>
                <textarea
                  className='form-control'
                  id='comments'
                  value={temp.comments}
                  onChange={handleChange}
                  name='comments'
                  disabled={props.formDisabled}
                ></textarea>
              </div>
            </div>
            {error ? (
              <div className='row'>
                <p className='text-danger col-md-4 offset-3'>{error}</p>
              </div>
            ) : null}
            <div className='row'>
              <div className='col-md-3 d-flex justify-content-end'>
                <button
                  className='btn btn-primary btn-sm'
                  disabled={props.formDisabled}
                >
                  ADD
                </button>
              </div>
            </div>
          </form>
        )}
        <table className='table table-sm table-striped my-3 table-bordered'>
          <thead>
            <tr>
              <th>Type</th>
              <th>Location</th>
              <th>Date</th>
              <th>In the last 3 months?</th>
              <th>Comments</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {props.markings?.map((x, i) => {
              return (
                <tr
                  key={i}
                  className={x.last_three_months ? 'table-danger' : null}
                >
                  <td>{x.markingType}</td>
                  <td>{x.location}</td>
                  <td>{x.date}</td>
                  <td>{x.last_three_months?'Yes':'No'}</td>
                  <td>{x.comments}</td>
                  <td>
                    <Button disabled={!!props.readOnly}>
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => handleDeleteMarking(i)}
                      ></FontAwesomeIcon>
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className='row mb-2'>
          <label for='comments' className='col-sm-3 col-form-label text-end'>
            Physician Sub Comments<span style={{ color: 'red' }}>&#42;</span>:
          </label>
          <div className='col-sm-6'>
            <textarea
              className='form-control'
              name='bodyMarkingsPhySubComments'
              value={props.bodyMarkingsPhySubComments}
              onChange={e =>
                props.setBodyMarkingsPhySubComments(e.target.value)
              }
              disabled={props.formDisabled}
            ></textarea>
          </div>
        </div>
        <div className='row mb-2'>
          <strong>
            Donor Acknowledgement: My signature below indicates I have reviewed
            the above documentation regarding my piercings, brandings, and
            tattoos. I confirm this information including number of each body
            art, dates of receipt, and description is accurate, and I understand
            that if future discrepancies are noted, I may be permanently
            deferred from donations. I also agree to promptly inform donor
            center staff upon the receipt of any additional tattoos, piercings,
            brandings, scarification, or any type of permanent make-up.
          </strong>
        </div>
        <div className='row mb-2'>
          <label for='comments' className='col-sm-3 col-form-label text-end'>
            Donor Signature<span style={{ color: 'red' }}>&#42;</span>:
          </label>
          <div className='col-sm-6'>
            <input
              type='text'
              className='form-control'
              name='bodyMarkingsDonorSign'
              value={props.bodyMarkingsDonorSign}
              onChange={e => props.setBodyMarkingsDonorSign(e.target.value)}
              disabled={props.formDisabled}
            ></input>
          </div>
        </div>
        <div className='row mb-2'>
          <label for='comments' className='col-sm-3 col-form-label text-end'>
            Date:
          </label>
          <div className='col-sm-6 d-flex align-items-center'>
            <p className='p-0 m-0'>
              {props.assessmentDate
                ? moment(props.assessmentDate).format('MM/DD/YYYY')
                : moment().format('MM/DD/YYYY')}
            </p>
          </div>
        </div>
        {props.readOnly ? null : (
          <div className='row mb-2'>
            <div className='col-md-4'>
              <Button
                type='primary'
                onClick={() => props.setAssessmentPage('4')}
              >
                Next
              </Button>
            </div>
          </div>
        )}
      </div>

      <div className='col-md-5'>
        <img src={BodyMap} alt='bodymap' width={500}></img>
      </div>
    </div>
  )
}

export default BodyArt
