import React, { useEffect, useState } from 'react'
import { Input, Select, DatePicker } from 'antd'
import { InputMask } from 'primereact/inputmask'
import moment from 'moment'
import { toast } from 'react-toastify'
const { TextArea } = Input

const frequencyPm = [
  {
    value: 'Annual',
    label: 'Annual'
  },
  {
    value: 'Quarterly',
    label: 'Quarterly'
  },
  {
    value: 'Monthly',
    label: 'Monthly'
  },
  {
    value: 'Weekly',
    label: 'AnWeeklynual'
  },
  {
    value: 'Daily',
    label: 'Daily'
  }
]

const equipStatus = [
  {
    value: 'In-Service',
    label: 'In-Service'
  },
  {
    value: 'OOS',
    label: 'OOS'
  },
  {
    value: 'Retired',
    label: 'Retired'
  }
]

function UpdateEquipmentDetails (props) {
  const [formData, setFormData] = useState({})
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (Object.keys(props.data)) {
      const temp = {}
      temp.centerCode = props.data.center_code
      temp.area = props.data.area
      temp.station = props.data.station
      temp.description = props.data.description
      temp.manufacturer = props.data.manufacturer
      temp.machineNo = props.data.machine_no
      temp.serialNo = props.data.serial_no
      temp.dateInService = moment(props.data.date_in_service).format(
        'MM/DD/YYYY'
      )
      temp.pmFrequency = props.data.pm_frequency
      temp.status = props.data.status
      temp.nextPmDate = moment(props.data.next_pm_date).format('MM/DD/YYYY')
      setFormData(temp)
    }
  }, [props.data])

  const getCenters = () => {
    if (props.centers.length) {
      return props.centers.map(x => {
        return {
          value: x.center_code,
          label: x.center_code
        }
      })
    }
  }

  const getAreas = () => {
    if (props.centers.length && formData.centerCode) {
      const center = props.centers.find(x => {
        return x.center_code === formData.centerCode
      })
      if (center) {
        return Object.keys(JSON.parse(center.center_config)).map(x => {
          return {
            label: x,
            value: x
          }
        })
      } else {
        return []
      }
    }
  }

  const getStations = () => {
    if (props.centers.length && formData.centerCode && formData.area) {
      const centerData = props.centers.find(
        x => x.center_code === formData.centerCode
      )
      const metaData = JSON.parse(centerData.center_config)
      const filteredStations = metaData[formData.area]
      if (filteredStations) {
        return filteredStations.map(x => {
          return {
            label: x,
            value: x
          }
        })
      }
      return []
    }
  }

  const handleChange = e => {
    setErrors({})
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSelectChange = (prop, value) => {
    setErrors({})
    setFormData({ ...formData, [prop]: value })
  }

  const handleDateChange = (s, key) => {
    const date = moment(s, 'MM/DD/YYYY').format('YYYY-MM-DD')
    setErrors({})
    setFormData({ ...formData, [key]: date })
  }

  const validateForm = () => {
    const errors = {}
    if (!formData.centerCode) {
      errors.centerCode = 'Center code is required'
    }
    if (!formData.area) {
      errors.area = 'Area is required'
    }
    if (!formData.station) {
      errors.station = 'Station is required'
    }
    if (!formData.description) {
      errors.description = 'Description is required'
    }
    if (!formData.manufacturer) {
      errors.manufacturer = 'Manufacturer is required'
    }
    if (!formData.machineNo) {
      errors.machineNo = 'Machine No is required'
    }
    if (!formData.serialNo) {
      errors.serialNo = 'Serial number is required'
    }
    if (!formData.dateInService) {
      errors.dateInService = 'Date in service is required'
    }
    if (!formData.pmFrequency) {
      errors.pmFrequency = 'PM Frequency is required'
    }
    if (!formData.nextPmDate) {
      errors.nextPmDate = 'Next PM is required'
    }
    if (!formData.status) {
      errors.status = 'Status is required'
    }
    if (
      formData.dateInService &&
      !moment(formData.dateInService, 'MM/DD/YYYY').isValid()
    ) {
      errors.dateInService = 'Invalid date - should be MM/DD/YYYY'
    }
    if (
      formData.nextPmDate &&
      !moment(formData.nextPmDate, 'MM/DD/YYYY').isValid()
    ) {
      errors.nextPmDate = 'Invalid date - should be MM/DD/YYYY'
    }
    setErrors(errors)
    if (Object.keys(errors).length) {
      return false
    }
    return true
  }

  const submit = e => {
    e.preventDefault()
    if (validateForm()) {
      props.updateEquipment(formData)
    } else {
      toast.error('Please correct the form')
    }
  }
  return (
    <form onSubmit={submit}>
      <div className='row my-2'>
        <div className='form-group col-md-6'>
          <label htmlFor='centercode'>Center Code:</label>
          <Select
            options={getCenters()}
            onChange={value => handleSelectChange('centerCode', value)}
            className='w-100'
            name='centerCode'
            value={formData.centerCode}
            placeholder='Select Center'
          />
          {errors.centerCode ? (
            <span className='invalidInput'>{errors.centerCode}</span>
          ) : null}
        </div>
        <div className='form-group col-md-6'>
          <label htmlFor='area'>Area:</label>
          <Select
            options={getAreas()}
            onChange={value => handleSelectChange('area', value)}
            className='w-100'
            name='area'
            value={formData.area}
            placeholder='Select Area'
            disabled={!formData.centerCode}
          />
          {errors.area ? (
            <span className='invalidInput'>{errors.area}</span>
          ) : null}
        </div>
      </div>
      <div className='row my-2'>
        <div className='form-group col-md-6'>
          <label htmlFor='station'>Station:</label>
          <Select
            options={getStations()}
            onChange={value => handleSelectChange('station', value)}
            className='w-100'
            name='station'
            value={formData.station}
            placeholder='Select Station'
            disabled={!formData.center_code && !formData.area}
          />
          {errors.station ? (
            <span className='invalidInput'>{errors.station}</span>
          ) : null}
        </div>
        <div className='form-group col-md-6'>
          <label htmlFor='description'>Equipment Description:</label>
          <TextArea
            id='description'
            onChange={handleChange}
            className='form-control'
            name='description'
            value={formData.description}
            placeholder='Enter Equipment description'
          />
          {errors.description ? (
            <span className='invalidInput'>{errors.description}</span>
          ) : null}
        </div>
      </div>
      <div className='row my-2'>
        <div className='form-group col-md-6'>
          <label htmlFor='manufacturer'>Manufacturer:</label>
          <Input
            id='manufacturer'
            onChange={handleChange}
            className='form-control'
            name='manufacturer'
            value={formData.manufacturer}
            placeholder='Enter manufacturer'
          />
          {errors.manufacturer ? (
            <span className='invalidInput'>{errors.manufacturer}</span>
          ) : null}
        </div>
        <div className='form-group col-md-6'>
          <label htmlFor='machine_no'>Machine Number:</label>
          <Input
            id='machine_no'
            onChange={handleChange}
            className='form-control form-control-md'
            name='machineNo'
            value={formData.machineNo}
            placeholder='Enter machine number'
          />
          {errors.machineNo ? (
            <span className='invalidInput'>{errors.machineNo}</span>
          ) : null}
        </div>
      </div>
      <div className='row my-2'>
        <div className='form-group col-md-6'>
          <label htmlFor='serialNo'>Serial Number:</label>
          <Input
            id='serialNo'
            onChange={handleChange}
            className='form-control form-control-md'
            name='serialNo'
            value={formData.serialNo}
            placeholder='Enter serial number'
            disabled
          />
          {errors.serialNo ? (
            <span className='invalidInput'>{errors.serialNo}</span>
          ) : null}
        </div>
        <div className='form-group col-md-6'>
          <label htmlFor='dateInService'>Date in Service:</label>
          <InputMask
            id='dateInService'
            mask='99/99/9999'
            placeholder='MM/DD/YYYY'
            className='form-control'
            onChange={handleChange}
            name='dateInService'
            value={formData.dateInService}
          ></InputMask>
          {errors.dateInService ? (
            <span className='invalidInput'>{errors.dateInService}</span>
          ) : null}
        </div>
      </div>
      <div className='row my-2'>
        <div className='form-group col-md-6'>
          <label htmlFor='station'>Frequency of PM:</label>
          <Select
            options={frequencyPm}
            onChange={value => handleSelectChange('pmFrequency', value)}
            className='w-100'
            name='pmFrequency'
            value={formData.pmFrequency}
            placeholder='Select PM frequency'
          />
          {errors.pmFrequency ? (
            <span className='invalidInput'>{errors.pmFrequency}</span>
          ) : null}
        </div>
        <div className='form-group col-md-6'>
          <label htmlFor='nextPmDate'>Next PM Date:</label>
          <InputMask
            id='nextPmDate'
            mask='99/99/9999'
            placeholder='MM/DD/YYYY'
            className='form-control'
            onChange={handleChange}
            name='nextPmDate'
            value={formData.nextPmDate}
          ></InputMask>
          {errors.nextPmDate ? (
            <span className='invalidInput'>{errors.nextPmDate}</span>
          ) : null}
        </div>
      </div>
      <div className='row my-2'>
        <div className='form-group col-md-6'>
          <label htmlFor='status'>Status:</label>
          <Select
            options={equipStatus}
            onChange={value => handleSelectChange('status', value)}
            className='w-100'
            name='status'
            value={formData.status}
            placeholder='Select equipment status'
          />
          {errors.status ? (
            <span className='invalidInput'>{errors.status}</span>
          ) : null}
        </div>
      </div>
      <div className='row my-3'>
        <div className='form-group col-md-6'>
          <button className='btn btn-primary'>Submit</button>
        </div>
      </div>
    </form>
  )
}

export default UpdateEquipmentDetails
