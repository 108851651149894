import React from 'react'
import { formatDate } from '../../utilities/format'
import { Button } from 'antd'

function InformedConsent (props) {
  const handleNext = () => {
    if (props.assessmentType === 'Full Physical') {
      props.setAssessmentPage('3')
    } else {
      props.setAssessmentPage('4')
    }
  }

  return (
    <div
      className='mt-4'
      style={{ fontFamily: 'Font-Regular', color: '#000000' }}
    >
      <ol>
        <li>
          I understand the purpose of plasmapheresis is to obtain blood plasma
          without depriving me of red blood cells.
        </li>
        <li>
          The plasmapheresis collection procedure will be performed utilizing an
          automated blood processor. This technique involves the following:{' '}
          <br />
          A. Approximately one pint or less of blood will be drawn into a
          separation device to separate it into plasma and red blood cells.{' '}
          <br />
          B. Removal of the plasma portion of the blood into a separate
          collection container. <br />
          C. Under aseptic procedure, the remaining red blood cells are returned
          to me. <br />
          D. Steps A, B, and C are repeated until a specific amount of plasma
          based on my weight is obtained. <br />
          E. There is a possibility that a repeat venipuncture might be
          necessary in some instances
        </li>
        <li>
          I HAVE BEEN INSTRUCTED IN THE BASICS OF THE AUTOMATED PHERESIS
          PROCEDURE. I HAVE BEEN GIVEN THE OPPORTUNITY TO ASK QUESTIONS I MAY
          HAVE REGARDING THE PROCEDURE. I UNDERSTAND THAT IT IS MY
          RESPONSIBILITY TO NOTIFY THE TECHNICIAN OF ANY DIFFICULITIES I MAY
          EXPERIENCE WHILE I AM USING THE AUTOMATED BLOOD PROCESSOR AND DURING
          THE ENTIRE PLASMAPHERESIS PROCEDURE. I UNDERSTAND THAT FAILURE TO
          NOTIFY THE TECHNICIAN MAY RESULT IN SERIOUS INJURY TO ME.
        </li>
        <li>
          I will be accepted for automated plasmapheresis no more than once in
          two calendar days and twice in any seven-day period.{' '}
        </li>
        <li>
          I will also only participate in one blood related donation program.
          While I am participating in the plasma donation program, I will not
          participate in any whole blood donation program. The donation of Whole
          Blood or Red Blood Cells while participating in the plasma donation
          program may serve as a basis for an 8-week deferral or a 16-week
          deferral for a double Red Blood Cell donation
        </li>

        <li>
          The protein or hemoglobin levels of my blood may be reduced by
          repeated plasmapheresis, which may require that I be removed
          temporarily from the donor program
        </li>
        <li>
          There is a possibility that my red blood cells may not be returned to
          me due to equipment failure, poor veins, or contamination of the red
          blood cells during the plasmapheresis process. If this occurs, federal
          regulations require that I be removed from the donor program for eight
          weeks during which time no fees will be paid to me
        </li>
        <li>
          During or shortly after the plasmapheresis procedure, there also is
          the possibility of experiencing - nausea, vomiting, and/or convulsions
          related to the change in blood volume and/or light-headedness and
          seizures
        </li>
        <li>
          Some individuals experience discomfort such as pain or itching at the
          infusion site, dizziness, or fainting. In addition, there is a
          possibility that a blood clot or infiltration (leakage of fluids or
          blood into the surrounding tissues) will occur at the venipuncture
          site. There is also a possibility of infection of the skin, the
          surrounding tissue, or the vein itself. A temporary rash may develop
          where the skin antiseptics are applied. A scar may develop at the
          venipuncture site.
        </li>
        <li>
          Although rare, some individuals may experience allergic reactions such
          as flushing, hives, abdominal cramps, difficulty breathing, chest
          pain, and/or bronchospasm which may possibly be life threatening.
        </li>
        <li>
          There is a possibility I may experience an adverse reaction or injury
          during plasmapheresis. Due to the use of Sodium Citrate as a blood
          anticoagulant, there is a possibility I may experience tingling of the
          fingers, mouth, or hand, or muscle cramps. If any of these occur, I am
          to notify a technician immediately. If such occurs, the physician or
          designated personnel will administer appropriate treatment as needed
          or will refer me to a local medical facility for treatment.
        </li>
        <li>
          I understand I must drink plenty of non-alcoholic, non-caffeinated
          fluids before and after every donation. I understand that I should
          drink enough water, non-caffeinated fluids, or electrolyte type drinks
          to replenish fluids removed.
        </li>
        <li>
          13. I have been given, understand, and agree to adhere to the
          following precautionary instructions which are intended to protect my
          health and well-being following this procedure:
          <br></br>A. After the donation, any trouble at the venipuncture site
          (bleeding, pain, redness, itching, bruising, swelling, or rash) must
          be shown to the center manager, assistant manager, physician, or
          physician substitute as soon as it appears, when the center is open.
          <br></br>B. Keep the needle puncture site clean
          <br></br>C. Do not wash the area (needle puncture site) for at least 2
          hours after the donation
          <br></br>D. Do not touch or pick at the venipuncture site.
        </li>
        <li>
          I understand that for my health and safety and that of the recipients
          of products made from my plasma, my blood will be tested for:
          <br />
          A. Hepatitis B
          <br />
          B. Hepatitis C
          <br />
          C. HIV
          <br />
          D. A serologic test for syphilis, protein levels, presence of certain
          atypical antibodies and other tests as required.
        </li>
        <li>
          Potential consequences if the results are reactive, positive, or
          outside of normal limits: <br />
          a. If the Hepatitis B, Hepatitis C, or HIV test of my blood is
          positive, I understand that I will be permanently deferred from
          donating blood or plasma and I will be informed of the positive
          results of a test or tests in a confidential manner at the center. My
          name will be placed on all applicable permanent deferral lists.
          Additionally, such information is also exchanged with other plasma
          centers in the local area. This information will also be supplied to
          appropriate authorities in localities, states, or the Federal
          Government that require the reporting of certain diseases, or that is
          otherwise required by law, or that is deemed necessary and appropriate
          in the sole discretion of the corporation. <br />
          b. If any of the other tests are outside required limits, I will be
          temporarily deferred from donation until test results return to
          acceptable values.
          <br />
          c. I understand that the results of these tests will be accessible to
          the facility manager, operations supervisor, Licensed physician,
          physician substitute, and regulatory department officials. I
          understand the above information on these screening tests and agree
          they may be performed on my blood / plasma. I understand that my
          acceptability to donate Source Plasma will be determined by a medical
          interview, physical examination, and laboratory testing. Certain
          procedures will be performed at each visit; others will be performed
          periodically. I understand that I will be examined by a qualified
          licensed Physician (responsible Physician) or trained Physician
          Substitute initially and annually thereafter. If these evaluations so
          indicate, I may be temporarily or permanently deferred from donating
          Source Plasma
        </li>
        <li>
          1I have been given the opportunity to ask any questions I choose about
          these procedures. My participation is completely voluntary, and I can
          withdraw my consent and discontinue participation in the automated
          plasmapheresis program at any time.
        </li>
        <li>
          I understand that my suitability for the plasma donation program is
          based on a medical examination as well my answers given to the medical
          and lifestyle history questions asked of me.
        </li>
        <li>
          I have reviewed and understand the information provided to me
          regarding the spread of the HIV virus by donated blood or plasma and
          if I consider myself to be a person at risk for spreading a virus
          known to cause HIV, I agree not to donate blood or plasma for
          transfusion to another person or for further manufacture.
        </li>
        <li>
          The donor center reserves the right to refuse plasma donations for any
          reason, from any individual, and without further explanation.
        </li>
        <li>
          A sample of blood will be drawn on the day of the initial physical
          examination or plasmapheresis, whichever comes first, and at least
          every 4 months thereafter to perform a serologic test for Syphilis, a
          Total Plasma or Serum Protein, including immunoglobin levels.
          <br />
          a.If the results are low or not within normal limits, you will be
          removed from the program until the values return to normal. You will
          be temporarily deferred if your lab results repeatedly show low or not
          within normal levels for Total Plasma or Serum Protein, including
          immunoglobin levels.
          <br />
          b. You will also be temporarily deferred if you have a reactive
          serologic test for Syphilis. You will only be re-admitted to the
          program one year after you complete treatment for Syphilis, as
          indicated by a letter from your physician.
        </li>
        <li>
          <strong>
            I have read and understand this consent agreement, and I agree to
            participate in the automated plasmapheresis program under the above
            conditions. I hereby certify and declare under the penalty of
            perjury, that to the best of my knowledge, I do not have any signs
            or symptoms of HIV and that I am not a member of any high-risk group
            for HIV.
          </strong>
        </li>
        <li>
          <strong>
            I have been informed that my participation in this donation program
            is voluntary and I may withdraw at any time. In addition, I have
            been provided a clear opportunity to refuse.
          </strong>
        </li>
      </ol>
      <div className='mb-3 row'>
        <label for='donorSign' className='col-sm-2 col-form-label'>
          <h6>
            Donor Signature<span style={{ color: 'red' }}>&#42;</span>:
          </h6>
        </label>
        <div className='col-sm-4'>
          <input
            type='text'
            className='form-control'
            id='donorSign'
            onChange={e => props.setDonorSign(e.target.value)}
            value={props.donorSign}
            disabled={props.readOnly || props.healthAssessmentFormData?.STATUS === 'Complete'}
          ></input>
        </div>
        <h5>
          I am signing this document electronically. I agree that my electronic
          signature is the legal equivalent of my manual/handwritten signature
          on this document.
        </h5>
      </div>
      <h6>Date : {formatDate(new Date().toLocaleDateString())}</h6>
      {props.readOnly ? null : (
        <div className='row mt-4'>
          <div className='col-md-4'>
            <Button type='primary' onClick={handleNext}>
              Next
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default InformedConsent
