import React, { useState, useEffect } from 'react'
import { Table, Alert } from 'antd'
import { request } from '../../utilities/msalFetch'
import Loader from '../../components/Loader'
import moment from 'moment'
import Accordion from 'react-bootstrap/Accordion'

import Modal from 'react-bootstrap/Modal'
import FollowUpForm from './Components/FollowUpForm'

const medicationColumns = [
  { title: 'Type', dataIndex: 'type', key: 'type' },
  { title: 'Lot Number', dataIndex: 'lotNumber', key: 'lotNumber' },
  {
    title: 'Expiration Date',
    dataIndex: 'expirationDate',
    key: 'expirationDate',
    render: text => moment(text).format('MM-DD-YYYY')
  },
  { title: 'Dosage', dataIndex: 'dosage', key: 'dosage' },
  { title: 'Route', dataIndex: 'route', key: 'route' },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time'
  },
  { title: 'Performed By', dataIndex: 'performedBy', key: 'performedBy' }
]

const followUpCols = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: text => moment(text).format('MM-DD-YYYY')
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time'
  },
  {
    title: 'Donor Condition',
    dataIndex: 'donorCondition',
    key: 'donorCondition'
  },
  {
    title: 'Donor Instructed',
    dataIndex: 'donorInstructed',
    key: 'donorInstructed'
  },
  {
    title: 'Person Contacted',
    dataIndex: 'personContacted',
    key: 'personContacted'
  },
  {
    title: 'Information Received',
    dataIndex: 'informationReceived',
    key: 'informationReceived'
  },
  { title: 'Performed By', dataIndex: 'performedBy', key: 'performedBy' }
]

const vitalSignsColumns = [
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time'
  },
  { title: 'Blood Pressure', dataIndex: 'bloodpressure', key: 'bloodpressure' },
  { title: 'Pulse', dataIndex: 'pulse', key: 'pulse' },
  { title: 'Performed By', dataIndex: 'performedBy', key: 'performedBy' }
]

const MIRReports = ({ donorId }) => {
  const [records, setRecords] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [record, setRecord] = useState(null)
  const [modal, setModal] = useState(false)

  useEffect(() => {
    const fetchRecords = async () => {
      try {
        const response = await request.get(
          `/api/mir/getMirByDonorId/${donorId}`
        )
        setRecords(response.data.data)
        setLoading(false)
      } catch (err) {
        setError('Failed to fetch records')
        setLoading(false)
      }
    }

    fetchRecords()
  }, [donorId])

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <Alert message={error} type='error' />
  }

  const columns = [
    { title: 'MIR Number', dataIndex: 'mir_id', key: 'mir_id' },
    { title: 'Center Code', dataIndex: 'center_code', key: 'center_code' },
    { title: 'UCN', dataIndex: 'ucn', key: 'ucn' },
    {
      title: 'Date of Reaction',
      dataIndex: 'date_of_reaction',
      key: 'date_of_reaction',
      render: text => moment(text).format('MM-DD-YYYY')
    },
    { title: 'Donor ID', dataIndex: 'donor_id', key: 'donor_id' },
    { title: 'Donor Name', dataIndex: 'donor_name', key: 'donor_name' },
    {
      title: 'Time of Reaction',
      dataIndex: 'time_of_reaction',
      key: 'time_of_reaction'
    }
  ]

  const handleRowClick = rowRecord => {
    const record = { ...rowRecord }
    setModal(true)
    record.collectionOfVolume = JSON.parse(record.collection_of_volume)
    record.typeOfReaction = JSON.parse(record.type_of_reaction)
    record.treatment = JSON.parse(record.treatment)
    record.donorRecoveryAndDesposition = JSON.parse(
      record.donor_recovery_and_desposition
    )
    record.physician = JSON.parse(record.physician)
    record.followUp = record.follow_up && JSON.parse(record.follow_up)
    setRecord(record)
  }

  const handleCloseModal = () => {
    setModal(false)
    setRecord(null)
  }

  const addFollowup = followUps => {
    let currentRecords = [...records]
    let recordIndex = currentRecords.findIndex(f => f.mir_id === record.mir_id)
    currentRecords[recordIndex] = {
      ...currentRecords[recordIndex],
      follow_up: followUps
    }
    setRecords(currentRecords)
  }

  return (
    <div className='row justify-content-center m-1'>
      <div className='col-md-11 col-sm-12'>
        <h2 className='card-title  my-4'>Medical Incident Reports</h2>
        <Table
          dataSource={records}
          columns={columns}
          pagination={false}
          rowKey='mir_id'
          onRow={record => ({
            onClick: () => handleRowClick(record)
          })}
        />

        {record && (
          <Modal
            centered
            show={modal}
            onHide={handleCloseModal}
            fullscreen={true}
          >
            <Modal.Header closeButton>
              <Modal.Title className='p-2'>Medical Incident Report</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modelContent'>
              <Accordion defaultActiveKey='0'>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header>Donor Information</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      <strong>UCN:</strong> {record?.ucn}
                    </p>
                    <p>
                      <strong>Donor Name:</strong> {record?.donor_name}
                    </p>
                    <p>
                      <strong>Date of Reaction:</strong>
                      {moment(record?.dateOfReaction).format('MM-DD-YYYY')}
                    </p>
                    <p>
                      <strong>Center Code:</strong> {record?.center_code}
                    </p>
                    <p>
                      <strong>Donor ID:</strong> {record?.donor_id}
                    </p>
                    <p>
                      <strong>Time of Reaction:</strong>{' '}
                      {record?.time_of_reaction}
                    </p>

                    <p>
                      <strong>Timing When Reaction Began:</strong>{' '}
                      {record?.reaction_was_first_known_to_begin}
                    </p>
                    <p>
                      <strong>Classification of Reaction:</strong>{' '}
                      {record?.classification_of_reaction}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1'>
                  <Accordion.Header>
                    Collection volume at time of reaction
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      <strong>Volume of Plasma:</strong>{' '}
                      {record?.collectionOfVolume.volumeOfPlasma}
                    </p>
                    <p>
                      <strong>Amount of Procedural Saline Administered:</strong>{' '}
                      {
                        record?.collectionOfVolume
                          .amountOfProceduralSalineAdministered
                      }
                    </p>
                    <p>
                      <strong>Amount of Citrate Used:</strong>{' '}
                      {record?.collectionOfVolume.amountOfCitrateUsed}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2'>
                  <Accordion.Header>Type of Reaction</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      <strong>Category:</strong>{' '}
                      {record?.typeOfReaction.category}
                    </p>
                    <p>
                      <strong>Sub-Category:</strong>{' '}
                      {record?.typeOfReaction.subCategory}
                    </p>
                    <p>
                      <strong>Symptoms:</strong>{' '}
                      {record?.typeOfReaction.symptoms.join(', ')}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='3'>
                  <Accordion.Header>Treatment</Accordion.Header>
                  <Accordion.Body>
                    <div className='row'>
                      <p className='col-5 m-0'>
                        <strong>Types of Treatment:</strong>{' '}
                        {record?.treatment.typesOfTreatment}
                      </p>
                      <p className='col m-0'>
                        <strong>Oral Fluids Given:</strong>{' '}
                        {record?.treatment.oralFluidsGiven}
                      </p>
                    </div>
                    <div className='mb-2'>
                      <p className='m-0'>
                        <strong>Medication:</strong>
                      </p>

                      <Table
                        dataSource={record?.treatment.medication}
                        columns={medicationColumns}
                        pagination={false}
                        rowKey='type'
                        size='small'
                      />
                    </div>
                    <div className='mb-2'>
                      <p className='m-0'>
                        <strong>Vital Signs:</strong>
                      </p>
                      <Table
                        columns={vitalSignsColumns}
                        dataSource={record?.treatment.vitalSigns}
                        pagination={false}
                        rowKey='time'
                        size='small'
                      />
                    </div>
                    <p>
                      <strong>Medication Comments:</strong>{' '}
                      {record?.treatment.medicationComments}
                    </p>
                    <p>
                      <strong>Description:</strong>{' '}
                      {record?.treatment.description}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='4'>
                  <Accordion.Header>
                    Donor Recovery and Disposition
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      <strong>Vital Signs:</strong>
                    </p>
                    <Table
                      columns={vitalSignsColumns}
                      dataSource={
                        record?.donorRecoveryAndDesposition.vitalSigns
                      }
                      pagination={false}
                      rowKey='time'
                      size='small'
                    />
                    <p>
                      <strong>Condition at Time of Release:</strong>{' '}
                      {
                        record?.donorRecoveryAndDesposition
                          .conditionAtTimeOfRelease
                      }
                    </p>
                    <p>
                      <strong>Recovery Period:</strong>{' '}
                      {record?.donorRecoveryAndDesposition.recoveryPeriod}
                    </p>
                    <p>
                      <strong>EMS Activated Response:</strong>{' '}
                      {record?.donorRecoveryAndDesposition.emsActivatedResponse}
                    </p>
                    <p>
                      <strong>Medical Deferral:</strong>{' '}
                      {record?.donorRecoveryAndDesposition.medicalDeferral}
                    </p>
                    <p>
                      <strong>Donor Phone Number:</strong>{' '}
                      {record?.donorRecoveryAndDesposition.donorPhoneNumber}
                    </p>
                    <p>
                      <strong>Center Physician Notified:</strong>{' '}
                      {
                        record?.donorRecoveryAndDesposition
                          .centerPhysicianNotified
                      }
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='5'>
                  <Accordion.Header>Physician Review</Accordion.Header>
                  <Accordion.Body>
                    <div className='d-flex gap-5'>
                      <p>
                        <strong>Donor Status:</strong>{' '}
                        {record?.physician.donorStatus}
                      </p>
                      {record?.physician.donorStatus ===
                        'Temporarily Deferred' && (
                        <p>
                          <strong>Temporarily Deferral Still:</strong>{' '}
                          {moment(record?.physician.deferralTill).format(
                            'MM-DD-YYYY'
                          )}
                        </p>
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='6'>
                  <Accordion.Header>
                    Follow Up (To be performed within 24 hours of event)
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className='mb-2'>
                      {record?.followUp && record?.followUp.length > 0 && (
                        <Table
                          columns={followUpCols}
                          dataSource={record?.followUp}
                          pagination={false}
                          rowKey='type'
                          size='small'
                          title={() => {
                            return (
                              <h3>
                                <b className='font-bold'>{'Follow up'}</b>
                              </h3>
                            )
                          }}
                        />
                      )}
                    </div>
                    <FollowUpForm
                      mirId={record.mir_id}
                      onClose={handleCloseModal}
                      setFollowUp={addFollowup}
                      followUps={record.followUp}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </div>
  )
}

export default MIRReports
