import React, { useState, useEffect } from 'react'
import { Collapse } from 'antd'
import PhysicalExamination from '../HealthAssessment/PhysicalExamination'
import InformedConsent from '../HealthAssessment/InformedConsent'
import BodyArt from '../HealthAssessment/BodyArt'
import { Calendar } from 'primereact/calendar'

function HealthAssessment (props) {
  const [informConsentSign, setInformedConsentSign] = useState(null)
  const [bodyMarkingsDonorSign, setBodyMarkingsDonorSign] = useState(null)
  const [markings, setMarkings] = useState([])
  const [assessmentType, setAssessmentType] = useState('Full Physical')
  const [answers, setAnswers] = useState({})
  const [errors, setErrors] = useState({})
  const [assessmentResultFormData, setAssessmentResultFormData] = useState({})
  const [formDisabled, setFormDisabled] = useState(true)
  const [bodyMarkingsPhySubComments, setBodyMarkingsPhySubComments] =
    useState('')
  const [missedQuestions, setMissedQuestions] = useState([])

  useEffect(() => {
    if (props.healthAssessment && Object.keys(props.healthAssessment).length) {
      props.healthAssessment.PHYSICAL_ASSESSMENT_ANSWERS &&
        setAnswers(
          JSON.parse(props.healthAssessment.PHYSICAL_ASSESSMENT_ANSWERS)
        )
      props.healthAssessment.ASSESSMENT_TYPE &&
        setAssessmentType(props.healthAssessment.ASSESSMENT_TYPE)
      props.healthAssessment.INFORMED_CONSENT_SIGN &&
        setInformedConsentSign(props.healthAssessment.INFORMED_CONSENT_SIGN)
      const assessmentResult = {
        donorStatus: props.healthAssessment?.DONOR_STATUS,
        assessmentResult: props.healthAssessment?.ASSESSMENT_RESULT,
        comments: props.healthAssessment?.COMMENTS
      }
      setAssessmentResultFormData(assessmentResult)
      props.healthAssessment.BODY_ART_DONOR_SIGN &&
        setBodyMarkingsDonorSign(props.healthAssessment.BODY_ART_DONOR_SIGN)
      props.healthAssessment.BODY_ART_COMMENTS &&
        setBodyMarkingsPhySubComments(props.healthAssessment.BODY_ART_COMMENTS)
      props.healthAssessment.BODY_ART &&
        setMarkings(JSON.parse(props.healthAssessment.BODY_ART))
    }
  }, [props.healthAssessment])

  const generateItems = () => {
    if (assessmentType === 'Full Physical') {
      return [
        {
          key: '1',
          label: 'Physical Assessment',
          children: (
            <PhysicalExamination
              formDisabled={formDisabled}
              questions={props.physicalExamQuestions}
              assessmentType={assessmentType}
              setAssessmentType={setAssessmentType}
              answers={answers}
              setAnswers={setAnswers}
              missedQuestions={missedQuestions}
              setMissedQuestions={setMissedQuestions}
              setAssessmentPage={null}
              readOnly={true}
            />
          )
        },
        {
          key: '2',
          label: 'Informed Consent',
          children: (
            <InformedConsent
              readOnly={true}
              donorSign={informConsentSign}
            ></InformedConsent>
          )
        },
        {
          key: '3',
          label: 'Body Art',
          children: (
            <BodyArt
              readOnly={true}
              markings={markings}
              bodyMarkingsPhySubComments={bodyMarkingsPhySubComments}
              bodyMarkingsDonorSign={bodyMarkingsDonorSign}
              formDisabled={formDisabled}
              assessmentDate={props.healthAssessment?.DATE_OF_ASSESSMENT}
            />
          )
        },
        {
          key: '4',
          label: 'Health Assessment Result',
          children: (
            <>
              <div className='row mb-3'>
                <label
                  htmlFor='healthassessmentresult'
                  className='col-sm-2 col-form-label'
                >
                  Assessment Result<span style={{ color: 'red' }}>&#42;</span>:
                </label>
                <div className='col-sm-4'>
                  <select
                    className='form-select'
                    aria-label='Default select example'
                    id='healthassessmentresult'
                    name='assessmentResult'
                    value={assessmentResultFormData.assessmentResult}
                    required
                    disabled={formDisabled}
                  >
                    <option selected disabled>
                      Select
                    </option>
                    <option value='Pass'>Pass</option>
                    <option value='Fail'>Fail</option>
                  </select>
                </div>
                {errors.assessmentResult ? (
                  <span style={{ color: 'red' }}>
                    {errors.assessmentResult}
                  </span>
                ) : null}
              </div>
              <div className='row mb-3 d-flex'>
                <label
                  className='col-sm-2 col-form-label'
                  htmlFor='donorStatus'
                >
                  Donor Status <span style={{ color: 'red' }}>&#42;</span>:
                </label>
                <div className='col-sm-4'>
                  <select
                    className='form-select'
                    aria-label='Default select example'
                    id='donorStatus'
                    name='donorStatus'
                    required
                    value={assessmentResultFormData.donorStatus}
                    disabled={formDisabled}
                  >
                    <option selected disabled>
                      Select
                    </option>
                    <option value='Approved to Donate'>
                      Approved To Donate
                    </option>
                    <option value='Temporarily Defer'>Temporarily Defer</option>
                    <option value='Permanently Defer'>Permanently Defer</option>
                  </select>
                </div>
                {errors.donorStatus ? (
                  <span style={{ color: 'red' }}>{errors.donorStatus}</span>
                ) : null}
              </div>

              {assessmentResultFormData.donorStatus === 'Temporarily Defer' ? (
                <div className=' row mb-3'>
                  <label htmlFor='deferalldate' className='col-sm-2 '>
                    Deferall Till<span style={{ color: 'red' }}>&#42;</span> :
                  </label>
                  <div className='col-sm-4'>
                    <Calendar
                      format='mm/dd/yy'
                      id='deferalldate'
                      name='deferralTill'
                      value={
                        assessmentResultFormData.deferralTill
                          ? new Date(assessmentResultFormData.deferralTill)
                          : props.donorInfo?.deferral_till
                          ? new Date(props.donorInfo?.deferral_till)
                          : null
                      }
                      disabled={formDisabled}
                    ></Calendar>
                    {errors.deferralTill && (
                      <span style={{ fontSize: '12px', color: 'red' }}>
                        {errors.deferralTill}
                      </span>
                    )}
                  </div>
                </div>
              ) : null}
              <div className='row mb-3'>
                <label className='col-sm-2 col-form-label' htmlFor='comments'>
                  Comments <span style={{ color: 'red' }}>&#42;</span>:
                </label>
                <div className='col-sm-4'>
                  <textarea
                    id='comments'
                    rows={3}
                    style={{ width: '100%' }}
                    className='form-control'
                    name='comments'
                    value={assessmentResultFormData.comments}
                    disabled={formDisabled}
                  ></textarea>
                </div>
                {errors.comments ? (
                  <span style={{ color: 'red' }}>{errors.comments}</span>
                ) : null}
              </div>
            </>
          )
        }
      ]
    } else if (assessmentType === 'Counselling') {
      return [
        {
          key: '1',
          label: 'Physical Assessment',
          children: (
            <PhysicalExamination
              formDisabled={formDisabled}
              questions={props.physicalExamQuestions}
              assessmentType={assessmentType}
              setAssessmentType={setAssessmentType}
              answers={answers}
              setAnswers={setAnswers}
              missedQuestions={missedQuestions}
              setMissedQuestions={setMissedQuestions}
              setAssessmentPage={null}
              readOnly={true}
            />
          )
        },
        {
          key: '2',
          label: 'Health Assessment Result',
          children: (
            <>
              <div className='row mb-3'>
                <label
                  htmlFor='healthassessmentresult'
                  className='col-sm-2 col-form-label'
                >
                  Assessment Result<span style={{ color: 'red' }}>&#42;</span>:
                </label>
                <div className='col-sm-4'>
                  <select
                    className='form-select'
                    aria-label='Default select example'
                    id='healthassessmentresult'
                    name='assessmentResult'
                    value={assessmentResultFormData.assessmentResult}
                    required
                    disabled={formDisabled}
                  >
                    <option selected disabled>
                      Select
                    </option>
                    <option value='Pass'>Pass</option>
                    <option value='Fail'>Fail</option>
                  </select>
                </div>
                {errors.assessmentResult ? (
                  <span style={{ color: 'red' }}>
                    {errors.assessmentResult}
                  </span>
                ) : null}
              </div>
              <div className='row mb-3 d-flex'>
                <label
                  className='col-sm-2 col-form-label'
                  htmlFor='donorStatus'
                >
                  Donor Status <span style={{ color: 'red' }}>&#42;</span>:
                </label>
                <div className='col-sm-4'>
                  <select
                    className='form-select'
                    aria-label='Default select example'
                    id='donorStatus'
                    name='donorStatus'
                    required
                    value={assessmentResultFormData.donorStatus}
                    disabled={formDisabled}
                  >
                    <option selected disabled>
                      Select
                    </option>
                    <option value='Approved to Donate'>
                      Approved To Donate
                    </option>
                    <option value='Temporarily Defer'>Temporarily Defer</option>
                    <option value='Permanently Defer'>Permanently Defer</option>
                  </select>
                </div>
                {errors.donorStatus ? (
                  <span style={{ color: 'red' }}>{errors.donorStatus}</span>
                ) : null}
              </div>

              {assessmentResultFormData.donorStatus === 'Temporarily Defer' ? (
                <div className=' row mb-3'>
                  <label htmlFor='deferalldate' className='col-sm-2 '>
                    Deferall Till<span style={{ color: 'red' }}>&#42;</span> :
                  </label>
                  <div className='col-sm-4'>
                    <Calendar
                      format='mm/dd/yy'
                      id='deferalldate'
                      name='deferralTill'
                      value={
                        assessmentResultFormData.deferralTill
                          ? new Date(assessmentResultFormData.deferralTill)
                          : props.donorInfo?.deferral_till
                          ? new Date(props.donorInfo?.deferral_till)
                          : null
                      }
                      disabled={formDisabled}
                    ></Calendar>
                    {errors.deferralTill && (
                      <span style={{ fontSize: '12px', color: 'red' }}>
                        {errors.deferralTill}
                      </span>
                    )}
                  </div>
                </div>
              ) : null}
              <div className='row mb-3'>
                <label className='col-sm-2 col-form-label' htmlFor='comments'>
                  Comments <span style={{ color: 'red' }}>&#42;</span>:
                </label>
                <div className='col-sm-4'>
                  <textarea
                    id='comments'
                    rows={3}
                    style={{ width: '100%' }}
                    className='form-control'
                    name='comments'
                    value={assessmentResultFormData.comments}
                    disabled={formDisabled}
                  ></textarea>
                </div>
                {errors.comments ? (
                  <span style={{ color: 'red' }}>{errors.comments}</span>
                ) : null}
              </div>
            </>
          )
        }
      ]
    }
  }

  const renderHealthAssessment = () => {
    if (!props.healthAssessment) {
      return <h5>Loading....</h5>
    } else if (props.healthAssessment) {
      if (Object.keys(props.healthAssessment).length) {
        if (
          props.healthAssessment &&
          props.healthAssessment?.STATUS === 'Pending'
        ) {
          return (
            <table className='table table-sm table-bordered'>
              <tbody>
                <tr>
                  <th>Donor Id</th>
                  <td>{props.healthAssessment.DONORID}</td>
                </tr>
                <tr>
                  <th>UCN</th>
                  <td>{props.healthAssessment.UCN}</td>
                </tr>
                <tr>
                  <th>STATUS</th>
                  <td className='text-danger'>
                    {props.healthAssessment.STATUS}
                  </td>
                </tr>
              </tbody>
            </table>
          )
        } else {
          return <Collapse items={generateItems()}></Collapse>
        }
      } else {
        return <h5>No Records Found</h5>
      }
    }
  }

  return <>{renderHealthAssessment()}</>
}

export default HealthAssessment
