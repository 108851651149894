import React, { useState, useRef } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import { Input, DatePicker, Space, Button, Descriptions } from 'antd'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Tag } from 'primereact/tag'
import { formatDate } from '../utilities/format'
import { msalFetch } from '../utilities/msalFetch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'

const { RangePicker } = DatePicker

function CDCSLogs (props) {
  const [cdcsReport, setCDCSReport] = useState(undefined)
  const [dateRange, setDateRange] = useState(undefined)
  const [ucn, setUCN] = useState('')

  const handleDateRangeChange = (dates, dateStrings, info) => {
    const [fromdate, todate] = dateStrings
    if (fromdate != '' && todate != '') {
      setDateRange({
        fromDate: moment(fromdate).format('YYYY-MM-DD'),
        toDate: moment(todate).format('YYYY-MM-DD')
      })
    } else {
      setDateRange(undefined)
    }
  }

  const getCDCSReport = () => {
    props.actions.setLoading()
    const body = {
      dateRange,
      ucn
    }
    const options = {
      method: 'post',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      },
      body: JSON.stringify(body)
    }
    msalFetch('/api/reports/getCDCSReport', options)
      .then(async res => {
        props.actions.clearLoading()
        const data = await res.json()
        if (res.ok) {
          const transformedData = data.map(x => {
            x.DONATION_DATE = formatDate(x.DONATION_DATE)
            return x
          })
          setCDCSReport(transformedData)
        } else {
          toast.error('Failed to get CDCS Upload Status Report')
        }
      })
      .catch(err => {
        props.actions.clearLoading()
      })
  }

  const getSeverity = status => {
    switch (status) {
      case 'Failed':
        return 'danger'

      case 'Success':
        return 'success'
      default:
        return null
    }
  }

  const reUploadCDCS = rowData => {
    const body = {
      ucn: rowData.UCN
    }
    props.actions.setLoading()
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      },
      body: JSON.stringify(body)
    }
    msalFetch('/api/donor/postDonationToCDCS', options)
      .then(async response => {
        props.actions.clearLoading()
        const data = await response.json()
        if (response.status == 200) {
          toast.success('Donation Logged into CDCS Successfully')
        } else {
          toast.error(data.message)
        }
        getCDCSReport()
      })
      .catch(error => {
        toast.error('Failed to log Donation into CDCS - Network Error')
        props.actions.clearLoading()
      })
  }

  const statusBodyTemplate = rowData => {
    return (
      <Tag
        value={rowData.UPLOAD_STATUS}
        severity={getSeverity(rowData.UPLOAD_STATUS)}
      />
    )
  }

  const dobBodyTemplate = rowData => {
    return <p style={{ width: '5rem' }}>{formatDate(rowData.BIRTH_DATE)}</p>
  }

  const createdOnBodyTemplate = rowData => {
    return <p style={{ width: '5rem' }}>{formatDate(rowData.CREATED_ON)}</p>
  }

  const actionBodyTemplate = rowData => {
    if (rowData.UPLOAD_STATUS == 'Failed') {
      return (
        <Button onClick={() => reUploadCDCS(rowData)}>
          <FontAwesomeIcon icon={faUpload}/>
        </Button>
      )
    }
    return null
  }

  const cdcsAnalytics = [
    {
      key: '1',
      label: 'Donations Uploaded',
      children: (() => {
        if (cdcsReport && cdcsReport.length) {
          let successRecords = cdcsReport.filter(
            x => (x.UPLOAD_STATUS == 'Success')
          )
          return <h5>{successRecords.length}</h5>
        }
        return <h5>0</h5>
      })()
    },
    {
      key: '2',
      label: 'Donations Failed',
      children: (() => {
        if (cdcsReport && cdcsReport.length) {
          let failed = cdcsReport.filter(x => (x.UPLOAD_STATUS == 'Failed'))
          return <h5>{failed.length}</h5>
        }
        return <h5>0</h5>
      })()
    }
  ]

  return (
    <div className='row justify-content-center mt-2'>
      <div className='col-md-10'>
        <div className='row d-flex justify-content-center mt-4 mb-4'>
          <div className='col-sm-12'>
            <h3 style={{ color: '#292764' }}>CDCS Report</h3>
            <div className='row'>
              <div className='col-md-6'>
                Donation Date :{' '}
                <RangePicker
                  style={{ marginRight: '24px' }}
                  format='DD-MMM-YYYY'
                  onCalendarChange={handleDateRangeChange}
                ></RangePicker>
              </div>
              <div className='col-md-6'>
                {' '}
                UCN :{' '}
                <Input
                  style={{ width: '40%' }}
                  onChange={e => setUCN(e.target.value)}
                ></Input>
              </div>
            </div>
            <div className='my-2'>
              <Button type='primary' onClick={getCDCSReport}>
                Apply
              </Button>
            </div>
            <div className='row my-4'>
              <div className='col-md-12'>
                {cdcsReport ? (
                  <>
                    <div className='my-2'>
                      <Descriptions
                        items={cdcsAnalytics}
                        size='small'
                        bordered
                      ></Descriptions>
                    </div>
                    <DataTable
                      value={cdcsReport}
                      size='small'
                      showGridlines
                      tableStyle={{ fontSize: '14px' }}
                    >
                      <Column field='UCN' header='UCN'></Column>
                      <Column field='FIRST_NAME' header='FIRSTNAME'></Column>
                      <Column field='LAST_NAME' header='LASTNAME'></Column>
                      <Column field='ID_NUMBER' header='ID_NUMBER'></Column>
                      <Column
                        field='BIRTH_DATE'
                        header='DATE OF BIRTH'
                        body={dobBodyTemplate}
                      ></Column>
                      <Column field='SEX' header='GENDER'></Column>
                      <Column
                        field='DONATION_DATE'
                        sortable
                        header='DONATION DATE'
                      ></Column>
                      <Column
                        field='CREATED_ON'
                        header='CREATED ON'
                        body={createdOnBodyTemplate}
                      ></Column>
                      <Column field='CREATED_BY' header='CREATED BY'></Column>
                      <Column
                        field='UPLOAD_STATUS'
                        header='STATUS'
                        body={statusBodyTemplate}
                      ></Column>
                      <Column
                        header='ACTIONS'
                        body={actionBodyTemplate}
                      ></Column>
                    </DataTable>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CDCSLogs)
