import React, { useEffect, useState } from 'react'
import styles from '../styles/DIS.module.css'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'
import { isParameterValid, checkValidUCN } from '../utilities/validations'
import { formatDate } from '../utilities/format'
import { msalFetch } from '../utilities/msalFetch'
import { Alert, Modal, Select, Descriptions } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { Button } from 'primereact/button'

const QAPage = props => {
  const [comments, setComments] = useState('')
  const [formData, setFormdata] = useState({})
  const [loading, setLoading] = useState(true)
  const [errors, setErrors] = useState({})
  const [checkedValues, setCheckedValues] = useState([])
  const [edit, setEdit] = useState(false)
  const history = useHistory()
  const [openUnitStatusModal, setOpenUnitStatusModal] = useState(false)
  const [newUnitStatus, setNewUnitStatus] = useState(null)
  const [noDonationConfirmModalOpen, setNoDonationConfirmModalOpen] =
    useState(false)
  const [changeLogs, setChangeLogs] = useState([])
  const [unitStatus, setUnitStatus] = useState('')

  const { confirm } = Modal

  const showConfirm = (onOkFunction, message) => {
    confirm({
      title: message,
      icon: <ExclamationCircleFilled />,
      onOk () {
        onOkFunction()
      },
      centered: true
    })
  }

  const getChangeLogs = ucn => {
    try {
      const options = {
        method: 'GET',
        headers: {
          Authorization: props.token,
          'Content-Type': 'application/json'
        }
      }
      msalFetch(`/api/donor/getChangeLogs/${ucn}/UNIT_STATUS`, options)
        .then(async res => {
          if (res.ok) {
            const data = await res.json()
            if (data.length) {
              const unitstatuslogs = data.filter(
                x => x.change_type === 'UNIT_STATUS'
              )
              setChangeLogs(unitstatuslogs)
            }
          } else {
            toast.error('Failed to fetch change logs')
          }
        })
        .catch(err => {
          toast.error(err.message)
        })
    } catch (err) {
      console.log(err)
    }
  }

  const getUnitStatus = ucn => {
    try {
      const options = {
        method: 'GET',
        headers: {
          Authorization: props.token,
          'Content-Type': 'application/json'
        }
      }
      msalFetch(`/api/donor/getUnitStatusByUCN/${ucn}`, options)
        .then(async res => {
          if (res.ok) {
            const data = await res.json()
            setUnitStatus(data.unit_status)
          } else {
            toast.error('Failed to fetch unit status')
          }
        })
        .catch(err => {
          toast.error(err.message)
        })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (props.drd?.ucn) {
      setFormdata(props.drd)
      setLoading(false)
      setComments(props.drd.qa_comments)
      getChangeLogs(props.drd.ucn)
      getUnitStatus(props.drd.ucn)
    } else {
      props.drd && setLoading(false)
    }
  }, [props.drd])

  const handleChange = e => {
    let stringValues = [
      'ucn',
      'NDDR',
      'CDCS',
      'machine_number',
      'arm_used',
      'anticoagulant_lot_number',
      'saline_lot_number',
      'bowl_lot_number',
      'bottle_lot_number',
      'harness_lot_number'
    ]
    let floatValues = ['temp1', 'temp2', 'total_protein']
    setErrors({})
    if (e.target.type !== 'checkbox') {
      if (stringValues.includes(e.target.name)) {
        setFormdata({ ...formData, [e.target.name]: e.target.value })
      } else if (floatValues.includes(e.target.name)) {
        setFormdata({
          ...formData,
          [e.target.name]: parseFloat(e.target.value)
        })
      } else {
        setFormdata({ ...formData, [e.target.name]: parseInt(e.target.value) })
      }
    } else {
      if (e.target.checked) {
        setCheckedValues([...checkedValues, e.target.value])
      } else {
        setCheckedValues(checkedValues.filter(x => x != e.target.value))
      }
      setFormdata({ ...formData, arm_used: checkedValues.join('') })
    }
  }

  const handleChangeComment = e => {
    setComments(e.target.value)
  }

  const validation = () => {
    let validationErrors = {}
    if (formData.weight < 110) {
      validationErrors.weight = 'Weight must be greater than or equal to 110lbs'
    }
    if (
      formData.bp_sys1 < 90 ||
      formData.bp_sys1 > 180 ||
      formData.bp_dia1 < 50 ||
      formData.bp_dia2 > 100
    ) {
      if (
        formData.bp_sys2 < 90 ||
        formData.bp_sys2 > 180 ||
        formData.bp_dia2 < 50 ||
        formData.bp_dia2 > 100
      ) {
        validationErrors.bp = 'Atleast one of the BP readings must be in range.'
      }
    }
    if (
      (formData.pulse1 < 50 || formData.pulse1 > 100) &&
      (formData.pulse2 < 50 || formData.pulse2 > 100)
    ) {
      validationErrors.pulse = 'Pulse is not in acceptable range'
    }
    if (
      (formData.temp1 < 96.0 || formData.temp1 > 99.5) &&
      (formData.temp2 < 96.0 || formData.temp2 > 99.5)
    ) {
      validationErrors.temp = 'Temperature is not in acceptable range'
    }
    if (formData.hct < 38 || formData.hct > 54) {
      validationErrors.hct = 'Hematocrit is not in acceptable range'
    }
    if (formData.total_protein < 6 || formData.total_protein > 9) {
      validationErrors.tp = 'Total Protein is not in acceptable range'
    }
    if (formData.NDDR == undefined || formData.NDDR == '') {
      validationErrors.nddr = 'NDDR Transaction ID is required.'
    }
    if (formData.CDCS == undefined || formData.CDCS == '') {
      validationErrors.cdcs = 'CDCS Enquiry ID is required.'
    }
    if (formData.machine_number == undefined || formData.machine_number == '') {
      validationErrors.machine_number = 'Machine Number cannot be blank'
    }
    if (formData.arm_used.length == 0) {
      validationErrors.armused = 'Please check Arm used'
    }
    if (
      formData.plasma_amt_machine == undefined ||
      formData.plasma_amt_machine == 0 ||
      formData.plasma_amt_machine == ''
    ) {
      validationErrors.plasma_amt = 'Plasma Volume cannot be 0'
    }
    if (
      formData.collection_amt_machine == undefined ||
      formData.collection_amt_machine == 0 ||
      formData.collection_amt_machine == ''
    ) {
      validationErrors.collection_amt =
        'Total Plasma collection volume cannot be 0'
    }
    if (
      formData.anticoagulant_lot_number == undefined ||
      formData.anticoagulant_lot_number == ''
    ) {
      validationErrors.anticoagulant = 'Please enter Anticoagulant lot number.'
    }
    if (
      formData.saline_lot_number == undefined ||
      formData.saline_lot_number == ''
    ) {
      validationErrors.saline = 'Please enter Saline lot number.'
    }
    if (
      formData.bowl_lot_number == undefined ||
      formData.bowl_lot_number == ''
    ) {
      validationErrors.bowl = 'Please enter Bowl lot number.'
    }
    if (
      formData.bottle_lot_number == undefined ||
      formData.bottle_lot_number == ''
    ) {
      validationErrors.bottle = 'Please enter Bottle lot number.'
    }
    if (
      formData.harness_lot_number == undefined ||
      formData.harness_lot_number == ''
    ) {
      validationErrors.harness = 'Please enter Harness lot number.'
    }
    setErrors({ ...validationErrors })
    if (Object.keys(validationErrors).length == 0) {
      return true
    } else {
      return false
    }
  }

  const handleSave = () => {
    if (validation()) {
      props.actions.setLoading()
      const data = {
        formData: { ...formData },
        role: props.roleid
      }
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.token
        },
        body: JSON.stringify(data)
      }
      msalFetch('api/donor/saveDRD', options)
        .then(async res => {
          const data = await res.json()
          props.actions.clearLoading()
          if (res.status == 200) {
            toast.success(data.message)
          } else {
            toast.error(data.message)
          }
        })
        .catch(err => props.actions.clearLoading())
    }
  }

  const qaReviewValidations = () => {
    if (
      props.drd &&
      props.drd.status === 'Pending' &&
      props.drd.collection_amt_machine &&
      props.drd.collection_amt_machine > 0
    ) {
      toast.error('Please complete the donation before QA Review')
      return false
    } else {
      return true
    }
  }

  const handleSubmit = () => {
    if (qaReviewValidations()) {
      props.actions.setLoading()
      let data = { comments, ucn: props.drd.ucn, donor_id: props.drd.donor_id }
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.token
        },
        body: JSON.stringify(data)
      }
      msalFetch('api/donor/completeQA', options)
        .then(async res => {
          props.actions.clearLoading()
          const data = await res.json()
          props.actions.clearLoading()
          if (res.status == 200) {
            toast.success(data.message)
            setTimeout(() => history.push('/dashboard'), 500)
          } else {
            toast.error(data.message)
          }
        })
        .catch(err => props.actions.clearLoading())
    }
  }

  const handleQuarantine = (e, action) => {
    props.actions.setLoading()

    if (true) {
      const data = { ucn: props.drd.ucn, action, comments }
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.token
        },
        body: JSON.stringify(data)
      }
      msalFetch('api/donor/quarantine', options)
        .then(async res => {
          props.actions.clearLoading()
          if (res.status == 200) {
            const temp = await res.json()
            toast.success(temp.message)
            props.getData()
          }
        })
        .catch(err => {
          console.error(err.message)
          toast.error(err.message)
        })
    }
  }

  const updateUnitStatus = () => {
    const data = {
      ucn: props.drd.ucn,
      unit_status: newUnitStatus
    }
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }
    props.actions.setLoading()
    msalFetch('/api/donor/updateUnitStatus', options)
      .then(async res => {
        props.actions.clearLoading()
        const data = await res.json()
        if (res.ok) {
          setOpenUnitStatusModal(false)
          setNewUnitStatus(null)
          toast.success(data.message)
        } else {
          toast.error(data.message)
        }
      })
      .catch(err => {
        props.actions.clearLoading()
        console.log(err)
      })
  }

  const handleNoDonation = () => {
    props.actions.setLoading()
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      },
      body: JSON.stringify({
        ucn: formData.ucn,
        donor_id: formData.donor_id,
        comments
      })
    }
    msalFetch(`api/donor/noDonation/QA`, options)
      .then(async response => {
        props.actions.clearLoading()
        const data = await response.json()
        if (response.ok) {
          toast.success(data.message)
          setNoDonationConfirmModalOpen(false)
        } else {
          toast.error(data.message)
        }
      })
      .catch(err => {
        toast.error(err.message)
        props.actions.clearLoading()
      })
  }

  return (
    <React.Fragment>
      {loading ? (
        <div className='row mt-5  justify-content-center text-center'>
          loading....
        </div>
      ) : props.drd?.ucn ? (
        edit ? (
          <form
            className='row mt-5 mb-5 d-flex justify-content-around'
            onSubmit={handleSave}
          >
            <div className='col-sm-5'>
              <div className='form-group row'>
                <label
                  htmlFor='UCN'
                  className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                >
                  UCN :
                </label>
                <div className='col-sm-6'>
                  <input
                    type='text'
                    className='form-control form-control-sm'
                    id='UCN'
                    placeholder='UCN'
                    name='ucn'
                    pattern='LP\d{8}'
                    required
                    onChange={handleChange}
                    value={formData.ucn}
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label
                  htmlFor='nddr'
                  className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                >
                  NDDR TransID :
                </label>
                <div className='col-sm-6'>
                  <input
                    type='text'
                    className='form-control form-control-sm'
                    id='nddr'
                    placeholder='NDDR Transaction ID'
                    name='NDDR'
                    onChange={handleChange}
                    value={formData.NDDR}
                  />
                  {errors.nddr != undefined ? (
                    <span className={styles.validationError}>
                      {errors.nddr}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className='form-group row'>
                <label
                  htmlFor='cdds'
                  className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                >
                  CDCS EnquiryID :
                </label>
                <div className='col-sm-6'>
                  <input
                    type='text'
                    className='form-control form-control-sm'
                    id='cdcs'
                    placeholder='CDCS Enquiry ID'
                    name='CDCS'
                    onChange={handleChange}
                    value={formData.CDCS}
                  />
                  {errors.cdcs != undefined ? (
                    <span className={styles.validationError}>
                      {errors.cdcs}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className='form-group row'>
                <label
                  htmlFor='weight'
                  className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                >
                  Weight :
                </label>
                <div className='col-sm-6'>
                  <input
                    type='number'
                    className='form-control form-control-sm'
                    id='weight'
                    placeholder='>=110lbs'
                    name='weight'
                    onChange={handleChange}
                    value={formData.weight}
                  />
                  {errors.weight != undefined ? (
                    <span className={styles.validationError}>
                      {errors.weight}
                    </span>
                  ) : null}
                </div>
                <span className={`col-sm-1 ${styles.drdunits}`}>lbs</span>
              </div>
              <div className='form-group row pb-2'>
                <label
                  htmlFor='bp1'
                  className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                >
                  Blood Pressure :
                </label>
                <div className='col-sm-3 d-flex'>
                  <input
                    type='number'
                    className='form-control form-control-sm'
                    id='bp1sys'
                    placeholder='Sys'
                    name='bp_sys1'
                    onChange={handleChange}
                    value={formData.bp_sys1}
                  />
                  <span className={styles.valueSepFont}>/</span>
                  <input
                    type='number'
                    className='form-control form-control-sm'
                    id='bp1dia'
                    placeholder='Dia'
                    name='bp_dia1'
                    onChange={handleChange}
                    value={formData.bp_dia1}
                  />
                </div>
                <div
                  className={`col-sm-1 d-flex justify-content-end align-items-center p-0 ${styles.drdLabel}`}
                >
                  <span>Retake:</span>{' '}
                </div>
                <div className='col-sm-3 d-flex'>
                  <input
                    type='number'
                    className='form-control form-control-sm'
                    id='bp2sys'
                    placeholder='Sys'
                    name='bp_sys2'
                    onChange={handleChange}
                    value={formData.bp_sys2}
                  />
                  <span className={styles.valueSepFont}>/</span>
                  <input
                    type='number'
                    className='form-control form-control-sm'
                    id='bp1dia'
                    placeholder='Dia'
                    name='bp_dia2'
                    onChange={handleChange}
                    value={formData.bp_dia2}
                  />
                </div>
                {errors.bp != undefined ? (
                  <div className='col-md-6 offset-5'>
                    <span className={styles.validationError}>{errors.bp}</span>
                  </div>
                ) : null}
              </div>
              <div className='form-group row'>
                <label
                  htmlFor='pulse'
                  className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                >
                  Pulse :
                </label>
                <div className='col-sm-2'>
                  <input
                    type='number'
                    className='form-control form-control-sm'
                    id='pulse'
                    placeholder='50-100bpm'
                    name='pulse1'
                    onChange={handleChange}
                    value={formData.pulse1}
                  />
                </div>
                <span className={`col-sm-1 ${styles.drdunits}`}>/min</span>
                <div
                  className={`col-sm-1 d-flex justify-content-end align-items-center p-0 ${styles.drdLabel}`}
                >
                  <span>Retake:</span>{' '}
                </div>
                <div className='col-sm-2'>
                  <input
                    type='number'
                    className='form-control form-control-sm'
                    id='pulse'
                    placeholder='50-100bpm'
                    name='pulse2'
                    onChange={handleChange}
                    value={formData.pulse2}
                  />
                </div>
                <span className={`col-sm-1 ${styles.drdunits}`}>/min</span>
                {errors.pulse != undefined ? (
                  <div className='col-md-6 offset-5'>
                    <span className={styles.validationError}>
                      {errors.pulse}
                    </span>
                  </div>
                ) : null}
              </div>
              <div className='form-group row'>
                <label
                  htmlFor='temp'
                  className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                >
                  Temperature :
                </label>
                <div className='col-sm-2'>
                  <input
                    type='number'
                    className='form-control form-control-sm'
                    id='temp'
                    placeholder='96.0 - 99.5F'
                    name='temp1'
                    onChange={handleChange}
                    value={formData.temp1}
                  />
                </div>
                <span className={`col-sm-1 ${styles.drdunits}`}>&#8457;</span>
                <div
                  className={`col-sm-1 d-flex justify-content-end align-items-center p-0 ${styles.drdLabel}`}
                >
                  <span>Retake:</span>{' '}
                </div>
                <div className='col-sm-2'>
                  <input
                    type='number'
                    className='form-control form-control-sm'
                    id='temp'
                    placeholder='96.0 - 99.5F'
                    name='temp2'
                    onChange={handleChange}
                    value={formData.temp2}
                  />
                </div>
                <span className={`col-sm-1 ${styles.drdunits}`}>&#8457;</span>
                {errors.temp != undefined ? (
                  <div className='col-md-6 offset-5'>
                    <span className={styles.validationError}>
                      {errors.temp}
                    </span>
                  </div>
                ) : null}
              </div>
              <div className='form-group row'>
                <label
                  htmlFor='hct'
                  className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                >
                  hct :
                </label>
                <div className='col-sm-6'>
                  <input
                    type='number'
                    className='form-control form-control-sm'
                    id='hct'
                    placeholder='hct'
                    name='hct'
                    onChange={handleChange}
                    value={formData.hct}
                  />
                  {errors.hct != undefined ? (
                    <span className={styles.validationError}>{errors.hct}</span>
                  ) : null}
                </div>
                <span className={`col-sm-1 ${styles.drdunits}`}>%</span>
              </div>
              <div className='form-group row'>
                <label
                  htmlFor='TP'
                  className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                >
                  TP :
                </label>
                <div className='col-sm-6'>
                  <input
                    type='number'
                    className='form-control form-control-sm'
                    id='tp'
                    placeholder='TP'
                    name='total_protein'
                    onChange={handleChange}
                    value={formData.total_protein}
                  />
                  {errors.tp != undefined ? (
                    <span className={styles.validationError}>{errors.tp}</span>
                  ) : null}
                </div>
                <span className={`col-sm-1 ${styles.drdunits}`}>/100ml</span>
              </div>
            </div>
            <div className='col-sm-5'>
              <div className='form-group row align-items-center'>
                <label
                  htmlFor='plasmaamt'
                  className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                >
                  Target Plasma Volume (mL):
                </label>
                <div className='col-sm-6'>
                  <input
                    type='number'
                    className='form-control form-control-sm'
                    id='plasmaamt'
                    placeholder='Target Plasma Volume'
                    value={props.drd?.plasma_amt}
                    name='plasma_amt'
                    disabled
                  />
                </div>
              </div>
              <div className='form-group row align-items-center'>
                <label
                  htmlFor='collection_amount'
                  className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                >
                  Target Total Collection Volume (mL):
                </label>
                <div className='col-sm-6'>
                  <input
                    type='number'
                    className='form-control form-control-sm'
                    id='collection_amount'
                    placeholder='Target Total Collection Volume'
                    value={props.drd?.collection_amt}
                    name='collection_amt'
                    disabled
                  />
                </div>
              </div>

              <div className='form-group row align-items-center'>
                <label
                  htmlFor='machine_number'
                  className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                >
                  Machine Number :
                </label>
                <div className='col-sm-6'>
                  <input
                    type='text'
                    className='form-control form-control-sm'
                    id='machine_number'
                    placeholder='Machine Number'
                    value={formData.machine_number}
                    name='machine_number'
                    onChange={handleChange}
                  />
                  {errors.machine_number != undefined ? (
                    <span className={styles.validationError}>
                      {errors.machine_number}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className='form-group row align-items-center'>
                <label
                  htmlFor='arm_used'
                  className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                >
                  Arm Used :
                </label>
                <div className='col-sm-2 d-flex justify-content-between'>
                  <label className='form-check-label' htmlFor='arm_usedl'>
                    L&nbsp;&nbsp;
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='L'
                      name='arm_used'
                      id='arm_usedl'
                      defaultChecked={formData.arm_used?.includes('L')}
                      onChange={handleChange}
                    />
                  </label>
                  <label className='form-check-label' htmlFor='arm_usedr'>
                    R&nbsp;&nbsp;
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='R'
                      name='arm_used'
                      id='arm_usedr'
                      defaultChecked={formData.arm_used?.includes('R')}
                      onChange={handleChange}
                    />
                  </label>
                </div>
                <div className='col-md-6 offset-5 mt-0 pt-0'>
                  {errors.armused != undefined ? (
                    <span className={styles.validationError}>
                      {errors.armused}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className='form-group row align-items-center'>
                <label
                  htmlFor='plasmaamt_machine'
                  className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                >
                  Actual Plasma Volume (mL):
                </label>
                <div className='col-sm-6'>
                  <input
                    type='number'
                    className='form-control form-control-sm'
                    id='plasmaamt_machine'
                    placeholder='Actual Plasma Volume(Machine Reading)'
                    name='plasma_amt_machine'
                    value={formData.plasma_amt_machine}
                    onChange={handleChange}
                  />
                  {errors.plasma_amt != undefined ? (
                    <span className={styles.validationError}>
                      {errors.plasma_amt}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className='form-group row align-items-center'>
                <label
                  htmlFor='collection_amount_machine'
                  className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                >
                  Actual Total Collection Volume (mL):
                </label>
                <div className='col-sm-6'>
                  <input
                    type='number'
                    className='form-control form-control-sm'
                    id='collection_amount_machine'
                    placeholder='Actual Collection Volume'
                    name='collection_amt_machine'
                    value={formData.collection_amt_machine}
                    onChange={handleChange}
                  />
                  {errors.collection_amt != undefined ? (
                    <span className={styles.validationError}>
                      {errors.collection_amt}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className='form-group row align-items-center'>
                <label
                  htmlFor='Anticoagulant'
                  className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                >
                  Anticoagulant Lot Number:
                </label>
                <div className='col-sm-6'>
                  <input
                    type='text'
                    className='form-control form-control-sm'
                    id='Anticoagulant'
                    placeholder='Anticoagulant Lot #'
                    name='anticoagulant_lot_number'
                    value={formData.anticoagulant_lot_number}
                    onChange={handleChange}
                  />
                  {errors.anticoagulant != undefined ? (
                    <span className={styles.validationError}>
                      {errors.anticoagulant}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className='form-group row align-items-center'>
                <label
                  htmlFor='Saline'
                  className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                >
                  Saline Lot Number:
                </label>
                <div className='col-sm-6'>
                  <input
                    type='text'
                    className='form-control form-control-sm'
                    id='Saline'
                    placeholder='Saline Lot #'
                    name='saline_lot_number'
                    value={formData.saline_lot_number}
                    onChange={handleChange}
                  />
                  {errors.saline != undefined ? (
                    <span className={styles.validationError}>
                      {errors.saline}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className='form-group row align-items-center'>
                <label
                  htmlFor='bowl'
                  className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                >
                  Bowl Lot Number:
                </label>
                <div className='col-sm-6'>
                  <input
                    type='text'
                    className='form-control form-control-sm'
                    id='bowl'
                    placeholder='Bowl Lot #'
                    name='bowl_lot_number'
                    value={formData.bowl_lot_number}
                    onChange={handleChange}
                  />
                  {errors.bowl != undefined ? (
                    <span className={styles.validationError}>
                      {errors.bowl}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className='form-group row align-items-center'>
                <label
                  htmlFor='bottle'
                  className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                >
                  Bottle Lot Number:
                </label>
                <div className='col-sm-6'>
                  <input
                    type='text'
                    className='form-control form-control-sm'
                    id='bottle'
                    placeholder='Bottle Lot #'
                    name='bottle_lot_number'
                    value={formData.bottle_lot_number}
                    onChange={handleChange}
                  />
                  {errors.bottle != undefined ? (
                    <span className={styles.validationError}>
                      {errors.bottle}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className='form-group row align-items-center'>
                <label
                  htmlFor='harness'
                  className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                >
                  Harness Lot Number:
                </label>
                <div className='col-sm-6'>
                  <input
                    type='text'
                    className='form-control form-control-sm'
                    id='harness'
                    placeholder='Harness Lot #'
                    name='harness_lot_number'
                    value={formData.harness_lot_number}
                    onChange={handleChange}
                  />
                  {errors.harness != undefined ? (
                    <span className={styles.validationError}>
                      {errors.harness}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className='col-sm-5'>
              <button
                type='submit'
                className='btn btn-primary p-0'
                style={{ width: '100px', height: '35px' }}
              >
                Save
              </button>
              <button
                className='btn btn-danger mx-3 text-center p-0'
                style={{ width: '100px', height: '35px' }}
                onClick={e => {
                  e.preventDefault()
                  setEdit(false)
                }}
              >
                Exit
              </button>
            </div>
            <div className='col-sm-5'></div>
          </form>
        ) : (
          <div className='row mt-4 mb-5 justify-content-center'>
            <div className='col-sm-10'>
              {formData.weight_lost &&
              formData.weight_lost !== 0 &&
              formData.weight_lost > 10 ? (
                props.healthAssessment?.STATUS &&
                props.healthAssessment.STATUS === 'Pending' ? (
                  <Alert
                    message={`! Donor has lost ${formData.weight_lost} lbs since last donation`}
                    type='error'
                  />
                ) : null
              ) : null}
              <Descriptions
                title=<h4>Donation Details</h4>
                bordered
                className='mb-3'
                size={'small'}
              >
                <Descriptions.Item label=<h5>UCN</h5>>
                  <h5 style={{ width: '100px' }}>{formData.ucn}</h5>
                </Descriptions.Item>
                <Descriptions.Item label=<h5>Donation Date</h5>>
                  <h5>{formatDate(formData.donation_date)}</h5>
                </Descriptions.Item>
                <Descriptions.Item label=<h5>Donation Status</h5>>
                  <h5>{formData.status}</h5>
                </Descriptions.Item>
                <Descriptions.Item label=<h5>No Donation</h5>>
                  <h5>{formData.no_donation == 1 ? 'Yes' : 'No'}</h5>
                </Descriptions.Item>
                <Descriptions.Item
                  label=<h5>Unit Status</h5>
                  contentStyle={
                    unitStatus && unitStatus !== 'Qualified'
                      ? {
                          backgroundColor: '#dc3546',
                          color: '#FFFFFF'
                        }
                      : unitStatus == 'Qualified'
                      ? {
                          backgroundColor: '#28A745',
                          color: '#FFFFFF'
                        }
                      : null
                  }
                >
                  <h5>{unitStatus == '' ? 'Loading...' : unitStatus}</h5>
                </Descriptions.Item>
                {formData.qa && (
                  <Descriptions.Item label=<h5>QA</h5>>
                    <h5>{formData.qa}</h5>
                  </Descriptions.Item>
                )}
              </Descriptions>

              <Descriptions
                title=<h4>Medical Screening</h4>
                //column={{ xxl: 4, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
                bordered
                //layout="vertical"
                className='mb-3'
                size={'small'}
              >
                <Descriptions.Item label=<h5>CDCS Inquiry ID</h5>>
                  <h5>{formData.CDCS}</h5>
                </Descriptions.Item>
                <Descriptions.Item
                  label=<h5>CDCS Check</h5>
                  contentStyle={
                    formData.cdcs_check === 'No'
                      ? { backgroundColor: '#dc3546', color: '#FFFFFF' }
                      : null
                  }
                >
                  <h5 style={{ minWidth: '100px' }}>
                    {formData.cdcs_check == 'Yes'
                      ? 'Pass'
                      : formData.cdcs_check == 'No'
                      ? 'Fail'
                      : ''}
                  </h5>
                </Descriptions.Item>
                {formData.nddr ? (
                  <>
                    <Descriptions.Item label=<h5>NDDR Inquiry ID</h5>>
                      <h5>{formData.nddr}</h5>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label=<h5>NDDR Check</h5>
                      contentStyle={
                        formData.nddr_check == 'Fail'
                          ? { backgroundColor: '#dc3546', color: '#FFFFFF' }
                          : null
                      }
                    >
                      <h5 style={{ minWidth: '100px' }}>
                        {formData?.nddr_check}
                      </h5>
                    </Descriptions.Item>
                  </>
                ) : null}
                <Descriptions.Item
                  label=<h5>Weight (lbs)</h5>
                  contentStyle={
                    parseInt(formData.weight) < 110
                      ? { backgroundColor: '#dc3546', color: '#FFFFFF' }
                      : null
                  }
                >
                  <h5>{formData.weight}</h5>
                </Descriptions.Item>
                <Descriptions.Item
                  label=<h5>Arm Check</h5>
                  contentStyle={
                    formData.arm_check == 'U'
                      ? { backgroundColor: '#dc3546', color: '#FFFFFF' }
                      : null
                  }
                >
                  <h5 style={{ minWidth: '7rem' }}>
                    {formData.arm_check == 'S'
                      ? 'Satisfactory'
                      : formData.arm_check == 'U'
                      ? 'Unsatisfactory'
                      : ''}
                  </h5>
                </Descriptions.Item>
                <Descriptions.Item
                  label=<h5>Blood Pressure (mmhg)</h5>
                  contentStyle={
                    formData.bp_sys1 &&
                    !isParameterValid('BP', [
                      formData.bp_sys1,
                      formData.bp_dia1
                    ])
                      ? { backgroundColor: '#dc3546', color: '#FFFFFF' }
                      : null
                  }
                >
                  <h5>
                    {formData.bp_sys1 && formData.bp_sys1 != 0
                      ? formData.bp_sys1 + '/' + formData.bp_dia1
                      : ''}
                  </h5>
                </Descriptions.Item>
                {formData.bp_sys2 && formData.bp_sys2 != 0 ? (
                  <Descriptions.Item
                    label=<h5>Blood Pressure (Retake)</h5>
                    contentStyle={
                      formData.bp_sys1 &&
                      !isParameterValid('BP', [
                        formData.bp_sys2,
                        formData.bp_dia2
                      ])
                        ? { backgroundColor: '#dc3546', color: '#FFFFFF' }
                        : null
                    }
                  >
                    <h5>{formData.bp_sys2 + '/' + formData.bp_dia2}</h5>
                  </Descriptions.Item>
                ) : null}
                <Descriptions.Item
                  label=<h5>Pulse (bpm)</h5>
                  contentStyle={
                    formData.pulse1 &&
                    !isParameterValid('PULSE', formData.pulse1)
                      ? { backgroundColor: '#dc3546', color: '#FFFFFF' }
                      : null
                  }
                >
                  <h5>{formData.pulse1}</h5>
                </Descriptions.Item>
                {formData.pulse2 && (
                  <Descriptions.Item
                    label=<h5>Pulse-Retake(bpm)</h5>
                    contentStyle={
                      formData.pulse2 &&
                      !isParameterValid('PULSE', formData.pulse2)
                        ? { backgroundColor: '#dc3546', color: '#FFFFFF' }
                        : null
                    }
                  >
                    <h5>{formData.pulse2}</h5>
                  </Descriptions.Item>
                )}
                <Descriptions.Item
                  label=<h5>Temperature (F)</h5>
                  contentStyle={
                    formData.temp1 && !isParameterValid('TEMP', formData.temp1)
                      ? { backgroundColor: '#dc3546', color: '#FFFFFF' }
                      : null
                  }
                >
                  <h5>{formData.temp1}</h5>
                </Descriptions.Item>
                {formData.temp2 && (
                  <Descriptions.Item
                    label=<h5>Temperature-Retake(F)</h5>
                    contentStyle={
                      formData.temp1 &&
                      !isParameterValid('TEMP', formData.temp2)
                        ? { backgroundColor: '#dc3546', color: '#FFFFFF' }
                        : null
                    }
                  >
                    <h5>{formData.temp2}</h5>
                  </Descriptions.Item>
                )}
                <Descriptions.Item
                  label=<h5>Hematocrit (%)</h5>
                  contentStyle={
                    formData.hct &&
                    !isParameterValid('Hemotacrit', [
                      formData.hct,
                      props.donorInfo.gender
                    ])
                      ? { backgroundColor: '#dc3546', color: '#FFFFFF' }
                      : null
                  }
                >
                  <h5>{formData.hct}</h5>
                </Descriptions.Item>
                <Descriptions.Item
                  label=<h5>Total Protein (gm/dL)</h5>
                  contentStyle={
                    formData.total_protein &&
                    !isParameterValid('TP', formData.total_protein)
                      ? { backgroundColor: '#dc3546', color: '#FFFFFF' }
                      : null
                  }
                >
                  <h5>{formData.total_protein}</h5>
                </Descriptions.Item>
                <Descriptions.Item label=<h5>Comments</h5>>
                  <h5>
                    {formData.screening_comments &&
                    formData.screening_comments != ''
                      ? formData.screening_comments
                      : 'N/A'}
                  </h5>
                </Descriptions.Item>
                <Descriptions.Item
                  label=<h5>DHQ Result</h5>
                  contentStyle={
                    props.dhqData && props.dhqData[0]?.RESULT == 'FAIL'
                      ? { backgroundColor: '#dc3546', color: '#FFFFFF' }
                      : null
                  }
                >
                  <h5>{props.dhqData && props.dhqData[0]?.RESULT}</h5>
                </Descriptions.Item>
                <Descriptions.Item label=<h5>DHQ Comments</h5>>
                  <h5>
                    {props.dhqData && props.dhqData[0]?.COMMENTS
                      ? props.dhqData[0]?.COMMENTS
                      : 'N/A'}
                  </h5>
                </Descriptions.Item>
                <Descriptions.Item label=<h5>Medical Screener</h5>>
                  <h5>{formData.medical_screener}</h5>
                </Descriptions.Item>
              </Descriptions>
              {props.healthAssessment && props.healthAssessment.STATUS ? (
                <Descriptions
                  title=<h4>Health Assessment</h4>
                  bordered
                  className='mb-3'
                  size={'small'}
                >
                  <Descriptions.Item
                    label=<h5>Result</h5>
                    contentStyle={
                      props.healthAssessment &&
                      props.healthAssessment.ASSESSMENT_RESULT === 'Fail'
                        ? { backgroundColor: '#dc3546', color: '#FFFFFF' }
                        : null
                    }
                  >
                    <h5 style={{ minWidth: '5rem' }}>
                      {props.healthAssessment &&
                        props.healthAssessment?.ASSESSMENT_RESULT}
                    </h5>
                  </Descriptions.Item>
                  <Descriptions.Item label=<h5>Assessment Date</h5>>
                    <h5 style={{ minWidth: '6rem' }}>
                      {props.healthAssessment &&
                      props.healthAssessment.DATE_OF_ASSESSMENT
                        ? formatDate(props.healthAssessment?.DATE_OF_ASSESSMENT)
                        : null}
                    </h5>
                  </Descriptions.Item>
                  <Descriptions.Item label=<h5>Donor Status</h5>>
                    <h5>
                      {props.healthAssessment &&
                        props.healthAssessment?.DONOR_STATUS}
                    </h5>
                  </Descriptions.Item>
                  {props.donorInfo && props.donorInfo.allergic_to ? (
                    <>
                      {' '}
                      <Descriptions.Item label=<h5>Allergic To</h5>>
                        <h5>
                          {props.donorInfo && props.donorInfo?.allergic_to}
                        </h5>
                      </Descriptions.Item>
                      <Descriptions.Item label=<h5>Allergy Comments</h5>>
                        <h5>
                          {props.donorInfo && props.donorInfo?.allergy_comments}
                        </h5>
                      </Descriptions.Item>
                    </>
                  ) : null}

                  <Descriptions.Item label=<h5>Comments</h5>>
                    <h5>
                      {props.healthAssessment &&
                        props.healthAssessment?.COMMENTS}
                    </h5>
                  </Descriptions.Item>
                  <Descriptions.Item label=<h5>Physician Substitute</h5>>
                    <h5>
                      {props.healthAssessment &&
                        props.healthAssessment?.UPLOADEDBY}
                    </h5>
                  </Descriptions.Item>
                </Descriptions>
              ) : null}
              <Descriptions
                title=<h4>Phlebotomy</h4>
                //column={{ xxl: 4, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
                bordered
                //layout="vertical"
                size={'small'}
              >
                <Descriptions.Item label=<h5>Machine Number</h5>>
                  <h5>{formData.machine_number}</h5>
                </Descriptions.Item>
                <Descriptions.Item label=<h5>Arm Used</h5>>
                  <h5>
                    {formData.arm_used != null
                      ? formData?.arm_used == 'L'
                        ? 'Left'
                        : 'Right'
                      : ''}
                  </h5>
                </Descriptions.Item>
                {formData.arm_used_restick && (
                  <Descriptions.Item
                    label=<h5>Arm used (Restick)</h5>
                    contentStyle={{
                      backgroundColor: '#dc3546',
                      color: '#FFFFFF'
                    }}
                  >
                    <h5>
                      {formData.arm_used_restick == 'R'
                        ? 'Right'
                        : formData.arm_used_restick == 'L'
                        ? 'Left'
                        : ''}
                    </h5>
                  </Descriptions.Item>
                )}
                <Descriptions.Item label=<h5>AntiCoagulant Lot #</h5>>
                  <h5>{formData.anticoagulant_lot_number}</h5>
                </Descriptions.Item>
                <Descriptions.Item label=<h5>Saline Lot #</h5>>
                  <h5>{formData.saline_lot_number}</h5>
                </Descriptions.Item>
                <Descriptions.Item label=<h5>Bowl Lot #</h5>>
                  <h5>{formData.bowl_lot_number}</h5>
                </Descriptions.Item>
                <Descriptions.Item label=<h5>Bottle Lot #</h5>>
                  <h5>{formData.bottle_lot_number}</h5>
                </Descriptions.Item>
                <Descriptions.Item label=<h5>Harness Lot #</h5>>
                  <h5>{formData.harness_lot_number}</h5>
                </Descriptions.Item>
                <Descriptions.Item label=<h5>SPE Sample Taken</h5>>
                  <h5>{formData.spe_taken == '1' ? 'Yes' : 'No'}</h5>
                </Descriptions.Item>
                <Descriptions.Item label=<h5>Setup/Connect</h5>>
                  <h5>{formData.setup_by}</h5>
                </Descriptions.Item>
                {formData.arm_used_restick && (
                  <Descriptions.Item label=<h5>Restick</h5>>
                    <h5>{formData.restick_by}</h5>
                  </Descriptions.Item>
                )}
                <Descriptions.Item label=<h5>Disconnect</h5>>
                  <h5>{formData.disconnect_by}</h5>
                </Descriptions.Item>
                <Descriptions.Item
                  label=<h5>Actual Plasma Volume (mL)</h5>
                  contentStyle={
                    formData.plasma_amt_machine &&
                    !isParameterValid('ACTUAL_PLASMA', [
                      formData.plasma_amt,
                      formData.plasma_amt_machine
                    ])
                      ? { backgroundColor: '#dc3546', color: '#FFFFFF' }
                      : null
                  }
                >
                  <h5>{formData.plasma_amt_machine}</h5>
                </Descriptions.Item>

                <Descriptions.Item
                  label=<h5>Actual Total Collection Volume (mL)</h5>
                >
                  <h5>{formData.collection_amt_machine}</h5>
                </Descriptions.Item>
                {formData.rbc_loss ? (
                  <Descriptions.Item
                    label=<h5>RBC Loss:</h5>
                    contentStyle={{
                      backgroundColor: '#dc3546',
                      color: '#FFFFFF'
                    }}
                  >
                    <h5 style={{ minWidth: '100px' }}>
                      {formData.rbc_loss ? formData.rbc_loss : 'None'}
                    </h5>
                  </Descriptions.Item>
                ) : null}
                {formData.alert_flags ? (
                  <>
                    <Descriptions.Item
                      label=<h5>Alert Flag</h5>
                      contentStyle={{
                        backgroundColor: '#dc3546',
                        color: '#FFFFFF'
                      }}
                    >
                      <h5 style={{ minWidth: '200px' }}>
                        {formData.alert_flags}
                      </h5>
                    </Descriptions.Item>
                    <Descriptions.Item label=<h5>Alert Flag Reason</h5>>
                      <h5 style={{ minWidth: '140px' }}>
                        {formData.alert_flag_reason}
                      </h5>
                    </Descriptions.Item>
                  </>
                ) : null}
                {formData.phlebotomy_comments ? (
                  <Descriptions.Item label=<h5>Comments</h5>>
                    <h5 style={{ minWidth: '100px' }}>
                      {formData.phlebotomy_comments
                        ? formData.phlebotomy_comments
                        : 'N/A'}
                    </h5>
                  </Descriptions.Item>
                ) : null}
              </Descriptions>
            </div>
            <div className='row mb-4 mt-4 justify-content-center'>
              <div className='col-sm-10'>
                <div className='row'>
                  <div className='col-sm-4 d-flex'>
                    <label htmlFor='comments'>Comments :</label>
                    <textarea
                      id='comments'
                      rows='4'
                      cols='40'
                      value={comments}
                      onChange={handleChangeComment}
                    ></textarea>
                  </div>
                  <div className='col-sm-8 d-flex justify-content-end align-items-center'>
                    <Button
                      onClick={() => setOpenUnitStatusModal(true)}
                      label='Change Unit Status'
                      severity='primary'
                      style={{ borderRadius: '5px', margin: '0 1rem' }}
                      size='small'
                    />
                    {!formData.no_donation ? (
                      <Button
                        label='No Donation'
                        severity='warning'
                        style={{ borderRadius: '5px', margin: '0 1rem' }}
                        size='small'
                        onClick={e => setNoDonationConfirmModalOpen(true)}
                      />
                    ) : null}
                    <Button
                      onClick={() =>
                        showConfirm(
                          handleSubmit,
                          'Do you want to complete QA Review?'
                        )
                      }
                      disabled={
                        formData.status == 'QA Reviewed' ||
                        formData.status == 'Quarantined'
                      }
                      label=' Complete QA Review'
                      severity='info'
                      style={{ borderRadius: '5px', margin: '0 1rem' }}
                      size='small'
                    ></Button>
                    {/*{formData.status !== 'Quarantined' ? (
                      <Button
                        onClick={e =>
                          showConfirm(
                            () => handleQuarantine(e, 'Quarantined'),
                            'Do you want to Quarantine this donation?'
                          )
                        }
                        disabled={formData.status == 'Quarantined'}
                        label='Quarantine'
                        severity='danger'
                        style={{ borderRadius: '5px', margin: '0 1rem' }}
                        size='small'
                      ></Button>
                    ) : null} */}
                    {formData.status === 'Quarantined' ? (
                      <Button
                        className='btn btn-info'
                        onClick={e =>
                          showConfirm(
                            () => handleQuarantine(e, 'Pending QA Review'),
                            'Do you want to release this donation from Quarantine?'
                          )
                        }
                        label='Release from Quarantine'
                        severity='info'
                        style={{ borderRadius: '5px', margin: '0 1rem' }}
                        size='small'
                        disabled={!(formData.status == 'Quarantined')}
                      >
                        Release from Quarantine
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {changeLogs.length ? (
              <div className='row justify-content-center'>
                <div className='col-md-10'>
                  <h5>Unit Status Change Logs</h5>
                  <table className='table table-sm table-bordered table-light'>
                    <thead>
                      <tr>
                        <th>User</th>
                        <th>Old Value</th>
                        <th>New Value</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {changeLogs.map(x => {
                        return (
                          <tr key={x.change_id}>
                            <td>{x.user}</td>
                            <td>{x.old_value}</td>
                            <td>{x.new_value}</td>
                            <td>{formatDate(x.datetime)}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
          </div>
        )
      ) : (
        <div className='row mt-5 mb-4 justify-content-center'>
          <div className='col-md-6'>
            <div className='card'>
              <div className='card-body'>
                <h6 className='card-title'>
                  No active Donation for this Donor
                </h6>
                <button
                  style={{
                    marginRight: '16px',
                    width: '80px',
                    height: '30px',
                    verticalAlign: 'center',
                    padding: '0',
                    borderRadius: '10px'
                  }}
                  className='btn btn-outline-danger mr-3'
                  onClick={() => history.push('/dashboard')}
                >
                  <FontAwesomeIcon
                    icon={faArrowRightFromBracket}
                    style={{ marginRight: '5px' }}
                  />
                  Exit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        title='Update Unit Status'
        style={{
          top: 20
        }}
        centered
        open={openUnitStatusModal}
        onOk={() => updateUnitStatus()}
        onCancel={() => {
          setOpenUnitStatusModal(false)
          setNewUnitStatus(null)
        }}
      >
        <div>
          <Select
            defaultValue={null}
            style={{ width: '100%' }}
            placeholder='Select Unit Status'
            allowClear
            value={newUnitStatus}
            onChange={value => setNewUnitStatus(value)}
            options={[
              { value: 'Qualified', label: 'Qualified' },
              { value: 'Not Qualified', label: 'Not Qualified' },
              { value: 'Quarantine', label: 'Quarantine' },
              { value: 'Destroyed', label: 'Destroyed' }
            ]}
          />
        </div>
      </Modal>
      <Modal
        title='Confirm'
        open={noDonationConfirmModalOpen}
        onOk={handleNoDonation}
        onCancel={() => setNoDonationConfirmModalOpen(false)}
        okButtonProps={{
          disabled: !checkValidUCN(formData.ucn)
        }}
        centered
      >
        <p>Are you sure this is a No Donation?</p>
      </Modal>
    </React.Fragment>
  )
}

export default QAPage
