import React, { useState, useEffect, useRef } from 'react'
import styles from '../styles/DonorRegister.module.css'
import Webcam from 'react-webcam'
import selfie from '../images/selfies-80.png'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import StateCities from '../utilities/US_States_and_Cities.json'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { InputMask } from 'primereact/inputmask'
import { isValidSSN } from '../utilities/validations'
import { msalFetch } from '../utilities/msalFetch'
import moment from 'moment'

const videoConstraints = {
  width: 220,
  height: 200,
  facingMode: 'user'
}

function DonorRegister (props) {
  const [image, setImage] = useState('')
  const [enableCamera, setEnablecamera] = useState(false)
  const [formData, setFormdata] = useState({})
  const [picError, setPicError] = useState(false)
  const formRef = useRef(null)
  const [state, setState] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [ageFlag, setAgeFlag] = useState(false)
  const [duplicateDonor, setDuplicateDonor] = useState(false)
  const [height, setHeight] = useState({ feet: '', inches: 0 })

  const history = useHistory()

  const webcamRef = React.useRef(null)

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot()
    setImage(imageSrc)
    setPicError('')
  }, [webcamRef])

  const checkDuplicateDonor = ssn => {
    const options = {
      method: 'GET',
      headers: {
        Authorization: props.token
      }
    }
    const url = `/api/donor/checkDuplicateDonor/${ssn}`
    msalFetch(url, options).then(async res => {
      if (res.ok) {
        const data = await res.json()
        setDuplicateDonor(data)
      }
    })
  }

  const handleChange = e => {
    const data = { ...formData }
    data[e.target.name] = e.target.value
    setFormdata(data)
    if ([e.target.name] == 'state') {
      setState(e.target.value)
      document.getElementById('state').classList.remove('is-invalid')
    }
    if ([e.target.name] == 'city') {
      document.getElementById('city').classList.remove('is-invalid')
    }
    if ([e.target.name] == 'dob') {
      let dob = moment(e.target.value)
      let today = moment()
      let age = today.diff(dob, 'years', true)
      if (age < 18 || age > 70) {
        setAgeFlag(true)
      } else {
        setAgeFlag(false)
      }
    }
    if ([e.target.name] == 'ssn' && isValidSSN(e.target.value)) {
      checkDuplicateDonor(e.target.value)
    }
  }

  const handleHeightChange = e => {
    setHeight({ ...height, [e.target.name]: e.target.value })
  }

  useState(() => {
    setFormdata({ country: 'USA' })
  }, [])

  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    b64Data = b64Data.split(',')[1]
    const byteCharacters = window.atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, { type: contentType })
    return blob
  }

  const validate = () => {
    formRef.current.classList.add('was-validated')
    if (formData.state == undefined) {
      document.getElementById('state').classList.add('is-invalid')
    }
    if (formData.city == undefined) {
      document.getElementById('city').classList.add('is-invalid')
    }
    if (
      formData.first_name &&
      formData.last_name &&
      formData.ssn &&
      formData.dob &&
      formData.emergency_contact_name &&
      formData.emergency_contact_number &&
      formData.phone &&
      formData.gender &&
      formData.addr1 &&
      formData.country &&
      formData.state &&
      formData.city &&
      formData.zipcode &&
      formData.address_verified &&
      height.feet
    ) {
      if (image == '') {
        setPicError(true)
        return false
      } else {
        setPicError(false)
        return true
      }
    } else {
      return false
    }
  }

  const handleCancel = e => {
    e.preventDefault()
    if (Object.keys(formData).length > 1) {
      let flag = false
      for (let i in formData) {
        if (i != 'country' && formData[i].length > 0) {
          flag = true
        }
      }
      if (flag) {
        setShowModal(true)
      }
    } else {
      history.push('/dashboard')
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    const temp = validate()
    if (temp) {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
      props.actions.setLoading()
      const formdata1 = new FormData()
      for (let key in formData) {
        formdata1.append(key, formData[key])
      }
      formdata1.append(
        'height',
        parseFloat(height.feet.toString() + '.' + height.inches.toString())
      )
      const picblob = b64toBlob(image, 'image/jpeg')
      formdata1.append('pic', picblob)

      const options = {
        method: 'POST',
        headers: {
          Authorization: props.token
        },
        body: formdata1
      }
      msalFetch('/api/donor/register', options).then(async res => {
        props.actions.clearLoading()
        const data = await res.json()
        if (res.status == 200) {
          toast.success('Donor Registered successfully')

          sessionStorage.setItem('selectedDonorId', data.donorId)
          history.push('/donor/dis')
        } else if (res.status == 409) {
          toast.error('Donor information already exists', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          })
        } else {
          props.actions.clearLoading()
          toast.error(data.message)
        }
      })
    }
  }

  return (
    <React.Fragment>
      <div className='row justify-content-center mb-5'>
        <div className='col-sm-8 mt-4'>
          <h4 className='text-center pb-4'>Donor Registration</h4>
          <form
            className='needs-validation'
            noValidate
            onSubmit={handleSubmit}
            ref={formRef}
          >
            <div className='row'>
              <div className='col-md-4 mb-3'>
                <label className={styles.label} htmlFor='fname'>
                  First name <span style={{ color: 'red' }}>&#42;</span>
                </label>
                <input
                  type='text'
                  className='form-control form-control-sm'
                  id='fname'
                  placeholder='First name'
                  name='first_name'
                  onChange={handleChange}
                  required
                  maxLength={45}
                />
                <div className='invalid-feedback'>
                  First name cannot be blank
                </div>
              </div>
              <div className='col-md-4 mb-3'>
                <label htmlFor='mname'>Middle Name</label>
                <input
                  type='text'
                  className='form-control form-control-sm'
                  id='mname'
                  placeholder='Middle name'
                  name='middle_name'
                  onChange={handleChange}
                  maxLength={45}
                />
              </div>
              <div className='col-md-4 mb-3'>
                <label htmlFor='lastname'>
                  Last name <span style={{ color: 'red' }}>&#42;</span>
                </label>
                <input
                  type='text'
                  className='form-control form-control-sm'
                  id='lastname'
                  placeholder='Last name'
                  name='last_name'
                  onChange={handleChange}
                  required
                  maxLength={45}
                />
                <div className='invalid-feedback'>
                  Last name cannot be blank
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-8'>
                <div className='row'>
                  <div className='form-group col-md-6'>
                    <label htmlFor='ssn'>
                      SSN <span style={{ color: 'red' }}>&#42;</span>
                    </label>
                    <InputMask
                      id='ssn'
                      mask='999-99-9999'
                      className={
                        duplicateDonor
                          ? 'form-control form-control-sm p-invalid'
                          : 'form-control form-control-sm'
                      }
                      name='ssn'
                      placeholder='999-99-9999'
                      onChange={e => handleChange(e)}
                      pattern='\d{3}-?\d{2}-?\d{4}'
                      required
                    ></InputMask>
                    <div className='invalid-feedback'>Invalid SSN.</div>
                    {duplicateDonor && (
                      <p
                        className='text-danger text-sm'
                        style={{ fontSize: '14px' }}
                      >
                        ! Duplicate Donor
                      </p>
                    )}
                  </div>
                  <div className='form-group col-md-6'>
                    <label htmlFor='dob'>
                      Date of Birth <span style={{ color: 'red' }}>&#42;</span>
                    </label>
                    <input
                      type='date'
                      className='form-control form-control-sm'
                      id='dob'
                      name='dob'
                      onChange={handleChange}
                      required
                    />
                    <div className='invalid-feedback'>
                      Date of Birth is required
                    </div>
                    {ageFlag ? (
                      <span className={styles.ageWarning}>
                        Donor does not meet age necessary age requirement
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className='row pt-3'>
                  <div className='col-md-6'>
                    <div className='pt-3'>
                      <label>
                        Gender <span style={{ color: 'red' }}>&#42;</span>
                      </label>
                    </div>
                    <div className='form-check form-check-inline'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='gender'
                        id='inlineRadio1'
                        value='M'
                        onChange={handleChange}
                        required
                      />
                      <label
                        className='form-check-label'
                        htmlFor='inlineRadio1'
                      >
                        Male
                      </label>
                    </div>
                    <div className='form-check form-check-inline'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='gender'
                        id='inlineRadio2'
                        value='F'
                        onChange={handleChange}
                        required
                      />
                      <label
                        className='form-check-label'
                        htmlFor='inlineRadio2'
                      >
                        Female
                      </label>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='pt-3'>
                      <label>
                        Height <span style={{ color: 'red' }}>&#42;</span>
                      </label>
                    </div>
                    <div className='form-inline row'>
                      <div className='form-group col-sm-4'>
                        <label htmlFor='feet' style={{ fontSize: '14px' }}>
                          Feet:
                        </label>
                        <select
                          className='form-control form-control-sm'
                          id='feet'
                          onChange={handleHeightChange}
                          required
                          name='feet'
                          value={height.feet}
                        >
                          <option selected disabled value=''>
                            Select
                          </option>
                          <option value={1}>1 feet</option>
                          <option value={2}>2 feet</option>
                          <option value={3}>3 feet</option>
                          <option value={4}>4 feet</option>
                          <option value={5}>5 feet</option>
                          <option value={6}>6 feet</option>
                          <option value={7}>7 feet</option>
                          <option value={8}>8 feet</option>
                        </select>
                      </div>
                      <div className='form-group col-sm-4'>
                        <label htmlFor='inches' style={{ fontSize: '14px' }}>
                          Inches:
                        </label>
                        <select
                          className='form-control form-control-sm '
                          id='inches'
                          name='inches'
                          required
                          onChange={handleHeightChange}
                          value={height.inches}
                        >
                          <option selected disabled value=''>
                            Select
                          </option>
                          <option value={0}>0 inch</option>
                          <option value={1}>1 inch</option>
                          <option value={2}>2 inches</option>
                          <option value={3}>3 inches</option>
                          <option value={4}>4 inches</option>
                          <option value={5}>5 inches</option>
                          <option value={6}>6 inches</option>
                          <option value={7}>7 inches</option>
                          <option value={8}>8 inches</option>
                          <option value={9}>9 inches</option>
                          <option value={10}>10 inches</option>
                          <option value={11}>11 inches</option>
                          <option value={12}>12 inches</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row pt-3'>
                  <div className='form-group col-md-6'>
                    <label htmlFor='phone'>
                      Telephone <span style={{ color: 'red' }}>&#42;</span>
                    </label>
                    <InputMask
                      className='form-control form-control-sm'
                      id='phone'
                      name='phone'
                      onChange={handleChange}
                      required
                      mask='(999) 999-9999'
                      placeholder='(999) 999-9999'
                    ></InputMask>
                    <div className='invalid-feedback'>Invalid phone number</div>
                  </div>
                  <div className='form-group col-md-6'>
                    <label htmlFor='phone'>
                      Emergency Contact Name{' '}
                      <span style={{ color: 'red' }}>&#42;</span>
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='phone'
                      placeholder='Emergency Contact Name'
                      name='emergency_contact_name'
                      onChange={handleChange}
                      required
                      maxLength={45}
                    />
                    <div className='invalid-feedback'>
                      This field cannot be blank
                    </div>
                  </div>
                </div>
                <div className='row pt-3'>
                  <div className='form-group col-md-6'>
                    <label htmlFor='ephone'>
                      Emergency Contact Number{' '}
                      <span style={{ color: 'red' }}>&#42;</span>
                    </label>
                    <InputMask
                      className='form-control form-control-sm'
                      id='phone'
                      name='emergency_contact_number'
                      mask='(999) 999-9999'
                      placeholder='(999) 999-9999'
                      onChange={handleChange}
                      required
                    ></InputMask>
                    <div className='invalid-feedback'>Invalid phone number</div>
                  </div>
                  <div className='form-group col-md-6'>
                    <label htmlFor='vein_grade_left'>
                      Vein Grade (Left Arm)
                    </label>
                    <select
                      className='form-select form-select-sm'
                      id='vein_grade_left'
                      name='vein_grade_left'
                      onChange={handleChange}
                    >
                      <option selected disabled value=''>
                        Select
                      </option>
                      <option value={'Orange'}>Orange</option>
                      <option value={'Green'}>Green</option>
                      <option value={'Unsuitable'}>Unsuitable</option>
                    </select>
                    <div className='invalid-feedback'>
                      Please select Vein Grade
                    </div>
                  </div>
                </div>
                <div className='row pt-3'>
                  <div className='form-group col-md-6'>
                    <label htmlFor='vein_grade_right'>
                      Vein Grade (Right Arm)
                    </label>
                    <select
                      className='form-select form-select-sm'
                      id='vein_grade_right'
                      name='vein_grade_right'
                      onChange={handleChange}
                    >
                      <option selected disabled value=''>
                        Select
                      </option>
                      <option value={'Orange'}>Orange</option>
                      <option value={'Green'}>Green</option>
                      <option value={'Unsuitable'}>Unsuitable</option>
                    </select>
                    <div className='invalid-feedback'>
                      Please select Vein Grade
                    </div>
                  </div>
                  <div className='form-group col-md-6'>
                    <label htmlFor='veingradeby'>
                      Vein Grade Performed By{' '}
                      <span style={{ color: 'red' }}>&#42;</span>
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='veingradeby'
                      placeholder='Phlebotomist Name'
                      name='vein_grade_performedby'
                      onChange={handleChange}
                      required
                      maxLength={45}
                    />
                    <div className='invalid-feedback'>
                      This field cannot be blank
                    </div>
                  </div>
                </div>
                <div className='row pt-3'>
                  <div className='form-group col-md-6'>
                    <label htmlFor='inputAddress1'>
                      Address 1 <span style={{ color: 'red' }}>&#42;</span>
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='inputAddress1'
                      placeholder='Apartment, studio, or floor'
                      name='addr1'
                      onChange={handleChange}
                      required
                      maxLength={254}
                    />
                    <div className='invalid-feedback'>
                      Primary address cannot be blank
                    </div>
                  </div>
                  <div className='form-group col-md-6'>
                    <label htmlFor='inputAddress2'>Address 2 </label>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='inputAddress2'
                      placeholder='Street, Lane'
                      name='addr2'
                      onChange={handleChange}
                      maxLength={254}
                    />
                  </div>
                </div>
                <div className='row pt-3'>
                  <div className='form-group col-md-6'>
                    <label htmlFor='country'>
                      Country <span style={{ color: 'red' }}>&#42;</span>
                    </label>
                    <select
                      id='country'
                      className='form-control form-control-sm'
                      name='country'
                      onChange={handleChange}
                    >
                      <option defaultValue={'USA'} selected disabled>
                        USA
                      </option>
                    </select>
                  </div>
                  <div className='form-group col-md-6'>
                    <label htmlFor='state'>
                      State <span style={{ color: 'red' }}>&#42;</span>
                    </label>
                    <select
                      id='state'
                      className={`form-select form-select-sm`}
                      name='state'
                      onChange={handleChange}
                      required
                    >
                      <option selected disabled value=''>
                        Select
                      </option>
                      {Object.keys(StateCities).map(state => {
                        return <option key={state}>{state}</option>
                      })}
                    </select>
                    <div className='invalid-feedback'>Please select state</div>
                  </div>
                </div>
                <div className='row pt-3'>
                  <div className='form-group col-md-6'>
                    <label htmlFor='city'>
                      City <span style={{ color: 'red' }}>&#42;</span>
                    </label>
                    <input
                      type='text'
                      id='city'
                      className={`form-control form-control-sm`}
                      name='city'
                      onChange={handleChange}
                      required
                      maxLength={100}
                    />
                    <div className='invalid-feedback'>Please select city</div>
                  </div>
                  <div className='form-group col-md-6'>
                    <label htmlFor='inputZip'>
                      ZipCode <span style={{ color: 'red' }}>&#42;</span>
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='inputZip'
                      name='zipcode'
                      required
                      pattern='^[0-9]{5}(?:-[0-9]{4})?$'
                      onChange={handleChange}
                      maxLength={5}
                      minLength={5}
                    />
                    <div className='invalid-feedback'>
                      ZipCode is blank / incorrect
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='pt-3'>
                      <label id='verified_address'>
                        Verified as Suitable Address{' '}
                        <span style={{ color: 'red' }}>&#42;</span>
                      </label>
                    </div>
                    <div className='form-check form-check-inline'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='address_verified'
                        id='inlineRadio1'
                        value='Yes'
                        onChange={handleChange}
                        required
                      />
                      <label
                        className='form-check-label'
                        htmlFor='verified_address'
                      >
                        Yes
                      </label>
                    </div>
                    <div className='form-check form-check-inline'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='address_verified'
                        id='inlineRadio2'
                        value='No'
                        onChange={handleChange}
                        required
                      />
                      <label
                        className='form-check-label'
                        htmlFor='verified_address'
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className={styles.webcamcontainer}>
                  <div className={styles.webcamOutput}>
                    {!enableCamera ? (
                      <div>
                        <img src={selfie}></img>
                        <p>
                          No Image Available. Please start camera to capture
                        </p>
                      </div>
                    ) : null}
                    {image == '' && enableCamera ? (
                      <Webcam
                        audio={false}
                        height={240}
                        ref={webcamRef}
                        screenshotFormat='image/jpeg'
                        width={220}
                        videoConstraints={videoConstraints}
                      />
                    ) : (
                      <img src={image}></img>
                    )}
                  </div>
                  {picError ? (
                    <span className='text-danger'>Please capture photo</span>
                  ) : null}
                  <div className={styles.captureButtonsContainer}>
                    <button
                      onClick={() => {
                        setEnablecamera(true)
                        setImage('')
                      }}
                      disabled={enableCamera && image == ''}
                      className='btn btn-outline-dark btn-sm'
                    >
                      {image == '' ? 'Start' : 'Retake'}
                    </button>
                    <button
                      onClick={e => {
                        e.preventDefault()
                        capture()
                      }}
                      disabled={image != '' || !enableCamera}
                      className='btn btn-outline-dark btn-sm'
                    >
                      Capture
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button className='btn btn-primary btn-rounded mt-4' type='submit'>
              Submit
            </button>
            <button
              className='btn btn-danger btn-rounded mt-4 mx-4'
              onClick={handleCancel}
            >
              Cancel
            </button>
          </form>
        </div>
      </div>
      <Modal
        show={showModal}
        backdrop='static'
        keyboard={false}
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <Modal.Title>Please Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Unsaved data will be lost. Do you still want to cancel?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
            size='sm'
            style={{ width: '50px' }}
            onClick={() => history.push('/dashboard')}
          >
            Yes
          </Button>
          <Button
            variant='primary'
            size='sm'
            style={{ width: '50px' }}
            onClick={() => setShowModal(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DonorRegister)
