import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styles from "../styles/Search.module.css";
import { connect } from "react-redux";
import * as actions from "../redux/actions";
import { bindActionCreators } from "redux";
import { msalFetch } from "../utilities/msalFetch";

function ScanUCN(props) {
  const [ucn, setUCN] = useState("");
  const [ucnError, setUCNError] = useState("");

  const history = useHistory();

  const handleUCNChange = (e) => {
    setUCNError("");
    setUCN(e.target.value);
  };

  const redirect = (id) => {
    sessionStorage.setItem("selectedDonorId", id);
    sessionStorage.setItem("selectedUCN", ucn);
    history.push("/donor/dis");
  };

  const handleUCNGo = (e) => {
    e.preventDefault();
    props.actions.setLoading();
    const options = {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: props.token,
      },
    };
    msalFetch(`/api/donor/getDonorIDfromUCN/${ucn}`, options)
      .then(async (res) => {
        props.actions.clearLoading();
        const data = await res.json();

        if (res.status === 200) {
          redirect(data.donor_id);
        } else {
          setUCNError(data.message);
        }
      })
      .catch((err) => {
        props.actions.clearLoading();
      });
  };

  return (
    <div className="row mt-4 justify-content-center" >
      <div className="col-md-6">
        <h3 className={styles.searchtitle}>Scan UCN:</h3>
        <form
          id="searchform"
          className="needs-validation"
          onSubmit={handleUCNGo}
        >
          <div className="form-group row mt-md-2">
            <label
              htmlFor="ucn"
              className="col-sm-2 col-form-label searchLabel"
            >
              UCN :{" "}
            </label>
            <div className="col-sm-7">
              <input
                type="text"
                className="form-control form-control-sm"
                id="ucn"
                name="ucn"
                value={ucn}
                onChange={handleUCNChange}
              />
              {ucnError.length ? (
                <span style={{ fontSize: "12px", color: "red" }}>
                  {ucnError}
                </span>
              ) : null}
            </div>
            <div className="col-sm-3">
              <button
                type="submit"
                className="btn btn-outline-secondary p-0"
                style={{ width: "60px", height: "30px" }}
              >
                Go
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
    return {
      token: state.token,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      actions: bindActionCreators(actions, dispatch),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(ScanUCN);
