import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import favicon from '../../images/life-plasma-logo.webp'
import { Button, Card, Modal, DatePicker, Input, Progress } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loader from '../../components/Loader'
import { toast } from 'react-toastify'
import { faClipboardList } from '@fortawesome/free-solid-svg-icons'
import MedicationList from './MedicationList'
import RiskPoster from './RiskPoster'
import moment from 'moment/moment'
import TravelPoster from './TravelPoster'
import profile from '../../images/user.png'
import questionnaire from '../../images/questionnaire.png'
import { InputMask } from 'primereact/inputmask'

const dashboardStyles = {
  content: {
    fontFamily: 'Font-Medium'
  },
  cardStyles: {
    cursor: 'pointer'
  }
}

const requirePoster = {
  4: {
    questionId: 4,
    posterType: 'MedicationList',
    component: () => <MedicationList />,
    posterHint:
      'Please read Medication List before you answer this question by clicking on clipboard icon on top right'
  },
  5: {
    questionId: 5,
    posterType: 'RiskPoster',
    component: () => <RiskPoster />,
    posterHint:
      'Please read Risk Poster before you answer this question by clicking on clipboard icon on top right'
  },
  55: {
    questionId: 55,
    posterType: 'TravelPoster',
    component: () => <TravelPoster />,
    posterHint:
      'Please read Travel Poster before you answer this question by clicking on clipboard icon on top right'
  },
  58: {
    questionId: 58,
    posterType: 'MedicationList',
    component: () => <MedicationList />,
    posterHint:
      'Please read Medication List before you answer this question by clicking on clipboard icon on top right'
  },
  64: {
    questionId: 64,
    posterType: 'RiskPoster',
    component: () => <RiskPoster />,
    posterHint:
      'Please read Risk Poster before you answer this question by clicking on clipboard icon on top right'
  },
  73: {
    questionId: 73,
    posterType: 'TravelPoster',
    component: () => <TravelPoster />,
    posterHint:
      'Please read Travel Poster before you answer this question by clicking on clipboard icon on top right'
  }
}

function DonorDHQ (props) {
  const [questions, setQuestions] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState({})
  const [answers, setAnswers] = useState({})
  const [formData, setFormData] = useState({})
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [openPoster, setOpenPoster] = useState(false)
  const [page, setPage] = useState(0)
  const [donorInfo, setDonorInfo] = useState({})
  const [donorSign, setDonorSign] = useState('')
  const [acknowledgementAccepted, setAcknowledgmentAccepted] = useState(false)
  const [showExitModal, setShowExitModal] = useState(false)

  const handleStartFormChange = e => {
    setErrors({})
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleDobChange = e => {
    setErrors({})
    setFormData({ ...formData, dob: e.value })
  }

  const validateInitialForm = () => {
    if (!formData.ssn) {
      setErrors({ ...errors, ssn: 'Please enter last 4 digits of SSN' })
      return false
    } else if (!formData.dob) {
      setErrors({ ...errors, dob: 'Please enter Date of Birth' })
      return false
    } else {
      return true
    }
  }

  const validateDonor = e => {
    e.preventDefault()
    if (validateInitialForm()) {
      const body = {
        ssn: formData.ssn,
        dob: moment(formData.dob, 'MM/DD/YYYY').format('YYYY-MM-DD')
      }
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }
      setLoading(true)
      fetch('/api/publicRoutes/validateDonor', options)
        .then(async res => {
          setLoading(false)
          const data = await res.json()
          if (res.ok) {
            let questions = data.questions.filter(
              x => x.GENDER === 'A' || x.GENDER === data.gender
            )
            questions = questions.map((x, i) => {
              x.seq = i + 1
              if (requirePoster[x.QUESTION_ID]) {
                x.hasPoster = true
                x.posterReviewed = false
                x.posterHint = requirePoster[x.QUESTION_ID]['posterHint']
              } else {
                x.hasPoster = false
                x.posterReviewed = false
                x.posterHint = ''
              }
              return x
            })
            setDonorInfo({
              first_name: data.first_name,
              last_name: data.last_name,
              donor_id: data.donor_id,
              dhqType: data.dhqType
            })
            setPage(2)
            setQuestions(questions)
            setCurrentQuestion(questions[0])
          } else {
            setErrors({ ...errors, validateDonor: data.message })
            toast.error(data.message)
          }
        })
        .catch(err => {
          setLoading(false)
        })
    }
  }

  const navigateQuestions = (type, seq) => {
    if (type == 'forward') {
      const question = questions.find(x => x.seq === seq + 1)
      setCurrentQuestion(question)
    } else if (type == 'backward') {
      const question = questions.find(x => x.seq === seq - 1)
      setCurrentQuestion(question)
    }
  }

  const handleChange = (questionId, e) => {
    setAnswers({ ...answers, [questionId]: e.target.value })
  }
  const handlePosterClose = () => {
    setOpenPoster(false)
    const newQuestions = questions.map(x => {
      if (x.QUESTION_ID === currentQuestion.QUESTION_ID) {
        x.posterReviewed = true
      }
      return x
    })
  }

  const handleDHQSubmit = e => {
    e.preventDefault()
    if (!acknowledgementAccepted) {
      setErrors({
        ...errors,
        acknowledgement:
          'Please accept acknowledgement by clicking the checkbox below'
      })
    } else if (!donorSign) {
      setErrors({ ...errors, donorSign: 'Please enter your signature' })
    } else {
      setLoading(true)
      const body = {
        donorId: donorInfo.donor_id,
        answers: answers,
        donorSign: donorSign,
        dhqType: donorInfo.dhqType,
        donorName: `${donorInfo.first_name} ${donorInfo.last_name}`
      }
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }
      fetch('/api/publicRoutes/submitDHQ', options)
        .then(async res => {
          setLoading(false)
          if (res.ok) {
            setPage(4)
          }
        })
        .catch(err => {
          setLoading(false)
          console.log(err)
        })
    }
  }

  const renderDashBoard = () => {
    return (
      <div
        className='card p-2 col-md-2'
        style={dashboardStyles.cardStyles}
        onClick={() => setPage(1)}
      >
        <div className='card-body text-center'>
          <img src={questionnaire}></img>
        </div>
        <div className='card-footer'>
          <h5 className='card-text text-center'>Donor Kiosk</h5>
        </div>
      </div>
    )
  }

  const renderQuestion = question => {
    if (question) {
      return (
        <div className='col-sm-11 col-md-10 col-lg-9'>
          <Card>
            <div className='row'>
              <div className='col-md-12 d-flex mb-3'>
                <Progress
                  showInfo={false}
                  percent={
                    answers[question.QUESTION_ID]
                      ? (currentQuestion.seq * 100) / questions.length
                      : ((currentQuestion.seq - 1) * 100) / questions.length
                  }
                ></Progress>
                <h6>{`${currentQuestion.seq}/${questions.length}`}</h6>
              </div>
              <div className={question.hasPoster ? 'col-sm-8' : 'col-sm-11'}>
                <h5 className='m-0 mb-2'>{question.QUESTION_HEAD}</h5>
                <p style={{ fontSize: '16px' }} className='p-0 mb-4'>
                  {question.QUESTION}
                </p>
              </div>
              {question.hasPoster ? (
                <div className='col-sm-4'>
                  <Button
                    size='large'
                    type='primary'
                    danger={!question.posterReviewed}
                    onClick={() => setOpenPoster(true)}
                    style={{ fontSize: '50px', height: '100px' }}
                    className='px-1 m-0'
                  >
                    {/* <FontAwesomeIcon
                      size='large'
                      style={{ fontSize: '100px', color: 'red' }}
                      icon={faClipboardList}
                    /> */}
                    {question.posterReviewed ? 'Reviewed' : 'Click Here'}
                  </Button>{' '}
                </div>
              ) : null}
            </div>
            <div className='form-check'>
              <input
                className='form-check-input'
                type='radio'
                name='flexRadioDefault'
                id={`${question.seq}yes`}
                defaultChecked={
                  answers[question.QUESTION_ID] &&
                  answers[question.QUESTION_ID] === 'Yes'
                    ? true
                    : false
                }
                value='Yes'
                onChange={e => handleChange(question.QUESTION_ID, e)}
                key={question.seq}
                disabled={question.hasPoster && !question.posterReviewed}
              />
              <label
                className='form-check-label p-0'
                htmlFor={`${question.seq}yes`}
              >
                Yes
              </label>
            </div>
            <div className='form-check '>
              <input
                className='form-check-input'
                type='radio'
                name='flexRadioDefault'
                id={`${question.seq}no`}
                value='No'
                defaultChecked={
                  answers[question.QUESTION_ID] &&
                  answers[question.QUESTION_ID] === 'No'
                    ? true
                    : false
                }
                onChange={e => handleChange(question.QUESTION_ID, e)}
                key={question.seq}
                disabled={question.hasPoster && !question.posterReviewed}
              />
              <label
                className='form-check-label p-0'
                htmlFor={`${question.seq}no`}
              >
                No
              </label>
            </div>
            {/*            {question.hasPoster && !question.posterReviewed ? (
              <h5 style={{ color: 'red' }} className='p-0 m-0'>
                {question.posterHint}
              </h5>
            ) : null} */}
            <div className='row justify-content-between mt-3'>
              <Button
                type='primary'
                style={{ width: '35%' }}
                disabled={question.seq == 1}
                onClick={() => navigateQuestions('backward', question.seq)}
              >
                Previous Question
              </Button>
              {question.seq < questions.length ? (
                <Button
                  type='primary'
                  style={{ width: '35%' }}
                  disabled={
                    question.seq == questions.length ||
                    !answers[question.QUESTION_ID]
                  }
                  onClick={() => navigateQuestions('forward', question.seq)}
                >
                  Next Question
                </Button>
              ) : (
                <Button
                  type='primary'
                  className='col-md-4 col-lg-3 col-sm-3'
                  disabled={!answers[question.QUESTION_ID]}
                  onClick={() => setPage(3)}
                >
                  Continue
                </Button>
              )}
            </div>
          </Card>
        </div>
      )
    }
  }

  const renderStartForm = () => {
    return (
      <div className='col-sm-11 col-md-5 col-lg-4'>
        <Card>
          <form onSubmit={validateDonor}>
            <div data-mdb-input-init className='form-outline mb-4'>
              <label className='form-label' htmlFor='ssn'>
                {`SSN`}
              </label>
              <Input
                type='text'
                id='ssn'
                name='ssn'
                className='form-control'
                placeholder='Please enter last 4 digits of SSN'
                maxLength={4}
                minLength={4}
                onChange={handleStartFormChange}
              />
              {errors.ssn ? <p className='text-danger'>{errors.ssn}</p> : null}
            </div>
            <label className='form-label' htmlFor='dob'>
              Date of Birth
            </label>
            <div
              data-mdb-input-init
              className='form-outline mb-4 position-relative'
            >
              <InputMask
                value={formData.dob}
                onChange={handleDobChange}
                mask='99/99/9999'
                placeholder='MM/DD/YYYY'
                style={{ width: '100%', position: 'relative' }}
              />
              {errors.dob ? <p className='text-danger'>{errors.dob}</p> : null}
            </div>
            {errors.validateDonor ? (
              <p className='text-danger'>{errors.validateDonor}</p>
            ) : null}
            <button
              data-mdb-ripple-init
              type='submit'
              className='btn btn-primary btn-block'
            >
              Proceed
            </button>
          </form>
        </Card>
      </div>
    )
  }

  const handleAcknowledgement = e => {
    if (e.target.checked) {
      setErrors({})
      setAcknowledgmentAccepted(true)
    } else {
      setAcknowledgmentAccepted(false)
    }
  }

  const renderSignForm = () => {
    return (
      <div className='col-sm-11 col-md-8 col-lg-10'>
        <Card>
          <form onSubmit={handleDHQSubmit} style={{ fontSize: '16px' }}>
            {donorInfo.dhqType === 'FDHQ' ? (
              <p>
                <strong className='text-primary'>Acknowledgment</strong>:
                <ol>
                  <li>
                    I have reviewed the educational materials regarding
                    infections that can be transmitted by my donation, such as,
                    Syphilis, HIV, Hepatitis B and C.{' '}
                  </li>
                  <li>
                    I agree not to donate if my donation could result in a
                    potential risk to people who receive plasma products.{' '}
                  </li>
                  <li>
                    A sample of my blood will be tested for infections that can
                    be transmitted by my donation, such as, Syphilis, HIV,
                    Hepatitis B and C.{' '}
                  </li>
                  <li>
                    I understand you will attempt to notify me if for any reason
                    I cannot donate, and records will be maintained indicating
                    the reason for the deferral and the deferral time period.{' '}
                  </li>
                  <li>
                    I have reviewed the information regarding the potential
                    risks and hazards of donating Source Plasma.{' '}
                  </li>
                  <li>
                    I have been given the opportunity to ask questions and
                    understand that I may withdraw from the donation procedure
                    at any time.
                  </li>
                </ol>
                <h5
                  className={
                    errors.acknowledgement
                      ? 'p-0 m-0 text-danger'
                      : 'p-0 m-0 text-primary'
                  }
                >
                  Please accept acknowledgement by clicking this checkbox
                </h5>
                <input
                  type='checkbox'
                  onChange={handleAcknowledgement}
                  checked={acknowledgementAccepted}
                  style={{ width: '50px', height: '50px' }}
                />
              </p>
            ) : (
              <p>
                <strong className='text-primary'>Acknowledgment</strong>:1. I
                have reviewed the educational materials regarding infections
                that can be transmitted by my donation, such as, Syphilis, HIV,
                Hepatitis B and C. 2. I agree not to donate if my donation could
                result in a potential risk to people who receive plasma
                products. 3. A sample of my blood will be tested for infections
                that can be transmitted by my donation, such as, Syphilis, HIV,
                Hepatitis B and C. 4. I understand you will attempt to notify me
                if for any reason I cannot donate, and records will be
                maintained indicating the reason for the deferral and the
                deferral time period. 5. I have reviewed the information
                regarding the potential risks and hazards of donating Source
                Plasma. 6. I have been given the opportunity to ask questions
                and understand that I may withdraw from the donation procedure
                at any time.
                <h5
                  className={
                    errors.acknowledgement
                      ? 'p-0 m-0 text-danger'
                      : 'p-0 m-0 text-primary'
                  }
                >
                  Please accept acknowledgement by clicking this checkbox
                </h5>
                <input
                  type='checkbox'
                  onChange={handleAcknowledgement}
                  checked={acknowledgementAccepted}
                  style={{ width: '50px', height: '50px' }}
                />
              </p>
            )}
            <div className='form-group row'>
              <label htmlFor='name' className='col-sm-2 col-form-label'>
                Name:
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  readonly
                  className='form-control-plaintext'
                  id='name'
                  value={`${donorInfo.first_name} ${donorInfo.last_name}`}
                />
              </div>
            </div>
            <div className='form-group row mt-2'>
              <label htmlFor='donorsign' className='col-sm-2 col-form-label'>
                Signature:
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  className='form-control'
                  id='donorsign'
                  onChange={e => {
                    setDonorSign(e.target.value)
                    setErrors({})
                  }}
                  placeholder='Sign here with your first and last name starting with capitals'
                />
                {errors.donorSign ? (
                  <p className='text-danger'>{errors.donorSign}</p>
                ) : null}
                <h5 className='m-0 text-primary'>
                  I am signing this document electronically. I agree that my
                  electronic signature is the legal equivalent of my
                  manual/handwritten signature on this document.
                </h5>
              </div>
            </div>
            <div className='form-group row mt-2'>
              <label htmlFor='date' className='col-sm-2 col-form-label'>
                Date:
              </label>
              <div className='col-sm-10'>
                <input
                  type='text'
                  readonly
                  className='form-control-plaintext'
                  id='date'
                  value={moment().format('MM/DD/YYYY')}
                />
              </div>
            </div>
            <div className='mt-4'>
              <button type='submit' className='btn btn-primary btn-sm'>
                Submit Donor History Questionnaire
              </button>
            </div>
          </form>
        </Card>
      </div>
    )
  }

  const renderSuccess = () => {
    return (
      <div className='col-sm-11 col-md-8 col-lg-6'>
        <Card>
          <h6 className='text-success'>
            Thank you for completing the Donor History Questionnaire. Please be
            seated until you are called by a Medical Screener
          </h6>
          <div className='mt-3'>
            <Button type='primary' onClick={() => window.location.reload()}>
              Close
            </Button>
          </div>
        </Card>
      </div>
    )
  }

  const renderContent = () => {
    switch (page) {
      case 0:
        return renderDashBoard()
      case 1:
        return renderStartForm()
      case 2:
        return renderQuestion(currentQuestion)
      case 3:
        return renderSignForm()
      case 4:
        return renderSuccess()
      default:
        return null
    }
  }

  return (
    <>
      {loading ? <Loader></Loader> : null}
      <div className='app' style={{ backgroundColor: '#F5F7F8' }}>
        <div className='row'>
          <nav className='navbar navbar-expand-lg navbar-light bg-light border-bottom justify-content-between p-4 pt-2 pb-2'>
            <Link
              className='navbar-brand'
              to='/DHQ'
              onClick={() => page != 0 && setShowExitModal(true)}
            >
              <img src={favicon} width='150px' alt='' />
            </Link>
            {page > 0 ? (
              <div className='ml-auto px-2 d-flex align-items-center'>
                <Button
                  danger
                  type='primary'
                  size='large'
                  className='mx-4'
                  onClick={() => setShowExitModal(true)}
                >
                  Exit
                </Button>
                {page > 1 ? (
                  <>
                    <img
                      src={profile}
                      style={{
                        height: '30px',
                        cursor: 'pointer',
                        marginRight: '10px'
                      }}
                      alt='profile'
                    ></img>
                    <h5 className='p-0 m-0'>{`${donorInfo.first_name} ${donorInfo.last_name}`}</h5>
                  </>
                ) : null}
              </div>
            ) : null}
          </nav>
        </div>
        <div
          className='row my-2 d-flex justify-content-center'
          style={{ zoom: '1.5', overflow: 'hidden' }}
        >
          {page > 0 ? (
            <h3 className='text-center' style={{ color: '#2E236C' }}>
              Donor History Questionnaire
            </h3>
          ) : null}
          {renderContent()}
        </div>
      </div>
      <Modal
        centered
        width={1000}
        closable={true}
        open={openPoster}
        onCancel={handlePosterClose}
        footer={[
          <Button type='primary' onClick={handlePosterClose}>
            Close
          </Button>
        ]}
      >
        {requirePoster[currentQuestion.QUESTION_ID] &&
          requirePoster[currentQuestion.QUESTION_ID]['component']()}
      </Modal>
      <Modal
        title='Are you sure you want to exit?'
        open={showExitModal}
        onOk={() => window.location.reload()}
        onCancel={() => setShowExitModal(false)}
      >
        <p>Please be noted that all the changes will be lost.</p>
      </Modal>
    </>
  )
}

export default DonorDHQ
