import React, { useEffect, useState } from 'react'
import { msalFetch } from '../../../utilities/msalFetch'
import { toast } from 'react-toastify'
import { Input, Card, Tag, Modal, Select, Divider, Space, Button, Table } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX, faPlus } from '@fortawesome/free-solid-svg-icons'
import { centerAreas, nomogramTypes } from '../../../constants/admin'
import { PlusOutlined } from '@ant-design/icons'

const { Search } = Input

function ManageCenters (props) {
  const [centers, setCenters] = useState([])
  const [editCenter, setEditCenter] = useState({})
  const [formData, setFormData] = useState({})
  const [areaInput, setAreaInput] = useState('')
  const [areas, setAreas] = useState([])
  const [centerConfig, setCenterConfig] = useState({})
  const [stationInput, setStationInput] = useState({})
  const [errors, setErrors] = useState({})
  const [selectedAreas, setSelectedAreas] = useState([])

  useEffect(() => {
    if (editCenter && editCenter.center_code) {
      setFormData({
        centerName: editCenter.center_name,
        centerAddress: editCenter.center_address,
        nomogramType: editCenter.nomogram_type
      })
      const configs = JSON.parse(editCenter.center_config)
      setAreas(Object.keys(configs))
      setCenterConfig(configs)
    }
  }, [editCenter])

  const getAllCenters = () => {
    props.setLoading()
    const options = {
      method: 'GET',
      headers: {
        Authorization: props.token,
        'Content-Type': 'application/json'
      }
    }
    msalFetch('/api/centerManagement/getCenters', options)
      .then(async res => {
        props.clearLoading()
        if (res.ok) {
          const data = await res.json()
          setCenters(data)
          props.clearLoading()
        } else {
          toast.error('Failed to get all centers')
        }
      })
      .catch(err => {
        toast.error('Failed to get all centers')
        props.clearLoading()
        console.log(err)
      })
  }

  useEffect(() => {
    getAllCenters()
  }, [])

  const columns = [
    { title: 'Center Code', key: 'center_code', dataIndex: 'center_code' },
    { title: 'Center Name', key: 'center_name', dataIndex: 'center_name' },
    {
      title: 'Center Address',
      key: 'center_address',
      dataIndex: 'center_address'
    },
    {
      title: 'Actions',
      render: (_, record) => {
        return (
          <Button type='primary' onClick={() => setEditCenter(record)}>
            Edit
          </Button>
        )
      }
    }
  ]

  const handleChange = e => {
    setErrors({})
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const addArea = () => {
    setErrors({})
    if (areaInput) {
      const flag = areas.findIndex(x => x == areaInput)
      if (flag === -1) {
        setAreas([...areas, areaInput])
        setAreaInput('')
        const newConfig = { ...centerConfig }
        newConfig[[areaInput]] = []
        setCenterConfig(newConfig)
      } else {
        setAreaInput('')
      }
    }
  }

  const onDeleteArea = value => {
    const newAreas = areas.filter(x => x != value)
    setAreas(newAreas)
    const newConfig = { ...centerConfig }
    delete newConfig[[value]]
    setCenterConfig(newConfig)
  }

  const handleStationAdd = (value, area) => {
    if (value) {
      setErrors({})
      const newConfig = { ...centerConfig }
      newConfig[[area]] = [...newConfig[area], value]
      setCenterConfig(newConfig)
      setStationInput({})
    }
  }

  const onDeleteStation = (area, station) => {
    const stations = centerConfig[[area]]
    const newStations = stations.filter(x => x != station)
    const newConfig = { ...centerConfig }
    centerConfig[[area]] = newStations
    setCenterConfig(newConfig)
  }

  const handleAreaMultiSelect = value => {
    const newConfig = { ...centerConfig }
    let newAreas = [...areas]
    value.forEach(x => {
      if (!newConfig[x]) {
        newConfig[x] = []
      }
    })

    Object.keys(newConfig).forEach(x => {
      if (!value.includes(x)) {
        delete newConfig[x]
      }
    })
    setCenterConfig(newConfig)
    setAreas(value)
  }

  const validate = () => {
    const errors = {}
    if (!formData.centerName) {
      errors.centerName = 'Center name is required'
    }
    if (!formData.centerAddress) {
      errors.centerAddress = 'Center address is required'
    }
    if (!areas.length) {
      errors.areas = 'Atleast one area is required'
    }
    if (!formData.nomogramType) {
      errors.nomogramType = 'Nomogram Type is required'
    }
    if (Object.keys(errors).length) {
      setErrors(errors)
      return false
    } else {
      setErrors({})
      return true
    }
  }

  const onSubmit = e => {
    e.preventDefault()
    if (validate()) {
      props.setLoading()
      const data = {
        ...formData,
        centerConfig,
        centerCode: editCenter.center_code
      }
      const options = {
        method: 'POST',
        headers: {
          Authorization: props.token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...data })
      }
      msalFetch('/api/centerManagement/updateCenter', options)
        .then(async res => {
          props.clearLoading()
          if (res.ok) {
            toast.success('Center updated successfully')
            setEditCenter({})
          } else {
            toast.error('Failed to create center')
          }
        })
        .catch(err => {
          toast.error('Failed to create center')
          props.clearLoading()
          console.log(err)
        })
    }
  }

  return (
    <>
      <div className='row'>
        <h3>Manage Centers</h3>
        <Table columns={columns} dataSource={centers}></Table>
      </div>
      <Modal
        open={!!editCenter.center_code}
        centered
        width={'70%'}
        title={`Manage Center - ${editCenter.center_code}`}
        onCancel={() => setEditCenter({})}
        onOk={onSubmit}
      >
        <form>
          <div className='row my-2'>
            <div className='col-md-6 form-group'>
              <label htmlFor='centerName'>
                Center Name<span style={{ color: 'red' }}>&#42;</span>:
              </label>
              <input
                type='text'
                className='form-control'
                id='centerName'
                value={formData.centerName}
                name='centerName'
                onChange={handleChange}
                placeholder='Center Name'
              ></input>
              {errors.centerName ? (
                <span className='invalidInput'>{errors.centerName}</span>
              ) : null}
            </div>
            <div className='col-md-6 form-group'>
              <label htmlFor='centerAddress'>
                Center Address<span style={{ color: 'red' }}>&#42;</span>:
              </label>
              <textarea
                type='text'
                className='form-control'
                id='centerAddress'
                value={formData.centerAddress}
                name='centerAddress'
                onChange={handleChange}
                placeholder='Center Address'
              ></textarea>
              {errors.centerAddress ? (
                <span className='invalidInput'>{errors.centerAddress}</span>
              ) : null}
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-md-6 form-group'>
              <label htmlFor='nomogramType'>
                Nomogram Type<span style={{ color: 'red' }}>&#42;</span>:
              </label>
              <select
                className='form-select'
                id='nomogramType'
                value={formData.nomogramType}
                name='nomogramType'
                onChange={handleChange}
              >
                <option selected={!formData.nomogramType} disabled>
                  select
                </option>
                <option value={nomogramTypes.weightBased}>
                  {nomogramTypes.weightBased}
                </option>
                <option value={nomogramTypes.personalized}>
                  {nomogramTypes.personalized}
                </option>
              </select>
              {errors.nomogramType ? (
                <span className='invalidInput'>{errors.nomogramType}</span>
              ) : null}
            </div>
            <div className='form-group col-md-6 col-lg-4 '>
              <label htmlFor='addArea'>
                Add Areas<span style={{ color: 'red' }}>&#42;</span>:
              </label>
              <Select
                style={{ width: '100%', display:'block' }}
                size='large'
                mode='multiple'
                allowClear
                onChange={handleAreaMultiSelect}
                placeholder='Select Areas'
                value={areas}
                dropdownRender={menu => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: '8px 0'
                      }}
                    />
                    <Space
                      style={{
                        padding: '0 8px 4px'
                      }}
                    >
                      <Input
                        placeholder='Please enter area name'
                        value={areaInput}
                        onChange={e => setAreaInput(e.target.value)}
                        onKeyDown={e => e.stopPropagation()}
                      />
                      <Button
                        type='primary'
                        icon={<PlusOutlined />}
                        onClick={addArea}
                      >
                        Add Area
                      </Button>
                    </Space>
                  </>
                )}
                options={centerAreas.map(item => ({
                  label: item,
                  value: item
                }))}
              />
              {errors.areas ? (
                <span className='invalidInput'>{errors.areas}</span>
              ) : null}
            </div>
            <div className='row p-1'>
              {areas?.map(x => {
                return (
                  <Card
                    size='small'
                    title={
                      <div className='d-flex justify-content-between px-1 align-items-center'>
                        <p className='text-center d-flex p-0 m-0'>{x}</p>
                        <button
                          className='btn p-0 m-0'
                          onClick={() => onDeleteArea(x)}
                        >
                          <FontAwesomeIcon icon={faX}></FontAwesomeIcon>
                        </button>
                      </div>
                    }
                    style={{
                      width: 200,
                      height: 'min-content'
                    }}
                    key={x}
                    className='p-1 col-md-4 col-lg-3 col-sm-12 m-1'
                    actions={[
                      <Search
                        placeholder='Station Name'
                        enterButton=<FontAwesomeIcon
                          icon={faPlus}
                          size='small'
                        ></FontAwesomeIcon>
                        onChange={e =>
                          setStationInput({
                            ...stationInput,
                            [x]: e.target.value
                          })
                        }
                        onSearch={value => handleStationAdd(value, x)}
                        value={stationInput[[x]]}
                      ></Search>
                    ]}
                  >
                    {centerConfig[[x]]?.map((y, i) => {
                      return (
                        <Tag
                          closeIcon
                          onClose={() => onDeleteStation(x, y)}
                          key={i}
                          className='p-1 my-1'
                          style={{ fontSize: '14px' }}
                        >
                          {y}
                        </Tag>
                      )
                    })}
                  </Card>
                )
              })}
            </div>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ManageCenters
