export const TimingWhenReactionBegin = [
    "Pre-donation",
    "During Donation",
    "Post-donation (on-site)",
    "Post-donation (off-site)"
]
export const classificationOfReaction = [
    "Moderate",
    "Severe",
    "Minor",
]

const commonSymptoms = [
    "Abdominal cramps",
    "Auditory disturbance (e.g. sounds coming from a distance or 'buzzing' in the ears)",
    "Chills or Shivering",
    "Clammy",
    "Cold extremities",
    "Dizziness",
    "Epigastric discomfort",
    "Facial pallor (e.g. pale skin or lips)",
    "Feeling of Warmth",
    "Headache or neck ache",
    "Hypotension",
    "Lightheadedness",
    "Nausea",
    "Palpitations",
    "Sweating",
    "Visual Disturbance (e.g. blurred or faded vision)",
]



export const reactions =
{
    "Hypotensive Event": {
        "Hypotensive: Prefaint, No LOC (Minor)": [
            ...commonSymptoms,
            "Weakness"
        ],
        "Hypotensive: Prefaint, No LOC (Moderate)": [
            ...commonSymptoms,
            "Weakness",
            "Vomiting"
        ],
        "Hypotensive: LOC (brief)": [
            ...commonSymptoms,
            "Weakness",
            "Vomiting"
        ],
        "Hypotensive: LOC (prolonged)": [
            ...commonSymptoms,
            "Weakness",
            "Vomiting"
        ],
        "Hypotensive: Severe (With or Without LOC)": [
            ...commonSymptoms,
            "Weakness",
            "Vomiting",
            "Chest Pain",
            "Convulsions/Seizures",
            "Loss of Bladder/Bowel Control",
            "Prolonged signs or symptoms that do not resolve"
        ],
        "Hypotensive: Injury": [
            ...commonSymptoms,
            "Weakness",
            "Vomiting",
            "Chest Pain",
            "Convulsions/Seizures",
            "Loss of Bladder/Bowel Control",
            "Prolonged signs or symptoms that do not resolve",
            "Closed Head Injury",
            "Dental Injury",
            "Fracture",
            "Laceration",
            "Soft Tissue Injury (not phlebotomy related)",
            "Other"
        ]
    },
    "Major Cardiovascular or Respiratory Event": {
        "Major Cardiovascular or Respiratory Event": [
            "Angina Pectoris",
            "Cardiac Arrest",
            "Cerebrovascular Accident",
            "Myocardial Infarction",
            "Transient Ischemic Attack",
            "Respiratory Arrest"
        ]
    },
    "Local Injury Related to Phlebotomy": {
        "Nerve Irritation": [
            "Immediate Intense Pain at Site",
            "Paresthesias",
            "Numbness/Tingling of Fingers, Hand, or Arm",
            "Shooting Pain Down Arm",
            "Weakness of Arm"
        ],
        "Hematoma/Bruise (Uncomplicated)": [
            "Mild Pain",
            "No Restriction of Movement",
            "Skin Discoloration",
            "Swelling"
        ],
        "Hematoma/Bruise (Complicated)": [
            "Paresthesias",
            "Numbness/Tingling of Fingers, Hand, or Arm",
            "Pressure",
            "Redness",
            "Restricted Movement",
            "Shooting Pain Down Arm",
            "Significant Pain",
            "Skin Discoloration",
            "Swelling",
            "Tenderness",
            "Warmth",
            "Weakness of Arm"
        ],
        "Infection": [
            "Drainage",
            "Pain",
            "Redness",
            "Swelling",
            "Tenderness",
            "Warmth"
        ],
        "Arterial Puncture": [
            "Bright Red Blood",
            "Pulse Sensation in Tubing",
            "Pulsing Blood Flow"
        ],
        "Infiltration": [
            "Pain",
            "Swelling"
        ],
        "Major Blood Vessel Injury": [
            "Arteriovenous Fistula",
            "Brachial Artery Pseudoaneurysm",
            "Compartment Syndrome",
            "Venous Thrombosis",
            "Phlebitis",
            "Thrombophlebitis"
        ]
    },
    "Citrate Reaction Event": {
        "Minor": [
            "Metallic Taste",
            "Paresthesia (Perioral – Lips Tingling/Numbness)",
            "Paresthesia (Peripheral - Hands/Feet Tingling/Numbness)"
        ],
        "Moderate": [
            "Metallic Taste",
            "Paresthesia (Perioral – Lips Tingling/Numbness)",
            "Paresthesia (Peripheral - Hands/Feet Tingling/Numbness)",
            "Carpopedal Spasms",
            "Chest Pressure",
            "Cold Extremities",
            "Chills/Shivering",
            "Muscle Tightness and/or Cramping",
            "Nausea",
            "Pallor, Pale Skin or Lips",
            "Shortness of Breath",
            "Sneezing/Nasal Congestion",
            "Tetany (Transient)",
            "Tremors (Sensation of Vibration)",
            "Twitching",
            "Vomiting"
        ],
        "Severe": [
            "Metallic Taste",
            "Paresthesia (Perioral – Lips Tingling/Numbness)",
            "Paresthesia (Peripheral - Hands/Feet Tingling/Numbness)",
            "Carpopedal Spasms",
            "Chest Pressure",
            "Cold Extremities",
            "Chills/Shivering",
            "Muscle Tightness and/or Cramping",
            "Nausea",
            "Pallor, Pale Skin or Lips",
            "Shortness of Breath",
            "Sneezing/Nasal Congestion",
            "Tetany (Transient)",
            "Tremors (Sensation of Vibration)",
            "Twitching",
            "Vomiting",
            "Bluish Tint to Skin (Cyanosis)",
            "Chest Pain",
            "Heart Arrhythmia",
            "Hypotension (Severe)",
            "Incontinence",
            "Mental Confusion",
            "Tetany (Severe)"
        ]
    },
    "Hemolysis/Hemoglobinuria Event": {
        "Uncomplicated": [
            "Red/brown colored urine as the only sign"
        ],
        "Complicated": [
            "Red/brown colored urine as the only sign",
            "Back/Flank Pain",
            "Bluish Tint to Skin (Cyanosis)",
            "Mental Confusion",
            "Pallor, Pale Skin or Lips",
            "Shortness of Breath",
            "Symptoms of Hemolysis"
        ]
    },
    "Allergic Reaction": {
        "Mild": [
            "Pruritus (itching)",
            "Rash",
            "Urticaria (hives)",
            "Wheals (small areas of swelling)"
        ],
        "Moderate": [
            "Pruritus (itching)",
            "Rash",
            "Urticaria (hives)",
            "Wheals (small areas of swelling)",
            "Angioedema",
            "Wheezing"
        ],
        "Severe": [
            "Pruritus (itching)",
            "Rash",
            "Urticaria (hives)",
            "Wheals (small areas of swelling)",
            "Angioedema",
            "Wheezing",
            "Anaphylaxis"
        ]
    },
    "Air Embolism Event": {
        "Air Embolism": [
            "Blurred Vision",
            "Chest Pain",
            "Mental Confusion",
            "Shortness of Breath",
            "Weakness"
        ]
    },
    "Equipment Failure or Operator Error": {
        "Equipment Malfunction": [
            "No direct symptoms; associated with equipment failure"
        ],
        "Operator Error": [
            "No direct symptoms; associated with human error"
        ]
    },

    "Immunization Event": {
        "Local, mild": [
            "Induration (hardening)",
            "Itching",
            "Nodule formation",
            "Pain",
            "Rash",
            "Redness",
            "Swelling",
            "Tenderness",
            "Urticaria"
        ],
        "Local, severe": [
            "Induration (hardening)",
            "Itching",
            "Nodule formation",
            "Pain",
            "Rash",
            "Redness",
            "Swelling",
            "Tenderness",
            "Urticaria",
            "Brachial Neuritis",
            "Infection",
            "Necrosis"
        ],
        "Systemic, mild": [
            "Arthralgia",
            "Diarrhea",
            "Dizziness",
            "Fatigue",
            "Fever",
            "Flu-like symptoms",
            "Headache",
            "Lymphadenopathy (enlarged, sometimes tender lymph glands)",
            "Malaise",
            "Myalgia (muscular pain)",
            "Nausea",
            "Rash, disseminated, diffuse",
            "Vomiting"
        ],
        "Systemic, severe": [
            "Arthralgia",
            "Diarrhea",
            "Dizziness",
            "Fatigue",
            "Fever",
            "Flu-like symptoms",
            "Headache",
            "Lymphadenopathy (enlarged, sometimes tender lymph glands)",
            "Malaise",
            "Myalgia (muscular pain)",
            "Nausea",
            "Rash, disseminated, diffuse",
            "Vomiting",
            "Anaphylaxis or anaphylactoid reactions",
            "Hemolytic transfusion reaction (when human red blood cells are used as the antigen)",
            "Serum sickness"
        ],
        "Hypotensive, no LOC": [
            ...commonSymptoms,
            "Weakness",
            "Vomiting"
        ],
        "Hypotensive, LOC": [
            ...commonSymptoms,
            "Weakness",
            "Vomiting",
            "Chest Pain",
            "Convulsions/Seizures",
            "Loss of Bladder/Bowel Control",
            "Prolonged signs or symptoms that do not resolve"
        ]
    },
    "Other Event": {
        "Other": [
            "Atypical signs or symptoms that do not fit into other categories"
        ]
    },
}


export const typeOfReaction = Object.keys(reactions)

export const getSubCategory = (category) => Object.keys(reactions[category])

export const getSymptoms = (category, subCategory) => reactions[category][subCategory]

export const treatmentOptions = [
    "Cold Compress Applied",
    "Donation Discontinued Early and: RBC’s returned",
    "RBC’s lost",
    "Loosened Tight Clothing",
    "Airway Management",
    "Legs Elevated",
    "Medication Administered",
    "Initiated CPR",
    "Bandage Applied",
    "Donor Refused Treatment",
    "Other",
    "NA"
];

export const medicationFields = [
    { name: 'type', label: 'Type', type: 'text' },
    { name: 'lotNumber', label: 'Lot #', type: 'text' },
    { name: 'expirationDate', label: 'Expiration Date', type: 'date' },
    { name: 'dosage', label: 'Dosage', type: 'text' },
    { name: 'route', label: 'Route', type: 'text' },
    { name: 'performedBy', label: 'Performed By', type: 'select' },
    { name: 'time', label: 'Time', type: 'time' },
];
export const softGoodFields = [
    { name: 'type', label: 'Type', type: 'text' },
    { name: 'lotNumber', label: 'Lot', type: 'text' },
    { name: 'expirationDate', label: 'Expiration Date', type: 'date' },
];
export const vitalSigns = [
    {
        name: 'time', label: 'Time', type: 'time'
    },
    { name: 'bloodpressure', label: 'Blood Pressure', type: 'text', mask: '999/99?9' },
    { name: 'pulse', label: 'Pulse', type: 'text' },
    { name: 'performedBy', label: 'Performed By', type: 'select' },
];

export const initialMedication = {
    type: "",
    lotNumber: "",
    expirationDate: "",
    dosage: "",
    route: "",
    time: "",
};

export const initialVitalSigns = {
    time: "",
    bloodpressure: "",
    pulse: "",
    performedBy: "",
};

export const initialData = {
    ucn: "",
    centerCode: "",
    dateOfReaction: "",
    donorId: "",
    donorName: "",
    timeOfReaction: "",
    reactionWasFirstKnownToBegin: "",
    classificationOfReaction: "",
    collectionOfVolume: {
        volumeOfPlasma: "",
        amountOfProceduralSalineAdministered: "",
        amountOfCitrateUsed: "",
    },
    typeOfReaction: {
        category: "",
        subCategory: "",
        symptoms: [],
    },
    treatment: {
        typesOfTreatment: "",
        oralFluidsGiven: "",
        medication: [initialMedication],
        vitalSigns: [initialVitalSigns],
        medicationComments: "",
        description: "",
    },
    donorRecoveryAndDesposition: {
        vitalSigns: [initialVitalSigns],
        conditionAtTimeOfRelease: "",
        recoveryPeriod: "",
        emsActivatedResponse: "",
        medicalDeferral: "",
        donorPhoneNumber: "",
        centerPhysicianNotified: "",
    },
    physician: {
        donorStatus: '',
    }
};