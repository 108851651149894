import moment from 'moment'

export const checkValidUCN = UCN => {
  const re = new RegExp('^LP[0-9]{8}$')
  return re.test(UCN)
}

export const checkValidCaseNumber = caseno => {
  const re = new RegExp('[CL][SP][0-9]{8}$')
  return re.test(caseno)
}

export const checkDonorEligibility = (
  dob,
  veinGradeL,
  veinGradeR,
  nddrPass,
  addressVerified,
  donorStatus,
  dhqResult = 'PASS',
  donationStatus,
  medical_screener_page = false
) => {
  const currentDate = new Date()
  const a = moment(currentDate)
  const b = moment(dob)
  const ageDiff = a.diff(b, 'years', true)
  if (medical_screener_page && donationStatus == 'QA Reviewed') {
    return { eligible: true, reason: '' }
  } else if (
    donorStatus == 'TEMPORARILY DEFERRED' ||
    donorStatus == 'PERMANENTLY DEFERRED' ||
    (donorStatus == 'TEMPORARILY DEFERRED (Abnormal SPE)' &&
      !medical_screener_page)
  ) {
    return {
      eligible: false,
      reason: `Donor is ${donorStatus}`
    }
  } else if (ageDiff < 18 || ageDiff > 70) {
    return { eligible: false, reason: 'Age must be between 18 & 70' }
  } else if (veinGradeL == 'Unsuitable' && veinGradeR == 'Unsuitable') {
    return {
      eligible: false,
      reason: 'Vein Grade on both arms are Unsuitable'
    }
  } else if (nddrPass == 'No') {
    return { eligible: false, reason: 'NDDR not passed' }
  } else if (addressVerified == 'No') {
    return { eligible: false, reason: 'Address not verified' }
  } else if (dhqResult == 'FAIL') {
    return { eligible: false, reason: 'DHQ not passed' }
  } else {
    return { eligible: true, reason: '' }
  }
}

export const isParameterValid = (parameter, value) => {
  switch (parameter) {
    case 'CDCS_CHECK':
      return value == 'Yes' ? '' : 'text-bg-danger'
    case 'ARM_CHECK':
      return value == 'S' ? '' : 'text-bg-danger'
    case 'BP':
      const [bp_sys1, bp_dia1] = value
      if (bp_sys1 < 90 || bp_sys1 > 180 || bp_dia1 < 50 || bp_dia1 > 100) {
        return false
      }
      return true
    case 'BP_RETAKE':
      const [bp_sys2, bp_dia2] = value
      if (bp_sys2 < 90 || bp_sys2 > 180 || bp_dia2 < 50 || bp_dia2 > 100) {
        return false
      }
      return true
    case 'WEIGHT':
      if (value < 110) {
        return false
      }
      return true
    case 'PULSE':
      if (value < 50 || value > 100) {
        return false
      } else {
        return true
      }
    case 'TEMP':
      if (value < 96 || value > 99.5) {
        return false
      }
      return true
    case 'TP':
      if (value < 6 || value > 9) {
        return false
      } else {
        return true
      }
    case 'Hemotacrit':
      const [hct, gender] = value
      if (gender === 'M') {
        if (hct < 39 || hct > 54) {
          return false
        } else {
          return true
        }
      } else {
        if (hct < 38 || hct > 54) {
          return false
        } else {
          return true
        }
      }
    case 'ACTUAL_PLASMA':
      let [target, actual] = value
      if (actual > target + 5) {
        return false
      } else if (actual < target - 5) {
        return false
      } else {
        return true
      }
    case 'ACTUAL_VOLUME':
      const [targetVolume, actualVolume] = value
      if (targetVolume > actualVolume + 5) {
        return false
      } else if (targetVolume < actualVolume - 5) {
        return true
      } else {
        return true
      }
  }
}

export const isValidSSN = ssn => {
  const re = new RegExp(/\d{3}-?\d{2}-?\d{4}/)
  return re.test(ssn)
}

export const isValidBOL = bol => {
  const re = new RegExp('BPL-[0-9]{6}$')
  return re.test(bol)
}
