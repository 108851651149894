import React, { useState } from 'react'
import { Button } from 'antd'
import { formatDate } from '../../utilities/format'
import styles from '../../styles/DIS.module.css'
import { msalFetch } from '../../utilities/msalFetch'
import { toast } from 'react-toastify'

const LabReport = props => {


  const reviewLabReport = () => {
    props.actions.setLoading()
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      },
      body: JSON.stringify({ UCN: props.donationDetails.ucn })
    }
    msalFetch('/api/donor/reviewLabReport', options)
      .then(async res => {
        props.actions.clearLoading()
        if (res.ok) {
          const data = await res.json()
          if (data.success) {
            toast.success('Lab result approved')
            props.setApprovalDetails({ ...data })
            props.fetchDonationHistory()
            props.setVisible(false)
          }
        } else {
          props.actions.clearLoading()
          toast.error('Failed to approve')
        }
      })
      .catch(err => {
        props.actions.clearLoading()
        toast.error('Failed to approve')
      })
  }

  const checkAlbuminOutofRange = albumin => {
    if (albumin >= 3.01 && albumin <= 5.95) {
      return false
    }
    return true
  }

  const checkAlpha1OutofRange = alpha1 => {
    if (alpha1 >= 0.17 && alpha1 <= 0.44) {
      return false
    }
    return true
  }

  const checkAlpha2OutofRange = alpha2 => {
    if (alpha2 >= 0.42 && alpha2 <= 1.06) {
      return false
    }
    return true
  }

  const checkBetaOutofRange = beta => {
    if (beta >= 0.5 && beta <= 1.17) {
      return false
    }
    return true
  }

  const checkGammaOutofRange = gamma => {
    if (gamma >= 0.4 && gamma <= 1.8) {
      return false
    }
    return true
  }

  const checkTPOutofRange = tp => {
    if (tp >= 6.0 && tp <= 9.0) {
      return false
    }
    return true
  }

  const viralMarkersOutOfRange = x => {
    if (x == 'N') {
      return false
    }
    return true
  }

  const handleUpdateLabResultChange = e => {
    props.setUpdateLabResultFormData({
      ...props.updateLabResultFormData,
      [e.target.name]: e.target.value
    })
  }

  const renderApproveButton = () => {
    console.log(props)
    if (
      props.roleid === 7 &&
      props.labresults &&
      props.donationDetails &&
      props.donationDetails.spe_only
    ) {
      return (
        <Button type='primary' onClick={reviewLabReport}>
          Physician Sub Review
        </Button>
      )
    } else if ((props.roleid === 8 || props.roleid === 3) && props.labresults) {
      return (
        <Button type='primary' onClick={reviewLabReport}>
          QA Review
        </Button>
      )
    } else {
      return null
    }
  }

  const updateLabResult = () => {
    try {
      props.actions.setLoading()
      const body = {
        ucn: props.donationDetails.ucn,
        labResult: props.updateLabResultFormData.labResult,
        comments: props.updateLabResultFormData.comments
      }
      const options = {
        method: 'post',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.token
        },
        body: JSON.stringify(body)
      }
      msalFetch('/api/reports/updateLabResult', options)
        .then(async res => {
          props.actions.clearLoading()
          if (res.ok) {
            toast.success('Lab Result Updated successfully')
            props.fetchDonationHistory()
            props.setVisible(false)
          } else {
            const data = await res.json()
            toast.error(data.message)
          }
        })
        .catch(e => {
          props.actions.clearLoading()
        })
    } catch (e) {
      console.log(e)
    }
  }

  const renderLabResults = labReport => {
    let required = []
    const parameters = {
      Albumin: {
        key: 'Albumin',
        group: 'Serum Protein Electrophoresis',
        validator: checkAlbuminOutofRange,
        title: 'Albumin',
        expected: '3.01-5.95 g/dL'
      },
      Alpha1: {
        key: 'Alpha1',
        group: 'Serum Protein Electrophoresis',
        validator: checkAlpha1OutofRange,
        title: 'Alpha-1',
        expected: '0.17-0.44 g/dL'
      },
      Alpha2: {
        key: 'Alpha2',
        group: 'Serum Protein Electrophoresis',
        validator: checkAlpha2OutofRange,
        title: 'Alpha-2',
        expected: '0.42-1.06 g/dL'
      },
      Beta: {
        key: 'Beta',
        group: 'Serum Protein Electrophoresis',
        validator: checkBetaOutofRange,
        title: 'Beta Globulins',
        expected: '0.50-1.17 g/dL'
      },
      Gamma: {
        key: 'Gamma',
        group: 'Serum Protein Electrophoresis',
        validator: checkGammaOutofRange,
        title: 'Gamma',
        expected: '0.40-1.80 g/dL'
      },
      RPR: {
        key: 'RPR',
        group: 'RPR (Syphilis Screen)',
        validator: viralMarkersOutOfRange,
        title: 'RPR',
        expected: 'N'
      },
      TP: {
        key: 'TP',
        group: 'Total Protein',
        validator: checkTPOutofRange,
        title: 'TP',
        expected: '6.0-9.0 g/dL'
      },
      HBsAg: {
        key: 'HBsAg',
        group: 'Viral Markers',
        validator: viralMarkersOutOfRange,
        title: 'Hepatitis B Surface Antigen',
        expected: 'N'
      },
      HCV: {
        key: 'HCV',
        group: 'Viral Markers',
        validator: viralMarkersOutOfRange,
        title: 'Hepatitis C Antibody',
        expected: 'N'
      },
      HIV: {
        key: 'HIV',
        group: 'Viral Markers',
        validator: viralMarkersOutOfRange,
        title: 'HIV 1/2 plus 0',
        expected: 'N'
      },
      HBVDNA: {
        key: 'HBVDNA',
        group: 'NAT',
        validator: viralMarkersOutOfRange,
        title: 'HBV DNA',
        expected: 'N'
      },
      HCVRNA: {
        key: 'HCVRNA',
        group: 'NAT',
        validator: viralMarkersOutOfRange,
        title: 'HCV RNA',
        expected: 'N'
      },
      HIVRNA: {
        key: 'HIVRNA',
        group: 'NAT',
        validator: viralMarkersOutOfRange,
        title: 'HIV RNA',
        expected: 'N'
      },
      HAVRNA: {
        key: 'HAVRNA',
        group: 'NAT',
        validator: viralMarkersOutOfRange,
        title: 'HAV RNA',
        expected: 'N'
      },
      B19DNA: {
        key: 'B19DNA',
        group: 'NAT',
        validator: viralMarkersOutOfRange,
        title: 'Parvo B19 DNA',
        expected: 'N'
      },
      ABS: {
        key: 'ABS',
        group: 'Antibody Screen',
        validator: viralMarkersOutOfRange,
        title: 'Antibody Screen',
        expected: 'N'
      }
    }
    const {
      Albumin,
      Alpha1,
      Alpha2,
      Beta,
      Gamma,
      RPR,
      TP,
      HBsAg,
      HCV,
      HIV,
      HBVDNA,
      HCVRNA,
      HIVRNA,
      HAVRNA,
      B19DNA,
      ABS
    } = parameters
    const speParameters = [Albumin, Alpha1, Alpha2, Beta, Gamma, RPR, TP]
    if (props.donationDetails?.spe_only) {
      required = [Albumin, Alpha1, Alpha2, Beta, Gamma, RPR, TP]
    } else if (props.donationDetails?.donor_type === 'APPLICANT 1') {
      required = [
        Albumin,
        Alpha1,
        Alpha2,
        Beta,
        Gamma,
        RPR,
        TP,
        HBsAg,
        HCV,
        HIV,
        HBVDNA,
        HCVRNA,
        HIVRNA,
        HAVRNA,
        B19DNA,
        ABS
      ]
    } else if (
      props.donationDetails?.donor_type === 'APPLICANT 2' ||
      props.donationDetails?.donor_type === 'QUALIFIED'
    ) {
      if (props.donationDetails?.spe_taken) {
        required = [
          Albumin,
          Alpha1,
          Alpha2,
          Beta,
          Gamma,
          RPR,
          TP,
          HBsAg,
          HCV,
          HIV,
          HBVDNA,
          HCVRNA,
          HIVRNA,
          HAVRNA,
          B19DNA,
          ABS
        ]
      } else {
        required = [HAVRNA, HBVDNA, HCVRNA, HBsAg, HCV, HIV, HIVRNA, B19DNA]
      }
    } else {
      required = [HAVRNA, HBVDNA, HCVRNA, HBsAg, HCV, HIV, HIVRNA, B19DNA]
    }
    //adding missing params if we got them from Lab
    speParameters.forEach(x => {
      if (required.findIndex(y => y.key == x.key) == -1 && labReport[x.key]) {
        required.push(x)
      }
    })
    if (Object.keys(labReport).length && props.donationDetails) {
      return (
        <tbody>
          {required.map((x, i) => {
            return (
              <tr key={i}>
                <td>{x.group}</td>
                <td>{x.title}</td>
                <td
                  className={
                    labReport[x.key]
                      ? x.validator(labReport[x.key])
                        ? 'table-danger'
                        : 'table-success'
                      : ''
                  }
                >
                  {labReport[x.key] ? labReport[x.key] : ''}
                </td>
                <td>{x.expected}</td>
              </tr>
            )
          })}
        </tbody>
      )
    }
    return 'Loading...'
  }
  return (
    <React.Fragment>
      {props.labresults && props.donationDetails ? (
        Object.keys(props.labresults).length ? (
          <div>
            <table className='table table-borderless table-sm'>
              <tbody>
                <tr>
                  <td>UCN :</td>
                  <th>{props.labresults.UCN}</th>
                  <td>Draw Date :</td>
                  <th>{formatDate(props.labresults.DrawDate)}</th>
                </tr>
                {props.approvalDetails && props.approvalDetails.physub_review ? (
                  <tr>
                    <td>Physician Substitute :</td>
                    <th>{props.approvalDetails.physub_review}</th>
                    <td>PhySub Review Date :</td>
                    <th>{formatDate(props.approvalDetails.physub_review_date)}</th>
                  </tr>
                ) : null}
                {props.approvalDetails && props.approvalDetails.qa_review ? (
                  <tr>
                    <td>Quality Assurance :</td>
                    <th>{props.approvalDetails.qa_review}</th>
                    <td>QA Review Date :</td>
                    <th>{formatDate(props.approvalDetails.qa_review_date)}</th>
                  </tr>
                ) : null}
                <tr>
                  <td>LAB Name :</td>
                  <th>GC Tech Lab</th>
                  <td>Address :</td>
                  <th>485 Spencer Lane, San Antonio, TX 78201</th>
                </tr>
              </tbody>
            </table>
            <table className='table table-bordered table-sm'>
              <thead className={styles.labresultsheader}>
                <tr>
                  <td>Type</td>
                  <td>Flag</td>
                  <td>Result</td>
                  <td>Reference Range</td>
                </tr>
              </thead>
              {renderLabResults(props.labresults)}
            </table>
            <div className='row mt-2 d-flex justify-content-between'>
              <div className='col-md-6'>
                {props.roleid === 3 ? (
                  <div className='row'>
                    <div className='col-md-6'>
                      <label htmlFor='updateLabRsult'>
                        Update Lab Result :{' '}
                      </label>
                      <select
                        className='form-select form-select-sm'
                        aria-label='select'
                        onChange={handleUpdateLabResultChange}
                        name='labResult'
                      >
                        <option selected disabled>
                          Select
                        </option>
                        <option value='Pass'>Pass</option>
                        <option value='Reactive'>Reactive</option>
                        <option value='RPR Positive'>RPR Positive</option>
                        <option value='Abnormal SPE'>Abnormal SPE</option>
                        <option value='ATYA Positive'>ATYA Positive</option>
                        <option value='Partial'>Partial</option>
                      </select>
                      <label htmlFor='comments'>Comments :</label>
                      <textarea
                        className='form-control form-control-sm'
                        id='comments'
                        rows='3'
                        onChange={handleUpdateLabResultChange}
                        name='comments'
                        value={
                          props.updateLabResultFormData.comments
                            ? props.updateLabResultFormData.comments
                            : ''
                        }
                      ></textarea>
                      <Button
                        type='primary'
                        disabled={!props.updateLabResultFormData.labResult}
                        onClick={updateLabResult}
                        className='mt-2'
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                ) : null}
                <div className='mt-2'>
                  <h5>Lab Result Update Log</h5>
                  <table className='table table-sm table-bordered'>
                    <thead>
                      <tr>
                        <th>User</th>
                        <th>Old Value</th>
                        <th>New Value</th>
                        <th>Date</th>
                        <th>Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.updateLabResultLogs.map(x => {
                        return (
                          <tr key={x.change_id}>
                            <td>{x.user}</td>
                            <td>{x.old_value}</td>
                            <td>{x.new_value}</td>
                            <td>{formatDate(x.datetime)}</td>
                            <td>{x.change_info}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='col-md-2 d-flex justify-content-end'>
                {renderApproveButton()}
              </div>
            </div>
          </div>
        ) : (
          <h4>No Records found</h4>
        )
      ) : (
        <h4>Loading...</h4>
      )}
    </React.Fragment>
  )
}

export default LabReport
