import React, { useState } from "react";
import { connect } from "react-redux";
import CSVReader from "react-csv-reader";
import * as actions from "../redux/actions";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import { UploadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Message } from "primereact/message";
import { formatDate } from "../utilities/format";
import { msalFetch } from "../utilities/msalFetch";

function LabReportUpload(props) {
  const [parsedLabData, setParsedLabData] = useState([]);
  const [invalidFile, setInvalidFile] = useState(false);
  const [uploadedReports, setUploadedReports] = useState([]);
  const [uploadDisable, setUploadDisabled] = useState(true);

  const testMapping = {
    "HAV RNA": "HAVRNA",
    "HBV DNA": "HBVDNA",
    "HCV RNA": "HCVRNA",
    "Hepatitis B Surface Antigen": "HBsAg",
    "Hepatitis C Antibody": "HCV",
    "HIV 1/2 plus 0": "HIV",
    "HIV RNA": "HIVRNA",
    "Parvo B19 DNA": "B19DNA",
    Albumin: "Albumin",
    "Beta Globulins": "Beta",
    Gamma: "Gamma",
    "Total Protein": "TP",
    "RPR (Syphilis screen)": "RPR",
    "Alpha-1": "Alpha1",
    "Alpha-2": "Alpha2",
    "Antibody Screen": "ABS",
  };

  const onFileLoaded = (data, fileInfo) => {
    try {
      if (fileInfo.type === "text/csv") {
        setUploadDisabled(false);
        setInvalidFile(false);
        let temp = {};
        let finalArrayofLabResults = [];
        data.forEach((item) => {
          if (
            item.sample_id &&
            item.test &&
            item.draw_date
          ) {
            if (temp[item.sample_id]) {
              temp[item.sample_id][testMapping[item.test]] = item.reported_as;
            } else {
              temp[item.sample_id] = {};
              temp[item.sample_id].UCN = item.sample_id;
              temp[item.sample_id][testMapping[item.test]] = item.reported_as==''?null:item.reported_as;
              temp[item.sample_id].DrawDate = new Date(item.draw_date);
            }
          }
        });
        for (let key in temp) {
          finalArrayofLabResults.push(temp[key]);
        }
        setParsedLabData(finalArrayofLabResults);
      } else {
        setUploadDisabled(true);
        setInvalidFile(true);
      }
    } catch (err) {
      toast.error("Failed to parse file. Please upload correct file");
    }
  };

  const parseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
  };

  const handleUpload = (e) => {
    props.actions.setLoading();
    const options = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: props.token,
      },
      body: JSON.stringify(parsedLabData),
    };
    msalFetch("/api/donor/uploadLabReports", options)
      .then(async (res) => {
        props.actions.clearLoading();
        if (res.ok) {
          toast.success("File uploaded successfully");
          const data = await res.json();
          setUploadedReports(data);
        } else {
          toast.error("Failed to upload");
        }
      })
      .catch((err) => {
        props.actions.clearLoading();
        toast.error("Failed to upload");
      });
  };

  return (
    <div className="row mt-4 justify-content-center">
      <div className="col-sm-10">
        <h4 className="text-center mt-3">Upload Lab Results</h4>
        <div className="row justify-content-center mt-4">
          <div className="col-md-6" style={{ textAlign: "end" }}>
            <CSVReader
              cssClass="react-csv-input"
              label="Select CSV file : &nbsp;"
              onFileLoaded={onFileLoaded}
              parserOptions={parseOptions}
            />
          </div>
          <div className="col-md-4">
            <Button
              icon={<UploadOutlined />}
              disabled={uploadDisable}
              type="primary"
              onClick={handleUpload}
            >
              Upload
            </Button>
          </div>
          {invalidFile ? (
            <Message
              severity="error"
              text="Invalid file. Please upload correct file"
            />
          ) : null}
        </div>
        {uploadedReports.length > 0 ? (
          <div className="row justify-content-center mt-4">
            <div className="col-md-12 mt-2 p-2">
              <h5 style={{ color: "#472574" }}>Uploaded Reports</h5>
              <table
                className="table table-sm table-bordered table-striped table-responsive-md"
                style={{ fontSize: "14px" }}
              >
                <thead>
                  <tr>
                    <th>UCN</th>
                    <th>Draw Date</th>
                    <th>Upload Date</th>
                    <th>Albumin</th>
                    <th>Alpha1</th>
                    <th>Alpha2</th>
                    <th>Beta</th>
                    <th>Gamma</th>
                    <th>RPR</th>
                    <th>TP</th>
                    <th>HBsAg</th>
                    <th>HCV</th>
                    <th>HIV</th>
                    <th>HBVDNA</th>
                    <th>HCVRNA</th>
                    <th>HIVRNA</th>
                    <th>HAVRNA</th>
                    <th>B19DNA</th>
                    <th>ABS</th>
                    <th>Comments</th>
                  </tr>
                </thead>
                <tbody>
                  {uploadedReports.map((report, i) => {
                    return (
                      <tr key={i}>
                        <td>{report.UCN}</td>
                        <td>{formatDate(report.DrawDate)}</td>
                        <td>{formatDate(report.SystemDate)}</td>
                        <td>{report.Albumin}</td>
                        <td>{report.Alpha1}</td>
                        <td>{report.Alpha2}</td>
                        <td>{report.Beta}</td>
                        <td>{report.Gamma}</td>
                        <td>{report.RPR}</td>
                        <td>{report.TP}</td>
                        <td>{report.HBsAg}</td>
                        <td>{report.HCV}</td>
                        <td>{report.HIV}</td>
                        <td>{report.HBVDNA}</td>
                        <td>{report.HCVRNA}</td>
                        <td>{report.HIVRNA}</td>
                        <td>{report.HAVRNA}</td>
                        <td>{report.B19DNA}</td>
                        <td>{report.ABS}</td>
                        <td>{report.Comments}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    username: state.username,
    token: state.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LabReportUpload);
