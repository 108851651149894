import React from "react";

const RadioGroup = ({
  options = [],
  name,
  id,
  value,
  required,
  label,
  onChange,
  error,
}) => {
  return (
    <>
      {label && (
        <div>
          <label>
            {label}
            {required && <span style={{ color: "red" }}>&#42;</span>}
          </label>
        </div>
      )}
      {options.map((option) => {
        return (
          <div className="form-check form-check-inline align-content-center m-2">
            <input
              className="form-check-input"
              type="radio"
              name={name}
              id={id}
              value={option}
              checked={value === option}
              onChange={onChange}
            />
            <label className="form-check-label" htmlFor={option}>
              {option}
            </label>
          </div>
        );
      })}
      {error && <div className="text-danger">{error}</div>}
    </>
  );
};

export default RadioGroup;
