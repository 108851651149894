import React from 'react'
import RiskPosterImage from '../../images/riskposter.jpg'

function RiskPoster () {
  return (
    <div className='col-sm-12 col-lg-12'>
      <img src={RiskPosterImage} alt='riskposter' width='100%'></img>
    </div>
  )
}

export default RiskPoster
