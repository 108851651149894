import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import { formatDate } from '../utilities/format'
import { Button, Select, DatePicker, Statistic, Card, Table } from 'antd'
import moment from 'moment'
import { msalFetch } from '../utilities/msalFetch'
import Chart from 'react-apexcharts'
const { Option } = Select
const { RangePicker } = DatePicker

const kpiTableColumns = [
  {
    title: 'Date',
    dataIndex: 'donation_date',
    key: 'donation_date',
    sorter: (a, b) =>
      moment(b.donation_date).isValid
        ? moment(a.donation_date).valueOf() - moment(b.donation_date).valueOf()
        : moment(a.donation_date).valueOf() - moment('9999-12-30'),
    render: (_, { donation_date }) => formatDate(donation_date)
  },
  {
    title: 'Total Donors',
    dataIndex: 'total_donors',
    key: 'total_donors',
    sorter: (a, b) => a.total_donors - b.total_donors
  },
  {
    title: 'New',
    dataIndex: 'new_donors',
    key: 'new_donors',
    sorter: (a, b) => a.new_donors - b.new_donors
  },
  {
    title: 'Return',
    dataIndex: 'return_donors',
    key: 'return_donors',
    sorter: (a, b) => a.return_donors - b.return_donors
  },
  {
    title: 'Total Donations',
    dataIndex: 'total_donations',
    key: 'total_donations',
    sorter: (a, b) => a.total_donations - b.total_donations
  },
  {
    title: 'Total Volume (Ltrs)',
    dataIndex: 'total_volume',
    key: 'total_volume',
    render: (_, { total_volume }) => total_volume / 1000,
    sorter: (a, b) => a.total_volume - b.total_volume
  },
  {
    title: 'Underdraw',
    dataIndex: 'UNDERDRAW',
    key: 'UNDERDRAW',
    sorter: (a, b) => a.UNDERDRAW - b.UNDERDRAW
  },
  {
    title: 'Overdraw',
    dataIndex: 'OVERDRAW',
    key: 'OVERDRAW',
    sorter: (a, b) => a.OVERDRAW - b.OVERDRAW
  },
  {
    title: '<200RBC',
    dataIndex: 'L200RBCL',
    key: 'L200RBCL',
    sorter: (a, b) => a.L200RBCL - b.L200RBCL
  },
  {
    title: '>=200RBC',
    dataIndex: 'GE200RBCL',
    key: 'GE200RBCL',
    sorter: (a, b) => a.GE200RBCL - b.GE200RBCL
  },
  {
    title: 'Reactions',
    dataIndex: 'reactions',
    key: 'reactions',
    sorter: (a, b) => a.reactions - b.reactions
  }
]

function KPIReport (props) {
  const [dateBy, setDateBy] = useState('TDY')
  const [dateRange, setDateRange] = useState({
    fromDate: moment().format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD')
  })
  const [summary, setSummary] = useState({})
  const [tableData, setTableData] = useState([])
  const [donationsChartData, setDonationsChartData] = useState(undefined)
  const [donorsChartData, setDonorsChartData] = useState(undefined)
  const [alertFlagsChartData, setAlertFlagsChartData] = useState(undefined)
  const [volumeChartData, setVolumeChartData] = useState(undefined)

  useEffect(() => {
    const donationsChartConfig = {
      series: [
        {
          name: 'Donations',
          data: tableData.map(x => x.total_donations)
        }
      ],
      options: {
        chart: {
          type: 'bar',
          zoom: {
            enabled: true
          },
          height: 350,
          stacked: true
        },

        dataLabels: {
          enabled: true
        },
        stroke: {
          width: 2,
          curve: 'straight'
        },
        title: {
          text: 'Donations',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: tableData.map(x => formatDate(x.donation_date))
        }
      }
    }
    const donorsChartConfig = {
      series: [
        {
          name: 'New Donors',
          data: tableData.map(x => x.new_donors)
        },
        {
          name: 'Return Donors',
          data: tableData.map(x => x.return_donors)
        }
      ],
      options: {
        chart: {
          type: 'bar',
          zoom: {
            enabled: true
          },
          height: 350,
          stacked: true
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          width: 2,
          curve: 'straight'
        },
        title: {
          text: 'Donors',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: tableData.map(x => formatDate(x.donation_date))
        }
      }
    }
    const alertFlagsConfig = {
      series: [
        {
          name: 'Reactions',
          data: tableData.map(x => x.reactions)
        },
        {
          name: 'Underdraw',
          data: tableData.map(x => x.UNDERDRAW)
        },
        {
          name: 'OVERDRAW',
          data: tableData.map(x => x.OVERDRAW)
        },
        {
          name: '<200RBCL',
          data: tableData.map(x => x.L200RBCL)
        },
        {
          name: '>=200RBCL',
          data: tableData.map(x => x.GE200RBCL)
        }
      ],
      options: {
        chart: {
          type: 'bar',
          zoom: {
            enabled: true
          },
          height: 350,
          stacked: true
        },
        stacked: true,
        dataLabels: {
          enabled: true
        },
        stroke: {
          width: 2,
          curve: 'straight'
        },
        title: {
          text: 'Donation Exceptions',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: tableData.map(x => formatDate(x.donation_date))
        }
      }
    }
    const volumeChartConfig = {
      series: [
        {
          name: 'Volume',
          data: tableData.map(x => x.total_volume / 1000)
        }
      ],
      options: {
        chart: {
          type: 'bar',
          zoom: {
            enabled: true
          },
          height: 350,
          stacked: true
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          width: 2,
          curve: 'straight'
        },
        title: {
          text: 'Plasma Volume (Litres)',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: tableData.map(x => formatDate(x.donation_date))
        }
      }
    }
    setDonationsChartData(donationsChartConfig)
    setDonorsChartData(donorsChartConfig)
    setAlertFlagsChartData(alertFlagsConfig)
    setVolumeChartData(volumeChartConfig)
  }, [tableData])

  const handleDateBy = value => {
    let dateRangeObj = {}
    const today = moment()
    if (value == 'TDY') {
      dateRangeObj.fromDate = today.format('YYYY-MM-DD')
      dateRangeObj.toDate = today.format('YYYY-MM-DD')
    } else if (value == 'LW') {
      dateRangeObj.toDate = today.format('YYYY-MM-DD')
      dateRangeObj.fromDate = today.subtract(1, 'week').format('YYYY-MM-DD')
    } else if (value == 'LM') {
      dateRangeObj.toDate = today.format('YYYY-MM-DD')
      dateRangeObj.fromDate = today.subtract(1, 'month').format('YYYY-MM-DD')
    } else if (value == 'LSM') {
      dateRangeObj.toDate = today.format('YYYY-MM-DD')
      dateRangeObj.fromDate = today.subtract(6, 'month').format('YYYY-MM-DD')
    } else if (value == 'YTD') {
      dateRangeObj.toDate = today.subtract(1, 'day').format('YYYY-MM-DD')
      dateRangeObj.fromDate = today.format('YYYY-MM-DD')
    } else {
      dateRangeObj = {}
    }
    setDateBy(value)
    setDateRange(dateRangeObj)
  }

  const handleDateRangeChange = (dates, dateStrings, info) => {
    const [fromdate, todate] = dateStrings
    setDateRange({
      fromDate: moment(fromdate).format('YYYY-MM-DD'),
      toDate: moment(todate).format('YYYY-MM-DD')
    })
  }

  const validateInputs = () => {
    if (!dateRange.fromDate || !dateRange.toDate) {
      toast.error('Please select date range')
      return false
    } else if (
      !moment(dateRange.fromDate).isValid() ||
      !moment(dateRange.toDate).isValid()
    ) {
      toast.error('Invalid date range')
      return false
    } else {
      return true
    }
  }

  const getKPIdata = () => {
    if (validateInputs()) {
      props.actions.setLoading()
      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.token
        }
      }
      msalFetch(
        `/api/reports/getKPIData?fromDate=${dateRange.fromDate}&toDate=${dateRange.toDate}`,
        options
      )
        .then(async res => {
          props.actions.clearLoading()
          if (res.ok) {
            let data = await res.json()
            setSummary(data.summary)
            setTableData(Object.values(data.donations))
          } else {
            toast.error('Failed to fetch report')
          }
        })
        .catch(err => {
          props.actions.clearLoading()
          toast.error(err)
        })
    }
  }

  const options = {
    stacked: false,
    maintainAspectRatio: false,
    aspectRatio: 1,
    plugins: {
      legend: {
        labels: {
          color: '#4b5563'
        }
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#6b7280'
        },
        grid: {
          color: '#dfe7ef'
        }
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        ticks: {
          color: '#6b7280'
        },
        grid: {
          color: '#dfe7ef'
        }
      }
    }
  }

  return (
    <div className='row justify-content-center mt-3'>
      <div className='col-md-10 mt-4'>
        <div className='row'>
          <h3 className='col-sm-4'>Center Performance Report</h3>
        </div>
        <div className='row mt-3'>
          <div className='col-md-12 d-flex align-items-center'>
            <label>Transaction Date : </label>
            <div className='mx-4'>
              <Select
                placeholder='Transaction Duration'
                onChange={handleDateBy}
                value={dateBy}
                style={{ width: '160px' }}
              >
                <Option value='TDY'>Today</Option>
                <Option value='YTD'>Yesterday</Option>
                <Option value='LW'>Last Week</Option>
                <Option value='LM'>Last Month</Option>
                <Option value='LSM'>Last Six Months</Option>
                <Option value='RNG'>Date Range</Option>
              </Select>
            </div>
            {dateBy == 'RNG' ? (
              <RangePicker
                style={{ marginRight: '24px' }}
                format='DD-MMM-YYYY'
                onCalendarChange={handleDateRangeChange}
              ></RangePicker>
            ) : null}
            <div>
              <Button type='primary' onClick={getKPIdata}>
                Apply
              </Button>
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          <Card bordered={false} className='col-lg-2 col-md-4 col-sm-6 m-2'>
            <Statistic
              title='Total Donors'
              value={summary.total_donors}
              valueStyle={{
                color: '#010068',
                fontSize: '2em',
                fontWeight: '600'
              }}
            />
          </Card>
          <Card bordered={false} className='col-lg-2 col-md-4 col-sm-6 m-2'>
            <Statistic
              title='New Donors'
              value={summary.new_donors}
              valueStyle={{
                color: '#010068',
                fontSize: '2em',
                fontWeight: '600'
              }}
            />
          </Card>
          <Card bordered={false} className='col-lg-2 col-md-4 col-sm-6 m-2'>
            <Statistic
              title='Return Donors'
              value={summary.return_donors}
              valueStyle={{
                color: '#010068',
                fontSize: '2em',
                fontWeight: '600'
              }}
            />
          </Card>
          <Card bordered={false} className='col-lg-2 col-md-4 col-sm-6 m-2'>
            <Statistic
              title='Total Donations'
              value={summary.total_donations}
              valueStyle={{
                color: '#010068',
                fontSize: '2em',
                fontWeight: '600'
              }}
            />
          </Card>
          <Card bordered={false} className='col-lg-2 col-md-4 col-sm-6 m-2'>
            <Statistic
              title='Total Volume (Litres)'
              value={summary.total_volume / 1000}
              valueStyle={{
                color: '#010068',
                fontSize: '2em',
                fontWeight: '600'
              }}
            />
          </Card>
          <Card bordered={false} className='col-lg-2 col-md-4 col-sm-6 m-2'>
            <Statistic
              title='Underdraw'
              value={summary.UNDERDRAW}
              valueStyle={{
                color: '#010068',
                fontSize: '2em',
                fontWeight: '600'
              }}
            />
          </Card>
          <Card bordered={false} className='col-lg-2 col-md-4 col-sm-6 m-2'>
            <Statistic
              title='Overdraw'
              value={summary.OVERDRAW}
              valueStyle={{
                color: '#010068',
                fontSize: '2em',
                fontWeight: '600'
              }}
            />
          </Card>
          <Card bordered={false} className='col-lg-2 col-md-4 col-sm-6 m-2'>
            <Statistic
              title='<200RBC Loss'
              value={summary.L200RBCL}
              valueStyle={{
                color: '#010068',
                fontSize: '2em',
                fontWeight: '600'
              }}
            />
          </Card>
          <Card bordered={false} className='col-lg-2 col-md-4 col-sm-6 m-2'>
            <Statistic
              title='>=200RBC Loss'
              value={summary.GE200RBCL}
              valueStyle={{
                color: '#010068',
                fontSize: '2em',
                fontWeight: '600'
              }}
            />
          </Card>
          <Card bordered={false} className='col-lg-2 col-md-4 col-sm-6 m-2'>
            <Statistic
              title='Reactions '
              value={summary.reactions}
              valueStyle={{
                color: '#010068',
                fontSize: '2em',
                fontWeight: '600'
              }}
            />
          </Card>
        </div>
        <div className='row mt-3'>
          <div className='col-sm-12 col-md-6 col-lg-6'>
            {donationsChartData && (
              <Chart
                type='bar'
                series={donationsChartData.series}
                options={donationsChartData.options}
                height={400}
              ></Chart>
            )}
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6'>
            {donorsChartData && (
              <Chart
                type='bar'
                series={donorsChartData.series}
                options={donorsChartData.options}
                height={400}
              ></Chart>
            )}
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6'>
            {volumeChartData && (
              <Chart
                type='bar'
                series={volumeChartData.series}
                options={volumeChartData.options}
                height={400}
              ></Chart>
            )}
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6'>
            {alertFlagsChartData && (
              <Chart
                type='bar'
                series={alertFlagsChartData.series}
                options={alertFlagsChartData.options}
                height={400}
              ></Chart>
            )}
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-sm-12'>
            <Table
              dataSource={tableData}
              columns={kpiTableColumns}
              size='small'
            ></Table>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token,
    isAuthenticated: state.isAuthenticated
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KPIReport)
