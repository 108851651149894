import React from 'react';
import { Dna } from  'react-loader-spinner'


const style ={
    position:"fixed",
    zIndex:'1000000',
    height:"100%",
    width:"100vw",
    display:"flex",
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:"rgba(255,255,255,0.7)",
    padding:0,
    margin:0 -12,
    marginBottom:-12,
    top:0,
    bottom:0
}

const loaderText = {
  position:"relative",
  top:"3rem",
  right:"4rem",
  fontSize:"20px",
  textAlign:"center"
}

function Loader(props) {
  return (
      <div style={style}>
        <Dna
          visible={true}
          height="80"
          width="80"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
        />
        <p style={loaderText}>{props.message}</p>
      </div>
  )
}

export default Loader