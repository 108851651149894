import React, { useState, useEffect } from 'react'
import styles from '../styles/CaseShipping.module.css'
import { checkValidCaseNumber, isValidBOL } from '../utilities/validations'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import { toast } from 'react-toastify'
import { msalFetch } from '../utilities/msalFetch'
import { Button, Modal } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { formatDate } from '../utilities/format'

function CaseShipping (props) {
  const [caseTable, setCaseTable] = useState([])
  const [inputCaseNo, setInputCaseNo] = useState('')
  const [invalidCaseNo, setInvalidCaseNo] = useState(false)
  const [duplicateCaseNo, setDuplicateCaseNo] = useState(false)
  const [validated, setValidated] = useState(false)
  const [validationErrors, setValidationErrors] = useState({})
  const [changeLogs, setChangeLogs] = useState([])
  const [showLogModal, setShowLogModal] = useState(false)
  const [logData, setLogData] = useState({})

  const [formData, setFormData] = useState({
    BOL: '',
    SHIP_DATE: '',
    SHIP_TO: '',
    CARRIER_TEMP_VERIFIED: '',
    SHIP_FROM: ''
  })

  const handleFormDataChange = e => {
    setValidationErrors({})
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  /*   const handleChange = (e) => {
    setInputCaseNo(e.target.value);
    if (checkValidCaseNumber(e.target.value)) {
      setInvalidCaseNo(false);
      const checkDuplicate = caseTable.findIndex(
        (x) => x.caseNo == e.target.value
      );
      if (checkDuplicate == -1) {
        let caseDetails = {
          seq: caseTable.length + 1,
          caseNo: e.target.value,
          status: undefined,
          valid: undefined,
          notFound: undefined,
        };
        setCaseTable([...caseTable, caseDetails]);
        setInputCaseNo("");
        setDuplicateCaseNo(false);
        setValidated(false);
      } else {
        setDuplicateCaseNo(true);
      }
    } else {
      setInvalidCaseNo(true);
    }
  };
 */
  const handleAdd = e => {
    setInputCaseNo(e.target.value)
    if (checkValidCaseNumber(e.target.value)) {
      setInvalidCaseNo(false)
      const checkDuplicate = caseTable.findIndex(
        x => x.caseNo == e.target.value
      )
      if (checkDuplicate == -1) {
        let caseDetails = {
          seq: caseTable.length + 1,
          caseNo: e.target.value,
          status: undefined,
          valid: undefined,
          not_found: undefined
        }
        setCaseTable([...caseTable, caseDetails])
        setInputCaseNo('')
        setDuplicateCaseNo(false)
        setValidated(false)
      } else {
        setDuplicateCaseNo(true)
      }
    } else {
      setInvalidCaseNo(true)
    }
  }

  const handleDeleteCaseNo = seq => {
    let newTable = caseTable.filter(x => {
      return x.seq !== seq
    })
    newTable = newTable.map(x => {
      if (x.seq > seq) {
        x.seq = x.seq - 1
      }
      return x
    })
    setCaseTable(newTable)
  }

  const validateForm = () => {
    let errors = { ...validationErrors }
    if (formData.BOL == '') {
      errors.billNumber = 'Bill number cannot be empty'
    } else if (formData.SHIP_DATE == '') {
      errors.shipDate = 'Ship Date cannot be empty'
    } else if (formData.SHIP_FROM == '') {
      errors.shipFrom = 'Please enter shipping From address'
    } else if (formData.SHIP_TO == '') {
      errors.shipTo = 'Please enter shipping To address'
    } else if (formData.CARRIER_TEMP_VERIFIED == undefined) {
      errors.carrierTempVerified = 'Please confirm carrier temp verification'
    } else {
      errors = {}
    }
    setValidationErrors(errors)
    if (Object.keys(errors).length == 0) {
      return true
    }
    return false
  }

  const validate = () => {
    if (true) {
      props.actions.setLoading()
      const options = {
        method: 'POST',
        headers: {
          Authorization: props.token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(caseTable)
      }
      msalFetch('api/caseShipping/validate', options)
        .then(async response => {
          props.actions.clearLoading()
          if (response.ok) {
            const data = await response.json()
            if (data.length > 0) {
              setCaseTable(data)
              setValidated(true)
            } else {
              toast.error(data.message)
            }
          } else {
            const data = await response.json()
            toast.error(data.message)
          }
        })
        .catch(err => toast.error(err.message))
    }
  }

  const checkCaseTable = () => {
    const unsuitable = caseTable
      .filter(x => x.valid == false)
      .map(x => x.caseNo)
    if (unsuitable.length) {
      const message = `Please remove ${unsuitable.join(
        ','
      )} from the list to continue.`
      toast.error(message)
      return false
    } else {
      return true
    }
  }

  const getBillOfLadingDetails = () => {
    if (formData.BOL) {
      props.actions.setLoading()
      const options = {
        method: 'GET',
        headers: {
          Authorization: props.token,
          'Content-Type': 'application/json'
        }
      }
      msalFetch(
        `/api/caseShipping/getBillOfLadingDetails/${formData.BOL}`,
        options
      )
        .then(async res => {
          props.actions.clearLoading()
          if (res.ok) {
            const data = await res.json()
            if (Object.keys(data).length) {
              const {
                BOL,
                SHIP_DATE,
                SHIP_FROM,
                SHIP_TO,
                CARRIER_TEMP_VERIFIED
              } = data
              setFormData({
                ...formData,
                BOL,
                SHIP_DATE: SHIP_DATE ? SHIP_DATE.slice(0, 10) : '',
                SHIP_FROM,
                SHIP_TO,
                CARRIER_TEMP_VERIFIED
              })
              const cases = data.cases.map((x, i) => {
                return {
                  seq: i + 1,
                  caseNo: x,
                  status: undefined,
                  valid: undefined,
                  notFound: undefined
                }
              })
              console.log(cases)
              setChangeLogs(data.changeLogs)
              setCaseTable([...cases])
            }
          } else {
            props.actions.clearLoading()
            toast.error('Failed to fetch BOL details')
          }
        })
        .catch(err => {
          props.actions.clearLoading()
          console.log(err)
        })
    }
  }

  const updateLocation = () => {
    if (validateForm()) {
      if (checkCaseTable()) {
        const caseNumbers = caseTable.map(x => x.caseNo)
        props.actions.setLoading()
        const body = {
          ...formData,
          caseNumbers
        }
        const options = {
          method: 'POST',
          headers: {
            Authorization: props.token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }
        msalFetch('/api/caseShipping/updateLocation', options)
          .then(async res => {
            props.actions.clearLoading()
            if (res.ok) {
              const data = await res.json()
              toast.success(data.message)
              //clearStates()
            }
          })
          .catch(err => {
            props.actions.clearLoading()
            toast.error(err.message)
          })
      }
    } else {
      toast.error('Please enter all the required fields')
    }
  }

  const saveShippingDetails = () => {
    const caseNumbers = caseTable.map(x => x.caseNo)
    if (caseNumbers.length && formData.BOL) {
      props.actions.setLoading()
      const body = {
        BOL: formData.BOL,
        caseNumbers
      }
      const options = {
        method: 'POST',
        headers: {
          Authorization: props.token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }
      msalFetch('/api/caseShipping/saveLocation', options)
        .then(async res => {
          props.actions.clearLoading()
          if (res.ok) {
            const data = await res.json()
            toast.success(data.message)
            //clearStates()
          }
        })
        .catch(err => {
          props.actions.clearLoading()
          toast.error(err.message)
        })
    } else {
      toast.error('Please enter BOL and Case Packing Numbers')
    }
  }

  return (
    <div className='row d-flex mt-2 justify-content-center'>
      <div className='col-md-10'>
        <div className='row mt-2 justify-content-center'>
          <div className='col-md-10'>
            <div className={styles.caseShippingTitle}>UCN Location Update</div>
          </div>
        </div>
        <div className='row d-flex justify-content-center mt-4'>
          <div className='col-md-10'>
            <div className='form-group row'>
              <label for='bill' className='col-sm-3 col-form-label'>
                Bill of Lading#
              </label>
              <div className='col-sm-4'>
                <input
                  type='text'
                  className='form-control'
                  id='bill'
                  name='BOL'
                  placeholder='Bill Number'
                  value={formData.BOL}
                  onChange={handleFormDataChange}
                />
                {validationErrors.billNumber != '' ? (
                  <p style={{ fontSize: '14px', color: 'red' }}>
                    {validationErrors.billNumber}
                  </p>
                ) : null}
              </div>
              <div className='col-md-2'>
                <Button onClick={getBillOfLadingDetails}>
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              </div>
            </div>
            <div className='form-group row mt-2'>
              <label for='shipdate' className='col-sm-3 col-form-label'>
                Ship Date :
              </label>
              <div className='col-sm-4'>
                <input
                  type='date'
                  className='form-control'
                  id='shipdate'
                  placeholder='Ship Date'
                  value={formData.SHIP_DATE}
                  name='SHIP_DATE'
                  onChange={handleFormDataChange}
                />
                {validationErrors.shipDate != '' ? (
                  <p style={{ fontSize: '14px', color: 'red' }}>
                    {validationErrors.shipDate}
                  </p>
                ) : null}
              </div>
            </div>
            <div className='form-group row mt-2'>
              <label for='shipfrom' className='col-sm-3 col-form-label'>
                Ship From :
              </label>
              <div className='col-sm-4'>
                <select class='form-select' aria-label='Select Center'>
                  <option value='' disabled selected>
                    Select
                  </option>
                  <option value='LP01 (Reading, PA)'>LP01 (Reading, PA)</option>
                </select>
                {validationErrors.shipFrom != '' ? (
                  <p style={{ fontSize: '14px', color: 'red' }}>
                    {validationErrors.shipFrom}
                  </p>
                ) : null}
              </div>
            </div>
            <div className='form-group row mt-2'>
              <label for='shipto' className='col-sm-3 col-form-label'>
                Ship To :
              </label>
              <div className='col-sm-4'>
                <textarea
                  className='form-control'
                  id='shipto'
                  placeholder='Ship To'
                  value={formData.SHIP_TO}
                  onChange={handleFormDataChange}
                  name='SHIP_TO'
                />
                {validationErrors.shipTo != '' ? (
                  <p style={{ fontSize: '14px', color: 'red' }}>
                    {validationErrors.shipTo}
                  </p>
                ) : null}
              </div>
            </div>
            <div className='form-group row mt-2'>
              <label for='carriertemp' className='col-sm-4 col-form-label'>
                Carrier Temperature Verified :
              </label>
              <div className='col-sm-2 d-flex justify-content-between align-items-center'>
                <label className='col-form-label'>Yes</label>:
                <input
                  type='radio'
                  className='form-check-input'
                  id='carriertemp'
                  name='CARRIER_TEMP_VERIFIED'
                  value='Yes'
                  onChange={handleFormDataChange}
                  checked={formData.CARRIER_TEMP_VERIFIED == 'Yes'}
                />
                <label className='col-form-label'>No</label>
                <input
                  type='radio'
                  className='form-check-input'
                  id='carriertemp'
                  name='CARRIER_TEMP_VERIFIED'
                  value='No'
                  onChange={handleFormDataChange}
                  checked={formData.CARRIER_TEMP_VERIFIED == 'No'}
                />
              </div>
              {validationErrors.carrierTempVerified != '' ? (
                <p style={{ fontSize: '14px', color: 'red' }}>
                  {validationErrors.carrierTempVerified}
                </p>
              ) : null}
            </div>
            <div className='form-group row mt-3 border-top pt-2'>
              <label for='scancase' className='col-sm-3 col-form-label'>
                Scan CasePacking #:
              </label>
              <div className='col-sm-4 d-flex'>
                <input
                  type='text'
                  className='form-control'
                  id='scancase'
                  placeholder='Case Packing Number'
                  maxLength={10}
                  minLength={10}
                  onChange={handleAdd}
                  value={inputCaseNo}
                />
              </div>
              {invalidCaseNo ? (
                <p style={{ fontSize: '14px' }} className='text-danger'>
                  !Invalid Case Number
                </p>
              ) : null}
              {duplicateCaseNo ? (
                <p style={{ fontSize: '14px' }} className='text-danger'>
                  !Duplicate Case Number
                </p>
              ) : null}
            </div>
            <div className={styles.caseTableContainer}>
              <table
                className={`table mt-2 table-bordered mt-4 ${styles.caseTableHeight}`}
              >
                <thead className='thead-dark table-dark'>
                  <tr>
                    <td scope='col'>Count</td>
                    <td scope='col'>Case Packing#</td>
                    <td scope='col'>Status</td>
                    <td scope='col'>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {caseTable.length > 0 &&
                    caseTable.map((cas, index) => {
                      return (
                        <tr
                          className={
                            cas.status == undefined
                              ? ''
                              : cas.valid == true
                              ? 'text-success'
                              : 'text-danger'
                          }
                        >
                          <td>{cas.seq}</td>
                          <td>{cas.caseNo}</td>
                          <td>{cas.status}</td>
                          <td>
                            <button
                              className='btn btn-outline-dark btn-sm'
                              onClick={e => handleDeleteCaseNo(cas.seq)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
            <div className='card text-bg-white mb-3 bottom-0'>
              <div className='card-body d-flex  align-items-center'>
                <button
                  className='btn btn-primary'
                  disabled={caseTable.length == 0}
                  onClick={saveShippingDetails}
                >
                  Save
                </button>
                <button
                  className='btn btn-warning'
                  disabled={caseTable.length == 0}
                  onClick={validate}
                  style={{ marginLeft: '2rem' }}
                >
                  Validate
                </button>
                <button
                  className='btn btn-primary'
                  style={{ marginLeft: '2rem' }}
                  disabled={!validated}
                  onClick={updateLocation}
                >
                  Update Location
                </button>
                <button
                  className='btn btn-secondary'
                  style={{ marginLeft: '2rem' }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-2 justify-content-center'>
          <div className='col-md-10'>
            <h5>Location Update Logs</h5>
            <table
              className='table table-sm table-bordered table-light'
              style={{ width: '50vw' }}
            >
              <thead>
                <tr>
                  <th>User</th>
                  <th>Data</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {changeLogs.reverse().map(x => {
                  return (
                    <tr key={x.change_id}>
                      <td>{x.user}</td>
                      <td>
                        <Button
                          onClick={e => {
                            setShowLogModal(true)
                            setLogData(JSON.parse(x.meta_data))
                          }}
                        >
                          View
                        </Button>
                      </td>
                      <td>{formatDate(x.datetime)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        closable
        footer={null}
        open={showLogModal}
        onCancel={() => {
          setShowLogModal(false)
          setLogData({})
        }}
      >
        <h4>{logData['BOL']}</h4>
        <table className='table table-sm table-bordered'>
          <thead>
            <tr>
              <th>Field</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ship From</td>
              <td>{logData.SHIP_FROM}</td>
            </tr>
            <tr>
              <td>Ship To</td>
              <td>{logData.SHIP_TO}</td>
            </tr>
            <tr>
              <td>Ship Date</td>
              <td>{logData.SHIP_DATE}</td>
            </tr>
            <tr>
              <td>Carrier Temp Verified</td>
              <td>{logData.CARRIER_TEMP_VERIFIED}</td>
            </tr>
            <tr>
              <td>Cases</td>
              <td>{logData.cases?.join(', ')}</td>
            </tr>
          </tbody>
        </table>
      </Modal>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token,
    roleid: state.roleid
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseShipping)
