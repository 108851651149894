import React, { useCallback } from "react";
import DynamicForm from "../../../shared/FormInputs/DynamicInput";
import Select from "../../../shared/FormInputs/Select";
import TextField from "../../../shared/FormInputs/TextField";
import { vitalSigns, initialVitalSigns } from "../constant";

const DonorRecovery = ({ data, onChangeData, errors = {}, options }) => {
  const handleChange = useCallback(
    (value, name) => {
      onChangeData({ ...data, [name]: value });
    },
    [data]
  );

  return (
    <fieldset>
      <legend className="legend">Donor recovery and disposition</legend>

      <div className="row mb-2">
        <div className="col d-flex flex-column">
          <DynamicForm
            formData={data.vitalSigns}
            onChange={(val) => handleChange(val, "vitalSigns")}
            fields={vitalSigns}
            label="Vital Signs After Treatment Initiated"
            options={options}
            initialState={initialVitalSigns}
            error={errors?.vitalSigns}
            maxRows={1}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-3 d-flex flex-column">
          <Select
            id="conditionAtTimeOfRelease"
            name="conditionAtTimeOfRelease"
            label="Condition at time of release"
            options={["Same", "Improved", "Worsened"]}
            value={data.conditionAtTimeOfRelease}
            onChange={(e) =>
              handleChange(e.target.value, "conditionAtTimeOfRelease")
            }
            placeholder={"Select"}
            required={true}
            error={errors?.conditionAtTimeOfRelease}
          />
        </div>
        <div className="col-3">
          <Select
            id="recoveryPeriod"
            name="recoveryPeriod"
            label="Recovery Period (minutes)"
            options={["< 10", "10 – 19", "20 – 30", "> 30"]}
            value={data.recoveryPeriod}
            onChange={(e) => handleChange(e.target.value, "recoveryPeriod")}
            placeholder={"Select"}
            required={true}
            error={errors?.recoveryPeriod}
          />
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-5 d-flex flex-column">
          <Select
            id="emsActivatedResponse"
            name="emsActivatedResponse"
            options={["Yes", "No", "NA"]}
            value={data.emsActivatedResponse}
            onChange={(e) =>
              handleChange(e.target.value, "emsActivatedResponse")
            }
            label={"EMS activated, donor went POV, or transport recommended?"}
            required={true}
            placeholder={"Select"}
            error={errors?.emsActivatedResponse}
          />
        </div>
        <div className="col d-flex flex-column">
          <Select
            id="donorRefusal"
            name="donorRefusal"
            label="Donor refusal of recommendation?"
            options={["Yes", "No", "NA"]}
            value={data.donorRefusal}
            onChange={(e) => handleChange(e.target.value, "donorRefusal")}
            required={true}
            placeholder={"Select"}
            error={errors?.donorRefusal}
          />
        </div>
        <div className="col d-flex flex-column">
          <Select
            id="centerPhysicianNotified"
            name="centerPhysicianNotified"
            label="Center Physician notified?"
            options={["Yes", "No", "NA"]}
            value={data.centerPhysicianNotified}
            onChange={(e) =>
              handleChange(e.target.value, "centerPhysicianNotified")
            }
            required={true}
            placeholder={"Select"}
            error={errors?.centerPhysicianNotified}
          />
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-4 d-flex flex-column">
          <TextField
            id="donorPhoneNumber"
            name="donorPhoneNumber"
            label="Donor phone number for follow up call"
            placeholder="Enter Phone Number"
            value={data.donorPhoneNumber}
            onChange={(e) => handleChange(e.target.value, "donorPhoneNumber")}
            required={true}
            error={errors?.donorPhoneNumber}
          />
        </div>
        <div className="col-3 d-flex flex-column">
          <Select
            id="medicalDeferral"
            name="medicalDeferral"
            label="Medical Deferral Applied in File?"
            options={["Yes", "No", "NA (minor)"]}
            value={data.medicalDeferral}
            onChange={(e) => handleChange(e.target.value, "medicalDeferral")}
            required={true}
            placeholder={"Select"}
            error={errors?.medicalDeferral}
          />
        </div>
      </div>
    </fieldset>
  );
};

export default DonorRecovery;
