import moment from 'moment'

const formatDonorId = donorId => {
  return `XXX-X${donorId.slice(5)}`
}

const formatDate = (date, format='MM/DD/YYYY') => {
  if (date) {
    const newDate = date.slice(0, 10)
    return moment(newDate).format(format)
  }
  return ''
}

const formatTime = time => {
  if (time) {
    if (moment(time).isValid()) {
      let newTime = moment(time)
      newTime = moment(newTime).local().format('h:mm A')
      return newTime
    } else {
      return 'Invalid DateTime'
    }
  }
  return 'Invalid DateTime'
}

const formatDateTime = time => {
  if (time) {
    if (moment(time).isValid()) {
      let newTime = moment(time)
      newTime = moment(newTime).local().format('MM/DD/YYYY h:mm A')
      return newTime
    } else {
      return 'Invalid DateTime'
    }
  }
  return 'Invalid DateTime'
}

const formatPhoneNumber = phone => {
  if (phone && phone.length >= 10) {
    phone = phone.replaceAll('(', '')
    phone = phone.replaceAll(')', '')
    phone = phone.replaceAll(' ', '')
    phone = phone.replaceAll('-', '')
    let newphone =
      phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6)
    return newphone
  }
  return phone ? phone : ''
}

export { formatDonorId, formatDate, formatTime, formatPhoneNumber, formatDateTime }
