import React, { useState } from 'react'
import { Button, DatePicker, Table } from 'antd'
import moment from 'moment'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import { toast } from 'react-toastify'
import { msalFetch } from '../utilities/msalFetch'
import { formatDate } from '../utilities/format'

const { RangePicker } = DatePicker



function ShippingSummary (props) {
  const [rows, setRows] = useState([])
  const [dateRange, setDateRange] = useState({ fromDate: '', toDate: '' })

  const columns = [
    {
      title: 'BOL',
      dataIndex: 'BOL',
      key: 'BOL',
    },
    { title: 'Ship From', dataIndex: 'SHIP_FROM', key: 'SHIP_FROM' },
    { title: 'Ship To', dataIndex: 'SHIP_TO', key: 'SHIP_TO' },
    { title: 'Ship Date', dataIndex: 'SHIP_DATE', key: 'SHIP_DATE' },
    { title: 'Cases', dataIndex: 'CASES', key: 'CASES' },
    { title: 'Units', dataIndex: 'UNITS', key: 'UNITS' },
    { title: 'Volume (Litres)', dataIndex: 'VOLUME', key: 'VOLUME' }
  ]

  const handleDateRangeChange = (dates, dateStrings) => {
    const [fromdate, todate] = dateStrings
    if (fromdate != '' && todate != '') {
      setDateRange({
        fromDate: moment(fromdate).format('YYYY-MM-DD'),
        toDate: moment(todate).format('YYYY-MM-DD')
      })
    } else {
      setDateRange({ fromDate: '', toDate: '' })
    }
  }

  const getSummaryReport = () => {
    const { fromDate, toDate } = dateRange
    if (fromDate && toDate) {
      const body = {
        fromDate,
        toDate
      }
      props.actions.setLoading()
      const options = {
        method: 'POST',
        headers: {
          Authorization: props.token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }
      msalFetch('/api/reports/getShippingSummaryByDateRange', options).then(
        async res => {
          props.actions.clearLoading()
          if (res.ok) {
            const data = await res.json()
            let totalVolume = 0
            let totalUnits = 0
            let totalCases = 0
            let newData = data.map(x => {
              x.SHIP_DATE = formatDate(x.SHIP_DATE)
              x.VOLUME = x.VOLUME / 1000
              totalVolume += x.VOLUME
              totalCases += x.CASES
              totalUnits += x.UNITS
              return x
            })
            let lastObj = {
              BOL: 'Total',
              VOLUME: totalVolume,
              UNITS: totalUnits,
              CASES: totalCases
            }
            data.length&&newData.push(lastObj)
            setRows(newData)
          } else {
            toast.error('Failed to get summary report')
          }
        }
      )
    } else {
      toast.error('Please select date range')
    }
  }

  return (
    <div className='row d-flex justify-content-center mt-3'>
      <div className='col-md-10'>
        <p className='title'>Shipping Summary Report</p>
        <div>
          <label htmlFor='shippingdaterange'>Shipping Date Range : </label>
          <RangePicker
            id='shippingdaterange'
            className='mx-2'
            onCalendarChange={handleDateRangeChange}
            format='DD-MMM-YYYY'
            allowClear
          />
          <Button type='primary' onClick={getSummaryReport}>
            Apply
          </Button>
        </div>
        <div className='mt-4'>
          <Table columns={columns} dataSource={rows} size='small'></Table>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token,
    roleid: state.roleid
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShippingSummary)
