import { Calendar } from "primereact/calendar";
import React from "react";

const DatePicker = ({
  id,
  name,
  label,
  value,
  onChange,
  error,
  placeholder,
  required,
  disabled,
}) => {
  return (
    <>
      {label && (
        <label htmlFor={id}>
          {label} {required && <span style={{ color: "red" }}>&#42;</span>}
        </label>
      )}
      <Calendar
        id={id}
        name={name}
        inputClassName="form-control"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        dateFormat="mm-dd-yy"
        disabled={disabled}
        showOnFocus
        autoZIndex={true}
        baseZIndex={"1000"}
      />
      {error && <div className="text-danger">{error}</div>}
    </>
  );
};

export default DatePicker;
