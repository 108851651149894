import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, DatePicker, Tag, Select, Table, Input } from 'antd'
import moment from 'moment'
import { msalFetch } from '../utilities/msalFetch'
import { toast } from 'react-toastify'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import { formatDate, formatPhoneNumber } from '../utilities/format'

const { Option } = Select
const { RangePicker } = DatePicker

export const DonorReport = props => {
  const [filters, setFilters] = useState({})
  const [records, setRecords] = useState([])

  const columns = [
    {
      title: 'Donor Id',
      dataIndex: 'donor_id',
      key: 'donor_id'
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name'
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name'
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      filters: [
        {
          text: 'Male',
          value: 'M'
        },
        {
          text: 'Female',
          value: 'F'
        }
      ],
      onFilter: (value, record) => record.gender.indexOf(value) === 0
    },
    {
      title: 'Date of Birth',
      dataIndex: 'dob',
      key: 'dob',
      render: (_, { dob }) => {
        return formatDate(dob)
      }
    },
    {
      title: 'Allergic To',
      dataIndex: 'allergic_to',
      key: 'allergic_to',
      filters: [
        {
          text: 'Iodine',
          value: 'Iodine'
        },
        {
          text: 'Environmental',
          value: 'Environmental'
        },
        { text: 'Medication', value: 'Medication' },
        { text: 'Latex', value: 'Latex' },
        { text: 'Others', value: 'Others' },
        { text: 'No Allergy', value: '-' }
      ],
      onFilter: (value, record) => record.allergic_to.indexOf(value) === 0
    },
    {
      title: 'Allergy Comments',
      dataIndex: 'allergy_comments',
      key: 'allergy_comments'
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      render: (_, { phone }) => {
        return formatPhoneNumber(phone)
      }
    },
    {
      title: 'Address Verified',
      dataIndex: 'address_verified',
      key: 'address_verified',
      filters: [
        {
          text: 'Yes',
          value: 'Yes'
        },
        {
          text: 'No',
          value: 'No'
        }
      ],
      onFilter: (value, record) => record.address_verified.indexOf(value) === 0
    },
    {
      title: 'Donor Status',
      dataIndex: 'donor_status',
      key: 'donor_status',
      render: (_, { donor_status }) => {
        switch (donor_status) {
          case 'ACTIVE':
            return <Tag color='green'>{donor_status}</Tag>
          case 'PERMANENTLY DEFERRED':
            return <Tag color='red'>{donor_status}</Tag>
          case 'TEMPORARILY DEFERRED':
            return <Tag color='red'>{donor_status}</Tag>
          case 'TEMPORARILY DEFERRED (Abnormal SPE)':
            return <Tag color='red'>{donor_status}</Tag>
        }
      }
    },
    {
      title: 'Donor Type',
      dataIndex: 'donor_type',
      key: 'donor_type',
      render: (_, { donor_type }) => {
        switch (donor_type) {
          case 'NEW':
            return <Tag color='purple'>{donor_type}</Tag>
          case 'QUALIFIED':
            return <Tag color='green'>{donor_type}</Tag>
          case 'APPLICANT 1':
            return <Tag color='orange'>{donor_type}</Tag>
          case 'APPLICANT 2':
            return <Tag color='orange'>{donor_type}</Tag>
        }
      }
    },
    {
      title: 'Last Donation Date',
      dataIndex: 'donation_date',
      key: 'donation_date'
    },
    {
      title: 'UCN',
      dataIndex: 'ucn',
      key: 'ucn'
    },
    {
      title: 'Lab Result',
      dataIndex: 'Lab_Result',
      key: 'Lab_Result',
      render: (_, { Lab_Result }) => {
        switch (Lab_Result) {
          case 'Partial':
            return <Tag color='geekblue'>{Lab_Result}</Tag>
          case 'Pass':
            return <Tag color='green'>{Lab_Result}</Tag>
          case 'Reactive':
            return <Tag color='red'>{Lab_Result}</Tag>
          case 'Abnormal SPE':
            return <Tag color='yellow'>{Lab_Result}</Tag>
          case 'RPR Positive':
            return <Tag color='yellow'>{Lab_Result}</Tag>
          case 'ATYA Positive':
            return <Tag color='red'>{Lab_Result}</Tag>
          case 'No Records':
            return <p>{Lab_Result}</p>
        }
      },
      filters: [
        {
          text: 'Partial',
          value: 'Partal'
        },
        {
          text: 'Pass',
          value: 'Pass'
        },
        {
          text: 'Reactive',
          value: 'Reactive'
        },
        {
          text: 'Abnormal SPE',
          value: 'Abnormal SPE'
        },
        {
          text: 'ATYA Positive',
          value: 'ATYA Positive'
        },
        {
          text: 'RPR Positive',
          value: 'RPR Positive'
        },
        {
          text: 'No Records',
          value: 'No Records'
        }
      ],
      onFilter: (value, record) => record.Lab_Result.indexOf(value) === 0
    }
  ]

  const handleDateRangeChange = (dates, dateStrings) => {
    const [fromdate, todate] = dateStrings
    if (fromdate != '' && todate != '') {
      setFilters({
        ...filters,
        fromDate: moment(fromdate).format('YYYY-MM-DD'),
        toDate: moment(todate).format('YYYY-MM-DD')
      })
    } else {
      const newFilters = { ...filters }
      filters.hasOwnProperty('fromDate') && delete newFilters.fromDate
      filters.hasOwnProperty('toDate') && delete newFilters.toDate
      setFilters({ ...newFilters })
    }
  }

  const handleDonorStatusChange = value => {
    if (value) {
      setFilters({ ...filters, donorStatus: value })
    } else {
      const newFilters = { ...filters }
      delete newFilters.donorStatus
      setFilters({ ...newFilters })
    }
  }

  const handleDonorTypeChange = value => {
    if (value) {
      setFilters({ ...filters, donorType: value })
    } else {
      const newFilters = { ...filters }
      delete newFilters.donorType
      setFilters({ ...newFilters })
    }
  }

  const handleUcnChange = e => {
    if (e.target.value) {
      setFilters({ ...filters, ucn: e.target.value })
    } else {
      const newFilters = { ...filters }
      delete newFilters.ucn
      setFilters({ ...newFilters })
    }
  }

  const fetchRecords = () => {
    if (!Object.keys(filters).length) {
      toast.error('Atleast one filter is needed')
    } else {
      props.actions.setLoading()
      const body = {
        ...filters
      }
      const options = {
        method: 'POST',
        body: JSON.stringify(body),
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.token
        }
      }
      msalFetch('/api/reports/donorReport', options)
        .then(async res => {
          props.actions.clearLoading()
          if (res.ok) {
            let data = await res.json()
            data = data.map(x => {
              x.donation_date = x.donation_date
                ? formatDate(x.donation_date)
                : null
              x.Lab_Result = x.Lab_Result ? x.Lab_Result : 'No Records'
              x.allergic_to = x.allergic_to ? x.allergic_to : '-'
              return x
            })
            data.sort((a, b) => {
              if (a.donor_id < b.donor_id) {
                return -1
              }
            })
            setRecords(data)
          } else {
          }
        })
        .then(err => {
          props.actions.clearLoading()
          console.log(err)
        })
    }
  }

  return (
    <div className='row justify-content-center mt-2'>
      <div className='col-md-11'>
        <div className='row d-flex mt-4 mb-4'>
          <div className='col-sm-9'>
            <h3 style={{ color: '#292764' }}>Donor Report</h3>
            <div className='row d-flex justify-content-between'>
              <div className='col-md-3 col-sm-12 col-lg-2'>
                Donation Date :
                <RangePicker
                  style={{ width: '220px' }}
                  format='DD-MMM-YYYY'
                  onCalendarChange={handleDateRangeChange}
                ></RangePicker>
              </div>
              <div className='col-md-3 col-sm-12 col-lg-2'>
                <label htmlFor='donor-status'>Donor Status : </label>
                <Select
                  placeholder='Select Donor Status'
                  allowClear
                  style={{ width: '15rem' }}
                  id='donor-status'
                  onChange={handleDonorStatusChange}
                  fieldNames='donor-status'
                >
                  <Option value='ACTIVE'>ACTIVE</Option>
                  <Option value='TEMPORARILY DEFERRED'>
                    TEMPORARILY DEFERRED
                  </Option>
                  <Option value='TEMPORARILY DEFERRED (Abnormal SPE)'>
                    TEMPORARILY DEFERRED (Abnormal SPE)
                  </Option>
                  <Option value='PERMANENTLY DEFERRED'>
                    PERMANENTLY DEFERRED
                  </Option>
                </Select>
              </div>
              <div className='col-md-3 col-sm-12 col-lg-2'>
                <label htmlFor='donor-type'>Donor Type : </label>
                <Select
                  placeholder='Select Donor Type'
                  allowClear
                  style={{ width: '15rem' }}
                  id='donor-type'
                  onChange={handleDonorTypeChange}
                >
                  <Option value='NEW'>NEW</Option>
                  <Option value='APPLICANT 1'>APPLICANT 1</Option>
                  <Option value='APPLICANT 2'>APPLICANT 2</Option>
                  <Option value='QUALIFIED'>QUALIFIED</Option>
                </Select>
              </div>
              <div className='col-md-3 col-sm-12 col-lg-2'>
                <label htmlFor='ucn'>UCN : </label>
                <Input
                  allowClear={true}
                  onChange={handleUcnChange}
                  placeholder='Scan UCN'
                ></Input>
              </div>
            </div>
            <div className='my-4'>
              <Button type='primary' onClick={fetchRecords}>
                Apply
              </Button>
            </div>
          </div>
        </div>
        <div className='row d-flex justify-content-center mt-4 mb-4'>
          <div className='col-md-12'>
            <Table columns={columns} dataSource={records} size='small'></Table>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token,
    roleid: state.roleid
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DonorReport)
