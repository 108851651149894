import React, { useEffect, useState } from 'react'
import NavBar from '../../../components/NavBar'
import AddCenter from './AddCenter'
import ManageCenters from './ManageCenters'
import EquipmentManagement from './EquipmentManagement'
import { connect } from 'react-redux'
import * as actions from '../../../redux/actions'
import { bindActionCreators } from 'redux'
import { toast } from 'react-toastify'
import AddEquipment from './AddEquipment'
import { msalFetch } from '../../../utilities/msalFetch'

function CenterManagement (props) {
  const [activeTab, setActiveTab] = useState('manageCenters')
  const [centers, setCenters] = useState([])

  const items = [
    { title: 'Add New Center', key: 'addCenter' },
    { title: 'Manage Centers', key: 'manageCenters' },
    { title: 'Add Equipment', key: 'addEquipment' },
    { title: 'Equipment Management', key: 'equipmentManagement' }
  ]

  const handleLinkClick = key => {
    setActiveTab(key)
  }

  const renderContent = () => {
    switch (activeTab) {
      case 'addCenter':
        return (
          <AddCenter
            setLoading={props.actions.setLoading}
            clearLoading={props.actions.clearLoading}
            setActiveTab={setActiveTab}
          />
        )
      case 'manageCenters':
        return (
          <ManageCenters
            setLoading={props.actions.setLoading}
            clearLoading={props.actions.clearLoading}
            setActiveTab={setActiveTab}
          />
        )
      case 'equipmentManagement':
        return (
          <EquipmentManagement
            setLoading={props.actions.setLoading}
            clearLoading={props.actions.clearLoading}
            setActiveTab={setActiveTab}
          />
        )
      case 'addEquipment':
        return (
          <AddEquipment
            setLoading={props.actions.setLoading}
            clearLoading={props.actions.clearLoading}
            setActiveTab={setActiveTab}
          />
        )
    }
  }

  return (
    <>
      <NavBar
        active={activeTab}
        items={items}
        onLinkClick={key => handleLinkClick(key)}
      ></NavBar>
      <div className='row d-flex justify-content-center'>
        <div className='col-md-10 col-lg-8 col-sm-12 my-4'>
          {renderContent()}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token,
    roleid: state.roleid
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CenterManagement)
