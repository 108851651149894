import React from "react";
import TextField from "../../../shared/FormInputs/TextField";

const CollectionOfVolume = ({ onChangeFormData, data, errors={} }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onChangeFormData({ ...data, [name]: value });
  };

  return (
    <fieldset>
      <legend className="legend">Collection volume at time of reaction</legend>
      <div className="row mb-2">
        <div className="col-4 d-flex flex-column">
          <TextField
            id="amountOfProceduralSalineAdministered"
            name="amountOfProceduralSalineAdministered"
            label="Amount of Procedural Saline Administered"
            value={data.amountOfProceduralSalineAdministered}
            onChange={handleChange}
            error={errors.amountOfProceduralSalineAdministered}
            required={true}
          />
        </div>
        <div className="col-3 d-flex flex-column">
          <TextField
            id="amountOfCitrateUsed"
            name="amountOfCitrateUsed"
            label="Amount of Citrate Used"
            value={data.amountOfCitrateUsed}
            onChange={handleChange}
            error={errors.amountOfCitrateUsed}
            required={true}
          />
        </div>
      </div>
    </fieldset>
  );
};

export default CollectionOfVolume;
