import React from 'react'

function TravelPoster () {
  return (
    <div>
      <h1 style={{ color: 'red', textAlign: 'center' }}>TRAVEL POSTER</h1>
      <ol>
        <li>
          <b>
            From 1980 through 1996 did you spend time that adds up to 3 months
            or more
          </b>{' '}
          in England, Northern Ireland, Scotland, Wales, the Isle of Man, the
          Channel Islands, Gibraltar, or the Falkland Islands?
        </li>
        <li>
          <b>
            From 1980 through 2001, did you spend time that adds up to 5 years
            or more
          </b>{' '}
          in France or Ireland? Time spent in Ireland does not include time
          spent in Northern Ireland which is part of the United Kingdom.
        </li>
        <li>
          <b>From 1980 to the present, did you receive a blood transfusion</b>{' '}
          in France, Ireland, England, Northern Ireland, Scotland, Wales, the
          Isle of Man, the Channel Islands, Gibraltar, or the Falkland Islands?
        </li>
      </ol>
    </div>
  )
}

export default TravelPoster
