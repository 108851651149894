import React, { useState, useEffect } from "react";
import { request } from "../../utilities/msalFetch";
import Select from "../../shared/FormInputs/Select";
import TextField from "../../shared/FormInputs/TextField";
import { Table, DatePicker } from "antd";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

import moment from "moment";
import DeviationFormReview from "./DeviationFormReview";
const { RangePicker } = DatePicker;

const dateFormat = "MM-DD-YYYY";

const columns = [
  {
    title: "Deviation Number",
    dataIndex: "deviation_number",
    key: "deviation_number",
  },
  { title: "Center Code", dataIndex: "center_code", key: "center_code" },
  { title: "Area", dataIndex: "area", key: "area" },
  {
    title: "Exception Code",
    dataIndex: "exception_code",
    key: "exception_code",
  },
  {
    title: "Date of Creation",
    dataIndex: "date_of_creation",
    key: "date_of_creation",
    render: (text) => moment(text).format(dateFormat),
  },
  {
    title: "Date of Occurrence",
    dataIndex: "date_of_occurrence",
    key: "date_of_occurrence",
    render: (text) => moment(text).format(dateFormat),
  },
  {
    title: "Risk Classification",
    dataIndex: "risk_classification",
    key: "risk_classification",
  },
  { title: "Employee Name", dataIndex: "employee_name", key: "employee_name" },
  { title: "Status", dataIndex: "status", key: "status" },
];

const DeviationReports = () => {
  const [modal, setModal] = useState(false);
  const [deviationNumber, setDeviationNumber] = useState();
  const [centerCode, setCenterCode] = useState();
  const [riskClassification, setRiskClassification] = useState();
  const [dateRange, setDateRange] = useState(undefined);
  const [reports, setReports] = useState([]);
  const [centerCodes, setCenterCodes] = useState([]);
  const [record, setRecord] = useState(null);

  useEffect(() => {
    const fetchCenterCodes = async () => {
      const res = await request.get("/api/deviation/centercodes");
      if (res.data.success) {
        setCenterCodes(res.data.centerCodes);
      }
    };

    fetchCenterCodes();
  }, []);

  const handleSearch = async (e) => {
    e.preventDefault();
    const queryParams = {
      deviationNumber,
      centerCode,
      riskClassification,
      startDate: dateRange?.fromDate,
      endDate: dateRange?.toDate,
    };

    try {
      const res = await request.post(`/api/deviation/getReports`, queryParams);
      if (res.data.success) {
        console.log(res.data)
        setReports(res.data.reports);
      } else {
        toast.error("Failed to fetch reports");
      }
    } catch (error) {
      toast.error("Error occurred while fetching reports");
    }
  };

  const handleDateRangeChange = (_, dateStrings) => {
    const [fromdate, todate] = dateStrings;
    if (fromdate != "" && todate != "") {
      setDateRange({
        fromDate: moment(fromdate).format("YYYY-MM-DD"),
        toDate: moment(todate).format("YYYY-MM-DD"),
      });
    } else {
      setDateRange(undefined);
    }
  };

  const handleRowClick = (record) => {
    if (record.status === "Closed") return;
    setModal(true);
    setRecord(record);
  };

  return (
    <div className="container">
      <h3 className="text-center mb-4">Deviation Reports</h3>
      <form>
        <div className="form-group row mb-2">
          <div className="col-md-3">
            <TextField
              label="Deviation Number"
              id="deviationNumber"
              name="deviationNumber"
              value={deviationNumber}
              onChange={(e) => setDeviationNumber(e.target.value)}
            />
          </div>
          <div className="col-md-3">
            <Select
              label="Center Code"
              id="centerCode"
              name="centerCode"
              value={centerCode}
              onChange={(e) => setCenterCode(e.target.value)}
              options={centerCodes}
              placeholder="Select Center Code"
            />
          </div>
          <div className="col-md-3">
            <Select
              label="Risk Classification"
              id="riskClassification"
              name="riskClassification"
              value={riskClassification}
              onChange={(e) => setRiskClassification(e.target.value)}
              options={["Minor", "Major"]}
              placeholder="Select Risk Classification"
            />
          </div>

          <div className="col-md-3">
            <label>Date Range</label>
            <RangePicker
              format={dateFormat}
              className="p-2"
              onCalendarChange={handleDateRangeChange}
            ></RangePicker>
          </div>
        </div>

        <div className="col-md-9 ">
          <button
            type="submit"
            onClick={handleSearch}
            className="btn btn-primary"
          >
            Search
          </button>
        </div>
      </form>

      <div className="mt-4">
        <h4>Reports</h4>
        <Table
          columns={columns}
          dataSource={reports}
          rowKey="deviation_number"
          pagination={false}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
          rowClassName={() => "hover-row"}
        />
      </div>

      <Modal centered show={modal} onHide={() => setModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Deviation Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DeviationFormReview
            record={record}
            onClose={() => setModal(false)}
            onSubmit={handleSearch}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DeviationReports;
