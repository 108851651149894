import React from 'react'
import logo from '../images/life-plasma-logo.webp'
import { ReactBarcode as Barcode } from 'react-jsbarcode';
import { formatDonorId, formatDate } from '../utilities/format'
import { Alert } from 'antd'
import moment from 'moment'

const tablecell = {
  border: '1px solid black',
  borderCollapse: 'collapse',
  color: 'black',
}

class PrintReceipt extends React.Component {
  constructor (props) {
    super(props)
  }

  calculatePlasma = () => {
    if (this.props.drd?.weight) {
      let weight = this.props.drd.weight
      if (weight > 10 && weight <= 149) {
        return 625
      } else if (weight >= 150 && weight <= 174) {
        return 750
      } else {
        return 800
      }
    }
  }

  getReturnDate = () => {
    const today = moment()
    const returnDate = today.add('56', 'days')
    return moment(returnDate).format('MM/DD/YYYY')
  }

  render () {
    if (
      this.props.drd &&
      this.props.donorInfo &&
      this.props.donorInfo.donor_id
    ) {
      return (
        <div className='row d-flex justify-content-center border p-4'>
          <div className='col-sm-10'>
            <div className='row d-sm-flex justify-content-between'>
              <table className='table table-borderless'>
                <tbody>
                  <tr>
                    <td>
                      <img alt='logo' width={200} src={logo}></img>
                      <h3 className='mt-3 mb-2'>
                        Donation Information Receipt
                      </h3>
                      <h4 className='p-0 m-0' style={{padding:'4px 0'}}>
                        Name: {this.props.donorInfo.first_name}{' '}
                        {this.props.donorInfo.last_name}
                      </h4>

                      <h4 className='p-0 m-0' style={{padding:'4px 0'}}>
                        SSN: {formatDonorId(this.props.donorInfo.ssn)}
                      </h4>

                      <h4 className='p-0 m-0' style={{padding:'4px 0'}}>
                        Donor ID: {this.props.drd.donor_id}
                      </h4>
                      <h4 className='p-0 m-0' style={{padding:'4px 0'}}>
                        Date of Birth: {formatDate(this.props.donorInfo.dob)}
                      </h4>

                      <h4 className='p-0 m-0' style={{padding:'4px 0'}}>
                        Donor Weight (LBS) : {this.props.drd.weight}
                      </h4>
                      <h4 className='p-0 m-0' style={{padding:'4px 0'}}>
                        HCT: {this.props.drd.hct}
                      </h4>
                      <h4 className='p-0 m-0' style={{padding:'4px 0'}}>
                        Blood Pressure:{' '}
                        {this.props.drd.bp_sys1 + '/' + this.props.drd.bp_dia1}
                      </h4>
                      {this.props?.drd.bp_sys2 ? (
                        <h4 className='p-0 m-0' style={{padding:'4px 0'}}>
                          {`Blood Pressure(Retake)`}:{' '}
                          {this.props.drd.bp_sys2 +
                            '/' +
                            this.props.drd.bp_dia2}
                        </h4>
                      ) : null}
                    </td>
                    <td className='d-flex justify-content-end'>
                      <img src={this.props.dp} alt='dp'></img>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className='col-sm-4'></div>
            </div>
            <div className='row'>
              {this.props.drd.weight_lost &&
              this.props.drd.weight_lost !== 0 &&
              this.props.drd.weight_lost > 10 ? (
                <Alert
                  message={`Donor has lost ${this.props.drd.weight_lost} lbs since last donation`}
                  type='error'
                />
              ) : null}
              <table className='table table-borderless'>
                <tbody>
                  <tr>
                    <td style={tablecell}>Plasma to be collected (mL)</td>
                    <td style={tablecell}>
                      {this.calculatePlasma()}
                      {this.props.drd.spe_only
                        ? 'SPE Sample only Donation'
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <td style={tablecell}>VP Performed</td>
                    <td style={tablecell}>Y or N</td>
                  </tr>
                  <tr>
                    <td style={tablecell}>Actual Plasma collected (mL)</td>
                    <td style={tablecell}>
                      {this.props.drd.spe_only
                        ? 'SPE Sample only Donation'
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <td style={tablecell}>UCN #</td>
                    <td style={tablecell}>
                      {this.props.drd.ucn ? (
                        <Barcode
                          value={this.props.drd.ucn}
                          options={{ format: 'code128', height: 40 }}
                        ></Barcode>
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td style={tablecell}>Exception</td>
                    <td style={tablecell}>
                      <table className='table table-borderless'>
                        <tbody>
                          <tr>
                            <td>
                              <input type='checkbox'></input>
                            </td>
                            <td>8-week Deferral due to &gt;200 mL RBC loss</td>
                          </tr>
                          <tr>
                            <td>
                              <input type='checkbox'></input>
                            </td>
                            <td>
                              No Plasma/Restick did not work/Refused Restick
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input type='checkbox'></input>
                            </td>
                            <td>Discontinued at &lt;150 ml donation</td>
                          </tr>
                          <tr>
                            <td>
                              <input type='checkbox'></input>
                            </td>
                            <td>
                              Partial donation that is &gt;=150ml but &lt;80%
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input type='checkbox'></input>
                            </td>
                            <td>
                              Machine error or operator error that resulted in
                              zero plasma or &lt;150 ml donation
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style={tablecell}>
                      {
                        'Return Date (If you had a 8-week deferral due to >200 mL RBC Loss)'
                      }
                    </td>
                    <td style={tablecell}>{this.getReturnDate()}</td>
                  </tr>
                  <tr>
                    <td style={tablecell}>Comments</td>
                    <td
                      style={{
                        fontWeight: '600',
                        fontSize: '16px',
                        border: '1px solid black',
                        borderCollapse: 'collapse'
                      }}
                      className='text-dark'
                    >
                      <ul>
                        {this.props.speDue ? (
                          <li>Donor is due for SPE Sample</li>
                        ) : null}
                        {this.props.drd?.spe_only ? (
                          <li>SPE sample only donation</li>
                        ) : null}
                        {this.props.donorInfo.allergic_to ? (
                          <>
                            <li className='text-danger'>
                              Allergic To: {this.props.donorInfo.allergic_to}
                            </li>
                          </>
                        ) : null}
                        {this.props.donorInfo.donor_type === 'NEW' &&
                        !this.props.healthAssessment.STATUS ? (
                          <li className='text-danger'>
                            The Donor Requires a Physical
                          </li>
                        ) : null}
                        {this.props.screeningComments ? (
                          <li>{this.props.screeningComments}</li>
                        ) : null}
                        {this.props.dhqComments ? (
                          <li>{this.props.dhqComments}</li>
                        ) : null}
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='row d-flex mt-2 justify-content-center'>
              <div className='col-md-11'>
                <p>
                  Present this payment slip to the Reception after completion of
                  your donation. Visit our website{' '}
                  <span>https://lifeplasma.com</span> for latest updates and how
                  to contact us.
                </p>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return null
  }
}

export default PrintReceipt
