export const DEVIATION_CATEGORIES = {
    Reception: [
      'Vitals out of range',
      'Vitals not documented',
      'Donor was allowed to donate with out of range vitals',
      'Arm Check not documented',
      'Arm Check not graded',
      'Missing screener initials and date',
      'Missing Physician Substitute determination of “Unsuitable"',
      'HCT/TP out of range',
      'HCT/TP not documented',
      'Missed/incomplete physical exam',
      'Missing/incomplete consents',
      'Missing/incomplete questions/findings',
      'Missing body art record/incomplete',
      'Questions not asked or documented',
      'Donor unsuitable due to previous cell loss',
      'Flagged question answer not addressed',
      'Medication, condition, etc. not documented properly',
      'Donation Frequency (3 x 7)',
      'Weight Loss',
      'ATYA (missed, etc.)',
      'Missed CDCS/NDDR, Failed to Document, Incorrectly Documented',
      'Missing information on DIS',
      'Unsuitable address',
      'Missed initials/date',
      'Incorrect documentation',
    ],
    'Donor Floor': [
      'Overdraw',
      'Arm Prep (observed deficiency)',
      'Machine Set up',
      'Saline Contamination',
      'Missing documentation (phlebs initials, DC time, initials, amount of plasma collected)',
      'Missing documentation on Donor event log',
      'Failed to document/add flags for RBC </> than 200mls',
      'Machine Fault',
      'Underdraw',
      'Missed overdue SPE',
      'Incorrect lot number scanned',
      'Did not select “save” or “complete donation”',
    ],
    Processing: [
      'Mislabeled product',
      'Mislabeled samples',
      'Unexplained gap in the Accsense reading',
      'Missed temperature reading during calibrations',
      'Temperature excursion',
      'Accsense Alarm Triggered',
    ],
    'Testing Laboratory (Product/Donor Samples)': [
      'Test Lab sample error reported',
      'Sample paperwork error',
      'Sample age problems',
      'Test results problems',
    ],
    'Shipment/Shipment Preparation Errors': [
      'Unit shipped with no test results',
      'Shipment of unsuitable unit',
      'Shipment paperwork problem',
    ],
    'Unsuitable Test Results': [
      'Timeframes set by SOP not met',
      'Unit disposition',
      'Incorrect units listed',
      'Units missing',
      'Exceeded allowable reporting time',
    ],
    Equipment: [
      'Instrument used with QC results out of range',
      'QC not performed',
      'QC not documented',
      'Required calibration/maintenance not performed',
      'Expired equipment in use',
    ],
    Complaints: [
      'Donor',
      'Customer/outside party',
    ],
    'Physician Substitute Department': [
      'SPE not reviewed within 14 days and donor donates',
      'Failed SPE and donor donates',
    ],
    Other: [
      'Errors that affect or could potentially affect product quality and/or donor safety that don’t fall into the above categories.',
    ],
    'DMS System': [
      'System Error',
    ],
  };
  
export const areas = Object.keys(DEVIATION_CATEGORIES);


  
  