import React from 'react'
import { formatTime, formatDate } from '../../utilities/format'

const Phlebotomy = props => {
  return (
    <div className='row mt-2 d-flex'>
      <div className='col-md-12'>
        {!props.donationDetails ? (
          <h4>Loading...</h4>
        ) : (
          <div className='row'>
            <div className='col-md-6'>
              <table className='table table-sm table-bordered'>
                <tbody>
                  <tr>
                    <td>UCN</td>
                    <th>{props.donationDetails.ucn}</th>
                  </tr>
                  <tr>
                    <td>Donation Date</td>
                    <th>{formatDate(props.donationDetails.donation_date)}</th>
                  </tr>
                  <tr>
                    <td>Setup/Connect By</td>
                    <th>
                      {props.donationDetails.setup_by
                        ? props.donationDetails.setup_by
                        : ''}
                    </th>
                  </tr>
                  <tr>
                    <td>Venipuncture Time</td>
                    <th>
                      {props.donationDetails.setup_time
                        ? formatTime(props.donationDetails.setup_time)
                        : ''}
                    </th>
                  </tr>
                  <tr>
                    <td>Restick By</td>
                    <th>
                      {props.donationDetails.restick_by
                        ? props.donationDetails.restick_by
                        : ''}
                    </th>
                  </tr>
                  <tr>
                    <td>Restick Time</td>
                    <th>
                      {props.donationDetails.restick_time
                        ? formatTime(props.donationDetails.restick_time)
                        : ''}
                    </th>
                  </tr>
                  <tr>
                    <td>Disconnect By</td>
                    <th>
                      {props.donationDetails.disconnect_by
                        ? props.donationDetails.disconnect_by
                        : ''}
                    </th>
                  </tr>
                  <tr>
                    <td>Disconnect Time</td>
                    <th>
                      {props.donationDetails.disconnect_time
                        ? formatTime(props.donationDetails.disconnect_time)
                        : ''}
                    </th>
                  </tr>
                  <tr>
                    <td>Donation Status</td>
                    <th>{props.donationDetails.status}</th>
                  </tr>
                  <tr>
                    <td>Target Plasma Volume</td>
                    <th>{props.donationDetails.plasma_amt} mL</th>
                  </tr>
                  <tr>
                    <td>Target Collection Volume</td>
                    <th>{props.donationDetails.collection_amt} mL</th>
                  </tr>
                  <tr>
                    <td>Machine Number</td>
                    <th>{props.donationDetails.machine_number}</th>
                  </tr>
                  <tr>
                    <td>Arm Used</td>
                    <th>
                      {props.donationDetails.arm_used == 'L'
                        ? 'Left'
                        : props.donationDetails.arm_used == 'R'
                        ? 'Right'
                        : null}
                    </th>
                  </tr>
                  <tr>
                    <td>AntiCoagulant Lot #</td>
                    <th>{props.donationDetails.anticoagulant_lot_number}</th>
                  </tr>
                  <tr>
                    <td>Saline Lot #</td>
                    <th>{props.donationDetails.saline_lot_number}</th>
                  </tr>
                  <tr>
                    <td>Bowl Lot #</td>
                    <th>{props.donationDetails.bowl_lot_number}</th>
                  </tr>
                  <tr>
                    <td>Bottle Lot #</td>
                    <th>{props.donationDetails.bottle_lot_number}</th>
                  </tr>
                  <tr>
                    <td>Harness Lot #</td>
                    <th>{props.donationDetails.harness_lot_number}</th>
                  </tr>
                  <tr>
                    <td>SPE Sample Taken</td>
                    <th>
                      {props.donationDetails.spe_taken !== null
                        ? props.donationDetails.spe_taken == 1
                          ? 'Yes'
                          : 'No'
                        : null}
                    </th>
                  </tr>
                  <tr>
                    <td>Arm Used (Restick)</td>
                    <th>
                      {props.donationDetails.arm_used_restick
                        ? props.donationDetails.arm_used_restick == 'R'
                          ? 'Right'
                          : 'Left'
                        : ''}
                    </th>
                  </tr>

                  <tr>
                    <td>Actual Plasma Volume</td>
                    {props.donationDetails.plasma_amt_machine > 0 ? (
                      <th>{props.donationDetails.plasma_amt_machine} mL</th>
                    ) : (
                      <th></th>
                    )}
                  </tr>
                  <tr>
                    <td>Actual Collection Volume</td>
                    {props.donationDetails.collection_amt_machine > 0 ? (
                      <th>{props.donationDetails.collection_amt_machine} mL</th>
                    ) : (
                      <th></th>
                    )}
                  </tr>
                  {props.donationDetails.reaction ? (
                    <tr className='table-danger'>
                      <td>Reaction</td>
                      <th>{props.donationDetails.reaction}</th>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
            {props.donationDetails.alert_flags ||
            props.donationDetails.rbc_loss ? (
              <div className='col-md-6'>
                <table className='table table-sm table-bordered'>
                  <tbody>
                    <tr>
                      <td>RBC Loss</td>
                      <th className='text-bg-danger'>
                        {props.donationDetails?.rbc_loss}
                      </th>
                    </tr>
                    <tr>
                      <td>Alert Flag</td>
                      <th className='text-bg-danger'>
                        {props.donationDetails?.alert_flags}
                      </th>
                    </tr>
                    <tr>
                      <td>Alert Flag Reason</td>
                      <th>{props.donationDetails?.alert_flag_reason}</th>
                    </tr>
                    <tr>
                      <td>Alert Flag Comments</td>
                      <th>{props.donationDetails?.phlebotomy_comments}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  )
}

export default Phlebotomy
