import { useCallback, useEffect, useState } from "react"
import { request } from "../utilities/msalFetch";


const useEmpNames = () => {
    const [empNames, setEmpNames] = useState([])
    const [error, setError] = useState(null);


    const fetchEmpNames = useCallback(async () => {
        setError(null)
        try {
            const res = await request.get("/api/deviation/empName");
            if (res.data.success) {
                setEmpNames(res.data.employeeNames);
            }
        } catch (error) {
            setError('Something went wrong')
        }

    }, [])

    useEffect(() => { fetchEmpNames() }, [fetchEmpNames])


    return { empNames, error }
}

export default useEmpNames