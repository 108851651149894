import React from "react";
import Select from "../../../shared/FormInputs/Select";
import RadioGroup from "../../../shared/FormInputs/RadioGroup";
import DynamicForm from "../../../shared/FormInputs/DynamicInput";
import TextField from "../../../shared/FormInputs/TextField";
import {
  medicationFields,
  treatmentOptions,
  vitalSigns,
  initialMedication,
  initialVitalSigns,
} from "../constant";

const Treatment = ({ data, onChangeData, errors = {}, options }) => {
  const handleChange = (value, name) => {
    onChangeData({ ...data, [name]: value });
  };

  return (
    <fieldset>
      <legend className="legend">Treatment</legend>
      <div className="row mb-2">
        <div className="col d-flex flex-column">
          <Select
            id="typesOfTreatment"
            name="treatment.typesOfTreatment"
            label="Types of Treatment"
            options={treatmentOptions}
            placeholder="Select"
            value={data.typesOfTreatment}
            onChange={(e) => handleChange(e.target.value, "typesOfTreatment")}
            required={true}
            error={errors.typesOfTreatment}
          />
        </div>
        <div className="col-md-6">
          <RadioGroup
            id="oralFluidsGiven"
            name="treatment.oralFluidsGiven"
            label="Oral Fluids Given?"
            options={["Yes", "No"]}
            value={data.oralFluidsGiven}
            onChange={(e) => handleChange(e.target.value, "oralFluidsGiven")}
            required={true}
            error={errors.oralFluidsGiven}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex flex-column">
          <DynamicForm
            formData={data.medication}
            onChange={(val) => handleChange(val, "medication")}
            fields={medicationFields}
            label="Medication"
            options={options}
            initialState={initialMedication}
            maxRows={4}
            error={errors?.medication}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex flex-column">
          <TextField
            value={data.medicationComments}
            onChange={(e) => handleChange(e.target.value, "medicationComments")}
            label="Medication Comments:"
            multiline={true}
            required={true}
            error={errors.medicationComments}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex flex-column">
          <DynamicForm
            formData={data.vitalSigns}
            onChange={(val) => handleChange(val, "vitalSigns")}
            fields={vitalSigns}
            label="Vital Signs After Treatment Initiated"
            options={options}
            initialState={initialVitalSigns}
            maxRows={1}
            required={true}
            error={errors?.vitalSigns}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex flex-column">
          <TextField
            value={data.description}
            onChange={(e) => handleChange(e.target.value, "description")}
            label="Description Narrative of reaction and treatment, Donor Response"
            multiline={true}
            required={true}
            error={errors.description}
          />
        </div>
      </div>
    </fieldset>
  );
};

export default Treatment;
