import React from 'react'
import { formatDate, formatDateTime } from '../../utilities/format'

const LocationDetails = props => {
  return (
    <React.Fragment>
      {props.donationDetails.ucn ? (
        !props.locationDetails ? (
          <h5>Loading...</h5>
        ) : props.locationDetails &&
          Object.keys(props.locationDetails).length ? (
          props.locationDetails.CASE_NO ? (
            <div className='row'>
              <div className='col-md-6'>
                <table
                  className='table table-bordered table-sm'
                  style={{ fontSize: '14px' }}
                >
                  <tbody>
                    <tr>
                      <td>Case Packing No</td>
                      <th scope='row'>{props.locationDetails.CASE_NO}</th>
                    </tr>
                    <tr>
                      <td>Case Packing Status</td>
                      <th
                        scope='row'
                        className={
                          props.locationDetails.STATUS == 'QA RELEASED'
                            ? 'table-success'
                            : 'table-danger'
                        }
                      >
                        {props.locationDetails.STATUS}
                      </th>
                    </tr>
                    <tr>
                      <td>Shipped</td>
                      <th scope='row'>
                        {props.locationDetails.SHIPPED == '1' ? 'YES' : 'NO'}
                      </th>
                    </tr>
                    <tr>
                      <td>Bill of Loading</td>
                      <th scope='row'>{props.locationDetails.BOL}</th>
                    </tr>
                    <tr>
                      <td>Ship From</td>
                      <th scope='row'>{props.locationDetails.SHIP_FROM}</th>
                    </tr>
                    <tr>
                      <td>Ship To</td>
                      <th scope='row'>{props.locationDetails.SHIP_TO}</th>
                    </tr>
                    <tr>
                      <td>Ship Date</td>
                      <th scope='row'>
                        {formatDate(props.locationDetails.SHIP_DATE)}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <h4>No Records</h4>
          )
        ) : (
          <h4>No Records found</h4>
        )
      ) : (
        <h4>No Records found</h4>
      )}
    </React.Fragment>
  )
}
export default LocationDetails
