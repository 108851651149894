import React from 'react'
import favicon from '../images/life-plasma-logo.webp'
import profile from '../images/user.png'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import { Link, NavLink } from 'react-router-dom'
import styles from '../styles/Header.module.css'
import { Popover, Space } from 'antd'
import { useMsal } from '@azure/msal-react'
import { msalFetch } from '../utilities/msalFetch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTable, faShop } from '@fortawesome/free-solid-svg-icons'

function Header (props) {
  const { instance } = useMsal()

  const logout = () => {
    msalFetch('/api/auth/logout', { method: 'GET' }).then(res => {
      if (res.ok) {
        instance.logoutRedirect({
          account: instance.getActiveAccount()
        })
        localStorage.clear()
        sessionStorage.clear()
      }
    })
  }

  const generateHeaderLinks = () => {
    if (props.roleid == 4) {
      return (
        <li className='nav-item active'>
          <NavLink
            className={`nav-link mr-5 d-flex align-items-center ${styles.linkStyle}`}
            to='/dashboard'
          >
            <FontAwesomeIcon
              icon={faTable}
              size='2x'
              className='mx-2'
            ></FontAwesomeIcon>
            Admin Console
          </NavLink>
        </li>
      )
    } else if (props.roleid == 6) {
      return (
        <li className='nav-item active'>
          <NavLink
            className={`nav-link mr-5 d-flex align-items-center ${styles.linkStyle}`}
            to='/dashboard'
          >
            <FontAwesomeIcon
              icon={faTable}
              size='2x'
              className='mx-2'
            ></FontAwesomeIcon>
            LAB Console
          </NavLink>
        </li>
      )
    } else {
      return (
        <React.Fragment>
          <li className='nav-item'>
            <NavLink
              className={`nav-link mr-5 d-flex align-items-center ${styles.linkStyle}`}
              to='/dashboard'
            >
              <FontAwesomeIcon
                icon={faTable}
                size='2x'
                className='mx-2'
              ></FontAwesomeIcon>
              Dashboard
            </NavLink>
          </li>
        </React.Fragment>
      )
    }
  }

  const menuContent = () => {
    return (
      <div className={styles.profileMenu}>
        <p>{props.email}</p>
        <p onClick={logout}>Logout</p>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className='row'>
        <nav className='navbar navbar-expand-lg navbar-light bg-light border-bottom justify-content-between p-3 pt-2 pb-2'>
          <Link className='navbar-brand' to='/dashboard'>
            <img src={favicon} width='150px' alt='' />
          </Link>

          {props.isAuthenticated ? (
            <>
              <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
                <li className='nav-item'>
                  <h5 className='text-secondary'>
                    <FontAwesomeIcon
                      icon={faShop}
                      fontSize={20}
                    ></FontAwesomeIcon>{' '}
                    {props.centerCode}
                  </h5>
                </li>
              </ul>
              <div className={styles.headerFullWidth}>
                <ul className='navbar-nav ms-auto mb-2 mb-lg-0 d-flex align-items-center'>
                  {generateHeaderLinks()}
                  <Space wrap>
                    <Popover
                      title={`${props.displayName}`}
                      content={menuContent()}
                      trigger='click'
                    >
                      <li className='nav-item position-relative'>
                        <div className={styles.user}>
                          <img
                            src={profile}
                            style={{ height: '30px', cursor: 'pointer' }}
                            alt='profile'
                          ></img>
                          <div className={styles.userName}>
                            <p>{props.displayName}</p>
                            <p>({props.rolename})</p>
                          </div>
                        </div>
                      </li>
                    </Popover>
                  </Space>
                </ul>
              </div>
              <div className={styles.minWidth}>
                <NavLink
                  className={`nav-link ${styles.linkStyle}`}
                  to='/dashboard'
                >
                  <FontAwesomeIcon
                    icon={faTable}
                    size='2x'
                    className='mx-2 align-content-center'
                  ></FontAwesomeIcon>
                </NavLink>
                <Popover
                  title={`${props.displayName}`}
                  content={menuContent()}
                  trigger='click'
                >
                  <span>
                    <img
                      src={profile}
                      className='iconStyle'
                      alt='profile'
                    ></img>
                  </span>
                </Popover>
              </div>
            </>
          ) : null}
        </nav>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token,
    isAuthenticated: state.isAuthenticated,
    roleid: state.roleid,
    rolename: state.rolename,
    email: state.email,
    centerCode: state.centerCode,
    displayName: state.displayName
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
