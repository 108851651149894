import React, { useState, useEffect, useRef } from 'react'
import styles from '../styles/DonorRegister.module.css'
import Webcam from 'react-webcam'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import StateCities from '../utilities/US_States_and_Cities.json'
import { toast } from 'react-toastify'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { InputMask } from 'primereact/inputmask'
import { msalFetch } from '../utilities/msalFetch'
import NoImage from '../images/no_image.png'

const videoConstraints = {
  width: 220,
  height: 200,
  facingMode: 'user'
}

function DonorUpdate (props) {
  const [image, setImage] = useState('')
  const [enableCamera, setEnablecamera] = useState(false)
  const [formData, setFormdata] = useState({})
  const [picError, setPicError] = useState(false)
  const formRef = useRef(null)
  const [showModal, setShowModal] = useState(false)
  const [formEdited, setFormEdited] = useState(false)
  const [height, setHeight] = useState({})

  const webcamRef = React.useRef(null)

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot()

    setImage(imageSrc)
    setFormEdited(true)
    setPicError('')
  }, [webcamRef])

  const handleChange = e => {
    const data = { ...formData }
    data[e.target.name] = e.target.value
    setFormdata(data)
    setFormEdited(true)
  }

  useState(() => {
    setFormdata({ country: 'USA' })
  }, [])

  function arrayBufferToBase64 (buffer) {
    var binary = ''
    var bytes = new Uint8Array(buffer)
    var len = bytes.byteLength
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
  }

  useState(() => {
    if (props.donorInfo.donor_id) {
      setFormdata({ ...props.donorInfo })
      if (props.donorInfo.height) {
        let a = props.donorInfo?.height.toString().split('.')
        if (a.length > 1 && a[1]) {
          setHeight({ feet: a[0], inches: a[1] })
        } else {
          setHeight({ feet: a[0] })
        }
      }
      if (props.b64) {
        let picdata = arrayBufferToBase64(props.b64)
        picdata = 'data:image/jpeg;base64,' + picdata
        setImage(picdata)
      }
    }
  }, [props.donorInfo])

  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    b64Data = b64Data.split(',')[1]
    const byteCharacters = window.atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, { type: contentType })
    return blob
  }

  const checkDonorId = e => {
    var regex = new RegExp('[0-9,-]')
    if (!regex.test(e.key)) {
      e.preventDefault()
      return false
    }
  }

  const validate = () => {
    formRef.current.classList.add('was-validated')
    if (formData.state == undefined) {
      document.getElementById('state').classList.add('is-invalid')
    }
    if (formData.city == undefined) {
      document.getElementById('city').classList.add('is-invalid')
    }
    if (
      formData.first_name &&
      formData.last_name &&
      formData.donor_id &&
      formData.dob &&
      formData.emergency_contact_name &&
      formData.emergency_contact_number &&
      formData.phone &&
      formData.gender &&
      formData.addr1 &&
      formData.state &&
      formData.city &&
      formData.zipcode &&
      formData.address_verified
    ) {
      /*     if (image == "") {
        setPicError(true);
        return false;
      } else {
        setPicError(false);
        return true;
      } */
      return true
    } else {
      return false
    }
  }

  const handleHeightChange = e => {
    setHeight({ ...height, [e.target.name]: e.target.value })
  }

  const handleCancel = e => {
    e.preventDefault()
    if (formEdited) {
      setShowModal(true)
    } else {
      props.setEdit(false)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    const temp = validate()
    if (temp) {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
      props.actions.setLoading()
      const formdata1 = new FormData()
      const updateFields = [
        'donor_id',
        'first_name',
        'last_name',
        'middle_name',
        'dob',
        'emergency_contact_name',
        'emergency_contact_number',
        'gender',
        'phone',
        'addr1',
        'addr2',
        'state',
        'city',
        'zipcode',
        'address_verified',
        'vein_grade_left',
        'vein_grade_right',
        'donor_status',
        'donor_type',
        'nddr',
        'nddr_date',
        'nddr_check',
        'spe_sample_date',
        'last_full_physical_date'
      ]
      for (let key in formData) {
        if (updateFields.find(x => x === key)) {
          formData[key] && formdata1.append(key, formData[key])
        }
      }
      if (height.feet && height.inches) {
        formdata1.append(
          'height',
          parseFloat(height.feet.toString() + '.' + height.inches.toString())
        )
      }
      formdata1.delete('pic')
      if (image) {
        const picblob = b64toBlob(image, 'image/jpeg')
        formdata1.append('pic', picblob)
      }
      const options = {
        method: 'POST',
        headers: {
          Authorization: props.token
        },
        body: formdata1
      }
      msalFetch('/api/donor/updateDonorInfo', options).then(res => {
        props.actions.clearLoading()
        if (res.status == 200) {
          toast.success('Donor Information updated successfully')
          props.getDonorInfo()
          props.setEdit(false)
        } else {
          props.actions.clearLoading()
          toast.error('Failed to update')
        }
      })
    }
  }

  return (
    <React.Fragment>
      {props.donorInfo.donor_id ? (
        <div className='row justify-content-center mb-5'>
          <div className='col-sm-8 mt-4'>
            <h4 className='text-center pb-4'>Donor Information Update</h4>
            <form
              className='needs-validation'
              noValidate
              onSubmit={handleSubmit}
              ref={formRef}
            >
              <div className='row'>
                <div className='col-md-4 mb-3'>
                  <label className={styles.label} htmlFor='fname'>
                    First name
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-sm'
                    id='fname'
                    placeholder='First name'
                    name='first_name'
                    onChange={handleChange}
                    required
                    maxLength={45}
                    value={formData.first_name}
                  />
                  <div className='invalid-feedback'>
                    First name cannot be blank
                  </div>
                </div>
                <div className='col-md-4 mb-3'>
                  <label htmlFor='mname'>Middle Name</label>
                  <input
                    type='text'
                    className='form-control form-control-sm'
                    id='mname'
                    placeholder='Middle name'
                    name='middle_name'
                    onChange={handleChange}
                    maxLength={45}
                    value={formData.middle_name}
                  />
                </div>
                <div className='col-md-4 mb-3'>
                  <label htmlFor='lastname'>Last name</label>
                  <input
                    type='text'
                    className='form-control form-control-sm'
                    id='lastname'
                    placeholder='Last name'
                    name='last_name'
                    onChange={handleChange}
                    required
                    maxLength={45}
                    value={formData.last_name}
                  />
                  <div className='invalid-feedback'>
                    Last name cannot be blank
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-8'>
                  <div className='row'>
                    <div className='form-group col-md-6'>
                      <label htmlFor='donor_id'>SSN</label>
                      <input
                        type='text'
                        className='form-control form-control-sm'
                        id='donor_id'
                        placeholder='XXX-XX-XXXX'
                        name='donor_id'
                        onKeyPress={checkDonorId}
                        onChange={handleChange}
                        required
                        pattern='\d\d\d-\d\d-\d\d\d\d'
                        minLength='11'
                        maxLength='11'
                        value={'XXX-XX-' + formData.ssn?.slice(7)}
                        disabled
                      />
                      <div className='invalid-feedback'>
                        Incorrect Donor Id. Should be in format XXX-XX-XXXX
                      </div>
                    </div>
                    <div className='form-group col-md-6'>
                      <label htmlFor='dob'>Date of Birth</label>
                      <input
                        type='date'
                        className='form-control form-control-sm'
                        id='dob'
                        name='dob'
                        onChange={handleChange}
                        required
                        value={formData.dob ? formData.dob.slice(0, 10) : ''}
                      />
                      <div className='invalid-feedback'>
                        Date of Birth is required
                      </div>
                    </div>
                  </div>
                  <div className='row pt-3'>
                    <div className='form-group col-md-6'>
                      <label htmlFor='phone'>Emergency Contact Name</label>
                      <input
                        type='text'
                        className='form-control form-control-sm'
                        id='phone'
                        placeholder='Emergency Contact Name'
                        name='emergency_contact_name'
                        onChange={handleChange}
                        required
                        maxLength={45}
                        value={formData.emergency_contact_name}
                      />
                      <div className='invalid-feedback'>
                        This field cannot be blank
                      </div>
                    </div>
                    <div className='form-group col-md-6'>
                      <label htmlFor='ephone'>Emergency Contact Number</label>
                      <InputMask
                        className='form-control form-control-sm'
                        id='ephone'
                        name='emergency_contact_number'
                        onChange={handleChange}
                        required
                        mask='(999) 999-9999'
                        placeholder='(999) 999-9999'
                        value={formData.emergency_contact_number}
                      ></InputMask>
                      <div className='invalid-feedback'>
                        Invalid phone number
                      </div>
                    </div>
                  </div>
                  <div className='row pt-3'>
                    <div className='col-md-6'>
                      <div className='pt-3'>
                        <label>Gender</label>
                      </div>
                      <div className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='gender'
                          id='inlineRadio1'
                          value='M'
                          onChange={handleChange}
                          required
                          checked={formData.gender == 'M'}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='inlineRadio1'
                        >
                          Male
                        </label>
                      </div>
                      <div className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='gender'
                          id='inlineRadio2'
                          value='F'
                          onChange={handleChange}
                          required
                          checked={formData.gender == 'F'}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='inlineRadio2'
                        >
                          Female
                        </label>
                      </div>
                    </div>
                    <div className='col-md-6 row'>
                      <label className=''>Height</label>
                      <div className='form-inline row'>
                        <div className='form-group col-sm-4'>
                          <label htmlFor='feet' style={{ fontSize: '14px' }}>
                            Feet:
                          </label>
                          <select
                            className='form-control form-control-sm'
                            id='feet'
                            onChange={handleHeightChange}
                            required
                            name='feet'
                            value={height.feet}
                          >
                            <option selected disabled value=''>
                              Select
                            </option>
                            <option value={1}>1 feet</option>
                            <option value={2}>2 feet</option>
                            <option value={3}>3 feet</option>
                            <option value={4}>4 feet</option>
                            <option value={5}>5 feet</option>
                            <option value={6}>6 feet</option>
                            <option value={7}>7 feet</option>
                            <option value={8}>8 feet</option>
                          </select>
                        </div>
                        <div className='form-group col-sm-4'>
                          <label htmlFor='inches' style={{ fontSize: '14px' }}>
                            Inches:
                          </label>
                          <select
                            className='form-control form-control-sm '
                            id='inches'
                            name='inches'
                            required
                            onChange={handleHeightChange}
                            value={height.inches}
                          >
                            <option selected disabled value=''>
                              Select
                            </option>
                            <option value={0}>0 inches</option>
                            <option value={1}>1 inch</option>
                            <option value={2}>2 inches</option>
                            <option value={3}>3 inches</option>
                            <option value={4}>4 inches</option>
                            <option value={5}>5 inches</option>
                            <option value={6}>6 inches</option>
                            <option value={7}>7 inches</option>
                            <option value={8}>8 inches</option>
                            <option value={9}>9 inches</option>
                            <option value={10}>10 inches</option>
                            <option value={11}>11 inches</option>
                            <option value={12}>12 inches</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row pt-3'>
                    <div className='form-group col-md-6'>
                      <label htmlFor='phone'>Telephone</label>
                      <InputMask
                        className='form-control form-control-sm'
                        id='phone'
                        name='phone'
                        onChange={handleChange}
                        required
                        mask='(999) 999-9999'
                        placeholder='(999) 999-9999'
                        value={formData.phone}
                      ></InputMask>
                      <div className='invalid-feedback'>
                        Invalid phone number
                      </div>
                    </div>
                  </div>
                  <div className='row pt-3'>
                    <div className='form-group col-md-6'>
                      <label htmlFor='vein_grade_left'>
                        Vein Grade (Left Arm)
                      </label>
                      <select
                        className='form-select form-select-sm'
                        id='vein_grade_left'
                        name='vein_grade_left'
                        onChange={handleChange}
                        value={formData.vein_grade_left}
                      >
                        <option selected disabled value=''>
                          Select
                        </option>
                        <option value={'Orange'}>Orange</option>
                        <option value={'Green'}>Green</option>
                        <option value={'Unsuitable'}>Unsuitable</option>
                      </select>
                      <div className='invalid-feedback'>
                        Please select Vein Grade
                      </div>
                    </div>
                    <div className='form-group col-md-6'>
                      <label htmlFor='vein_grade_right'>
                        Vein Grade (Right Arm)
                      </label>
                      <select
                        className='form-select form-select-sm'
                        id='vein_grade_right'
                        name='vein_grade_right'
                        onChange={handleChange}
                        value={formData.vein_grade_right}
                      >
                        <option selected disabled value=''>
                          Select
                        </option>
                        <option value={'Orange'}>Orange</option>
                        <option value={'Green'}>Green</option>
                        <option value={'Unsuitable'}>Unsuitable</option>
                      </select>
                      <div className='invalid-feedback'>
                        Please select Vein Grade
                      </div>
                    </div>
                  </div>
                  <div className='row pt-3'>
                    <div className='form-group col-md-6'>
                      <label htmlFor='inputAddress1'>Address 1</label>
                      <input
                        type='text'
                        className='form-control form-control-sm'
                        id='inputAddress1'
                        placeholder='Apartment, studio, or floor'
                        name='addr1'
                        onChange={handleChange}
                        required
                        maxLength={254}
                        value={formData.addr1 ? formData.addr1 : ''}
                      />
                      <div className='invalid-feedback'>
                        Primary address cannot be blank
                      </div>
                    </div>
                    <div className='form-group col-md-6'>
                      <label htmlFor='inputAddress2'>Address 2</label>
                      <input
                        type='text'
                        className='form-control form-control-sm'
                        id='inputAddress2'
                        placeholder='Street, Lane'
                        name='addr2'
                        onChange={handleChange}
                        maxLength={254}
                        value={formData.addr2 ? formData.addr2 : ''}
                      />
                    </div>
                  </div>

                  <div className='row pt-3'>
                    <div className='form-group col-md-6'>
                      <label htmlFor='country'>Country</label>
                      <select
                        id='country'
                        className='form-control form-control-sm'
                        name='country'
                        onChange={handleChange}
                        disabled
                      >
                        <option defaultValue={'USA'} selected disabled>
                          USA
                        </option>
                      </select>
                    </div>
                    <div className='form-group col-md-6'>
                      <label htmlFor='state'>State</label>
                      <select
                        id='state'
                        className={`form-select form-select-sm`}
                        name='state'
                        onChange={handleChange}
                        required
                        value={formData.state}
                      >
                        <option selected disabled value=''>
                          Select
                        </option>
                        {Object.keys(StateCities).map(state => {
                          return <option key={state}>{state}</option>
                        })}
                      </select>
                      <div className='invalid-feedback'>
                        Please select state
                      </div>
                    </div>
                  </div>
                  <div className='row pt-3'>
                    <div className='form-group col-md-6'>
                      <label htmlFor='city'>City</label>
                      <input
                        type='text'
                        id='city'
                        className={`form-control form-control-sm`}
                        name='city'
                        onChange={handleChange}
                        required
                        maxLength={100}
                        value={formData.city}
                      />
                      <div className='invalid-feedback'>Please select city</div>
                    </div>
                    <div className='form-group col-md-6'>
                      <label htmlFor='inputZip'>ZipCode</label>
                      <input
                        type='text'
                        className='form-control form-control-sm'
                        id='inputZip'
                        name='zipcode'
                        required
                        pattern='^[0-9]{5}(?:-[0-9]{4})?$'
                        onChange={handleChange}
                        maxLength={5}
                        minLength={5}
                        value={formData.zipcode}
                      />
                      <div className='invalid-feedback'>
                        ZipCode is blank / incorrect
                      </div>
                    </div>
                  </div>
                  <div className='row pt-3'>
                    <div className='col-md-6'>
                      <div className='pt-3'>
                        <label id='verified_address'>
                          Verified as Suitable Address
                        </label>
                      </div>
                      <div className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='address_verified'
                          id='inlineRadio1'
                          value='Yes'
                          onChange={handleChange}
                          required
                          checked={formData.address_verified == 'Yes'}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='verified_address'
                        >
                          Yes
                        </label>
                      </div>
                      <div className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='address_verified'
                          id='inlineRadio2'
                          value='No'
                          onChange={handleChange}
                          required
                          checked={formData.address_verified == 'No'}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='verified_address'
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='row pt-3'>
                    <div className='form-group col-md-6'>
                      <label htmlFor='donor_status'>Donor Status</label>
                      <select
                        className='form-select form-select-sm'
                        id='donor_status'
                        name='donor_status'
                        onChange={handleChange}
                        value={formData.donor_status}
                        required
                      >
                        <option selected disabled value=''>
                          Select
                        </option>
                        <option value={'ACTIVE'}>ACTIVE</option>
                        <option value={'TEMPORARILY DEFERRED'}>
                          TEMPORARILY DEFERRED
                        </option>
                        <option value={'TEMPORARILY DEFERRED (Abnormal SPE)'}>
                          TEMPORARILY DEFERRED (Abnormal SPE)
                        </option>
                        <option value={'PERMANENTLY DEFERRED'}>
                          TEMPORARILY DEFERRED
                        </option>
                      </select>
                      <div className='invalid-feedback'>
                        Please select donor status
                      </div>
                    </div>
                    <div className='form-group col-md-6'>
                      <label htmlFor='donor_type'>Donor Type</label>
                      <select
                        className='form-select form-select-sm'
                        id='donor_type'
                        name='donor_type'
                        onChange={handleChange}
                        value={formData.donor_type}
                        required
                      >
                        <option selected disabled value=''>
                          Select
                        </option>
                        <option value={'NEW'}>NEW</option>
                        <option value={'APPLICANT 1'}>APPLICANT 1</option>
                        <option value={'APPLICANT 2'}>APPLICANT 2</option>
                        <option value={'QUALIFIED'}>QUALIFIED</option>
                      </select>
                      <div className='invalid-feedback'>
                        Please select donor type
                      </div>
                    </div>
                  </div>
                  <div className='row pt-3'>
                    <div className='form-group col-md-6'>
                      <label htmlFor='nddr'>NDDR Transaction ID</label>
                      <input
                        type='text'
                        className='form-control form-control-sm'
                        id='nddr'
                        placeholder='NDDR transaction ID'
                        name='nddr'
                        onChange={handleChange}
                        maxLength={254}
                        value={formData.nddr ? formData.nddr : ''}
                      />
                      <div className='invalid-feedback'>
                        Please input NDDR transaction id
                      </div>
                    </div>
                    <div className='form-group col-md-6'>
                      <label htmlFor='nddr_check'>NDDR Check</label>
                      <select
                        className='form-select form-select-sm'
                        id='nddr_check'
                        name='nddr_check'
                        onChange={handleChange}
                        value={formData.nddr_check}
                      >
                        <option selected disabled value=''>
                          Select
                        </option>
                        <option value={'Pass'}>Pass</option>
                        <option value={'Fail'}>Fail</option>
                      </select>
                      <div className='invalid-feedback'>
                        Please select nddr check
                      </div>
                    </div>
                  </div>
                  <div className='row pt-3'>
                    <div className='form-group col-md-6'>
                      <label htmlFor='nddr_date'>NDDR Transaction Date</label>
                      <input
                        type='date'
                        className='form-control form-control-sm'
                        id='nddr_date'
                        name='nddr_date'
                        onChange={handleChange}
                        value={
                          formData.nddr_date
                            ? formData.nddr_date.slice(0, 10)
                            : ''
                        }
                      />
                      <div className='invalid-feedback'>
                        Please enter NDDR date
                      </div>
                    </div>
                    <div className='form-group col-md-6'>
                      <label htmlFor='spe_sample_date'>
                        Last SPE Sample Date
                      </label>
                      <input
                        type='date'
                        className='form-control form-control-sm'
                        id='spe_sample_date'
                        name='spe_sample_date'
                        onChange={handleChange}
                        required
                        value={
                          formData.spe_sample_date
                            ? formData.spe_sample_date.slice(0, 10)
                            : ''
                        }
                      />
                      <div className='invalid-feedback'>
                        Please enter last spe sample date
                      </div>
                    </div>
                  </div>
                  <div className='row pt-3'>
                    <div className='form-group col-md-6'>
                      <label htmlFor='last_full_physical_date'>
                        Last Full Physical Date
                      </label>
                      <input
                        type='date'
                        className='form-control form-control-sm'
                        id='last_full_physical_date'
                        name='last_full_physical_date'
                        onChange={handleChange}
                        value={
                          formData.last_full_physical_date
                            ? formData.last_full_physical_date.slice(0, 10)
                            : ''
                        }
                      />
                      <div className='invalid-feedback'>
                        Please enter Last Full Physical Date
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className={styles.webcamcontainer}>
                    <div className={styles.webcamOutput}>
                      {image == '' && enableCamera ? (
                        <Webcam
                          audio={false}
                          height={240}
                          ref={webcamRef}
                          screenshotFormat='image/jpeg'
                          width={220}
                          videoConstraints={videoConstraints}
                        />
                      ) : (
                        <img src={image ? image : NoImage} width='150'></img>
                      )}
                    </div>
                    {picError ? (
                      <span className='text-danger'>Please capture photo</span>
                    ) : null}
                    <div className={styles.captureButtonsContainer}>
                      <button
                        onClick={e => {
                          setEnablecamera(true)
                          setImage('')
                          e.preventDefault()
                        }}
                        disabled={enableCamera && image == ''}
                        className='btn btn-outline-dark btn-sm'
                      >
                        {image == '' ? 'Start' : 'Retake'}
                      </button>
                      <button
                        onClick={e => {
                          e.preventDefault()
                          capture()
                        }}
                        disabled={image != '' || !enableCamera}
                        className='btn btn-outline-dark btn-sm'
                      >
                        Capture
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className='btn btn-primary btn-rounded mt-4'
                type='submit'
              >
                Submit
              </button>
              <button
                className='btn btn-danger btn-rounded mt-4 mx-4'
                onClick={handleCancel}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      ) : (
        'Loading...'
      )}
      <Modal
        show={showModal}
        backdrop='static'
        keyboard={false}
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <Modal.Title>Please Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Unsaved data will be lost. Do you still want to cancel?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
            size='sm'
            style={{ width: '50px' }}
            onClick={() => props.setEdit(false)}
          >
            Yes
          </Button>
          <Button
            variant='primary'
            size='sm'
            style={{ width: '50px' }}
            onClick={() => setShowModal(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DonorUpdate)
