import React, { useState } from "react";
import styles from "../styles/Search.module.css";
import norecordsImage from "../images/no-results.png";
import { connect } from "react-redux";
import * as actions from "../redux/actions";
import { bindActionCreators } from "redux";
import { formatDate, formatPhoneNumber } from "../utilities/format";
import { useHistory } from "react-router-dom";
import { msalFetch } from "../utilities/msalFetch";

function Search(props) {
  const [searchData, setSearchdata] = useState({});
  const [isvalid, setIsvalid] = useState(true);
  const [records, setRecords] = useState([]);
  const [noRecords, setNorecords] = useState(false);

  const history = useHistory();

  const handleinputChange = (e) => {
    setIsvalid(true);
    let prevState = { ...searchData };
    prevState = { ...prevState, [e.target.name]: e.target.value };
    setSearchdata(prevState);
  };

  const redirect = (id) => {
    sessionStorage.setItem("selectedDonorId", id);
    history.push("/donor/dis");
  };

  const validateInput = () => {
    if (Object.keys(searchData) == 0) {
      setIsvalid(false);
      return false;
    } else {
      let temp = false;
      for (let key in searchData) {
        if (searchData[key].length > 0) {
          temp = true;
        }
      }
      if (temp) {
        setIsvalid(true);
        return true;
      } else {
        setIsvalid(false);
        return false;
      }
    }
  };

  const search = (e) => {
    e.preventDefault();
    if (validateInput()) {
      props.actions.setLoading();
      const options = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: props.token,
        },
        body: JSON.stringify(searchData),
      };
      msalFetch("/api/donor/search", options)
        .then(async (res) => {
          const data = await res.json();
          props.actions.clearLoading();
          if (data.length) {
            setRecords(data);
            setNorecords(false);
          } else {
            setNorecords(true);
            setRecords([]);
          }
        })
        .catch((err) => {
          props.actions.clearLoading();
        });
    }
  };

  return (
    <div className="row justify-content-center">
      <div className="col-md-10">
        <div className="row justify-content-center">
          <div className="col-md-6 mt-4">
            <h4 className={styles.searchtitle}>Advanced Search</h4>
            <form
              id="searchform"
              className="needs-validation"
              onSubmit={search}
            >
              <div className="form-group row">
                <label
                  htmlFor="donorid"
                  className="col-sm-2 col-form-label searchLabel"
                >
                  Donor ID
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="donorid"
                    name="donor_id"
                    onChange={handleinputChange}
                  />
                </div>
              </div>
              <div className="form-group row mt-2">
                <label
                  htmlFor="ssn"
                  className="col-sm-2 col-form-label searchLabel"
                >
                  SSN
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="ssn"
                    name="ssn"
                    onChange={handleinputChange}
                  />
                </div>
              </div>
              <div className="form-group row mt-md-2">
                <label
                  htmlFor="fname"
                  className="col-sm-2 col-form-label searchLabel"
                >
                  First Name
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="fname"
                    name="first_name"
                    onChange={handleinputChange}
                  />
                </div>
              </div>
              <div className="form-group row mt-md-2">
                <label
                  htmlFor="lname"
                  className="col-sm-2 col-form-label searchLabel"
                >
                  Last Name
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="lname"
                    name="last_name"
                    onChange={handleinputChange}
                  />
                </div>
              </div>
              <div className="form-group row mt-md-2">
                <label
                  htmlFor="address"
                  className="col-sm-2 col-form-label searchLabel"
                >
                  Address
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="address"
                    name="addr1"
                    onChange={handleinputChange}
                  />
                </div>
              </div>
              <div className="form-group row mt-md-2">
                <label
                  htmlFor="phone"
                  className="col-sm-2 col-form-label searchLabel"
                >
                  Phone
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="phone"
                    name="phone"
                    onChange={handleinputChange}
                  />
                </div>
              </div>
              <div className="form-group row mt-md-2">
                <label
                  htmlFor="bdate"
                  className="col-sm-2 col-form-label searchLabel"
                >
                  Birth Date
                </label>
                <div className="col-sm-7">
                  <input
                    type="date"
                    className="form-control form-control-sm"
                    id="bdate"
                    name="dob"
                    onChange={handleinputChange}
                  />
                </div>
              </div>
              {!isvalid ? (
                <div className={styles.validationError}>
                  ! Atleast one field is required
                </div>
              ) : null}
              <div className="row mt-4">
                <div className="col-md-7">
                  <button className="btn btn-primary">
                    {"Search"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          {records.length ? (
            <div className="col-sm-8">
              <table className="table table-hover table-sm">
                <thead className={styles.tableHeader}>
                  <tr>
                    <th scope="col">Donor Id</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Gender</th>
                    <th scope="col">DOB</th>
                    <th scope="col">Phone</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((record) => {
                    return (
                      <tr
                        className=""
                        onClick={() => redirect(record.donor_id, record)}
                        key={record.donor_id}
                      >
                        <td>{record.donor_id}</td>
                        <td>{record.first_name}</td>
                        <td>{record.last_name}</td>
                        <td>{record.gender == "M" ? "Male" : "Female"}</td>
                        <td>{formatDate(record.dob)}</td>
                        <td>{formatPhoneNumber(record.phone)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
        {noRecords ? (
          <div className="row justify-content-center mt-5">
            <h4 className="text-center">No records found</h4>
            <img
              src={norecordsImage}
              className={styles.noRecords}
              alt="no records"
            ></img>
          </div>
        ) : null}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    username: state.username,
    token: state.token,
    roleid: state.roleid,
    rolename: state.rolename,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
