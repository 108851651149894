export const validate = (formData) => {
    const newErrors = {};

    const requiredFields = {
        classificationOfReaction: "Classification of Reaction is required",
        timeOfReaction: "Time of Reaction is required",
        reactionWasFirstKnownToBegin: "Timing of Reaction is required",
    };

    // Validate required fields
    Object.keys(requiredFields).forEach((field) => {
        if (!formData[field]) {
            newErrors[field] = requiredFields[field];
        }
    });

    // Validate Collection of Volume
    const collectionOfVolumeFields = {
        amountOfProceduralSalineAdministered: "Amount of Procedural Saline Administered is required",
        amountOfCitrateUsed: "Amount of Citrate Used is required",
    };

    const collectionOfVolumeErrors = Object.keys(collectionOfVolumeFields)
        .filter((field) => !formData.collectionOfVolume?.[field]?.trim())
        .reduce((acc, field) => {
            acc[field] = collectionOfVolumeFields[field];
            return acc;
        }, {});

    if (Object.keys(collectionOfVolumeErrors).length > 0) {
        newErrors.collectionOfVolume = collectionOfVolumeErrors;
    }

    // Validate Type of Reaction
    const typeOfReactionFields = {
        category: "Category is required",
        subCategory: "Subcategory is required",
        symptoms: "Please select symptoms",
    };

    const typeOfReactionErrors = Object.keys(typeOfReactionFields)
        .filter((field) => !formData.typeOfReaction?.[field]?.length)
        .reduce((acc, field) => {
            acc[field] = typeOfReactionFields[field];
            return acc;
        }, {});

    if (Object.keys(typeOfReactionErrors).length > 0) {
        newErrors.typeOfReaction = typeOfReactionErrors;
    }

    // Validate Treatment
    const treatmentFields = {
        typesOfTreatment: "Types of Treatment is required",
        oralFluidsGiven: "Oral Fluids Given is required",
        medicationComments: "Please provide medication comments",
        description: "Please provide a description",
    };

    const treatmentErrors = Object.keys(treatmentFields)
        .filter((field) => !formData.treatment?.[field]?.trim())
        .reduce((acc, field) => {
            acc[field] = treatmentFields[field];
            return acc;
        }, {});

    // General error message for medication and vital signs
    if (
        formData.treatment.medication.some((med) =>
            ["type", "lotNumber", "expirationDate", "dosage", "route", "time", "performedBy"].some(
                (field) => !med[field]
            )
        )
    ) {
        treatmentErrors.medication = "All fields in medication are required for each row";
    }

    if (
        formData.treatment.vitalSigns.some((vital) =>
            ["time", "bloodpressure", "pulse", "performedBy"].some(
                (field) => !vital[field]
            )
        )
    ) {
        treatmentErrors.vitalSigns = "All fields in vital signs are required for each row";
    }

    if (Object.keys(treatmentErrors).length > 0) {
        newErrors.treatment = treatmentErrors;
    }

    // Validate Donor Recovery
    const donorRecoveryFields = {
        conditionAtTimeOfRelease: "Condition at time of release is required",
        recoveryPeriod: "Recovery period is required",
        emsActivatedResponse: "EMS activated response is required",
        donorPhoneNumber: "Donor phone number is required",
        medicalDeferral: "Medical deferral status is required",
        donorRefusal: "Donor Refusal is required",
        centerPhysicianNotified: "Center Physician Notified is required",
    };

    const donorRecoveryErrors = Object.keys(donorRecoveryFields)
        .filter((field) => !formData.donorRecoveryAndDesposition?.[field]?.trim())
        .reduce((acc, field) => {
            acc[field] = donorRecoveryFields[field];
            return acc;
        }, {});
    if (
        formData.donorRecoveryAndDesposition?.vitalSigns.some((vital) =>
            ["time", "bloodpressure", "pulse", "performedBy"].some(
                (field) => !vital[field]
            )
        )
    ) {
        donorRecoveryErrors.vitalSigns = "All fields in vital signs are required";
    }


    if (Object.keys(donorRecoveryErrors).length > 0) {
        newErrors.donorRecoveryAndDesposition = donorRecoveryErrors;
    }
    const physicianField = {
        donorStatus: "Donor Status is required",

    };

    const physicianErrors = Object.keys(physicianField)
        .filter((field) => !formData.physician?.[field]?.trim())
        .reduce((acc, field) => {
            acc[field] = physicianField[field];
            return acc;
        }, {});
    // Check if deferralStill date is required when donorStatus is "Temporary Deferral"
    if (formData.physician?.donorStatus === "Temporary Deferral" && !formData.physician?.defferalTill) {
        physicianErrors.defferalTill = "Deferral Still date is required when Donor Status is Temporary Deferral";
    }

    if (Object.keys(physicianErrors).length > 0) {
        newErrors.physician = physicianErrors;
    }

    return newErrors;
};
