import React, { useState, useEffect } from 'react'
import Select from '../../shared/FormInputs/Select'
import TimeInput from '../../shared/FormInputs/TimeInput'
import {
  TimingWhenReactionBegin,
  classificationOfReaction,
  initialData
} from './constant'
import { request } from '../../utilities/msalFetch'
import CollectionOfVolume from './Components/CollectionOfVolume'
import TypeOfReaction from './Components/TypeOfReaction'
import moment from 'moment'
import Treatment from './Components/TreatMent'
import DonorRecovery from './Components/DonorRecovery'
import { validate } from './helper'
import { toast } from 'react-toastify'
import useEmpNames from '../../hooks/useEmpNames'
import DatePicker from '../../shared/FormInputs/DatePicker'

const MIRForm = ({ donorInfo }) => {
  const [formData, setFormData] = useState(initialData)
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const { empNames } = useEmpNames()

  useEffect(() => {
    const { ucn, donor_id, first_name, last_name } = donorInfo
    setFormData(prev => ({
      ...prev,
      ucn: ucn?.ucn,
      donorId: donor_id,
      centerCode: ucn?.center_code,
      dateOfReaction: new Date(ucn?.donation_date),
      donorName: `${first_name} ${last_name}`,
      collectionOfVolume: {
        ...formData.collectionOfVolume,
        volumeOfPlasma: ucn?.plasma_amt_machine
      }
    }))
  }, [donorInfo])

  const handleChangeData = (name, value) => {
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }))
  }

  const reset = () => {
    setFormData(initialData)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const validationErrors = validate(formData)
    setErrors(validationErrors)
    try {
      if (Object.keys(validationErrors).length === 0) {
        setSubmitting(true)
        const res = await request.post('/api/mir/addMir', formData)
        if (res) {
          toast.success('MIR Successfully created')
          reset()
        }
      }
    } catch (error) {
      toast.error('Failed to create MIR')
    } finally {
      setSubmitting(false)
    }
  }

  const renderRecordField = (label, value) => (
    <div className='mb-3'>
      <strong>{label}:</strong> {value}
    </div>
  )

  const formatDate = date => moment(date).format('MM-DD-YYYY')

  return (
    <div className='row d-flex justify-content-center p-4'>
      <div className='col-md-10 col-sm-12'>
        <div className='card'>
          <h2 className='card-title text-center my-4'>
            Medical Incident Report Form
          </h2>
          <form onSubmit={handleSubmit}>
            <fieldset>
              <div className='row mb-2'>
                {renderRecordField('UCN', formData.ucn)}
                {renderRecordField('Center Code', formData.centerCode)}
                {renderRecordField('Donor Name', formData.donorName)}
                {renderRecordField('DonorId', formData.donorId)}
                {renderRecordField(
                  'Date of Reaction',
                  formatDate(formData.dateOfReaction.toString())
                )}
                {renderRecordField(
                  'Volume of Plasma',
                  `${formData.collectionOfVolume.volumeOfPlasma} mL`
                )}
              </div>
            </fieldset>
            <fieldset>
              <div className='row mb-2'>
                <div className='col-3 d-flex flex-column'>
                  <TimeInput
                    label='Time of Reaction'
                    id='timeOfReaction'
                    name='timeOfReaction'
                    value={formData.timeOfReaction}
                    onChange={e => {
                      handleChangeData('timeOfReaction', e.target.value)
                    }}
                    required={true}
                    error={errors.timeOfReaction}
                  />
                </div>

                <div className='col-4 d-flex flex-column'>
                  <Select
                    label='Classification of Reaction'
                    id='classificationOfReaction'
                    name='classificationOfReaction'
                    placeholder='Select'
                    options={classificationOfReaction}
                    value={formData.classificationOfReaction}
                    onChange={e =>
                      handleChangeData(
                        'classificationOfReaction',
                        e.target.value
                      )
                    }
                    required={true}
                    error={errors.classificationOfReaction}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend className='legend'>
                Timing when the reaction was first known to begin
                <span style={{ color: 'red' }}>&#42;</span>
              </legend>
              <div className='row mb-2'>
                <div className='col-4 d-flex flex-column'>
                  <Select
                    id='reactionWasFirstKnownToBegin'
                    name='reactionWasFirstKnownToBegin'
                    placeholder='Select'
                    options={TimingWhenReactionBegin}
                    value={formData.reactionWasFirstKnownToBegin}
                    onChange={e =>
                      handleChangeData(
                        'reactionWasFirstKnownToBegin',
                        e.target.value
                      )
                    }
                    required={true}
                    error={errors.reactionWasFirstKnownToBegin}
                  />
                </div>
              </div>
            </fieldset>

            <CollectionOfVolume
              data={formData.collectionOfVolume}
              onChangeFormData={data =>
                handleChangeData('collectionOfVolume', data)
              }
              errors={errors.collectionOfVolume}
            />

            <TypeOfReaction
              data={formData.typeOfReaction}
              onChangeFormData={data =>
                handleChangeData('typeOfReaction', data)
              }
              errors={errors.typeOfReaction}
            />

            <Treatment
              data={formData.treatment}
              onChangeData={data => handleChangeData('treatment', data)}
              errors={errors.treatment}
              options={{ performedBy: empNames }}
            />
            <DonorRecovery
              data={formData.donorRecoveryAndDesposition}
              onChangeData={data =>
                handleChangeData('donorRecoveryAndDesposition', data)
              }
              errors={errors.donorRecoveryAndDesposition}
              options={{ performedBy: empNames }}
            />

            <fieldset>
              <legend className='legend'>
                PHYSICIAN (moderate/severe) OR Physician Substitute (minor)
                REVIEW
              </legend>

              <div className='row mb-2'>
                <div className='col-3 d-flex flex-column'>
                  <Select
                    id='donorStatus'
                    name='donorStatus'
                    label='Donor Status'
                    options={[
                      'OK to resume donating',
                      'Temporarily Deferred',
                      'Medical Clearance Required',
                      'Permanently Deferred'
                    ]}
                    value={formData.physician.donorStatus}
                    onChange={e =>
                      handleChangeData('physician', {
                        ...formData.physician,
                        donorStatus: e.target.value,
                        defferalTill: ''
                      })
                    }
                    placeholder={'Select'}
                    required={true}
                    error={errors.physician?.donorStatus}
                  />
                </div>
                {formData.physician.donorStatus === 'Temporarily Deferred' && (
                  <div className='col-3 d-flex flex-column'>
                    <DatePicker
                      id='defferalTill'
                      name='defferalTill'
                      label='Defferal Till'
                      value={formData.physician.defferalTill}
                      onChange={e =>
                        handleChangeData('physician', {
                          ...formData.physician,
                          defferalTill: e.target.value
                        })
                      }
                      required={true}
                      error={errors.physician?.defferalTill}
                    />
                  </div>
                )}
              </div>
            </fieldset>

            <div class='d-flex gap-2 mb-2 justify-content-center'>
              <button
                class='btn btn-primary col-md-2 col-sm-6 mx-2'
                disabled={submitting}
                type='submit'
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default MIRForm
