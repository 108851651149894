import React, { useState, useEffect } from 'react'
import { Modal, Tabs } from 'antd'
import { toast } from 'react-toastify'
import { msalFetch } from '../../utilities/msalFetch'
import { connect } from 'react-redux'
import * as actions from '../../redux/actions'
import { bindActionCreators } from 'redux'

import DHQ from './DHQ'
import MedicalScreening from './MedicalScreening'
import Phlebotomy from './Phlebotomy'
import LocationDetails from './LocationDetails'
import LabReport from './LabReport'
import HealthAssessment from './HealthAssessment'

function DonationDetails (props) {
  const [donationDetails, setDonationDetails] = useState(undefined)
  const [locationDetails, setLocationDetails] = useState(undefined)
  const [dhqDetails, setDhqDetails] = useState(undefined)
  const [questions, setQuestions] = useState([])
  const [answers, setAnswers] = useState({})
  const [questionIds, setQuestionIds] = useState([])
  const [labresults, setLabresults] = useState(undefined)
  const [physicalExamQuestions, setPhysicalExamQuestions] = useState([])
  const [updateLabResultLogs, setUpdateLabResultLogs] = useState([])
  const [healthAssessment, setHealthAssessment] = useState(undefined)
  const [updateLabResultFormData, setUdateLabResultFormData] = useState({})
  const [approvalDetails, setApprovalDetails] = useState(undefined)

  const getDonationDetails = () => {
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      }
    }
    msalFetch(`/api/donor/getDRDbyUCN/${props.donationDetails.ucn}`, options)
      .then(async response => {
        const data = await response.json()
        if (response.ok && data.length) {
          setDonationDetails(data[0])
        } else {
          setDonationDetails({})
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getCasePackingDetails = ucn => {
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      }
    }
    msalFetch(`/api/caseShipping/getLocationDetails/${ucn}`, options)
      .then(async res => {
        const data = await res.json()
        if (data.length) setLocationDetails(data[0])
        else setLocationDetails({})
      })
      .catch(err => {
        toast.error(err.message)
        console.log(err.message)
      })
  }

  const getDHQ = () => {
    let ucn = props.donationDetails.ucn
    let donorId = props.donationDetails.donor_id
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      },
      body: JSON.stringify({ ucn, donorId })
    }
    msalFetch('/api/donor/getDHQAnswers', options).then(async response => {
      const data = await response.json()
      console.log(data)
      if (data.length > 0) {
        setDhqDetails(data[0])
        let answers = JSON.parse(data[0].ANSWERS)
        setAnswers(answers)
        let dhqType = data[0].DHQ_TYPE
        const options = {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: props.token
          }
        }
        const url = `/api/donor/getDHQQuestions/${dhqType}`
        msalFetch(url, options).then(async res => {
          if (res.ok) {
            const data = await res.json()
            let questionHeads = []
            let questionIds = []
            data.forEach(x => {
              if (answers[x.QUESTION_ID]) {
                questionIds.push(x.QUESTION_ID)
                if (questionHeads.indexOf(x.QUESTION_HEAD) == -1) {
                  questionHeads.push(x.QUESTION_HEAD)
                }
              }
            })
            let finalArray = []
            questionHeads.forEach(x => {
              let obj = {}
              let questions = []
              obj.questionHead = x
              data.forEach(y => {
                let questionObj = {}
                if (y.QUESTION_HEAD == x) {
                  questionObj.id = y.QUESTION_ID
                  questionObj.question = y.QUESTION
                  questionObj.expectedAnswer = y.USUAL_ANSWER
                  questions.push(questionObj)
                }
              })
              obj.questions = questions
              finalArray.push(obj)
            })
            setQuestions(finalArray)
            setQuestionIds(questionIds)
          }
        })
      } else {
        setDhqDetails({})
      }
    })
  }

  const fetchLabResultChangeLogs = ucn => {
    const options = {
      method: 'get',
      mode: 'cors'
    }
    msalFetch(`/api/donor/getChangeLogs/${ucn}/LAB_RESULT`, options).then(
      async res => {
        if (res.ok) {
          const data = await res.json()
          if (data.length) {
            setUpdateLabResultLogs(data)
          }
        } else {
          toast.error('Failed to fetch lab result change logs')
        }
      }
    )
  }

  const getLabResults = () => {
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      },
      body: JSON.stringify({ ucn: props.donationDetails.ucn })
    }
    msalFetch('/api/donor/fetchLabResults', options)
      .then(async res => {
        const data = await res.json()
        setLabresults(data)
        const { physub_review, physub_review_date, qa_review, qa_review_date } =
          data
        if (data) {
          setApprovalDetails({
            physub_review,
            physub_review_date,
            qa_review,
            qa_review_date,
            success: true
          })
          setUdateLabResultFormData({ labResult: data?.Lab_Result })
        }
      })
      .catch(err => {
        toast.error(err.message)
      })
  }
  const getPhysicalExaminationQuestions = () => {
    //props.actions.setLoading()
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      }
    }
    msalFetch('/api/donor/getPhysicalExaminationQuestions', options)
      .then(async res => {
        if (res.ok) {
          const questions = await res.json()
          setPhysicalExamQuestions(questions)
        }
        //props.actions.clearLoading()
      })
      .catch(err => {
        //props.actions.clearLoading()
        toast.error(err?.message)
      })
  }

  const getHealthAssessment = ucn => {
    //props.actions.setLoading()
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      }
    }
    msalFetch(`/api/donor/getHealthAssessmentByUCN/${ucn}`, options)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          if (data.length) {
            setHealthAssessment(data[0])
          } else {
            setHealthAssessment({})
          }
        } else {
          setHealthAssessment({})
        }
        //props.actions.clearLoading()
      })
      .catch(err => {
        //props.actions.clearLoading()
        setHealthAssessment({})
        toast.error(err?.message)
      })
  }

  useEffect(() => {
    props.donationDetails.ucn && getDonationDetails()
    props.donationDetails.ucn && getDHQ()
    props.donationDetails.ucn && getLabResults()
    props.donationDetails.ucn &&
      props.donationDetails.status !== 'Pending' &&
      getCasePackingDetails(props.donationDetails.ucn)
    props.donationDetails.ucn && getPhysicalExaminationQuestions()
    props.donationDetails.ucn && getHealthAssessment(props.donationDetails.ucn)
    props.donationDetails.ucn &&
      fetchLabResultChangeLogs(props.donationDetails.ucn)
  }, [props.donationDetails, props.donationDetails.ucn])

  const items = [
    {
      key: '1',
      label: `DHQ`,
      children: (
        <DHQ dhqDetails={dhqDetails} questions={questions} answers={answers} />
      )
    },
    {
      key: '2',
      label: `Medical Screening`,
      children: (
        <MedicalScreening
          donationDetails={donationDetails}
          donorInfo={props.donorInfo}
        />
      )
    },
    {
      key: '3',
      label: 'Health Assessment',
      children: (
        <HealthAssessment
          healthAssessment={healthAssessment}
          physicalExamQuestions={physicalExamQuestions}
          donorInfo={props.donorInfo}
        />
      )
    },
    {
      key: '5',
      label: `Phlebotomy`,
      children: (
        <Phlebotomy
          donationDetails={donationDetails}
          donorInfo={props.donorInfo}
        />
      )
    },
    {
      key: '6',
      label: `Location Details`,
      children: (
        <LocationDetails
          locationDetails={locationDetails}
          donationDetails={donationDetails}
        />
      )
    },
    {
      key: '7',
      label: `Lab Report`,
      children: (
        <LabReport
          updateLabResultLogs={updateLabResultLogs}
          labresults={labresults}
          updateLabResultFormData={updateLabResultFormData}
          approvalDetails={approvalDetails}
          setUdateLabResultFormData={setUdateLabResultFormData}
          donationDetails={donationDetails}
          roleid={props.roleid}
          setVisible={props.setVisible}
        />
      )
    }
  ]

  return (
    <React.Fragment>
      <div className='row'>
        <div className='col-sm-12 col-md-10'>
          <Modal
            title='Donation Details'
            centered
            open={true}
            onOk={() => props.setVisible(false)}
            onCancel={() => props.setVisible(false)}
            width={'80%'}
            footer={null}
            styles={{
              body: {
                minHeight: '700px',
                overflowY: 'scroll',
                maxHeight: '600px',
                padding: 10,
                overflowX: 'hidden'
              }
            }}
          >
            <Tabs items={items}></Tabs>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    roleid: state.roleid
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DonationDetails)
