import React, { useEffect, useState } from "react";
import Select from "../../../shared/FormInputs/Select";
import { getSubCategory, getSymptoms, typeOfReaction } from "../constant";

const TypeOfReaction = ({ data, onChangeFormData, errors = {} }) => {
  const [subCategories, setSubCategories] = useState([]);
  const [symptoms, setSymptoms] = useState([]);

  const handleCategoryChange = (value) => {
    const newSubCategories = getSubCategory(value);
    setSubCategories(newSubCategories);
    setSymptoms([]);
    onChangeFormData({
      ...data,
      category: value,
      subCategory: "",
      symptoms: [],
    });
  };

  const handleSubCategoryChange = (value) => {
    const newSymptoms = getSymptoms(data.category, value);
    setSymptoms(newSymptoms);
    onChangeFormData({ ...data, subCategory: value, symptoms: [] });
  };

  const handleSymptomChange = (e) => {
    const { value, checked } = e.target;
    onChangeFormData({
      ...data,
      symptoms: checked
        ? [...data.symptoms, value]
        : data.symptoms.filter((symptom) => symptom !== value),
    });
  };

  return (
    <fieldset>
      <legend className="legend">Type of Reaction</legend>
      <div className="row mb-2">
        <div className="col d-flex flex-column">
          <Select
            id="category"
            name="category"
            label="Category"
            options={typeOfReaction}
            placeholder="Select"
            value={data.category}
            onChange={(e) => handleCategoryChange(e.target.value)}
            required={true}
            error={errors.category}
          />
        </div>
        <div className="col d-flex flex-column">
          <Select
            id="subCategory"
            name="subCategory"
            label="Sub-Category"
            options={subCategories}
            placeholder="Select"
            value={data.subCategory}
            onChange={(e) => handleSubCategoryChange(e.target.value)}
            required={true}
            error={errors.subCategory}
            disabled={!data.category}
          />
        </div>
      </div>
      <div className="row">
        {data.subCategory && symptoms.length > 0 && (
          <label htmlFor="">Symptoms</label>
        )}
        {data.subCategory &&
          symptoms?.map((symptom, index) => (
            <div className="col-6" key={symptom}>
              <div className="form-check">
                <input
                  type="checkbox"
                  id={`symptom-${index}`}
                  className="form-check-input"
                  value={symptom}
                  checked={data.symptoms.includes(symptom)}
                  onChange={handleSymptomChange}
                />
                <label
                  className="form-check-label"
                  htmlFor={`symptom-${index}`}
                >
                  {symptom}
                </label>
              </div>
            </div>
          ))}
        {errors.symptoms && data.category && data.subCategory && (
          <div className="text-danger">{errors.symptoms}</div>
        )}
      </div>
    </fieldset>
  );
};

export default TypeOfReaction;
