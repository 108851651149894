import styles from '../styles/Search.module.css'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import { useHistory } from 'react-router-dom'
import DonationDetails from '../modules/DonationDetails'
import { formatDate } from '../utilities/format'
import { Button, Select, DatePicker } from 'antd'
import moment from 'moment'
import { msalFetch } from '../utilities/msalFetch'
import { Table, Tag, Descriptions } from 'antd'

const { Option } = Select
const { RangePicker } = DatePicker

const columns = [
  {
    title: 'UCN',
    dataIndex: 'ucn',
    key: 'ucn'
  },
  {
    title: 'Donor ID',
    dataIndex: 'donor_id',
    key: 'donor_id'
  },
  {
    title: 'Donation Date',
    dataIndex: 'donation_date',
    key: 'donation_date',
    sorter: (a, b) =>
      moment(b.donation_date).isValid
        ? moment(a.donation_date, 'MM/DD/YYYY').valueOf() -
          moment(b.donation_date, 'MM/DD/YYYY').valueOf()
        : moment(a.donation_date).valueOf() - moment('12/12/9999', 'MM/DD/YYYY')
  },
  {
    title: 'Plasma Volume (mL)',
    dataIndex: 'plasma_amt_machine',
    key: 'plasma_amt_machine',
    sorter: (a, b) => a.plasma_amt_machine - b.plasma_amt_machine
  },
  {
    title: 'Total Volume (mL)',
    dataIndex: 'collection_amt_machine',
    key: 'collection_amt_machine',
    sorter: (a, b) => a.collection_amt_machine - b.collection_amt_machine
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    filters: [
      {
        text: 'Pending',
        value: 'Pending'
      },
      {
        text: 'Pending QA Review',
        value: 'Pending QA Review'
      },
      {
        text: 'QA Reviewed',
        value: 'QA Reviewed'
      },
      {
        text: 'Quarantined',
        value: 'Quarantined'
      }
    ],
    onFilter: (value, record) => record.status === value,
    render: (_, { status }) => {
      switch (status) {
        case 'Pending':
          return <Tag color='geekblue'>{status}</Tag>
        case 'Pending QA Review':
          return <Tag color='purple'>{status}</Tag>
        case 'QA Reviewed':
          return <Tag color='green'>{status}</Tag>
        case 'Quarantined':
          return <Tag color='red'>{status}</Tag>
      }
    }
  }
]

const QADonationsPage = props => {
  const [donations, setDonations] = useState([])
  const history = useHistory()
  const [selectedDonation, setSelectedDonation] = useState({})
  const [donationModalVisible, setDonationModalVisible] = useState(false)
  const [statuses] = useState([
    'Pending',
    'Pending QA Review',
    'QA Reviewed',
    'Quarantined'
  ])
  const [dateBy, setDateBy] = useState('TDY')
  const [dateRange, setDateRange] = useState({
    fromDate: moment().format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD')
  })

  useEffect(() => {
    props.actions.setLoading()
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      }
    }
    msalFetch(
      `/api/donor/getDonations?fromDate=${moment().format(
        'YYYY-MM-DD'
      )}&toDate=${moment().format('YYYY-MM-DD')}`,
      options
    )
      .then(async res => {
        props.actions.clearLoading()
        let data = await res.json()
        data = data.map(x => {
          let temp = { ...x }
          temp.donation_date = moment(x.donation_date).isValid()
            ? formatDate(x.donation_date)
            : ''
          temp.collection_amt_machine =
            temp.collection_amt_machine && temp.collection_amt_machine > 0
              ? temp.collection_amt_machine
              : 0
          return temp
        })
        setDonations(data)
      })
      .catch(err => {
        props.actions.clearLoading()
        toast.error(err)
      })
  }, [])

  const handleDonationClick = item => {
    /*  if(item.status=="QA Reviewed"){
            setDonationModalVisible(true);
            setSelectedDonation(item);
        }
        else{
            sessionStorage.setItem('selectedDonorId',item.donor_id);
            sessionStorage.setItem('selectedUCN',item.ucn);
            history.push('/donor/dis')
        } */
    sessionStorage.setItem('selectedDonorId', item.donor_id)
    sessionStorage.setItem('selectedUCN', item.ucn)
    history.push('/donor/dis')
  }

  const handleDateBy = value => {
    let dateRangeObj = {}
    const today = moment()
    if (value == 'TDY') {
      dateRangeObj.fromDate = today.format('YYYY-MM-DD')
      dateRangeObj.toDate = today.format('YYYY-MM-DD')
    } else if (value == 'LW') {
      dateRangeObj.toDate = today.format('YYYY-MM-DD')
      dateRangeObj.fromDate = today.subtract(1, 'week').format('YYYY-MM-DD')
    } else if (value == 'LM') {
      dateRangeObj.toDate = today.format('YYYY-MM-DD')
      dateRangeObj.fromDate = today.subtract(1, 'month').format('YYYY-MM-DD')
    } else if (value == 'LSM') {
      dateRangeObj.toDate = today.format('YYYY-MM-DD')
      dateRangeObj.fromDate = today.subtract(6, 'month').format('YYYY-MM-DD')
    } else if (value == 'YTD') {
      dateRangeObj.toDate = today.subtract(1, 'day').format('YYYY-MM-DD')
      dateRangeObj.fromDate = today.format('YYYY-MM-DD')
    } else {
      setDateRange({})
    }
    setDateBy(value)
    setDateRange(dateRangeObj)
  }

  const handleDateRangeChange = (dates, dateStrings, info) => {
    const [fromdate, todate] = dateStrings
    setDateRange({
      fromDate: moment(fromdate).format('YYYY-MM-DD'),
      toDate: moment(todate).format('YYYY-MM-DD')
    })
  }

  const getDaonationsByDateRange = () => {
    props.actions.setLoading()
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      }
    }
    msalFetch(
      `/api/donor/getDonations?fromDate=${dateRange.fromDate}&toDate=${dateRange.toDate}`,
      options
    )
      .then(async res => {
        props.actions.clearLoading()
        let data = await res.json()
        data = data.map(x => {
          let temp = { ...x }
          temp.donation_date = moment(x.donation_date).isValid()
            ? formatDate(x.donation_date)
            : ''
          temp.collection_amt_machine =
            temp.collection_amt_machine && temp.collection_amt_machine > 0
              ? temp.collection_amt_machine
              : 0
          return temp
        })
        setDonations(data)
      })
      .catch(err => {
        props.actions.clearLoading()
        toast.error(err)
      })
  }

  const calculateTotalDonations = () => {
    /* const completedDonations = donations.filter(x => {
      return (
        x.collection_amt_machine > 0 &&
        !Boolean(x.no_donation) &&
        x.status !== 'Pending'
      )
    }) */
    return <h5>{donations.length}</h5>
  }

  const calculateDonationsInProgress = () => {
    const donationsInProgress = donations.filter(x => {
      return (
        !x.collection_amt_machine &&
        !Boolean(x.no_donation) &&
        x.status === 'Pending'
      )
    })
    return <h5>{donationsInProgress.length}</h5>
  }

  const calculateTotalVolume = () => {
    const totalVolume =
      donations &&
      donations.length &&
      donations.reduce((acc, item) => {
        if (item.collection_amt_machine && item.collection_amt_machine > 0) {
          return acc + parseInt(item.collection_amt_machine)
        } else {
          return acc
        }
      }, 0)
    return <h5>{totalVolume / 1000} Litres</h5>
  }

  const calculateCancelledDonations = () => {
    const cancelledDonationsList = donations.filter(x => {
      return !x.collection_amt_machine
    })
    return <h5>{cancelledDonationsList.length}</h5>
  }

  const analyticsItems = [
    {
      key: '1',
      label: 'Total Donations',
      children: calculateTotalDonations()
    },
    {
      key: '3',
      label: 'No Donations',
      children: calculateCancelledDonations()
    },
    {
      key: '3',
      label: 'Total Volume',
      children: calculateTotalVolume()
    }
  ]

  return (
    <React.Fragment>
      <div className='row justify-content-center mt-3'>
        <div className='col-md-10 mt-4'>
          <div className='row'>
            <p className={`col-sm-4 offset-1 ${styles.donationsTitle}`}>
              Donations
            </p>
          </div>
          <div className='row mt-2'>
            <div className='col-md-8 offset-1 d-flex align-items-center'>
              <label>Transaction Date : </label>
              <div className='mx-4'>
                <Select
                  placeholder='Transaction Duration'
                  onChange={handleDateBy}
                  value={dateBy}
                  style={{ width: '160px' }}
                >
                  <Option value='TDY'>Today</Option>
                  <Option value='YTD'>Yesterday</Option>
                  <Option value='LW'>Last Week</Option>
                  <Option value='LM'>Last Month</Option>
                  <Option value='LSM'>Last Six Months</Option>
                  <Option value='RNG'>Date Range</Option>
                </Select>
              </div>
              {dateBy == 'RNG' ? (
                <RangePicker
                  style={{ marginRight: '24px' }}
                  format='DD-MMM-YYYY'
                  onCalendarChange={handleDateRangeChange}
                ></RangePicker>
              ) : null}
              <div>
                <Button type='primary' onClick={getDaonationsByDateRange}>
                  Apply
                </Button>
              </div>
            </div>
          </div>
          {donations && donations.length ? (
            <div className='row my-2'>
              <div className='col-sm-10 offset-1'>
                <Descriptions
                  items={analyticsItems}
                  size='small'
                  bordered
                ></Descriptions>
              </div>
            </div>
          ) : null}
          <div className='row mt-2'>
            <div className='col-sm-10 offset-1'>
              <Table
                dataSource={donations}
                columns={columns}
                size='middle'
                onRow={record => {
                  return {
                    onClick: e => {
                      handleDonationClick(record)
                    }
                  }
                }}
                rowClassName={styles.antdTableRow}
              />
            </div>
          </div>
        </div>
      </div>
      {donationModalVisible ? (
        <DonationDetails
          setVisible={setDonationModalVisible}
          donationDetails={selectedDonation}
          token={props.token}
        ></DonationDetails>
      ) : null}
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token,
    isAuthenticated: state.isAuthenticated
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QADonationsPage)
