import moment from "moment";
import { Calendar } from "primereact/calendar";
import React from "react";

const TimeInput = ({
  id,
  name,
  label,
  value,
  onChange,
  error,
  placeholder,
  required,
}) => {
  const today = moment().startOf("day");

  const formattedDate = moment(today)
    .set({
      hour: moment(value, "HH:mm:ss").hour(),
      minute: moment(value, "HH:mm:ss").minute(),
      second: moment(value, "HH:mm:ss").second(),
    })
    .toDate();

  return (
    <>
      {label && (
        <label htmlFor={id}>
          {label} {required && <span style={{ color: "red" }}>&#42;</span>}
        </label>
      )}
      <Calendar
        id={id}
        name={name}
        inputClassName="form-control"
        value={value && formattedDate}
        onChange={(e) => {
          const val = moment(e.target.value).format("HH:mm:ss");
          onChange({ target: { value: val, name } });
        }}
        placeholder={placeholder}
        timeOnly
        hourFormat="12"
        autoZIndex={true}
        baseZIndex={"100"}
      />
      {error && <div className="text-danger">{error}</div>}
    </>
  );
};

export default TimeInput;
