import { Button, Select } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { msalFetch } from '../../../utilities/msalFetch'
import { toast } from 'react-toastify'
import { Table, Tag, Modal } from 'antd'
import { formatDate } from '../../../utilities/format'
import UpdateEquipmentDetails from './UpdateEquipmentDetails'

function EquipmentManagement (props) {
  const [centers, setCenters] = useState([])
  const [centerCode, setCenterCode] = useState('')
  const [records, setRecords] = useState([])
  const [openEquipmentUpdate, setOpenEquipmentUpdate] = useState(false)
  const [updateRecord, setUpdateRecord] = useState({})

  const getAllCenters = () => {
    props.setLoading()
    const options = {
      method: 'GET',
      headers: {
        Authorization: props.token,
        'Content-Type': 'application/json'
      }
    }
    msalFetch('/api/centerManagement/getCenters', options)
      .then(async res => {
        props.clearLoading()
        if (res.ok) {
          const data = await res.json()
          setCenters(data)
          props.clearLoading()
        } else {
          toast.error('Failed to get all centers')
        }
      })
      .catch(err => {
        toast.error('Failed to get all centers')
        props.clearLoading()
        console.log(err)
      })
  }

  useEffect(() => {
    getAllCenters()
  }, [])

  const getCenters = useCallback(() => {
    if (centers.length) {
      return centers.map(x => {
        return {
          label: x.center_code,
          value: x.center_code
        }
      })
    } else {
      return []
    }
  }, [centers])

  const getEquipmentList = () => {
    props.setLoading()
    const options = {
      method: 'GET',
      headers: {
        Authorization: props.token,
        'Content-Type': 'application/json'
      }
    }
    msalFetch(`/api/centerManagement/getEquipment/${centerCode}`, options)
      .then(async res => {
        props.clearLoading()
        if (res.ok) {
          const data = await res.json()
          setRecords(data)
        } else {
          toast.error('Failed to get equipment')
        }
      })
      .catch(err => {
        toast.error('Failed to get equipment')
        props.clearLoading()
        console.log(err)
      })
  }

  const columns = [
    { title: 'Center Code', dataIndex: 'center_code', key: 'center_code' },
    { title: 'Serial Number', dataIndex: 'serial_no', key: 'serial_no' },
    { title: 'Area', dataIndex: 'area', key: 'area' },
    { title: 'Station', dataIndex: 'station', key: 'station' },
    { title: 'Machine Number', dataIndex: 'machine_no', key: 'machine_no' },
    {
      title: 'Date In Service',
      dataIndex: 'date_in_service',
      key: 'date_in_service',
      render: (_, { date_in_service }) => {
        return <span>{formatDate(date_in_service)}</span>
      }
    },
    {
      title: 'Next PM Date',
      dataIndex: 'next_pm_date',
      key: 'next_pm_date',
      render: (_, { next_pm_date }) => {
        return <span>{formatDate(next_pm_date)}</span>
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, { status }) => {
        switch (status) {
          case 'Retired':
            return <Tag color='purple'>{status}</Tag>
          case 'In-Service':
            return <Tag color='green'>{status}</Tag>
          case 'OOS':
            return <Tag color='red'>{status}</Tag>
        }
      }
    }
  ]

  const handleRowClick = record => {
    setOpenEquipmentUpdate(true)
    setUpdateRecord(record)
  }

  const updateEquipment = formData => {
    props.setLoading()
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...formData
      })
    }
    msalFetch('/api/centerManagement/updateEquipment', options)
      .then(async res => {
        props.clearLoading()
        if (res.ok) {
          toast.success('Equipment details updated successfully')
          setOpenEquipmentUpdate(false)
          setUpdateRecord()
          getEquipmentList()
        } else {
          toast.error('Failed to update')
          props.clearLoading()
        }
      })
      .catch(err => {
        toast.error('Failed to update')
        props.clearLoading()
      })
  }

  return (
    <>
      <h3>Manage Equipment</h3>
      <div className='row'>
        <div className='form-group col-lg-4 col-md-6'>
          <label>Center Code:</label>
          <Select
            className='w-100'
            options={getCenters()}
            onChange={value => setCenterCode(value)}
          ></Select>
        </div>
        <div className='col-lg-2 col-md-4 d-flex align-items-end'>
          <Button type='primary' onClick={getEquipmentList}>
            Apply
          </Button>
        </div>
      </div>
      <div className='row mt-4'>
        <Table
          dataSource={records}
          columns={columns}
          onRow={row => {
            return {
              onClick: e => {
                handleRowClick(row)
              }
            }
          }}
        ></Table>
      </div>
      <Modal
        title='Equipment Info Update'
        centered
        open={openEquipmentUpdate}
        onOk={() => null}
        onCancel={() => setOpenEquipmentUpdate(false)}
        width={1000}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
          </>
        )}
      >
        <UpdateEquipmentDetails
          data={updateRecord}
          close={openEquipmentUpdate}
          centers={centers}
          updateEquipment={updateEquipment}
        ></UpdateEquipmentDetails>
      </Modal>
    </>
  )
}

export default EquipmentManagement
