import React, { useEffect, useState } from 'react'
import { Input, Select, DatePicker } from 'antd'
import { msalFetch } from '../../../utilities/msalFetch'
import { toast } from 'react-toastify'
import moment from 'moment'
const { TextArea } = Input

const frequencyPm = [
  {
    value: 'Annual',
    label: 'Annual'
  },
  {
    value: 'Quarterly',
    label: 'Quarterly'
  },
  {
    value: 'Monthly',
    label: 'Monthly'
  },
  {
    value: 'Weekly',
    label: 'AnWeeklynual'
  },
  {
    value: 'Daily',
    label: 'Daily'
  }
]

const equipStatus = [
  {
    value: 'In-Service',
    label: 'In-Service'
  },
  {
    value: 'OOS',
    label: 'OOS'
  },
  {
    value: 'Retired',
    label: 'Retired'
  }
]

function AddEquipment (props) {
  const [centers, setCenters] = useState([])
  const [formData, setFormData] = useState({})
  const [errors, setErrors] = useState({})

  useEffect(() => {
    props.setLoading()
    const options = {
      method: 'GET',
      headers: {
        Authorization: props.token,
        'Content-Type': 'application/json'
      }
    }
    msalFetch('/api/centerManagement/getCenters', options)
      .then(async res => {
        props.clearLoading()
        if (res.ok) {
          const data = await res.json()
          setCenters(data)
          props.clearLoading()
        } else {
          toast.error('Failed to get all centers')
        }
      })
      .catch(err => {
        toast.error('Failed to get all centers')
        props.clearLoading()
        console.log(err)
      })
  }, [])

  const getCenters = () => {
    if (centers.length) {
      return centers.map(x => {
        return {
          value: x.center_code,
          label: x.center_code
        }
      })
    }
  }

  const getAreas = () => {
    if (centers.length && formData.centerCode) {
      const center = centers.find(x => {
        return x.center_code === formData.centerCode
      })
      if (center) {
        return Object.keys(JSON.parse(center.center_config)).map(x => {
          return {
            label: x,
            value: x
          }
        })
      } else {
        return []
      }
    }
  }

  const getStations = () => {
    if (centers.length && formData.centerCode && formData.area) {
      const centerData = centers.find(
        x => x.center_code === formData.centerCode
      )
      const metaData = JSON.parse(centerData.center_config)
      const filteredStations = metaData[formData.area]
      if (filteredStations) {
        return filteredStations.map(x => {
          return {
            label: x,
            value: x
          }
        })
      }
      return []
    }
  }

  const handleChange = e => {
    setErrors({})
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSelectChange = (prop, value) => {
    setErrors({})
    setFormData({ ...formData, [prop]: value })
  }

  const handleDateChange = (s, key) => {
    const date = moment(s, 'MM/DD/YYYY').format('YYYY-MM-DD')
    setErrors({})
    setFormData({ ...formData, [key]: date })
  }

  const validateForm = () => {
    const errors = {}
    if (!formData.centerCode) {
      errors.centerCode = 'Center code is required'
    }
    if (!formData.area) {
      errors.area = 'Area is required'
    }
    if (!formData.station) {
      errors.station = 'Station is required'
    }
    if (!formData.description) {
      errors.description = 'Description is required'
    }
    if (!formData.manufacturer) {
      errors.manufacturer = 'Manufacturer is required'
    }
    if (!formData.machineNo) {
      errors.machineNo = 'Machine No is required'
    }
    if (!formData.serialNo) {
      errors.serialNo = 'Serial number is required'
    }
    if (!formData.dateInService) {
      errors.dateInService = 'Date in service is required'
    }
    if (!formData.pmFrequency) {
      errors.pmFrequency = 'PM Frequency is required'
    }
    if (!formData.nextPmDate) {
      errors.nextPmDate = 'Next PM is required'
    }
    if (!formData.status) {
      errors.status = 'Status is required'
    }
    setErrors(errors)
    if (Object.keys(errors).length) {
      return false
    }
    return true
  }

  const submit = e => {
    e.preventDefault()
    if (validateForm()) {
      props.setLoading()
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...formData
        })
      }
      msalFetch('/api/centerManagement/addEquipment', options)
        .then(async res => {
          props.clearLoading()
          if (res.ok) {
            toast.success('Equipment added successfully')
            props.setActiveTab('equipmentManagement')
          } else {
            props.clearLoading()
          }
        })
        .catch(err => {
          props.clearLoading()
        })
    }
  }
  return (
    <>
      <h3>Add Equipment</h3>
      <form onSubmit={submit}>
        <div className='row my-2'>
          <div className='form-group col-md-6 col-lg-4'>
            <label htmlFor='centercode'>
              Center Code<span style={{ color: 'red' }}>&#42;</span>:
            </label>
            <Select
              options={getCenters()}
              onChange={value => handleSelectChange('centerCode', value)}
              className='w-100'
              name='centerCode'
              value={formData.centerCode}
              placeholder='Select Center'
            />
            {errors.centerCode ? (
              <span className='invalidInput'>{errors.centerCode}</span>
            ) : null}
          </div>
          <div className='form-group col-md-6 col-lg-4'>
            <label htmlFor='area'>
              Area<span style={{ color: 'red' }}>&#42;</span>:
            </label>
            <Select
              options={getAreas()}
              onChange={value => handleSelectChange('area', value)}
              className='w-100'
              name='area'
              value={formData.area}
              placeholder='Select Area'
              disabled={!formData.centerCode}
            />
            {errors.area ? (
              <span className='invalidInput'>{errors.area}</span>
            ) : null}
          </div>
          <div className='form-group col-md-6 col-lg-4'>
            <label htmlFor='station'>
              Station<span style={{ color: 'red' }}>&#42;</span>:
            </label>
            <Select
              options={getStations()}
              onChange={value => handleSelectChange('station', value)}
              className='w-100'
              name='station'
              value={formData.station}
              placeholder='Select Station'
              disabled={!formData.center_code && !formData.area}
            />
            {errors.station ? (
              <span className='invalidInput'>{errors.station}</span>
            ) : null}
          </div>
        </div>
        <div className='row my-2'>
          <div className='form-group col-md-6 col-lg-4'>
            <label htmlFor='description'>
              Equipment Description<span style={{ color: 'red' }}>&#42;</span>:
            </label>
            <TextArea
              id='description'
              onChange={handleChange}
              className='form-control'
              name='description'
              value={formData.description}
              placeholder='Enter Equipment description'
            />
            {errors.description ? (
              <span className='invalidInput'>{errors.description}</span>
            ) : null}
          </div>
          <div className='form-group col-md-6 col-lg-4'>
            <label htmlFor='manufacturer'>
              Manufacturer<span style={{ color: 'red' }}>&#42;</span>:
            </label>
            <Input
              id='manufacturer'
              onChange={handleChange}
              className='form-control'
              name='manufacturer'
              value={formData.manufacturer}
              placeholder='Enter manufacturer'
            />
            {errors.manufacturer ? (
              <span className='invalidInput'>{errors.manufacturer}</span>
            ) : null}
          </div>
          <div className='form-group col-md-6 col-lg-4'>
            <label htmlFor='machine_no'>
              Machine Number<span style={{ color: 'red' }}>&#42;</span>:
            </label>
            <Input
              id='machine_no'
              onChange={handleChange}
              className='form-control'
              name='machineNo'
              value={formData.machineNo}
              placeholder='Enter machine number'
            />
            {errors.machineNo ? (
              <span className='invalidInput'>{errors.machineNo}</span>
            ) : null}
          </div>
        </div>
        <div className='row my-2'>
          <div className='form-group col-md-6 col-lg-4'>
            <label htmlFor='serialNo'>
              Serial Number<span style={{ color: 'red' }}>&#42;</span>:
            </label>
            <Input
              id='serialNo'
              onChange={handleChange}
              className='form-control'
              name='serialNo'
              value={formData.serialNo}
              placeholder='Enter serial number'
            />
            {errors.serialNo ? (
              <span className='invalidInput'>{errors.serialNo}</span>
            ) : null}
          </div>
          <div className='form-group col-md-6 col-lg-4'>
            <label htmlFor='dateInService'>
              Date in Service<span style={{ color: 'red' }}>&#42;</span>:
            </label>
            <DatePicker
              onChange={(d, s) => handleDateChange(s, 'dateInService')}
              name='dateInService'
              placeholder='MM/DD/YYYY'
              className='w-100'
              format='MM/DD/YYYY'
            />
            {errors.dateInService ? (
              <span className='invalidInput'>{errors.dateInService}</span>
            ) : null}
          </div>
          <div className='form-group col-md-6 col-lg-4'>
            <label htmlFor='station'>
              Frequency of PM<span style={{ color: 'red' }}>&#42;</span>:
            </label>
            <Select
              options={frequencyPm}
              onChange={value => handleSelectChange('pmFrequency', value)}
              className='w-100'
              name='pmFrequency'
              value={formData.pmFrequency}
              placeholder='Select PM frequency'
            />
            {errors.pmFrequency ? (
              <span className='invalidInput'>{errors.pmFrequency}</span>
            ) : null}
          </div>
        </div>
        <div className='row my-2'>
          <div className='form-group col-md-6 col-lg-4'>
            <label htmlFor='nextPmDate'>
              Next PM Date<span style={{ color: 'red' }}>&#42;</span>:
            </label>
            <DatePicker
              onChange={(d, s) => handleDateChange(s, 'nextPmDate')}
              name='nextPmDate'
              placeholder='MM/DD/YYYY'
              className='w-100'
              id='nextPmDate'
              format='MM/DD/YYYY'
            />
            {errors.nextPmDate ? (
              <span className='invalidInput'>{errors.nextPmDate}</span>
            ) : null}
          </div>
          <div className='form-group col-md-6 col-lg-4'>
            <label htmlFor='status'>
              Status<span style={{ color: 'red' }}>&#42;</span>:
            </label>
            <Select
              options={equipStatus}
              onChange={value => handleSelectChange('status', value)}
              className='w-100'
              name='status'
              value={formData.status}
              placeholder='Select equipment status'
            />
            {errors.status ? (
              <span className='invalidInput'>{errors.status}</span>
            ) : null}
          </div>
        </div>
        <div className='row my-3'>
          <div className='form-group col-md-6 col-lg-4'>
            <button className='btn btn-primary'>Submit</button>
          </div>
        </div>
      </form>
    </>
  )
}

export default React.memo(AddEquipment)
