import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../redux/actions'
import { bindActionCreators } from 'redux'
import styles from '../../styles/DIS.module.css'
import { Panel } from 'primereact/panel'
import { Button, Descriptions, Tabs, Table, Tag, Modal } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { Empty } from 'antd'
import { msalFetch } from '../../utilities/msalFetch'
import { toast } from 'react-toastify'
import { formatDate } from '../../utilities/format'
import moment from 'moment'
import HAFormModal from './HAFormModal'

const HealthAssessment = props => {
  const [toggleAllergy, setToggleAllergy] = useState(false)
  const [allergyData, setAlergyData] = useState({})
  const [allergyError, setAllergyError] = useState(undefined)
  const [healthRecords, setHealthRecords] = useState([])
  const [selectedAssessment, setSelectedAssessment] = useState(null)
  const [healthAssessmentFormData, setHealthAssessmentFormData] = useState({})
  const [physicalExamQuestions, setPhysicalExamQuestions] = useState([])
  const [cancelledHA, setCancelledHA] = useState(undefined)
  const [cancelHAComments, setCancelHAComments] = useState('')

  const healthRecordsColumns = [
    {
      title: 'Donor Id',
      dataIndex: 'DONORID',
      key: 'DONORID'
    },
    {
      title: 'UCN',
      dataIndex: 'UCN',
      key: 'UCN'
    },
    {
      title: 'Assessment Date',
      dataIndex: 'DATE_OF_ASSESSMENT',
      key: 'DATE_OF_ASSESSMENT',
      sorter: (a, b) =>
        moment(b.DATE_OF_ASSESSMENT).isValid
          ? moment(a.DATE_OF_ASSESSMENT, 'MM/DD/YYYY').valueOf() -
            moment(b.DATE_OF_ASSESSMENT, 'MM/DD/YYYY').valueOf()
          : moment(a.DATE_OF_ASSESSMENT).valueOf() -
            moment('12/12/9999', 'MM/DD/YYYY')
    },
    {
      title: 'Performed By',
      dataIndex: 'UPLOADEDBY',
      key: 'UPLOADEDBY'
    },
    {
      title: 'STATUS',
      dataIndex: 'STATUS',
      key: 'STATUS',
      filters: [
        {
          text: 'Pending',
          value: 'Pending'
        },
        {
          text: 'Complete',
          value: 'Complete'
        },
        {
          text: 'Cancelled',
          value: 'Cancelled'
        }
      ],
      onFilter: (value, record) => record.STATUS.indexOf(value) === 0,
      render: (_, { STATUS }) => {
        switch (STATUS) {
          case 'Cancelled':
            return <Tag color='purple'>{STATUS}</Tag>
          case 'Complete':
            return <Tag color='green'>{STATUS}</Tag>
          case 'Pending':
            return <Tag color='red'>{STATUS}</Tag>
        }
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button
          type='primary'
          onClick={e => {
            e.stopPropagation()
            record?.STATUS == 'Pending' && setCancelledHA(record)
          }}
          disabled={record?.STATUS !== 'Pending'}
        >
          Cancel
        </Button>
      )
    }
  ]

  const allergyDetails = [
    { key: 1, label: 'Allergic To', children: props.donorInfo.allergic_to },
    {
      Key: 2,
      label: 'Allergy Details',
      children: props.donorInfo.allergy_comments
    }
  ]

  const getHealthRecords = () => {
    props.actions.setLoading()
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      }
    }
    msalFetch(
      `api/donor/getHealthAssessmentsByDonorId/${props.donorInfo.donor_id}`,
      options
    )
      .then(async res => {
        props.actions.clearLoading()
        if (res.ok) {
          let data = await res.json()
          data = data.map(x => {
            if (x.REQUEST_DATE) {
              x.REQUEST_DATE = formatDate(x.REQUEST_DATE)
            }
            if (x.DATE_OF_ASSESSMENT) {
              x.DATE_OF_ASSESSMENT = formatDate(x.DATE_OF_ASSESSMENT)
            }
            return x
          })
          setHealthRecords(data)
        }
      })
      .catch(err => {})
  }

  const getPhysicalExaminationQuestions = () => {
    props.actions.setLoading()
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      }
    }
    msalFetch('/api/donor/getPhysicalExaminationQuestions', options)
      .then(async res => {
        if (res.ok) {
          const questions = await res.json()
          setPhysicalExamQuestions(questions)
        }
        props.actions.clearLoading()
      })
      .catch(err => {
        props.actions.clearLoading()
        toast.error(err?.message)
      })
  }

  useEffect(() => {
    if (props.donorInfo && props.donorInfo.donor_id) {
      getPhysicalExaminationQuestions()
      getHealthRecords()
    }
  }, [props.donorInfo])

  const allergyHeaderTemplate = options => {
    const className = `${options.className} justify-content-space-between`
    return (
      <div className={className} style={{ padding: '12px', fontWeight: 600 }}>
        <div className='flex align-items-center'>
          <span className='font-bold'>Allergy Details</span>
        </div>
        {props.roleid == 7 ? (
          <div>
            <Button onClick={() => setToggleAllergy(!toggleAllergy)}>
              <FontAwesomeIcon icon={faPenToSquare} />
            </Button>
          </div>
        ) : null}
      </div>
    )
  }

  const healthRecordsHeader = options => {
    const className = `${options.className} justify-content-space-between`
    return (
      <div className={className} style={{ padding: '12px', fontWeight: 600 }}>
        <div className='flex align-items-center'>
          <span className='font-bold'>Health Assessment Records</span>
        </div>
      </div>
    )
  }

  const handleAllergyChange = e => {
    setAllergyError(undefined)
    setAlergyData({ ...allergyData, [e.target.name]: e.target.value })
  }

  const validateAllergyDetails = () => {
    if (!allergyData.allergic_to && !allergyData.allergy_comments) {
      setAllergyError('Please fill all the details')
      return false
    }
    setAllergyError(undefined)
    return true
  }

  const submitAllergyDetails = e => {
    if (validateAllergyDetails()) {
      props.actions.setLoading()
      const data = {
        donor_id: props.donorInfo.donor_id,
        ...allergyData
      }
      const options = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      }
      msalFetch('/api/donor/submitAllergyDetails', options)
        .then(async res => {
          if (res.ok) {
            props.actions.clearLoading()
            toast.success('Allergy details added successfully')
            setToggleAllergy(false)
            props.getDonorInfo()
          } else {
            props.actions.clearLoading()
          }
        })
        .catch(err => {
          props.actions.clearLoading()
        })
    }
  }

  const assessmentRowSelect = record => {
    if (record?.STATUS !== 'Cancelled') {
      setHealthAssessmentFormData({
        ...record
      })
      setSelectedAssessment(record)
    }
  }

  const cancelHealthAssessment = () => {
    if (!cancelHAComments) {
      toast.error('Please add comments')
    } else {
      props.actions.setLoading()
      const body = {
        UCN: cancelledHA.UCN,
        COMMENTS: cancelHAComments
      }
      const options = {
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }
      msalFetch('/api/donor/cancelHealthAssessment', options).then(
        async res => {
          props.actions.clearLoading()
          if (res.ok) {
            toast.success('Health Assessment cancelled successfully')
            setCancelledHA(undefined)
            setCancelHAComments('')
            getHealthRecords()
          } else {
            props.actions.clearLoading()
            toast.error('Health Assessment cancellation failed')
          }
        }
      )
    }
  }

  return (
    <React.Fragment>
      <div className={`row p-2 mt-1 ${styles.drdRibbon}`}>
        <p className={`col-sm-3 ${styles.drdRibbonTitle}`}>Health Assessment</p>
        <p className={`col-sm-3 ${styles.drdRibbonDonorName}`}>
          <span>
            {props.donorInfo?.first_name} {props.donorInfo?.last_name}
          </span>
        </p>
        <div className={`col-sm-3 ${styles.drdRibbonID}`}>
          <span>Donor ID : {props.donorInfo?.donor_id}</span>
        </div>
        <div className={`col-sm-3 ${styles.drdRibbonID}`}>
          <span>Donor Status : {props.donorInfo?.donor_status}</span>
        </div>
      </div>
      <div className='row d-flex justify-content-center mt-3'>
        <div className='col-sm-8'>
          <Panel
            header='Allergy Details'
            headerTemplate={allergyHeaderTemplate}
          >
            {toggleAllergy ? (
              <div className='row'>
                <div className='col-sm-6'>
                  <div className='row mb-3'>
                    <label
                      htmlFor='allergicTo'
                      className='col-sm-4 col-form-label'
                    >
                      Allergic To :
                    </label>
                    <div className='col-sm-8'>
                      <select
                        className='form-select'
                        id='allergicTo'
                        name='allergic_to'
                        onChange={handleAllergyChange}
                        value={allergyData.allergic_to}
                      >
                        <option selected disabled>
                          Select
                        </option>
                        <option value='Nickel'>Nickel</option>
                        <option value='Iodine'>Iodine</option>
                        <option value='Environmental'>Environmental</option>
                        <option value='Medication'>Medication</option>
                        <option value='Latex'>Latex</option>
                        <option value='Others'>Others</option>
                      </select>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <label
                      htmlFor='allergycomments'
                      className='col-sm-4 col-form-label'
                    >
                      Allery Comments :
                    </label>
                    <div className='col-sm-8'>
                      <textarea
                        className='form-control'
                        id='allergycomments'
                        name='allergy_comments'
                        onChange={handleAllergyChange}
                        value={allergyData.allergy_comments}
                      />
                    </div>
                    {allergyError ? (
                      <span
                        className='text-warning'
                        style={{ fontSize: '14px' }}
                      >
                        {allergyError}
                      </span>
                    ) : null}
                    <Button
                      type='primary'
                      className='mt-3 col-sm-2 mx-2'
                      onClick={submitAllergyDetails}
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </div>
            ) : props.donorInfo.allergic_to ? (
              <Descriptions
                items={allergyDetails}
                bordered
                size='small'
                contentStyle={{ fontSize: '15px', fontWeight: 600 }}
              ></Descriptions>
            ) : (
              <Empty />
            )}
          </Panel>
        </div>
        <div className='col-sm-8'>
          <Panel headerTemplate={healthRecordsHeader} className='mt-2'>
            <Table
              dataSource={healthRecords}
              columns={healthRecordsColumns}
              onRow={record => {
                return {
                  onClick: e => {
                    assessmentRowSelect(record)
                  }
                }
              }}
            ></Table>
          </Panel>
        </div>
      </div>
      {!!selectedAssessment ? (
        <HAFormModal
          physicalExamQuestions={physicalExamQuestions}
          selectedAssessment={selectedAssessment}
          healthAssessmentFormData={healthAssessmentFormData}
          setSelectedAssessment={setSelectedAssessment}
          donorId={props.donorInfo.donor_id}
          getHealthRecords={getHealthRecords}
          donorInfo = {props.donorInfo}
        ></HAFormModal>
      ) : null}
      <Modal
        title='Please confirm'
        open={!!cancelledHA}
        onOk={cancelHealthAssessment}
        onCancel={() => {
          setCancelledHA(undefined)
          setCancelHAComments('')
        }}
        centered
      >
        <p>
          Are you sure you want to cancel this Health Assessment -{' '}
          {cancelledHA?.UCN}
        </p>
        <div class='form-group'>
          <label for='comments' style={{ fontSize: '14px' }}>
            Comments*
          </label>
          <textarea
            className='form-control'
            style={{ fontSize: '14px' }}
            id='comments'
            rows='2'
            value={cancelHAComments}
            onChange={e => setCancelHAComments(e.target.value)}
          ></textarea>
        </div>
      </Modal>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token,
    roleid: state.roleid
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HealthAssessment)
