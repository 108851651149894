import React, { memo, useEffect, useState } from "react";
import DatePicker from "./DatePicker";
import TimeInput from "./TimeInput";
import Select from "./Select";
import { InputMask } from "primereact/inputmask";

const DynamicForm = ({
  formData = [],
  onChange,
  fields,
  label,
  options = {},
  initialState,
  maxRows = Infinity,
  error,
}) => {
  const [isNotApplicable, setIsNotApplicable] = useState(false);

  useEffect(() => {
    if (JSON.stringify(formData) === JSON.stringify(initialState)) {
      setIsNotApplicable(false);
    }
  }, [formData, initialState]);

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const updatedData = formData.map((item, i) =>
      i === index ? { ...item, [name]: value } : item
    );
    onChange(updatedData);
  };

  const handleAdd = () => {
    if (formData.length >= maxRows) return;
    const newItem = fields.reduce((acc, field) => {
      acc[field.name] = "";
      return acc;
    }, {});
    onChange([...formData, newItem]);
  };

  const handleRemove = (index) => {
    if (formData.length > 1) {
      onChange(formData.filter((_, i) => i !== index));
    }
  };

  const handleNAChange = () => {
    setIsNotApplicable((prev) => !prev);
    if (!isNotApplicable) {
      onChange([]);
    } else {
      onChange([initialState]);
    }
  };

  const renderInput = (field, item, index) => {
    switch (field.type) {
      case "text":
        return (
          <input
            type={field.type}
            className="form-control"
            id={`${field.name}-${index}`}
            name={field.name}
            value={item[field.name]}
            onChange={(e) => handleChange(index, e)}
          />
        );
      case "date":
        return (
          <DatePicker
            id={`${field.name}-${index}`}
            name={field.name}
            value={item[field.name]}
            onChange={(e) => handleChange(index, e)}
          />
        );
      case "time":
        return (
          <TimeInput
            id={`${field.name}-${index}`}
            name={field.name}
            value={item[field.name]}
            onChange={(e) => handleChange(index, e)}
          />
        );
      case "select":
        return (
          <Select
            id={`${field.name}-${index}`}
            name={field.name}
            value={item[field.name]}
            options={options[field.name] ?? field.options}
            onChange={(e) => handleChange(index, e)}
            placeholder={"select"}
          />
        );
      case "mask":
        return (
          <InputMask
            id={`${field.name}-${index}`}
            name={field.name}
            value={item[field.name]}
            className="form-control"
            onChange={(e) => handleChange(index, e)}
            mask={field.mask}
          />
        );
    }
  };

  return (
    <div className="col">
      <div className="d-flex justify-content-between">
        {label}
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            checked={isNotApplicable}
            onChange={handleNAChange}
          />
          <label>NA</label>
        </div>
      </div>
      {!isNotApplicable && (
        <table>
          <thead>
            <tr>
              {fields.map((field, index) => (
                <td key={index}>
                  {field.label} <span style={{ color: "red" }}>&#42;</span>{" "}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {formData?.map((item, index) => (
              <>
                <tr key={index} className="">
                  {fields.map((field, fieldIndex) => (
                    <td key={fieldIndex}>{renderInput(field, item, index)}</td>
                  ))}
                  <td>
                    {formData.length > 1 && index !== formData.length - 1 && (
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleRemove(index)}
                      >
                        -
                      </button>
                    )}
                    {index === formData.length - 1 &&
                      formData.length < maxRows && (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleAdd}
                        >
                          +
                        </button>
                      )}
                  </td>
                </tr>
              </>
            ))}
            {error && (
              <tr>
                <td colSpan={fields.length + 1}>
                  <div className="text-danger">{error}</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default memo(DynamicForm);
