import React from 'react'
import MedicationListImage from '../../images/medicationlist.jpg'

const MedicationList = () => {
  return (
    <>
      <div className='row'>
        <div className='col-lg-12 col-sm-12'>
          <img src={MedicationListImage} alt='medicationlist' width='100%'></img>
        </div>
      </div>
    </>
  )
}

export default MedicationList
