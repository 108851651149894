import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { InputText } from 'primereact/inputtext'
import { Checkbox, Switch } from 'antd'
import { checkValidUCN } from '../utilities/validations'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import { msalFetch } from '../utilities/msalFetch'
import { formatDate } from '../utilities/format'
import { Modal } from 'antd'

function LabOrder (props) {
  let initialState = new Array(10).fill().map((_, i) => ({
    seq: i + 1,
    ucn: '',
    donorType: '',
    donationDate: moment().format('MM/DD/YYYY'),
    natvmt: false,
    atya: false,
    sperpr: false,
    invalid: false,
    comments: '',
    serum_sample_taken: false,
    plasma_sample_taken: false,
    samples_taken: undefined
  }))

  const [labOrderTable, setLabOrderTable] = useState([...initialState])
  const [openVerifySamplesModal, setOpenVerifySamplesModal] = useState(false)
  const [samplesVerified, setSamplesVerified] = useState(false)

  const labOrderRef = useRef(null)

  const history = useHistory()

  const validateLabOrder = async ucn => {
    try {
      props.actions.setLoading()
      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.token
        }
      }
      return msalFetch(`/api/donor/validateLabOrder/${ucn}`, options)
        .then(async res => {
          props.actions.clearLoading()
          if (res.ok) {
            const data = await res.json()
            return data
          } else {
            return ''
          }
        })
        .catch(err => {
          props.actions.clearLoading()
        })
    } catch (err) {
      props.actions.clearLoading()
      console.log(err)
    }
  }

  const handleChange = async (e, seq) => {
    let temp = [...labOrderTable]
    const record = temp.find(x => x.seq == seq)
    if (e.target.type == 'checkbox') {
      record[e.target.name] = e.target.checked ? true : false
      if (record.sperpr && record.natvmt) {
        record['atya'] = true
      }
    } else if ([e.target.name] == 'ucn' && checkValidUCN(e.target.value)) {
      let checkDuplicate = labOrderTable.findIndex(
        x => x.ucn === e.target.value
      )
      if (checkDuplicate !== -1) {
        toast.error(`Duplicate UCN - ${e.target.value}`)
        record.invalid = true
        record[e.target.name] = e.target.value
        record.comments = 'Duplicate UCN'
        record.donorType = null
        record.natvmt = false
        record.atya = false
        record.sperpr = false
      } else {
        const {
          donor_type,
          donation_date,
          spe_taken,
          spe_only,
          validUCN,
          collection_amt_machine,
          plasma_amt_machine
        } = await validateLabOrder(e.target.value)
        if (
          validUCN &&
          ((collection_amt_machine &&
            collection_amt_machine > 0 &&
            plasma_amt_machine &&
            plasma_amt_machine > 0) ||
            spe_only)
        ) {
          record.invalid = false
          record.ucn = e.target.value
          record.comments = ''
          record.donorType = donor_type
          record.donationDate = formatDate(donation_date)
          if (spe_only) {
            record.natvmt = false
            record.atya = false
            record.sperpr = true
          } else if (donor_type === 'QUALIFIED' && spe_taken) {
            record.natvmt = true
            record.atya = true
            record.sperpr = true
          } else if (
            donor_type === 'QUALIFIED' ||
            donor_type === 'APPLICANT 2'
          ) {
            record.natvmt = true
            record.atya = false
            record.sperpr = false
          } else if (donor_type === 'APPLICANT 1') {
            record.natvmt = true
            record.atya = true
            record.sperpr = true
          } else if (
            donor_type === 'TEMPORARILY DEFERRED (Abnormal SPE)' ||
            spe_only
          ) {
            record.natvmt = false
            record.atya = false
            record.sperpr = true
          }
        } else if (!validUCN) {
          record.invalid = true
          record[e.target.name] = e.target.value
          record.comments = 'UCN does not exist in the system'
          record.donorType = null
          record.natvmt = false
          record.atya = false
          record.sperpr = false
          record.donationDate = null
        } else if (
          !spe_only &&
          (!plasma_amt_machine ||
            plasma_amt_machine == 0 ||
            !collection_amt_machine ||
            collection_amt_machine == 0)
        ) {
          record.invalid = true
          record.comments =
            'Disconnect staff needs to Complete Donation for this UCN'
          record[e.target.name] = e.target.value
          record.sperpr = false
          record.natvmt = false
          record.atya = false
          record.donorType = null
          record.donationDate = null
        }
      }
    } else {
      if (e.target.value == '') {
        record.invalid = false
        record.comments = ''
        record[e.target.name] = e.target.value
        record.sperpr = false
        record.natvmt = false
        record.atya = false
        record.donorType = null
        record.donationDate = null
      } else {
        record.invalid = true
        record.comments = 'Invalid UCN'
        record[e.target.name] = e.target.value
        record.sperpr = false
        record.natvmt = false
        record.atya = false
        record.donorType = null
        record.donationDate = null
      }
    }
    temp = temp.map(x => {
      if (x.seq == seq) {
        x = { ...record }
      }
      return x
    })
    setLabOrderTable(temp)
  }

  const validate = () => {
    for (let i = 0; i < labOrderTable.length; i++) {
      if (labOrderTable[i].invalid) {
        toast.error(
          `Please remove unit ${labOrderTable[i].ucn} to process Lab Order`
        )
        return false
      } else if (labOrderTable[i].ucn && !labOrderTable[i].samples_taken) {
        toast.error(
          `Please verify samples taken for the unit ${labOrderTable[i].ucn} to process Lab Order`
        )
        return false
      } else if (
        labOrderTable[i].ucn &&
        !labOrderTable[i].sperpr &&
        !labOrderTable[i].natvmt &&
        !labOrderTable[i].atya
      ) {
        toast.error(`No selections marked on unit ${labOrderTable[i].ucn}`)
        return false
      }
    }
    return true
  }

  const handleSubmit = () => {
    if (validate()) {
      props.actions.setLoading()
      const body = labOrderTable.filter(x => {
        return x.ucn != '' && (x.natvmt || x.atya || x.sperpr)
      })
      const options = {
        method: 'post',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.token
        },
        body: JSON.stringify(body)
      }
      msalFetch('/api/reports/saveLabOrder', options)
        .then(async res => {
          props.actions.clearLoading()
          const data = await res.json()
          if (res.ok) {
            toast.success('Lab Order processed successfully')
            setLabOrderTable(initialState)
            labOrderRef.current.reset()
          } else {
            toast.error(data.message)
          }
        })
        .catch(err => {
          props.actions.clearLoading()
          console.log(err)
        })
    }
  }

  const getPlasmaSamples = () => {
    const ucnlist = labOrderTable
      .filter(x => x.atya || x.natvmt)
      .map(x => x.ucn)
    return ucnlist
  }

  const getSerumSamples = () => {
    const ucnlist = labOrderTable.filter(x => x.sperpr).map(x => x.ucn)
    return ucnlist
  }

  const handleSampleTaken = (checked, parameter, ucn) => {
    const newLaborder = labOrderTable.map(x => {
      if (x.ucn === ucn) {
        x[[parameter]] = checked
      }
      return x
    })
    setLabOrderTable(newLaborder)
  }

  const acceptVerifySamples = () => {
    const newLaborder = labOrderTable.map(x => {
      if (x.ucn && checkValidUCN(x.ucn)) {
        if (x.sperpr && !x.serum_sample_taken) {
          x.samples_taken = false
        } else if ((x.natvmt || x.atya) && !x.plasma_sample_taken) {
          x.samples_taken = false
        } else {
          x.samples_taken = true
        }
      }
      return x
    })
    setSamplesVerified(true)
    setOpenVerifySamplesModal(false)
    setLabOrderTable(newLaborder)
  }

  return (
    <div className='row d-flex justify-content-center'>
      <div className='col-md-10'>
        <div className='row d-flex justify-content-center mt-4 mb-4'>
          <div className='col-sm-12'>
            <h3 style={{ color: '#292764' }}>Lab Order</h3>
            <form id='labOrderForm' ref={labOrderRef}>
              <table className='table table-bordered  table-light table-sm text-center'>
                <thead>
                  <tr className='table-secondary'>
                    <th scope='col'>Seq</th>
                    <th scope='col'>UCN#</th>
                    <th scope='col'>Donor Type</th>
                    <th scope='col'>Donation Date</th>
                    <th scope='col'>NAT/VMT</th>
                    <th scope='col'>ATYA</th>
                    <th scope='col'>SPE/RPR</th>
                    <th scope='col'>SPE Sample taken</th>
                    <th scope='col'>Plasma sample taken</th>
                  </tr>
                </thead>
                {console.log(labOrderTable)}
                <tbody>
                  {labOrderTable.map((row, i) => {
                    return (
                      <tr
                        key={i}
                        className={
                          row.samples_taken !== undefined
                            ? row.samples_taken
                              ? 'table-success'
                              : 'table-danger'
                            : null
                        }
                      >
                        <td>{row.seq}</td>
                        <td>
                          <InputText
                            type='text'
                            placeholder='UCN'
                            name='ucn'
                            onChange={e => handleChange(e, row.seq)}
                            id={`laborderinput${row.seq}`}
                          />
                          {row.invalid ? (
                            <p style={{ fontSize: '12px', color: 'red' }}>
                              {row.comments}
                            </p>
                          ) : null}
                        </td>
                        <td style={{ verticalAlign: 'middle' }}>
                          {row.donorType}
                        </td>
                        <td style={{ verticalAlign: 'middle' }}>
                          {row.donationDate}
                        </td>
                        <td>
                          <Checkbox
                            name='natvmt'
                            onChange={e => handleChange(e, row.seq)}
                            checked={row.natvmt}
                            disabled={row.invalid || !row.ucn}
                          ></Checkbox>
                        </td>
                        <td>
                          <Checkbox
                            name='atya'
                            onChange={e => handleChange(e, row.seq)}
                            checked={row.atya}
                            disabled={row.invalid || !row.ucn}
                          ></Checkbox>
                        </td>
                        <td>
                          <Checkbox
                            name='sperpr'
                            onChange={e => handleChange(e, row.seq)}
                            checked={row.sperpr}
                            disabled={row.invalid || !row.ucn}
                          ></Checkbox>
                        </td>
                        <td>
                          <Checkbox
                            name='serum_sample_taken'
                            onChange={e => handleChange(e, row.seq)}
                            checked={row.serum_sample_taken}
                            //disabled={row.invalid || !row.ucn}
                            disabled
                          ></Checkbox>
                        </td>
                        <td>
                          <Checkbox
                            name='plasma_sample_taken'
                            onChange={e => handleChange(e, row.seq)}
                            checked={row.plasma_sample_taken}
                            disabled
                            //disabled={row.invalid || !row.ucn}
                          ></Checkbox>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </form>
            <h5 className='m-0 p-0'>Plasma Processor : {props.username}</h5>
            <h5 className='p-0 m-0 mb-4'>Date : {moment().format('MM/DD/YYYY')}</h5>
            <div>
              <button
                className='btn btn-primary'
                onClick={() => setOpenVerifySamplesModal(true)}
              >
                Verify Samples
              </button>
              <button
                className='btn btn-primary mx-4'
                onClick={handleSubmit}
                disabled={!samplesVerified}
              >
                Submit
              </button>
              <button
                className='btn btn-danger'
                onClick={() => history.push('/dashboard')}
              >
                Exit
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={openVerifySamplesModal}
        title=<h3 className='title'>Verify Samples</h3>
        centered
        onOk={() => acceptVerifySamples()}
        onCancel={() => setOpenVerifySamplesModal(false)}
        width={1000}
        okText='Accept'
      >
        <>
          <div className='my-4'>
            <h4 className='p-0 m-0'>Serum Samples</h4>
            <table className='table table-bordered table-striped w-50'>
              <thead>
                <tr>
                  <th scope='col' className='w-50'>
                    UCN
                  </th>
                  <th scope='col' className='w-50'>
                    Sample Taken
                  </th>
                </tr>
              </thead>
              <tbody>
                {getSerumSamples().map(x => {
                  return (
                    <tr key={x}>
                      <td>{x}</td>
                      <td>
                        <Switch
                          onChange={checked =>
                            handleSampleTaken(checked, 'serum_sample_taken', x)
                          }
                        ></Switch>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className='my-4'>
            <h4 className='p-0 m-0'>Plasma Samples</h4>
            <table className='table table-bordered table-striped w-50'>
              <thead>
                <tr>
                  <th scope='col' className='w-50'>
                    UCN
                  </th>
                  <th scope='col' className='w-50'>
                    Sample Taken
                  </th>
                </tr>
              </thead>
              <tbody>
                {getPlasmaSamples().map(x => {
                  return (
                    <tr key={x}>
                      <td>{x}</td>
                      <td>
                        <Switch
                          onChange={checked =>
                            handleSampleTaken(checked, 'plasma_sample_taken', x)
                          }
                        ></Switch>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </>
      </Modal>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LabOrder)
