import React from 'react'
import ReactDOM from 'react-dom/client'
import './client/styles/index.css'
import { HashRouter } from 'react-router-dom'
import { createStore } from 'redux'
import rootReducer from './client/redux/reducers'
import { PublicClientApplication, EventType } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { Provider } from 'react-redux'
import { msalConfig } from './client/config/authConfig'
import AuthWrapper from './client/AuthWrapper.jsx'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallbackUI from './client/components/ErrorFallbackUI'
import { msalFetch } from './client/utilities/msalFetch'

export const store = createStore(rootReducer)

export const msalInstance = new PublicClientApplication(msalConfig)

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents()

// Listen for sign-in event and set active account
msalInstance.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account
    msalInstance.setActiveAccount(account)
    msalFetch('/api/auth/login', { method: 'GET' }).then(response => {})
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <ErrorBoundary fallbackRender={ErrorFallbackUI}>
    <MsalProvider instance={msalInstance}>
      <HashRouter>
        <Provider store={store}>
          <AuthWrapper></AuthWrapper>
        </Provider>
      </HashRouter>
    </MsalProvider>
  </ErrorBoundary>
)

if (module.hot) {
  module.hot.accept()
}
