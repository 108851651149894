import React from 'react'
import styles from '../styles/NavBar.module.css'

const NavBar = props => {
  return (
    <div className={styles.navbar}>
      {props.items.map(item => {
        return (
          <a
            className={
              props.active === item.key
                ? `${styles.navbarItem} ${styles.navbarActive}`
                : `${styles.navbarItem}`
            }
            onClick={() => props.onLinkClick&&props.onLinkClick(item.key)}
          >
            {item.title}
          </a>
        )
      })}
    </div>
  )
}

export default NavBar
