import React, { useState } from 'react'
import { Input, Card, Tag, Modal, Select, Divider, Space, Button } from 'antd'
import { msalFetch } from '../../../utilities/msalFetch'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX, faPlus } from '@fortawesome/free-solid-svg-icons'
import { centerAreas, nomogramTypes } from '../../../constants/admin'
import { PlusOutlined } from '@ant-design/icons'

const { Search } = Input

const AddCenter = props => {
  const [formData, setFormData] = useState({})
  const [areaInput, setAreaInput] = useState('')
  const [areas, setAreas] = useState([])
  const [centerConfig, setCenterConfig] = useState({})
  const [stationInput, setStationInput] = useState({})
  const [errors, setErrors] = useState({})
  const [selectedAreas, setSelectedAreas] = useState([])

  const handleChange = e => {
    setErrors({})
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const addArea = () => {
    setErrors({})
    if (areaInput) {
      const flag = areas.findIndex(x => x == areaInput)
      if (flag === -1) {
        setAreas([...areas, areaInput])
        setAreaInput('')
        const newConfig = { ...centerConfig }
        newConfig[[areaInput]] = []
        setCenterConfig(newConfig)
      } else {
        setAreaInput('')
      }
    }
  }

  const onDeleteArea = value => {
    const newAreas = areas.filter(x => x != value)
    setAreas(newAreas)
    const newConfig = { ...centerConfig }
    delete newConfig[[value]]
    setCenterConfig(newConfig)
  }

  const handleStationAdd = (value, area) => {
    if (value) {
      setErrors({})
      const newConfig = { ...centerConfig }
      newConfig[[area]] = [...newConfig[area], value]
      setCenterConfig(newConfig)
      setStationInput({})
    }
  }

  const onDeleteStation = (area, station) => {
    const stations = centerConfig[[area]]
    const newStations = stations.filter(x => x != station)
    const newConfig = { ...centerConfig }
    centerConfig[[area]] = newStations
    setCenterConfig(newConfig)
  }

  const handleAreaMultiSelect = value => {
    const newConfig = { ...centerConfig };
    let newAreas = [...areas]
    value.forEach(x => {
      if (!newConfig[x]) {
        newConfig[x] = []
      }
    })
    
    Object.keys(newConfig).forEach(x => {
      if (!value.includes(x)) {
        delete newConfig[x];
      }
    })
    setCenterConfig(newConfig)
    setAreas(value)
  }

  const validate = () => {
    const errors = {}
    if (!formData.centerCode) {
      errors.centerCode = 'Code code is required'
    }
    if (!formData.centerName) {
      errors.centerName = 'Center name is required'
    }
    if (!formData.centerAddress) {
      errors.centerAddress = 'Center address is required'
    }
    if (!areas.length) {
      errors.areas = 'Atleast one area is required'
    }
    if (!formData.nomogramType) {
      errors.nomogramType = 'Nomogram Type is required'
    }
    if (Object.keys(errors).length) {
      setErrors(errors)
      return false
    } else {
      setErrors({})
      return true
    }
  }

  const onSubmit = e => {
    e.preventDefault()
    if (validate()) {
      props.setLoading()
      const data = {
        ...formData,
        centerConfig
      }
      const options = {
        method: 'POST',
        headers: {
          Authorization: props.token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...data })
      }
      msalFetch('/api/centerManagement/addCenter', options)
        .then(async res => {
          props.clearLoading()
          if (res.ok) {
            toast.success('Center added successfully')
            props.setActiveTab('manageCenters')
          } else {
            toast.error('Failed to create center')
          }
        })
        .catch(err => {
          toast.error('Failed to create center')
          props.clearLoading()
          console.log(err)
        })
    }
  }

  return (
    <div className='row'>
      <h3>Add Center</h3>
      <form onSubmit={onSubmit}>
        <div className='row my-2'>
          <div className='form-group col-md-6 col-lg-4'>
            <label htmlFor='center_code'>
              Center Code<span style={{ color: 'red' }}>&#42;</span>:
            </label>
            <input
              id='center_code'
              className='form-control'
              type='text'
              placeholder='Center Code'
              name='centerCode'
              onChange={handleChange}
            ></input>
            {errors.centerCode ? (
              <span className='invalidInput'>{errors.centerCode}</span>
            ) : null}
          </div>
          <div className='form-group col-md-6 col-lg-4'>
            <label htmlFor='center_name'>
              Center Name<span style={{ color: 'red' }}>&#42;</span>:
            </label>
            <input
              className='form-control form-control'
              type='text'
              id='center_name'
              placeholder='Center Name'
              name='centerName'
              onChange={handleChange}
            ></input>
            {errors.centerName ? (
              <span className='invalidInput'>{errors.centerName}</span>
            ) : null}
          </div>
        </div>
        <div className='row my-2'>
          <div className='form-group col-md-6 col-lg-4'>
            <label htmlFor='address'>
              Center Address<span style={{ color: 'red' }}>&#42;</span>:
            </label>
            <textarea
              id='address'
              className='form-control'
              type='text'
              placeholder='Center Address'
              name='centerAddress'
              rows={3}
              onChange={handleChange}
            ></textarea>
            {errors.centerAddress ? (
              <span className='invalidInput'>{errors.centerAddress}</span>
            ) : null}
          </div>
          <div className='form-group col-md-6 col-lg-4 '>
            <label htmlFor='addArea'>
              Add Areas<span style={{ color: 'red' }}>&#42;</span>:
            </label>
            <Select
              style={{ width: '100%', height:'36px' }}
              size='large'
              mode='multiple'
              allowClear
              onChange={handleAreaMultiSelect}
              placeholder='Select Areas'
              value={areas}
              dropdownRender={menu => (
                <>
                  {menu}
                  <Divider
                    style={{
                      margin: '8px 0'
                    }}
                  />
                  <Space
                    style={{
                      padding: '0 8px 4px'
                    }}
                  >
                    <Input
                      placeholder='Please enter area name'
                      value={areaInput}
                      onChange={e => setAreaInput(e.target.value)}
                      onKeyDown={e => e.stopPropagation()}
                    />
                    <Button
                      type='primary'
                      icon={<PlusOutlined />}
                      onClick={addArea}
                    >
                      Add Area
                    </Button>
                  </Space>
                </>
              )}
              options={centerAreas.map(item => ({
                label: item,
                value: item
              }))}
            />
            {errors.areas ? (
              <span className='invalidInput'>{errors.areas}</span>
            ) : null}
          </div>
        </div>
        <div className='row p-1'>
          {areas?.map(x => {
            return (
              <Card
                size='small'
                title={
                  <div className='d-flex justify-content-between px-1 align-items-center'>
                    <p className='text-center d-flex p-0 m-0'>{x}</p>
                    <button
                      className='btn p-0 m-0'
                      onClick={() => onDeleteArea(x)}
                    >
                      <FontAwesomeIcon icon={faX}></FontAwesomeIcon>
                    </button>
                  </div>
                }
                style={{
                  width: 200,
                  height: 'min-content'
                }}
                key={x}
                className='p-1 col-md-4 col-lg-3 col-sm-12 m-1'
                actions={[
                  <Search
                    placeholder='Station Name'
                    enterButton=<FontAwesomeIcon
                      icon={faPlus}
                      size='small'
                    ></FontAwesomeIcon>
                    onChange={e =>
                      setStationInput({ ...stationInput, [x]: e.target.value })
                    }
                    onSearch={value => handleStationAdd(value, x)}
                    value={stationInput[[x]]}
                  ></Search>
                ]}
              >
                {centerConfig[[x]]?.map((y, i) => {
                  return (
                    <Tag
                      closeIcon
                      onClose={() => onDeleteStation(x, y)}
                      key={i}
                      className='p-1 my-1'
                      style={{ fontSize: '14px' }}
                    >
                      {y}
                    </Tag>
                  )
                })}
              </Card>
            )
          })}
        </div>
        <div className='row my-2'>
          <div className='form-group col-md-6 col-lg-4'>
            <label htmlFor='nomogramType'>
              Nomogram Type<span style={{ color: 'red' }}>&#42;</span>:
            </label>
            <select
              className='form-select'
              type='text'
              id='nomogramType'
              placeholder='Nomogram Type'
              name='nomogramType'
              onChange={handleChange}
            >
              <option selected disabled>
                Select
              </option>
              <option value={nomogramTypes.weightBased}>
                {nomogramTypes.weightBased}
              </option>
              <option value={nomogramTypes.personalized}>
                {nomogramTypes.personalized}
              </option>
            </select>
            {errors.nomogramType ? (
              <span className='invalidInput'>{errors.nomogramType}</span>
            ) : null}
          </div>
        </div>
        <div className='row my-2'>
          <div className='form-group col-md-4'>
            <button className='btn btn-primary' type='submit'>
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default React.memo(AddCenter)
