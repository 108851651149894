import moment from 'moment'
import { Calendar } from 'primereact/calendar'
import React, { useState, useEffect } from 'react'
import { Button, Select, Table, Tag, Modal } from 'antd'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import { msalFetch } from '../utilities/msalFetch'
import { formatDateTime } from '../utilities/format'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

function DonorCenterQueue (props) {
  const [date, setDate] = useState(new Date())
  const [rows, setRows] = useState([])
  const [showKioskDHQDeleteModal, setKioskDeleteDHQModal] = useState(false)
  const [kioskDeleteId, setKioskDeleteId] = useState(undefined)

  const history = useHistory()

  useEffect(() => {
    getQueue()
  }, [date])

  useEffect(() => {
    const interval = setInterval(() => {
      getQueue(true)
    }, 5000)
    return () => clearInterval(interval)
  }, [date])

  const getQueue = (silent = false) => {
    !silent && props.actions.setLoading()
    const dt = moment(date).format('YYYY-MM-DD')
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      }
    }
    msalFetch(`/api/reports/getDonorQueueByDate/${dt}`, options)
      .then(async res => {
        props.actions.clearLoading()
        const data = await res.json()
        const awaiting = []
        const pendingRetake = []
        const inProcess = []
        const screeningCompleted = []
        const donationCompleted = []
        data.forEach(x => {
          if (x.status === 'Waiting') {
            awaiting.push(x)
          } else if (x.status === 'Pending Retake') {
            pendingRetake.push(x)
          } else if (x.status === 'In-Process') {
            inProcess.push(x)
          } else if (x.status === 'Screening Completed') {
            screeningCompleted.push(x)
          } else if (x.status === 'Donation Completed') {
            donationCompleted.push(x)
          } else if (x.status === 'No Donation') {
            donationCompleted.push(x)
          } else if (x.status === 'Cancelled') {
            donationCompleted.push(x)
          }
        })
        setRows([
          ...awaiting,
          ...pendingRetake,
          ...inProcess,
          ...screeningCompleted,
          ...donationCompleted
        ])
      })
      .catch(err => {
        console.log(err)
        props.actions.clearLoading()
      })
  }

  const handleDateChange = e => {
    setDate(e.value)
  }

  const handleDonationClick = record => {
    sessionStorage.setItem('selectedDonorId', record.donor_id)
    record.ucn && sessionStorage.setItem('selectedUCN', record.ucn)
    sessionStorage.setItem('redirectPage', 'DHQ')
    history.push('/donor/dis')
  }

  const columns = [
    { title: 'Number', dataIndex: 'queue_no', key: 'queue_no' },
    { title: 'Donor Name', dataIndex: 'donor_name', key: 'donor_name' },
    { title: 'Donor ID', dataIndex: 'donor_id', key: 'donor_id' },
    {
      title: 'DHQ Completion Time',
      dataIndex: 'dhq_time',
      key: 'dhq_time',
      render: (_, { dhq_time }) => <span>{formatDateTime(dhq_time)}</span>
    },
    { title: 'Employee', dataIndex: 'employee', key: 'employee' },
    { title: 'UCN', dataIndex: 'ucn', key: 'ucn' },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'Waiting', value: 'Waiting' },
        { text: 'In-Process', value: 'In-Process' },
        { text: 'Pending Retake', value: 'Pending Retake' },
        { text: 'Screening Completed', value: 'Screening Completed' },
        { text: 'Donation Completed', value: 'Donation Completed' },
        { text: 'No Donation', value: 'No Donation' },
        { text: 'Cancelled', value: 'Cancelled' }
      ],
      onFilter: (value, record) => record.status === value,
      render: (_, { status }) => {
        switch (status) {
          case 'Waiting':
            return (
              <Tag color='#3FA2F6'>
                <span className='p-1 m-0' style={{ fontSize: '16px' }}>
                  {status}
                </span>
              </Tag>
            )
          case 'In-Process':
            return (
              <Tag color='purple'>
                <span className='p-1 m-0' style={{ fontSize: '16px' }}>
                  {status}
                </span>
              </Tag>
            )
          case 'Screening Completed':
            return (
              <Tag color='green'>
                <span className='p-1 m-0' style={{ fontSize: '16px' }}>
                  {status}
                </span>
              </Tag>
            )
          case 'Donation Completed':
            return (
              <Tag color='green'>
                <span className='p-1 m-0' style={{ fontSize: '16px' }}>
                  {status}
                </span>
              </Tag>
            )
          case 'Pending Retake':
            return (
              <Tag color='yellow'>
                <span className='p-1 m-0' style={{ fontSize: '16px' }}>
                  {status}
                </span>
              </Tag>
            )
          case 'No Donation':
            return (
              <Tag color='red'>
                <span className='p-1 m-0' style={{ fontSize: '16px' }}>
                  {status}
                </span>
              </Tag>
            )
          case 'Cancelled':
            return (
              <Tag color='red'>
                <span className='p-1 m-0' style={{ fontSize: '16px' }}>
                  {status}
                </span>
              </Tag>
            )
        }
      }
    },
    {
      title: 'Actions',
      key: 'id',
      render: (_, record) => {
        if (
          !record.ucn &&
          record.status === 'Waiting' &&
          record.dhq_ucn === 'Pending' && props.roleid===3
        ) {
          return (
            <Button
              onClick={e => {
                e.stopPropagation()
                setKioskDeleteDHQModal(true)
                setKioskDeleteId(record)
              }}
            >
              Cancel
            </Button>
          )
        } else {
          return null
        }
      }
    }
  ]

  const deletKioskDHQ = () => {
    props.actions.setLoading()
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      },
      body: JSON.stringify({
        queue_id: kioskDeleteId.id,
        donorid: kioskDeleteId.donor_id,
        queuedate: kioskDeleteId.queue_date
      })
    }
    msalFetch('/api/reports/deleteKioskDHQ', options)
      .then(async res => {
        props.actions.clearLoading()
        if (res.ok) {
          toast.success('DHQ cancelled successfully')
          setKioskDeleteDHQModal(false)
          setKioskDeleteId(undefined)
        } else {
          toast.error('Failed to cancel DHQ')
          setKioskDeleteDHQModal(false)
          setKioskDeleteId(undefined)
        }
      })
      .catch(err => {
        props.actions.clearLoading()
        console.log(err)
        toast.error('Failed to cancel DHQ')
      })
  }

  return (
    <>
      <div className='row mt-4 justify-content-center'>
        <div className='col-md-10 col-sm-12'>
          <h3 style={{ color: '#2E236C' }}>Donor Center Queue</h3>
          <div className='row'>
            <div className='col-md-4 col-sm-12'>
              <Calendar
                value={date}
                showIcon
                placeholder='Queue Date'
                onChange={handleDateChange}
                dateFormat='mm/dd/yy'
                showButtonBar
              />
            </div>
            <div className='col-md-4 col-sm-12'>
              Center Code:{' '}
              <Select
                style={{ width: '200px', height: '40px' }}
                options={[{ value: 'LP01', label: 'LP01' }]}
                value={'LP01'}
              />
            </div>
          </div>
          <div className='row my-4'>
            <div className='col-md-10 col-sm-12'>
              <div className='d-flex justify-content-end'>
                <Button onClick={() => getQueue()}>Refresh</Button>
              </div>
              <Table
                dataSource={rows}
                columns={columns}
                onRow={record => {
                  return {
                    onClick: e => {
                      handleDonationClick(record)
                    }
                  }
                }}
                pagination={{ pageSize: 100 }}
              ></Table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={showKioskDHQDeleteModal}
        onOk={deletKioskDHQ}
        onCancel={() => {
          setKioskDeleteDHQModal(false)
          setKioskDeleteId(undefined)
        }}
        title='Are you sure you want to cancel this DHQ?'
        centered
      ></Modal>
    </>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token,
    isAuthenticated: state.isAuthenticated,
    roleid: state.roleid
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DonorCenterQueue)
