import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  DEVIATION_CATEGORIES,
  areas,
} from "../../constants/DeviationFormExceptionCodes";
import { request } from "../../utilities/msalFetch";
import Select from "../../shared/FormInputs/Select";
import TextField from "../../shared/FormInputs/TextField";
import { toast } from "react-toastify";
import DatePicker from "../../shared/FormInputs/DatePicker";
import useCenterCodes from "../../hooks/useCenterCodes";
import RadioGroup from "../../shared/FormInputs/RadioGroup";

const dateFormat = "MM/DD/YYYY";

const validateForm = (formValues) => {
  const newErrors = {};
  if (!formValues.area) newErrors.area = "Please select an area";
  if (!formValues.exceptionCode)
    newErrors.exceptionCode = "Please select an exception code";
  if (!formValues.centerCode)
    newErrors.centerCode = "Please select an center code";
  if (!formValues.dateOfCreation)
    newErrors.dateOfCreation = "Please select a date";
  if (!formValues.dateOfOccurrence)
    newErrors.dateOfOccurrence = "Please select a date";
  if (!formValues.riskClassification)
    newErrors.riskClassification = "Please select a risk classification";
  if (!formValues.iirReferenceNumber)
    newErrors.iirReferenceNumber = "Please enter an IIR reference number";
  if (!formValues.employeeName)
    newErrors.employeeName = "Please enter your name";
  if (!formValues.description)
    newErrors.description = "Please enter a description";
  if (!formValues.employeeSignature)
    newErrors.employeeSignature = "Please enter employee signature";
  return newErrors;
};

const initialState = {
  area: "",
  exceptionCode: "",
  centerCode: "",
  dateOfCreation: "",
  dateOfOccurrence: "",
  riskClassification: "",
  iirReferenceNumber: "",
  employeeName: "",
  description: "",
  actionTaken: "",
  qaComments: "",
  employeeSignature: "",
};

const DeviationForm = () => {
  const [formValues, setFormValues] = useState(initialState);
  const [exceptionCodes, setExceptionCodes] = useState([]);
  const [errors, setErrors] = useState({});
  const [empNames, setEmpNames] = useState([]);
  const centerCodes = useCenterCodes();

  useEffect(() => {
    const getEmpName = async () => {
      const res = await request.get("/api/deviation/empName");
      if (res.data.success) {
        setEmpNames(res.data.employeeNames);
      }
    };

    getEmpName();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleDateChange = (e, name) => {
    console.log(e.value);
    setFormValues({ ...formValues, [name]: e.value });
  };

  const handleAreaChange = (e) => {
    const value = e.target.value;
    setFormValues({ ...formValues, area: value, exceptionCode: "" });
    setExceptionCodes(DEVIATION_CATEGORIES[value] || []);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formValues);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const res = await request.post("/api/deviation/addDeviation", formValues);
    if (res.data.success) {
      setFormValues(initialState);
      setErrors({});
      toast.success("Deviation Created Successfully");
    }
  };

  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div className="p-4">
            <h3 className="card-title text-center mb-4">Deviation Form</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group row mb-2">
                <div className="col d-flex flex-column">
                  <Select
                    label="Center Code"
                    id="centerCode"
                    name="centerCode"
                    value={formValues.centerCode}
                    onChange={handleSelectChange}
                    options={centerCodes}
                    error={errors.centerCode}
                    placeholder={"Select Center Code"}
                    required={true}
                  />
                </div>
                <div className="col d-flex flex-column">
                  <Select
                    label="Area"
                    id="area"
                    name="area"
                    value={formValues.area}
                    onChange={handleAreaChange}
                    options={areas}
                    error={errors.area}
                    placeholder={"Select Area"}
                    required={true}
                  />
                </div>
                <div className="col d-flex flex-column">
                  <Select
                    label="Exception Code"
                    id="exceptionCode"
                    name="exceptionCode"
                    value={formValues.exceptionCode}
                    onChange={handleSelectChange}
                    disabled={!formValues.area}
                    options={exceptionCodes}
                    error={errors.exceptionCode}
                    placeholder={"Select Exception Code"}
                    required={true}
                  />
                </div>
              </div>

              <div className="form-group row mb-2">
                <div className="col-md-4 d-flex flex-column">
                  <DatePicker
                    label="Date of Creation"
                    id="dateOfCreation"
                    name="dateOfCreation"
                    error={errors.dateOfCreation}
                    value={formValues.dateOfCreation}
                    onChange={(e) => handleDateChange(e, "dateOfCreation")}
                    required={true}
                  />
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <DatePicker
                    label="Date of Occurrence"
                    id="dateOfOccurrence"
                    name="dateOfOccurrence"
                    value={formValues.dateOfOccurrence}
                    onChange={(e) => handleDateChange(e, "dateOfOccurrence")}
                    error={errors.dateOfOccurrence}
                    required={true}
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    label="IIR Reference Number"
                    id="iirReferenceNumber"
                    name="iirReferenceNumber"
                    value={formValues.iirReferenceNumber}
                    onChange={handleChange}
                    error={errors.iirReferenceNumber}
                    required={true}
                  />
                </div>
              </div>

              <div className="form-group row mb-2">
                <div className="col">
                  <Select
                    label="Employee Name"
                    id="employeeName"
                    name="employeeName"
                    value={formValues.employeeName}
                    onChange={handleSelectChange}
                    options={empNames || []}
                    error={errors.employeeName}
                    placeholder={"Select Employee Name"}
                    required={true}
                  />
                </div>
                <div className="col-md-3">
                  <RadioGroup
                    name="riskClassification"
                    value={formValues.riskClassification}
                    onChange={handleSelectChange}
                    label={"Risk Classification"}
                    required={true}
                    options={["Minor", "Mojor"]}
                    error={errors.riskClassification}
                  />
                </div>
              </div>

              <div className="form-group mb-2">
                <TextField
                  label="Description"
                  id="description"
                  name="description"
                  multiline={true}
                  rows={2}
                  value={formValues.description}
                  onChange={handleChange}
                  error={errors.description}
                  required={true}
                />
              </div>

              <div className="form-group">
                <TextField
                  label="Employee Signature"
                  id="employeeSignature"
                  name="employeeSignature"
                  value={formValues.employeeSignature}
                  onChange={handleChange}
                  error={errors.employeeSignature}
                  required={true}
                />
              </div>

              <div className="mb-3">
                <span>Date: </span>
                <span>{moment().format(dateFormat)}</span>
              </div>

              <div className="text-center d-flex justify-content-end">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviationForm;
