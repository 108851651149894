import React, { useState, useEffect } from 'react'
import { Dialog } from 'primereact/dialog'
import PhysicalExamination from './PhysicalExamination'
import BodyArt from './BodyArt'
import InformedConsent from './InformedConsent'
import { Tabs } from 'antd'
import { msalFetch } from '../../utilities/msalFetch'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import * as actions from '../../redux/actions'
import { bindActionCreators } from 'redux'
import { Calendar } from 'primereact/calendar'
import moment from 'moment'

function HAFormModal (props) {
  const [assessmentPage, setAssessmentPage] = useState('1')
  const [informConsentSign, setInformedConsentSign] = useState(null)
  const [bodyMarkingsDonorSign, setBodyMarkingsDonorSign] = useState(null)
  const [markings, setMarkings] = useState([])
  const [assessmentType, setAssessmentType] = useState('Full Physical')
  const [answers, setAnswers] = useState({})
  const [missedQuestions, setMissedQuestions] = useState([])
  const [errors, setErrors] = useState({})
  const [assessmentResultFormData, setAssessmentResultFormData] = useState({})
  const [formDisabled, setFormDisabled] = useState(false)
  const [bodyMarkingsPhySubComments, setBodyMarkingsPhySubComments] =
    useState('')

  useEffect(() => {
    if (
      props.selectedAssessment.ASSESSMENT_RESULT &&
      props.selectedAssessment.STATUS === 'Complete'
    ) {
      setAnswers(
        JSON.parse(props.selectedAssessment.PHYSICAL_ASSESSMENT_ANSWERS)
      )
      setFormDisabled(true)
      setAssessmentType(props.selectedAssessment.ASSESSMENT_TYPE)
      setAssessmentResultFormData({})
      setInformedConsentSign(props.selectedAssessment.INFORMED_CONSENT_SIGN)
      props.selectedAssessment.BODY_ART_DONOR_SIGN &&
        setBodyMarkingsDonorSign(props.selectedAssessment.BODY_ART_DONOR_SIGN)
      props.selectedAssessment.BODY_ART_COMMENTS &&
        setBodyMarkingsPhySubComments(
          props.selectedAssessment.BODY_ART_COMMENTS
        )
      props.selectedAssessment.BODY_ART &&
        setMarkings(JSON.parse(props.selectedAssessment.BODY_ART))

      setAssessmentResultFormData({
        assessmentResult: props.selectedAssessment.ASSESSMENT_RESULT,
        donorStatus: props.selectedAssessment.DONOR_STATUS,
        comments: props.selectedAssessment.COMMENTS
      })
    } else {
      props.selectedAssessment &&
        props.selectedAssessment?.ASSESSMENT_TYPE &&
        setAssessmentType(props.selectedAssessment.ASSESSMENT_TYPE)
    }
  }, [props.selectedAssessment])

  const handleAssessmentResultFormChange = e => {
    console.log(e.target.name, e.target.value)
    setErrors({})
    if (
      e.target.name === 'donorStatus' &&
      e.target.value === 'Temporarily Defer'
    ) {
      if (markings.length) {
        const markingsDates = markings.map(x => {
          return moment(x.date, 'MM/DD/YYYY').valueOf()
        })
        const latestDate = markingsDates.sort((a, b) => b - a)[0]
        const threeMonthDeferral = moment(latestDate)
          .add(90, 'days')
          .format('MM/DD/YYYY')
        setAssessmentResultFormData({
          ...assessmentResultFormData,
          deferralTill: threeMonthDeferral,
          [e.target.name]: e.target.value
        })
      } else {
        setAssessmentResultFormData({
          ...assessmentResultFormData,
          [e.target.name]: e.target.value
        })
      }
    } else {
      if (e.target.name === 'deferralTill' && e.target.value) {
        setAssessmentResultFormData({
          ...assessmentResultFormData,
          [e.target.name]: moment(e.target.value).format('MM/DD/YYYY')
        })
      } else {
        setAssessmentResultFormData({
          ...assessmentResultFormData,
          [e.target.name]: e.target.value
        })
      }
    }
  }

  const submitHealthAssessment = () => {
    props.actions.setLoading()
    const body = {
      assessmentId: props.selectedAssessment.ASSESSMENT_ID,
      informedConsentSign: informConsentSign,
      bodyMarkingsDonorSign: bodyMarkingsDonorSign,
      ucn: props.selectedAssessment.UCN,
      physicalAssessmentAnswers: answers,
      bodyArt: markings && markings.length ? markings : null,
      assessmentType: assessmentType,
      bodyArtPhySubComments: bodyMarkingsPhySubComments,
      assessmentResult: assessmentResultFormData.assessmentResult,
      donorStatus: assessmentResultFormData.donorStatus,
      comments: assessmentResultFormData.comments,
      donorId: props.donorId,
      deferralTill: assessmentResultFormData.deferralTill
        ? assessmentResultFormData.deferralTill
        : null
    }
    const options = {
      body: JSON.stringify(body),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    }
    msalFetch('/api/donor/submitHealthAssessment', options)
      .then(async res => {
        props.actions.clearLoading()
        if (res.ok) {
          toast.success('Health Assessment submitted successfully')
          props.getHealthRecords()
          props.setSelectedAssessment(undefined)
        } else {
          toast.error('Failed to upload Health Assessment')
        }
      })
      .catch(err => {
        toast.error(err.message)
        props.actions.clearLoading()
      })
  }

  const validateHAForm = () => {
    if (assessmentType) {
      if (assessmentType === 'Full Physical') {
        const allQuestionIds = props.physicalExamQuestions
          .filter(question => question.isQuestion)
          .map(question => question.questionId)
        const missedQuestions = []
        allQuestionIds.forEach(id => {
          if (!answers[id]) {
            missedQuestions.push(id)
          }
        })
        if (missedQuestions.length) {
          setMissedQuestions([...missedQuestions])
          const missedString = missedQuestions.join(',')
          toast.error(`Please answer questions ${missedString}`)
        } else {
          if (!informConsentSign) {
            toast.error('Donor signature on Informed Consent is required')
            setErrors({
              ...errors,
              informedConsentSign:
                'Donor signature on Informed Consent is required'
            })
          } else if (
            assessmentType === 'Full Physical' &&
            !bodyMarkingsDonorSign
          ) {
            toast.error('Donor signature on Body Art is required')
            setErrors({
              ...errors,
              bodyArt: 'Donor signature on Body Art is required'
            })
          } else if (!assessmentResultFormData.assessmentResult) {
            toast.error('Please enter Assessment Result')
            setErrors({
              ...errors,
              assessmentResult: 'Please enter Assessment Result'
            })
          } else if (!assessmentResultFormData.donorStatus) {
            toast.error('Please enter Donor Status')
            setErrors({ ...errors, donorStatus: 'Please enter donor status' })
          } else if (!assessmentResultFormData.comments) {
            toast.error('Please enter comments')
            setErrors({ ...errors, comments: 'Please enter comments' })
          } else if (
            assessmentResultFormData.donorStatus === 'Temporarily Defer' &&
            !assessmentResultFormData.deferralTill
          ) {
            console.log(assessmentResultFormData)
            toast.error('Please enter deferall till')
            setErrors({ ...errors, deferralTill: 'Please enter deferall till' })
          } else if (
            assessmentResultFormData.donorStatus === 'Temporarily Defer' &&
            assessmentResultFormData.deferralTill &&
            !moment(
              assessmentResultFormData.deferralTill,
              'MM/DD/YYYY'
            ).isValid()
          ) {
            toast.error('Invalid deferal date format')
            setErrors({
              ...errors,
              deferralTill: 'Invalid deferall date format'
            })
          } else {
            submitHealthAssessment()
          }
        }
      } else {
        if (!assessmentResultFormData.assessmentResult) {
          toast.error('Please enter Assessment Result')
          setErrors({
            ...errors,
            assessmentResult: 'Please enter Assessment Result'
          })
        } else if (!assessmentResultFormData.donorStatus) {
          toast.error('Please enter Donor Status')
          setErrors({ ...errors, donorStatus: 'Please enter donor status' })
        } else if (!assessmentResultFormData.comments) {
          toast.error('Please enter comments')
          setErrors({ ...errors, comments: 'Please enter comments' })
        } else {
          submitHealthAssessment()
        }
      }
    } else {
      toast.error('Please select Assessment Type')
    }
  }

  const disableHAPass = () => {
    const checkIfBodyInLast3Months = markings.filter(x => {
      return x.last_three_months == true
    })
    if (checkIfBodyInLast3Months.length) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (assessmentResultFormData.donorStatus === 'Temporarily Defer') {
    }
  }, [assessmentResultFormData])

  const healthAssessmentFormItems = [
    {
      key: '1',
      label: 'Physical Examination Record',
      children: (
        <PhysicalExamination
          questions={props.physicalExamQuestions}
          assessmentType={assessmentType}
          setAssessmentType={setAssessmentType}
          answers={answers}
          setAnswers={setAnswers}
          missedQuestions={missedQuestions}
          setMissedQuestions={setMissedQuestions}
          setAssessmentPage={setAssessmentPage}
          formDisabled={formDisabled}
        />
      )
    },
    {
      key: '2',
      label: 'Informed Consent',
      children: (
        <>
          <InformedConsent
            donorSign={informConsentSign}
            setDonorSign={setInformedConsentSign}
            healthAssessmentFormData={props.healthAssessmentFormData}
            assessmentType={assessmentType}
            setAssessmentPage={setAssessmentPage}
          />
        </>
      ),
      disabled: assessmentType !== 'Full Physical'
    },
    {
      key: '3',
      label: 'Body Art Record',
      children: (
        <BodyArt
          markings={markings}
          setMarkings={setMarkings}
          bodyMarkingsDonorSign={bodyMarkingsDonorSign}
          setBodyMarkingsDonorSign={setBodyMarkingsDonorSign}
          setAssessmentPage={setAssessmentPage}
          bodyMarkingsPhySubComments={bodyMarkingsPhySubComments}
          setBodyMarkingsPhySubComments={setBodyMarkingsPhySubComments}
          formDisabled={formDisabled}
        ></BodyArt>
      ),
      disabled: assessmentType !== 'Full Physical'
    },
    {
      key: '4',
      label: 'Health Assessment Result',
      children: (
        <>
          <div className='row mb-3'>
            <label
              htmlFor='healthassessmentresult'
              className='col-sm-2 col-form-label'
            >
              Assessment Result<span style={{ color: 'red' }}>&#42;</span>:
            </label>
            <div className='col-sm-4'>
              <select
                className='form-select'
                aria-label='Default select example'
                id='healthassessmentresult'
                name='assessmentResult'
                value={assessmentResultFormData.assessmentResult}
                onChange={handleAssessmentResultFormChange}
                required
                disabled={formDisabled}
              >
                <option selected disabled>
                  Select
                </option>
                <option value='Pass' disabled={disableHAPass()}>
                  Pass
                </option>
                <option value='Fail'>Fail</option>
              </select>
            </div>
            {errors.assessmentResult ? (
              <span style={{ color: 'red' }}>{errors.assessmentResult}</span>
            ) : null}
          </div>
          <div className='row mb-3 d-flex'>
            <label className='col-sm-2 col-form-label' htmlFor='donorStatus'>
              Donor Status <span style={{ color: 'red' }}>&#42;</span>:
            </label>
            <div className='col-sm-4'>
              <select
                className='form-select'
                aria-label='Default select example'
                id='donorStatus'
                name='donorStatus'
                onChange={handleAssessmentResultFormChange}
                required
                value={assessmentResultFormData.donorStatus}
                disabled={formDisabled}
              >
                <option selected disabled>
                  Select
                </option>
                <option value='Approved to Donate' disabled={disableHAPass()}>
                  Approved To Donate
                </option>
                <option value='Temporarily Defer'>Temporarily Defer</option>
                <option value='Permanently Defer'>Permanently Defer</option>
              </select>
            </div>
            {errors.donorStatus ? (
              <span style={{ color: 'red' }}>{errors.donorStatus}</span>
            ) : null}
          </div>
          {assessmentResultFormData.donorStatus === 'Temporarily Defer' ? (
            <div className=' row mb-3'>
              <label htmlFor='deferalldate' className='col-sm-2 '>
                Deferall Till<span style={{ color: 'red' }}>&#42;</span> :
              </label>
              <div className='col-sm-4'>
                <Calendar
                  format='mm/dd/yy'
                  id='deferalldate'
                  name='deferralTill'
                  onChange={handleAssessmentResultFormChange}
                  value={
                    assessmentResultFormData.deferralTill
                      ? new Date(assessmentResultFormData.deferralTill)
                      : props.donorInfo?.deferral_till
                      ? new Date(props.donorInfo?.deferral_till)
                      : null
                  }
                  disabled={formDisabled}
                ></Calendar>
                {errors.deferralTill && (
                  <span style={{ fontSize: '12px', color: 'red' }}>
                    {errors.deferralTill}
                  </span>
                )}
              </div>
            </div>
          ) : null}
          <div className='row mb-3'>
            <label className='col-sm-2 col-form-label' htmlFor='comments'>
              Comments <span style={{ color: 'red' }}>&#42;</span>:
            </label>
            <div className='col-sm-4'>
              <textarea
                id='comments'
                rows={3}
                style={{ width: '100%' }}
                className='form-control'
                name='comments'
                onChange={handleAssessmentResultFormChange}
                value={assessmentResultFormData.comments}
                disabled={formDisabled}
              ></textarea>
            </div>
            {errors.comments ? (
              <span style={{ color: 'red' }}>{errors.comments}</span>
            ) : null}
          </div>
          {disableHAPass() ? (
            <p className='text-danger'>
              Donor had Tatoo/Piercing in the last 3 months
            </p>
          ) : null}
          <div className='row mb-3 mt-3'>
            <div className='col-sm-4'>
              {!formDisabled && (
                <button onClick={validateHAForm} className='btn btn-primary'>
                  Submit
                </button>
              )}
            </div>
          </div>
        </>
      )
    }
  ]

  return (
    <Dialog
      header={
        <>
          <span>Health Assessment </span>
          <br></br>
          <span style={{ fontSize: '14px', fontWeight: '400' }}>
            {props.selectedAssessment?.REQUEST_REASON}
          </span>
        </>
      }
      visible={!!props.selectedAssessment}
      maximizable
      style={{ width: '80vw', maxHeight: '80vh', minHeight: '80vh' }}
      onHide={() => {
        props.setSelectedAssessment(null)
      }}
      headerStyle={{ padding: '10px 20px' }}
    >
      <Tabs
        items={healthAssessmentFormItems}
        activeKey={assessmentPage}
        onChange={activeKey => setAssessmentPage(activeKey)}
      ></Tabs>
    </Dialog>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token,
    roleid: state.roleid
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HAFormModal)
