import {
  CLEAR_LOGIN,
  SET_LOGIN_CREDENTIALS,
  SET_ROLES_LIST,
  SET_LOADING,
  CLEAR_LOADING,
  SET_SELECTED_UCN,
  CLEAR_SELECTED_UCN,
  SET_USER_ROLE,
  CLEAR_USER_ROLE,
  SET_PAGE,
  SET_CENTER_CODE
} from './constants'

const initialState = {
  token: null,
  username: '',
  email: '',
  displayName: '',
  isAuthenticated: false,
  roleid: 0,
  rolename: '',
  roles: [],
  loading: false,
  selectedUCN: undefined,
  page: null,
  centerCode: null
}

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN_CREDENTIALS:
      return {
        ...state,
        token: null,
        username: action.payload.username,
        isAuthenticated: true,
        displayName: action.payload.displayName,
        email: action.payload.email
      }
    case CLEAR_LOGIN: {
      return {
        ...state,
        token: null,
        username: '',
        userid: '',
        isAuthenticated: false,
        roles: []
      }
    }
    case SET_ROLES_LIST: {
      return { ...state, allRoles: action.payload }
    }
    case SET_LOADING: {
      return { ...state, loading: true }
    }
    case CLEAR_LOADING: {
      return { ...state, loading: false }
    }
    case SET_SELECTED_UCN: {
      return { ...state, selectedUCN: action.payload }
    }
    case CLEAR_SELECTED_UCN: {
      return { ...state, selectedUCN: undefined }
    }
    case SET_USER_ROLE: {
      return {
        ...state,
        roleid: action.payload.roleid,
        rolename: action.payload.rolename
      }
    }
    case CLEAR_USER_ROLE: {
      return { ...state, roleid: 0, rolename: '' }
    }
    case SET_PAGE: {
      return { ...state, page: action.payload.page }
    }
    case SET_CENTER_CODE: {
      return { ...state, centerCode: action.payload }
    }
    default:
      return state
  }
}

export default rootReducer
