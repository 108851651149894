import React, { useState } from 'react'
import { Button, DatePicker, Form, Input, Select, Radio, Space } from 'antd'
import { InputMask } from 'primereact/inputmask'
import StateCities from '../utilities/US_States_and_Cities.json';
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import { toast } from 'react-toastify'
import { msalFetch } from '../utilities/msalFetch';
import moment from 'moment';
import { useHistory } from 'react-router-dom'

const { Option } = Select
const { TextArea } = Input

function DonorEntry (props) {
  const [form] = Form.useForm()

  const history = useHistory();

  const onFinish = values => {
    let formData = {...values}
    for (let key in values) {
      if (key==='dob' || key==='nddr_date' || key ==='spe_sample_date' || key === 'last_full_physical_date') {
        formData[key] = moment(values[key], 'MM/DD/YYYY').format('YYYY-MM-DD');
      }
    }
    const options = {
      method: 'POST',
      headers: {
        Authorization: props.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData)
    }
    props.actions.setLoading();
    msalFetch('/api/donor/donorEntry', options).then(async res=>{
      props.actions.clearLoading();
      if (res.ok){
        const data = await res.json();
        toast.success(data.message);
        form.resetFields();
        sessionStorage.setItem('selectedDonorId', data.donorId)
          history.push('/donor/dis')
      } else {
        const data = await res.json();
        toast.success(data.message);
      }
    }).catch(err=>{
      props.actions.clearLoading();
    })
  }
  const onFinishFailed = errorInfo => {
    toast.error('Please fill all fields')
  }

  const checkDuplicateDonor = ssn => {
    const options = {
      method: 'GET',
      headers: {
        Authorization: props.token
      }
    }
    const url = `/api/donor/checkDuplicateDonor/${ssn}`
    msalFetch(url, options).then(async res => {
      if (res.ok) {
        const data = await res.json()
        
      }
    })
  }

  const renderForm = () => {
    return (
      <Form
        name='donorEntry'
        form={form}
        labelCol={{
          span: 8
        }}
        wrapperCol={{
          span: 16
        }}
        style={{
          maxWidth: '600px'
        }}
        initialValues={{
          remember: true
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        size='medium'
      >
        <Form.Item
          label='SSN'
          name='ssn'
          rules={[
            {
              required: true,
              message: 'SSN is required'
            }
          ]}
        >
          <InputMask
            id='ssn'
            mask='999-99-9999'
            name='ssn'
            placeholder='999-99-9999'
            //onChange={e => handleChange(e)}
            pattern='\d{3}-?\d{2}-?\d{4}'
          ></InputMask>
        </Form.Item>
        <Form.Item
          label='First Name'
          name='first_name'
          rules={[
            {
              required: true,
              message: 'Please input First Name!'
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Middle Name'
          name='middle_name'
          rules={[
            {
              required: false,
              message: 'Please input your Middle Name!'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Last Name'
          name='last_name'
          rules={[
            {
              required: true,
              message: 'Please input Last Name!'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Gender'
          name='gender'
          rules={[
            {
              required: true,
              message: 'Please input Gender!'
            }
          ]}
        >
          <Select placeholder='Select Gender' onChange={null} allowClear>
            <Option value='M'>Male</Option>
            <Option value='F'>Female</Option>
            <Option value='O'>Other</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label='Date of Birth'
          name='dob'
          rules={[
            {
              required: true,
              message: 'Please input DOB!'
            }
          ]}
        >
          <input type='date' className='form-control form-control-sm'></input>
        </Form.Item>
        <Form.Item
          name='phone'
          label='Phone Number'
          rules={[
            {
              required: true,
              message: 'Please input phone number!'
            }
          ]}
        >
          <InputMask
            className='form-control form-control-sm'
            id='phone'
            name='phone'
            onChange={null}
            required
            mask='(999) 999-9999'
            placeholder='(999) 999-9999'
          ></InputMask>
        </Form.Item>
        <Form.Item
          label='Allergic To'
          name='allergic_to'
          rules={[
            {
              required: false,
              message: 'Please select allergic to!'
            }
          ]}
        >
          <Select placeholder='Select Allergy' onChange={null} allowClear>
            <Option value='Iodine'>Iodine</Option>
            <Option value='Environmental'>Environmental</Option>
            <Option value='Medication'>Medication</Option>
            <Option value='Latex'>Latex</Option>
            <Option value='Others'>Others</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label='Allergy Comments'
          name='allergy_comments'
          rules={[
            {
              required: false,
              message: 'Please input Address 1'
            }
          ]}
        >
          <TextArea></TextArea>
        </Form.Item>
        <Form.Item
          label='Emergency Contact Name'
          name='emergency_contact_name'
          rules={[
            {
              required: true,
              message: 'Please input Emergency Contact Name!'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='emergency_contact_number'
          label='Emergency Contact Number'
          rules={[
            {
              required: true,
              message: 'Please input Emergency Contact phone number!'
            }
          ]}
        >
          <InputMask
            className='form-control form-control-sm'
            id='phone'
            name='phone'
            onChange={null}
            required
            mask='(999) 999-9999'
            placeholder='(999) 999-9999'
          ></InputMask>
        </Form.Item>
        <Form.Item
          label='Vein Grade Left'
          name='vein_grade_left'
          rules={[
            {
              required: true,
              message: 'Please select Vein Grade!'
            }
          ]}
        >
          <Select placeholder='Select Vein Grade' onChange={null} allowClear>
            <Option value='Orange'>Orange</Option>
            <Option value='Green'>Green</Option>
            <Option value='Unsuitable'>Unsuitable</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label='Vein Grade Right'
          name='vein_grade_right'
          rules={[
            {
              required: true,
              message: 'Please select Vein Grade!'
            }
          ]}
        >
          <Select placeholder='Select Vein Grade' onChange={null} allowClear>
            <Option value='Orange'>Orange</Option>
            <Option value='Green'>Green</Option>
            <Option value='Unsuitable'>Unsuitable</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label='Address 1'
          name='addr1'
          rules={[
            {
              required: true,
              message: 'Please input Address 1'
            }
          ]}
        >
          <TextArea></TextArea>
        </Form.Item>
        <Form.Item
          label='Address 2'
          name='addr2'
          rules={[
            {
              required: false,
              message: 'Please input Address 2'
            }
          ]}
        >
          <TextArea></TextArea>
        </Form.Item>
        <Form.Item
          label='City'
          name='city'
          rules={[
            {
              required: true,
              message: 'Please input City'
            }
          ]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item
          label='State'
          name='state'
          rules={[
            {
              required: true,
              message: 'Please select State'
            }
          ]}
        >
          <Select placeholder='Select State' onChange={null} allowClear>
            {Object.keys(StateCities).map(state => {
              return <Option value={state}>{state}</Option>
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label='Zipcode'
          name='zipcode'
          rules={[
            {
              required: true,
              message: 'Please input zipcode'
            }
          ]}
        >
          <Input type='number'></Input>
        </Form.Item>
        <Form.Item
          label='Address Verified'
          name='address_verified'
          rules={[
            {
              required: true,
              message: 'Please select address verified'
            }
          ]}
        >
          <Radio.Group>
            <Radio value='Yes'> Yes </Radio>
            <Radio value='No'> No </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label='Donor Status'
          name='donor_status'
          rules={[
            {
              required: true,
              message: 'Please select Donor Status!'
            }
          ]}
        >
          <Select placeholder='Select Donor Status' onChange={null} allowClear>
            <Option value='ACTIVE'>ACTIVE</Option>
            <Option value='TEMPORARILY DEFERRED'>TEMPORARILY DEFERRED</Option>
            <Option value='TEMPORARILY DEFERRED (Abnormal SPE)'>
              TEMPORARILY DEFERRED (Abnormal SPE)
            </Option>
            <Option value='PERMANENTLY DEFERRED'>PERMANENTLY DEFERRED</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label='Donor Type'
          name='donor_type'
          rules={[
            {
              required: true,
              message: 'Please select Donor Type!'
            }
          ]}
        >
          <Select placeholder='Select Donor Type' onChange={null} allowClear>
            <Option value='APPLICANT 1'>APPLICANT 1</Option>
            <Option value='APPLICANT 2'>APPLICANT 2</Option>
            <Option value='QUALIFIED'>QUALIFIED</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label='NDDR trans Id'
          name='nddr'
          rules={[
            {
              required: true,
              message: 'Please input NDDR!'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='NDDR Trans Date'
          name='nddr_date'
          rules={[
            {
              required: true,
              message: 'Please input NDDR Date!'
            }
          ]}
        >
          <input type='date' className='form-control form-control-sm'></input>
        </Form.Item>
        <Form.Item
          label='NDDR Check'
          name='nddr_check'
          rules={[
            {
              required: true,
              message: 'Please select NDDR Check!'
            }
          ]}
        >
          <Select placeholder='Select NDDR check' onChange={null} allowClear>
            <Option value='Pass'>Pass</Option>
            <Option value='Fail'>Fail</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label='Last SPE Sample Date'
          name='spe_sample_date'
          rules={[
            {
              required: true,
              message: 'Please select SPE sample date!'
            }
          ]}
        >
          <input type='date' className='form-control form-control-sm'></input>
        </Form.Item>
        <Form.Item
          label='Last Full Physical Date'
          name='last_full_physical_date'
          rules={[
            {
              required: true,
              message: 'Please select last full physical date!'
            }
          ]}
        >
          <input type='date' className='form-control form-control-sm'></input>
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16
          }}
        >
          <Space>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
            <Button htmlType='reset'>Reset</Button>
          </Space>
        </Form.Item>
      </Form>
    )
  }

  return (
    <div className='row justify-content-center'>
      <div className='col-md-10 mt-4'>
        <p className='title'>Existing Donor Entry</p>
        <div className='mt-3 '>{renderForm()}</div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DonorEntry)
