import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import styles from '../styles/CasePacking.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import { toast } from 'react-toastify'
import ReactToPrint from 'react-to-print'
import PrintCasePacking from './PrintCasePacking'
import { checkValidUCN, checkValidCaseNumber } from '../utilities/validations'
import { formatDate } from '../utilities/format'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { msalFetch } from '../utilities/msalFetch'
import { Input } from 'antd'
const { TextArea } = Input

function CasePacking (props) {
  let initialState = new Array(15).fill().map((_, i) => ({
    seq: i + 1,
    ucn: '',
    donor_id: '',
    name: '',
    volume: '',
    donationDate: '',
    donationStatus: '',
    donorStatus: '',
    donorType: '',
    invalidUCN: false,
    notFound: false,
    lab_report: '',
    valid: undefined,
    sixtyDayHold: true,
    redundant: false,
    redundant_case: ''
  }))

  const [caseid, setCaseid] = useState('')
  const [showDetails, setShowDetails] = useState(false)
  const [caseTable, setCaseTable] = useState([...initialState])
  const [validated, setValidated] = useState(false)
  const [status, setStatus] = useState('NEW')
  const [inValidCaseNo, setInValidCaseNo] = useState(false)
  const [shipped, setShipped] = useState(false)
  const [casePackingLogs, setCasePackingLogs] = useState([])
  const [comments, setComments] = useState('')

  const { caseno } = useParams()

  let printRef = useRef(null)

  const history = useHistory()

  useEffect(() => {
    return () => {
      setCaseTable(initialState)
    }
  }, [])

  useEffect(() => {
    if (caseno && checkValidCaseNumber(caseno)) {
      setCaseid(caseno)
      let url = `/api/casePacking/getCaseDetails/${caseno}`
      msalFetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: props.token,
          'Content-Type': 'application/json'
        }
      }).then(async res => {
        props.actions.clearLoading()
        if (res.ok) {
          getCasePackingLogs(caseno)
          const data = await res.json()
          if (data.UCNS?.length) {
            let ucnList = JSON.parse(data.UCNS)
            let newTable = caseTable.map((x, i) => {
              if (i < ucnList.length) {
                x.ucn = ucnList[i]
              }
              return x
            })
            setShipped(Boolean(data.SHIPPED))
            setCaseTable(newTable)
            setShowDetails(true)
            setStatus(data.STATUS)
            validate(newTable)
            setComments(data.COMMENTS)
          } else {
            setStatus('New')
            setCaseTable(initialState)
            setShowDetails(true)
          }
        }
      })
    }
  }, [caseno])

  const handleCaseNoChange = e => {
    setCaseid(e.target.value)
    if (checkValidCaseNumber(e.target.value)) {
      setInValidCaseNo(false)
    } else {
      setInValidCaseNo(true)
    }
  }

  const getCasePackingDetails = e => {
    props.actions.setLoading()
    setCaseTable(initialState)
    e.preventDefault()
    let url = `/api/casePacking/getCaseDetails/${caseid}`
    msalFetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: props.token,
        'Content-Type': 'application/json'
      }
    })
      .then(async res => {
        props.actions.clearLoading()
        if (res.ok) {
          getCasePackingLogs(caseid)
          const data = await res.json()
          if (data.UCNS?.length) {
            let ucnList = JSON.parse(data.UCNS)
            let newTable = initialState.map((x, i) => {
              if (i < ucnList.length) {
                x.ucn = ucnList[i]
              }
              return x
            })
            setCaseTable(newTable)
            setShowDetails(true)
            setStatus(data.STATUS)
            validate(newTable)
            setShipped(Boolean(data.SHIPPED))
            setComments(data.COMMENTS)
          } else {
            setStatus('New')
            setCaseTable(initialState)
            setShowDetails(true)
          }
        }
      })
      .catch(err => {
        toast.error(err.message)
        props.actions.clearLoading()
      })
  }

  const getCasePackingLogs = case_number => {
    const options = {
      method: 'GET',
      headers: {
        Authorization: props.token,
        'Content-Type': 'application/json'
      }
    }
    msalFetch(
      `/api/casePacking/getCasePackingLogs/${case_number}`,
      options
    ).then(async res => {
      if (res.ok) {
        const data = await res.json()
        setCasePackingLogs(data)
      } else {
        toast.error('Failed to fetch Case Packing Logs')
      }
    })
  }

  const handleUCNAdd = (e, id) => {
    if (checkValidUCN(e.target.value)) {
      let duplicate = caseTable.findIndex(x => x.ucn == e.target.value)

      if (duplicate == -1) {
        let newValues = caseTable.map(x => {
          if (x.seq == id) {
            x.ucn = e.target.value
            x.invalidUCN = false
          }
          return x
        })
        setCaseTable(newValues)
        id <= 14 && document.getElementById(id + 1).focus()
      } else {
        document.getElementById(id + 1).value = ''
        toast.warning('Duplicate UCN ')
      }
    } else {
      let newValues = caseTable.map(x => {
        if (x.seq == id) {
          x.invalidUCN = e.target.value == '' ? false : true
          x.ucn = e.target.value
        }
        return x
      })
      setCaseTable([...newValues])
    }
  }

  const deleteRow = id => {
    if (status !== 'QA RELEASED') {
      let newTable = caseTable.map(x => {
        if (x.seq == id) {
          x.ucn = ''
          x.volume = ''
          x.donationDate = ''
          x.donationStatus = ''
          x.donorStatus = ''
          x.invalidUCN = false
          x.notFound = false
          x.lab_report = ''
          x.valid = undefined
          x.donorType = ''
          x.donor_id = ''
          x.name = ''
          x.sixtyDayHold = true
          x.redundant = false
          x.redundant_case = ''
          x.unit_status = ''
        }
        return x
      })
      setCaseTable(newTable)
    }
  }

  const validateButtonDisable = () => {
    if (status == 'QA RELEASED') {
      return true
    } else {
      let non_blank_ucn = caseTable.filter(x => x.ucn != '')
      if (non_blank_ucn == 0) {
        return true
      } else {
        let temp = 0
        caseTable.forEach(x => {
          if (x.invalidUCN) {
            temp += 1
          }
        })
        return temp == 0 ? false : true
      }
    }
  }

  const completeCasePackingButtonDisable = () => {
    const nonEmptyRows = caseTable.filter(x => x.ucn != '')
    if (nonEmptyRows.length == 0) {
      return true
    } else if (status == 'QA RELEASED') {
      let invalidUnits = 0
      caseTable.forEach(x => {
        if (x.unit_status !== 'Qualified') {
          invalidUnits += 1
        }
      })
      if (invalidUnits && !shipped) {
        return false
      } else {
        return true
      }
    }
    return false
  }

  const qaButtonDisabled = () => {
    if (props.roleid != 3) {
      return true
    } else {
      if (status == 'PENDING QA REVIEW') {
        return false
      } else {
        if (status === 'QA RELEASED') {
          let invalidUnits = 0
          caseTable.forEach(x => {
            if (x.unit_status !== 'Qualified') {
              invalidUnits += 1
            }
          })
          if (invalidUnits && props.roleid == 3 && !shipped) {
            return false
          } else {
            return true
          }
        }
      }
    }
  }

  const validate = (table = []) => {
    props.actions.setLoading()
    const ucnList =
      table.length > 0
        ? table
            .filter(x => {
              return x.ucn != null && x.ucn != ''
            })
            .map(x => x.ucn)
        : caseTable
            .filter(x => {
              return x.ucn != null && x.ucn != ''
            })
            .map(x => x.ucn)

    const options = {
      method: 'POST',
      headers: {
        Authorization: props.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ucnList, caseid: caseid ? caseid : caseno })
    }
    msalFetch('/api/casePacking/validate', options).then(async res => {
      props.actions.clearLoading()
      if (res.ok) {
        let data = await res.json()
        let temp1 = table && table.length ? table : caseTable
        let temp2 = temp1.map((x, i) => {
          if (i < table.length) {
            x = table[i]
          }
          x.seq = i + 1
          return x
        })
        const newTable = temp2.map(x => {
          if (x.ucn != '') {
            let ucnDetails = data.find(y => y.ucn == x.ucn)
            x.volume = ucnDetails.volume
            x.donationDate = ucnDetails.donationDate
            x.donationStatus = ucnDetails.donationStatus
            x.donorStatus = ucnDetails.donorStatus
            x.notFound = ucnDetails.notFound
            x.lab_report = ucnDetails.lab_report
            x.donorType = ucnDetails.donorType
            x.donor_id = ucnDetails.donor_id
            x.name = ucnDetails.name
            x.sixtyDayHold = ucnDetails.sixtyDayHold
            x.redundant = ucnDetails.redundant
            x.redundant_case = ucnDetails.redundant_case
            x.unit_status = ucnDetails.unit_status
            if (
              ucnDetails.volume < 150 ||
              ucnDetails.donationStatus !== 'QA Reviewed' ||
              ucnDetails.unit_status !== 'Qualified' ||
              ucnDetails.lab_report === 'Reactive' ||
              ucnDetails.lab_report === 'ATYA Positive' ||
              ucnDetails.lab_report === 'Partial' ||
              ucnDetails.lab_report === 'No Records' ||
              ucnDetails.notFound ||
              ucnDetails.redundant ||
              !ucnDetails.sixtyDayHold
            ) {
              x.valid = false
            } else {
              x.valid = true
            }
          }
          return x
        })
        setValidated(true)
        setCaseTable([...newTable])
      } else {
        toast.error('Failed to validate')
      }
    })
  }

  const saveDisable = () => {
    if (status === 'QA RELEASED') {
      return true
    } else {
      const ucnList = [...caseTable]
      const non_blank_ucn = ucnList.filter(x => x.ucn && checkValidUCN(x.ucn))
      if (non_blank_ucn.length) {
        return false
      } else {
        //return true
        return false //changed as part of request 29 - need to save comment for empty cases
      }
    }
  }

  const getStatus = () => {
    props.actions.setLoading()
    let body = {
      case_number: caseid
    }
    const options = {
      method: 'POST',
      headers: {
        Authorization: props.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    msalFetch('/api/casePacking/getStatus', options)
      .then(async res => {
        props.actions.clearLoading()
        const data = await res.json()
        setStatus(data.status)
      })
      .catch(err => {
        toast.error(err.message)
        props.actions.clearLoading()
      })
  }

  const handleSave = () => {
    props.actions.setLoading()
    let body = {
      case_number: caseid,
      ucns: caseTable.filter(x => x.ucn !== '').map(x => x.ucn),
      comments
    }
    const options = {
      method: 'POST',
      headers: {
        Authorization: props.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    msalFetch('/api/casePacking/saveCasePacking', options).then(async res => {
      props.actions.clearLoading()
      if (res.ok) {
        toast.success('Case Packing saved successfully')
        getStatus()
      }
    })
  }

  const checkUnits = () => {
    let valid = true
    for (let i = 0; i < caseTable.length; i++) {
      if (
        caseTable[i].ucn &&
        (caseTable[i].volume == 0 ||
          caseTable[i].donationStatus !== 'QA Reviewed' ||
          (caseTable[i].lab_report !== 'Pass' &&
            caseTable[i].lab_report !== 'Abnormal SPE') ||
          caseTable[i].notFound ||
          caseTable[i].unit_status !== 'Qualified' ||
          !caseTable[i].sixtyDayHold ||
          caseTable[i].redundant)
      ) {
        valid = false
        toast.error(`Please remove unit ${caseTable[i].ucn} to proceed`)
        break
      }
    }
    return valid
  }

  const completeCasing = () => {
    if (checkUnits()) {
      props.actions.setLoading()
      let body = {
        case_number: caseid,
        ucns: caseTable.filter(x => x.ucn != '').map(x => x.ucn),
        volume: parseInt(
          caseTable.reduce((acc, record) => {
            return (
              parseInt(acc) +
              (record.volume && isNaN(record.volume)
                ? 0
                : parseInt(record.volume))
            )
          }, 0)
        )
      }
      const options = {
        method: 'POST',
        headers: {
          Authorization: props.token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }
      msalFetch('/api/casePacking/completeCasePacking', options).then(
        async res => {
          props.actions.clearLoading()
          if (res.ok) {
            toast.success('Case Packing completed successfully')
            getStatus()
          }
        }
      )
    }
  }

  const completeQAReview = () => {
    if (checkUnits()) {
      props.actions.setLoading()
      let body = {
        case_number: caseid,
        ucns: caseTable.filter(x => x.ucn != '').map(x => x.ucn),
        volume: parseInt(
          caseTable.reduce((acc, record) => {
            return (
              parseInt(acc) +
              (record.volume && !isNaN(record.volume)
                ? parseInt(record.volume)
                : 0)
            )
          }, 0)
        )
      }
      const options = {
        method: 'POST',
        headers: {
          Authorization: props.token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }
      msalFetch('/api/casePacking/completeQAReview', options)
        .then(async res => {
          props.actions.clearLoading()
          const data = await res.json()
          if (res.ok) {
            toast.success('QA Review completed successfully')
            getStatus()
          } else {
            toast.error(data.message)
          }
        })
        .catch(err => {
          toast.error(err.message)
          props.actions.clearLoading()
        })
    }
  }

  const rollBackQA = () => {
    try {
      props.actions.setLoading()
      const body = {
        case_number: caseid
      }
      const options = {
        method: 'POST',
        headers: {
          Authorization: props.token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }
      msalFetch('/api/casePacking/rollbackQARelease', options)
        .then(async res => {
          props.actions.clearLoading()
          if (res.ok) {
            let data = await res.json()
            toast.success(data.message)
            setStatus(data.status)
          }
        })
        .catch(err => {
          props.actions.clearLoading()
          toast.error('Failed to rollback')
        })
    } catch (err) {}
  }

  const getVolume = () => {
    return caseTable
      .filter(x => x.ucn != '' && x.volume > 0)
      .reduce((acc, caseunit) => {
        return parseInt(acc) + parseInt(caseunit.volume)
      }, 0)
  }

  const getDateRange = () => {
    const dates = caseTable
      .filter(x => x.donationDate != null && x.donationDate !== '')
      .sort(function (a, b) {
        return new Date(a.donationDate) - new Date(b.donationDate)
      })
    if (dates.length) {
      const minDate = dates[0].donationDate.slice(0, 10)
      const maxDate = dates[dates.length - 1].donationDate.slice(0, 10)
      return {
        maxDate: moment(maxDate.slice(0)).format('MM/DD/YYYY'),
        minDate: moment(minDate).format('MM/DD/YYYY')
      }
    } else {
      return {
        maxDate: '',
        minDate: ''
      }
    }
  }

  return (
    <div
      className={`row mt-2 d-flex justify-content-center ${styles.casePacking}`}
    >
      <div className='col-md-10'>
        <div className='row mt-2 justify-content-center d-flex mb-4 p-4'>
          <div className='col-md-12 p-0'>
            <h3 className={styles.casePackingHeading}>Case Packing</h3>
            <div className='row mt-4'>
              <div className='col-sm-12'>
                <form onSubmit={getCasePackingDetails}>
                  <div className='form-group row'>
                    <label htmlFor='caseid' className='col-sm-2 col-form-label'>
                      Case No:
                    </label>
                    <div className='col-sm-3'>
                      <input
                        type='text'
                        className='form-control form form-control-sm'
                        value={caseid}
                        id='caseid'
                        placeholder='Enter Case No'
                        onChange={handleCaseNoChange}
                      />
                      {inValidCaseNo ? (
                        <p style={{ fontSize: '14px', color: 'red' }}>
                          Invalid Case Number
                        </p>
                      ) : null}
                    </div>
                    <div className='col-sm-2'>
                      <button
                        type='submit'
                        className='btn btn-sm btn-primary'
                        disabled={caseid == '' || inValidCaseNo}
                      >
                        Submit
                      </button>
                    </div>
                    {showDetails ? (
                      <>
                        <div className='col-sm-3'>
                          <span className={styles.statusText}>
                            Case Packing Status :{' '}
                            <span className={styles.statusValue}>{status}</span>
                          </span>
                        </div>
                        <div className='col-sm-2'>
                          <span className={styles.statusText}>
                            Shipped :{' '}
                            <span className={styles.statusValue}>
                              {shipped ? 'Yes' : 'No'}
                            </span>
                          </span>
                        </div>
                      </>
                    ) : null}
                  </div>
                </form>
              </div>
            </div>
            {showDetails ? (
              <div className='row mt-4'>
                <Table className='table table-striped table-sm'>
                  <Thead className='thead-dark'>
                    <Tr>
                      <Th scope='col' style={{ minHeight: '1.5rem' }}>
                        #
                      </Th>
                      <Th scope='col' style={{ minHeight: '1.5rem' }}>
                        UCN
                      </Th>
                      <Th scope='col' style={{ minHeight: '1.5rem' }}>
                        Donor Id
                      </Th>
                      <Th scope='col' style={{ minHeight: '1.5rem' }}>
                        Name
                      </Th>
                      <Th scope='col' style={{ minHeight: '1.5rem' }}>
                        Volume (mL)
                      </Th>
                      <Th scope='col' style={{ minHeight: '1.5rem' }}>
                        Donation Date
                      </Th>
                      <Th scope='col' style={{ minHeight: '1.5rem' }}>
                        Donation Status
                      </Th>
                      <Th scope='col' style={{ minHeight: '1.5rem' }}>
                        Lab Result
                      </Th>
                      <Th scope='col' style={{ minHeight: '1.5rem' }}>
                        Unit Status
                      </Th>
                      <Th scope='col' style={{ minHeight: '1.5rem' }}></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {caseTable.map(x => {
                      return (
                        <Tr
                          key={x.seq}
                          className={
                            x.valid == undefined
                              ? ''
                              : x.valid == true
                              ? 'table-success'
                              : 'table-danger'
                          }
                        >
                          <Th scope='row'>{x.seq}</Th>
                          <Td>
                            <input
                              type='text'
                              value={x.ucn}
                              onChange={e => handleUCNAdd(e, x.seq)}
                              id={x.seq}
                              style={{ width: '120px' }}
                            ></input>
                            {x.invalidUCN ? (
                              <p
                                className='text-danger'
                                style={{ fontSize: '13px' }}
                              >
                                Invalid UCN
                              </p>
                            ) : null}
                            {x.notFound ? (
                              <p
                                className='text-danger'
                                style={{ fontSize: '13px' }}
                              >
                                UCN not found
                              </p>
                            ) : null}
                            {x.redundant ? (
                              <p
                                className='text-danger'
                                style={{ fontSize: '13px' }}
                              >
                                UCN {x.ucn} Exists in Case {x.redundant_case}
                              </p>
                            ) : null}
                          </Td>
                          <Td style={{ minHeight: '1.5rem' }}>{x.donor_id}</Td>
                          <Td style={{ minHeight: '1.5rem' }}>{x.name}</Td>
                          <Td style={{ minHeight: '1.5rem' }}>
                            {x.volume}{' '}
                            {x.volume != '' && x.volume < 150 ? (
                              <span> &lt; 150mL</span>
                            ) : null}
                          </Td>
                          <Td style={{ minHeight: '1.5rem' }}>
                            {x.donationDate ? formatDate(x.donationDate) : ''}{' '}
                            {/*x.donationDate && !x.sixtyDayHold ? (
                              <span> &lt; 60 Days</span>
                            ) : null*/}
                          </Td>
                          <Td style={{ minHeight: '1.5rem' }}>
                            {x.donationStatus}
                          </Td>

                          <Td style={{ minHeight: '1.5rem' }}>
                            {x.lab_report}
                          </Td>
                          <Td style={{ minHeight: '1.5rem' }}>
                            {x.unit_status}
                          </Td>
                          <Td>
                            {x.ucn != '' && (
                              <FontAwesomeIcon
                                icon={faTrash}
                                style={{ color: 'red' }}
                                onClick={() => deleteRow(x.seq)}
                              ></FontAwesomeIcon>
                            )}
                          </Td>
                        </Tr>
                      )
                    })}
                  </Tbody>
                </Table>
              </div>
            ) : null}
            {showDetails && (
              <div className='row'>
                <div className='col-md-10 my-2'>
                  <h5>Total Volume: {getVolume() + ' mL'}</h5>
                </div>
              </div>
            )}
            {showDetails ? (
              <div className='row'>
                <div className='col-md-4'>
                  <label htmlFor='comments'>Comments:</label>
                  <TextArea
                    rows={3}
                    onChange={e => setComments(e.target.value)}
                    value={comments}
                    disabled={saveDisable()}
                    id='comments'
                  ></TextArea>
                </div>
              </div>
            ) : null}
            {showDetails ? (
              <div className='row mt-2'>
                <div className='col-md-12 d-md-flex justify-content-between justify-content-sm-center align-items-center'>
                  <div className='col-sm-8 col-md-2 px-2'>
                    <button
                      className='btn btn-dark btn-sm w-100 m-2'
                      disabled={saveDisable()}
                      onClick={handleSave}
                    >
                      Save
                    </button>
                  </div>
                  <div className='col-sm-8 col-md-2 px-2'>
                    <button
                      className='btn btn-warning btn-sm w-100 m-2'
                      disabled={validateButtonDisable()}
                      onClick={validate}
                    >
                      Validate
                    </button>
                  </div>
                  {props.roleid != 3 ? (
                    <div className='col-sm-8 col-md-2 px-2'>
                      <button
                        className='btn btn-primary btn-sm w-100 m-2'
                        disabled={completeCasePackingButtonDisable()}
                        onClick={completeCasing}
                      >
                        Complete Case Packing
                      </button>
                    </div>
                  ) : null}
                  {props.roleid == 3 && status !== 'QA RELEASED' ? (
                    <div className='col-sm-8 col-md-2 px-2'>
                      <button
                        className='btn btn-success btn-sm w-100 m-2'
                        disabled={qaButtonDisabled()}
                        onClick={completeQAReview}
                      >
                        QA Release
                      </button>
                    </div>
                  ) : null}
                  {props.roleid == 3 && status == 'QA RELEASED' && !shipped ? (
                    <div className='col-sm-8 col-md-2 px-2'>
                      <button
                        className='btn btn-success btn-sm w-100 m-2'
                        onClick={rollBackQA}
                      >
                        Reverse QA Release
                      </button>
                    </div>
                  ) : null}

                  <div className='col-sm-8 col-md-2 px-2'>
                    <ReactToPrint
                      trigger={() => (
                        <button className='btn btn-secondary btn-sm w-100 m-2'>
                          Print Case Packing List
                        </button>
                      )}
                      content={() => printRef}
                    ></ReactToPrint>
                  </div>
                  <div className='col-sm-8 col-md-2 px-2'>
                    <button
                      className='btn btn-danger btn-sm w-100 m-2'
                      onClick={() => history.push('/dashboard')}
                    >
                      Exit
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div style={{ display: 'none' }}>
            <PrintCasePacking
              ref={el => {
                printRef = el
              }}
              status={status}
              caseno={caseid}
              casingData={caseTable}
              dates={getDateRange()}
              volume={getVolume()}
            ></PrintCasePacking>
          </div>
        </div>
        <div className='row mt-2 d-flex mb-4'>
          <div className='col-md-6'>
            <h5>Case Packing Change Logs</h5>
            <table className='table table-sm table-bordered table-light'>
              <thead>
                <tr>
                  <th>User</th>
                  <th>Old Status</th>
                  <th>New Status</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {casePackingLogs.map(x => {
                  return (
                    <tr key={x.change_id}>
                      <td>{x.user}</td>
                      <td>{x.old_value}</td>
                      <td>{x.new_value}</td>
                      <td>{formatDate(x.datetime)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token,
    roleid: state.roleid
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CasePacking)
