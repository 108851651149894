import React, { useState } from 'react'
import { Button, Input, Table } from 'antd'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import { toast } from 'react-toastify'
import { msalFetch } from '../utilities/msalFetch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel, faFileText } from '@fortawesome/free-solid-svg-icons'
import xlsx from 'json-as-xlsx'
import { saveAs } from 'file-saver'

function ShippingDetails (props) {
  const [rows, setRows] = useState([])
  const [bol, setBol] = useState('')

  const columns = [
    {
      title: 'BOL#',
      dataIndex: 'bol',
      key: 'bol'
    },
    { title: 'Bleed Number', dataIndex: 'ucn', key: 'ucn' },
    { title: 'Donor Number', dataIndex: 'ssn', key: 'ssn' },
    { title: 'Unit Vol (mL)', dataIndex: 'volume', key: 'volume' },
    { title: 'Bleed Date', dataIndex: 'donation_date', key: 'donation_date' },
    { title: 'Case Number', dataIndex: 'case_no', key: 'case_no' }
  ]

  const handleBolChange = e => {
    setBol(e.target.value)
  }

  const getBOlDetails = () => {
    if (bol) {
      props.actions.setLoading()
      const options = {
        method: 'GET',
        headers: {
          Authorization: props.token,
          'Content-Type': 'application/json'
        }
      }
      msalFetch(`/api/reports/getBOLDetailsReport/${bol}`, options).then(
        async res => {
          props.actions.clearLoading()
          if (res.ok) {
            const data = await res.json()
            setRows(data)
          } else {
            toast.error('Failed to get summary report')
          }
        }
      )
    } else {
      toast.error('Please enter Bill Of Lading')
    }
  }

  const renderFooter = () => {
    if (rows.length) {
      return (
        <>
          Download :
          <button
            className='btn btn-outline-primary mx-2'
            onClick={downloadToExcel}
          >
            <FontAwesomeIcon icon={faFileExcel} size='xl'></FontAwesomeIcon>
          </button>
          <button
            className='btn btn-outline-primary mx-2'
            onClick={downloadToNotePad}
          >
            <FontAwesomeIcon icon={faFileText} size='xl'></FontAwesomeIcon>
          </button>
        </>
      )
    }
    return null
  }

  const downloadToExcel = () => {
    const dataforExcel = rows.map(x => {
      let temp = {}
      temp['bleedNumber'] = x.ucn
      temp['donorNumber'] = x.ssn
      temp['unitVol'] = x.volume
      temp['bleedDate'] = x.donation_date
      temp['caseNumber'] = x.case_no
      return temp
    })
    const data = [
      {
        sheet: 'Sheet1',
        columns: [
          { label: 'Bleed Number', value: 'bleedNumber' },
          { label: 'Donor Number', value: 'donorNumber' },
          { label: 'Unit Vol (mL)', value: 'unitVol' },
          { label: 'Bleed Date', value: 'bleedDate' },
          { label: 'Case Number', value: 'caseNumber' }
        ],
        content: [...dataforExcel]
      }
    ]
    const fileSettings = {
      fileName: `DeliveryFile-${rows[0].bol}`,
      extraLength: 3,
      writeMode: 'writeFile',
      writeOptions: {},
      RTL: false
    }

    xlsx(data, fileSettings)
  }

  const downloadToNotePad = () => {
    let content = ''
    rows.forEach(x => {
      content += x.ucn + x.ssn + x.volume + x.donation_date + x.case_no
      content += '\n'
    })
    var blob = new Blob([content], { type: 'text/plain;charset=utf-8' })
    saveAs(blob, `${rows[0].bol}.txt`)
  }

  return (
    <div className='row d-flex justify-content-center mt-3'>
      <div className='col-md-10'>
        <p className='title'>Shipping Details Report</p>
        <div className='row d-flex'>
          <label htmlFor='shippingdaterange' className='col-md-1'>
            Bill of Lading :
          </label>
          <Input
            onChange={handleBolChange}
            placeholder='BOL'
            className='mx-3 col-md-2'
            value={bol}
          ></Input>
          <Button
            type='primary'
            onClick={getBOlDetails}
            className='col-md-1'
            disabled={!bol}
          >
            Apply
          </Button>
        </div>
        <div className='mt-4'>
          <Table
            columns={columns}
            dataSource={rows}
            size='small'
            footer={renderFooter}
          ></Table>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token,
    roleid: state.roleid
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShippingDetails)
