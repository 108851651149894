import React, { useState, useEffect } from "react";
import { request } from "../../utilities/msalFetch";
import { Modal, Button, Form } from "react-bootstrap";
import moment from "moment";
import { toast } from "react-toastify";

const DeviationFormReview = ({ record, onClose, onSubmit }) => {
  const [formValues, setFormValues] = useState({
    actionTaken: "",
    qaComments: "",
    status: "",
  });

  const [errors, setErrors] = useState({
    actionTaken: "",
    qaComments: "",
    status: "",
  });

  useEffect(() => {
    if (record) {
      setFormValues({
        actionTaken: record.action_taken || "",
        qaComments: record.qa_comments || "",
        status: record.status || "",
      });
    }
  }, [record]);

  const validate = () => {
    let valid = true;
    let errors = {};

    if (!formValues.status) {
      errors.status = "Status is required";
      valid = false;
    }

    if (!formValues.actionTaken) {
      errors.actionTaken = "Action Taken is required";
      valid = false;
    }

    if (!formValues.qaComments) {
      errors.qaComments = "QA Comments are required";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      return;
    }
    try {
      const response = await request.post("/api/deviation/updateDeviation", {
        deviationNumber: record.deviation_number,
        actionTaken: formValues.actionTaken,
        qaComments: formValues.qaComments,
        status: formValues.status,
      });
      if (response.data.success) {
        onClose();
        onSubmit(e);
        toast.success("Deviation Reviewed");
      } else {
        toast.error("Failed to update details");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error occurred while updating details");
    }
  };

  const renderRecordField = (label, value) => (
    <div className="mb-3">
      <strong>{label}:</strong> {value}
    </div>
  );

  const formatDate = (date) => moment(date).format("MM-DD-YYYY");

  return (
    <Modal.Body>
      {record && (
        <div>
          <div>
            {renderRecordField("Deviation Number", record.deviation_number)}
            {renderRecordField("Center Code", record.center_code)}
            {renderRecordField("Area", record.area)}
            {renderRecordField("Exception Code", record.exception_code)}
            {renderRecordField(
              "Date of Creation",
              formatDate(record.date_of_creation)
            )}
            {renderRecordField(
              "Date of Occurrence",
              formatDate(record.date_of_occurrence)
            )}
            {renderRecordField(
              "Risk Classification",
              record.risk_classification
            )}
            {renderRecordField("Employee Name", record.employee_name)}
          </div>

          <Form>
            <Form.Group controlId="formStatus" className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={formValues.status}
                onChange={handleChange}
                defaultValue={record.status}
                isInvalid={!!errors.status}
              >
                {["Created","In Progress", "QA Reviewed", "Closed"].map((value) => {
                  return (
                    <option value={value} disabled={record.status === value}>
                      {value}
                    </option>
                  );
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.status}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formActionTaken" className="mb-3">
              <Form.Label>Action Taken</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="actionTaken"
                value={formValues.actionTaken}
                onChange={handleChange}
                isInvalid={!!errors.actionTaken}
              />
              <Form.Control.Feedback type="invalid">
                {errors.actionTaken}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formQaComments" className="mb-3">
              <Form.Label>QA Comments</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="qaComments"
                value={formValues.qaComments}
                onChange={handleChange}
                isInvalid={!!errors.qaComments}
              />
              <Form.Control.Feedback type="invalid">
                {errors.qaComments}
              </Form.Control.Feedback>
            </Form.Group>
            <Modal.Footer>
              <Button variant="secondary" onClick={onClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Form>
        </div>
      )}
    </Modal.Body>
  );
};

export default DeviationFormReview;
