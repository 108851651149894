import './styles/App.css'
import React, { useState } from 'react'
import Header from './components/Header'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import ProtectedRoute from './components/Redirect'
import { connect } from 'react-redux'
import * as actions from './redux/actions'
import { bindActionCreators } from 'redux'
import DonorRegister from './components/DonorRegister'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import DIS from './components/DISMain'
import Dashboard from './components/Dashboard'
import Loader from './components/Loader'
import CasePacking from './components/CasePacking'
import Search from './components/Search'
import QADonationsPage from './components/QADonationsPage'
import CasePackReport from './components/CasePackReport'
import CaseShipping from './components/CaseShipping'
import LabOrderReport from './components/LabOrderReport'
import LabOrder from './components/LabOrder'
import LabReportUpload from './components/LabReportUpload'
import CDCSLogs from './components/CDCSLogs'
import ScanUCN from './components/ScanUCN'
import { useIdleTimer } from 'react-idle-timer'
import { Modal } from 'react-bootstrap'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { msalFetch } from './utilities/msalFetch'
import HealthAssessments from './modules/Reports/HAReport'
import LabResultsReport from './components/LabResultsReport'
import DonorReport from './components/DonorReport'
import DonorEntry from './components/DonorEntry'
import DonorUpload from './components/DonorUpload'
import ShippingSummary from './components/ShippingSummary'
import ShippingDetails from './components/ShippingDetails'
import KPIReport from './components/KPIReport'
import DonorCenterQueue from './components/DonorCenterQueue'
import 'primereact/resources/themes/lara-light-indigo/theme.css' // theme
import 'primereact/resources/primereact.css' // core css
import DeviationForm from './modules/Deviation/DeviationForm'
import DeviationReports from './modules/Deviation/DeviationReports'
import { CenterManagement, UserManagement } from './modules/Admin'

function App(props) {
  const [loading, setLoading] = useState(true)
  const [authenticated, setAuthenticated] = useState(true)
  const [allRoles, setAllRoles] = useState([])
  const [idlePopup, setIdlePopup] = useState(false)
  const [showRoleSelectPopUp, setShowRoleSelectPopUp] = useState(false)
  const [userRoles, setUserRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState('')
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [showUnAuthorizedPopUp, setShowUnAuthorizedPopup] = useState({
    open: false,
    message: ''
  })

  const { instance, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  //const isAuthenticated = props.msalContext.accounts.length > 0;

  useState(() => {
    const options = {
      method: 'GET'
    }
    isAuthenticated &&
      inProgress === 'none' &&
      msalFetch('/api/auth/checkAuth', { method: 'GET' })
        .then(async res => {
          if (res.ok) {
            msalFetch('/api/admin/getAllRoles', options)
              .then(async res => {
                if (res.ok) {
                  const allRoles = await res.json()
                  props.actions.setAllRoles(allRoles)
                  setAllRoles(allRoles)
                  msalFetch('/api/auth/getUserInfo', options)
                    .then(async res2 => {
                      if (res2.ok) {
                        setLoading(false)
                        const userInfo = await res2.json()
                        if (userInfo.status !== 'ACTIVE') {
                          setShowUnAuthorizedPopup({
                            open: true,
                            message:
                              'Account is locked. Please contact Administrator'
                          })
                        } else {
                          setIsAuthorized(true)
                          const centerCode = userInfo.center_code;
                          props.actions.setCenterCode(centerCode)
                          const userRoles = userInfo.roles
                            ? userInfo.roles.split(';')
                            : []
                          if (userRoles.length == 1) {
                            const roleid = parseInt(userRoles[0])
                            const rolename = allRoles.filter(role => {
                              return role.role_id == roleid
                            })[0].role_name
                            props.actions.setUserRole({ roleid, rolename })
                          } else {
                            const temp = allRoles.filter(x =>
                              userRoles.includes(String(x.role_id))
                            )
                            setShowRoleSelectPopUp(true)
                            setUserRoles(temp)
                          }
                        }
                      } else if (res2.status === 401) {
                        setLoading(false)
                        const { message } = await res2.json()
                        setShowUnAuthorizedPopup({ open: true, message })
                      } else {
                        setLoading(false)
                        toast.error('Something went wrong')
                      }
                    })
                    .catch(err => toast.error(err.message))
                } else {
                  throw new Error(res.statusText)
                }
              })
              .catch(err => {
                toast.error(err.message)
              })
          } else {
            const response = await res?.json()
            setLoading(false)
            setShowUnAuthorizedPopup({ open: true, message: response.message })
          }
        })
        .catch(e => console.log(e))
  }, [isAuthenticated, inProgress])

  const handleRoleSelection = () => {
    setShowRoleSelectPopUp(false)
    const role = allRoles.find(role => role.role_id == selectedRole)
    props.actions.setUserRole({
      roleid: role.role_id,
      rolename: role.role_name
    })
  }

  const logout = async () => {
    const homeAccountId = instance.getAccountByHomeId()
    const currentAccount = instance.getAccountByHomeId(homeAccountId)
    msalFetch('/api/auth/logout', { method: 'GET' }).then(res => {
      if (res.ok) {
        instance.logoutRedirect({
          account: currentAccount
        })
        localStorage.clear()
        sessionStorage.clear()
      }
    })
  }

  const onIdle = () => {
    if (authenticated) {
      setIdlePopup(true)
      logout()
    }
  }

  const { activate } = useIdleTimer({
    //onPresenceChange,
    //onPrompt,
    onIdle,
    //onActive,
    //onAction,
    timeout: 1000 * 60 * 60,
    //promptBeforeIdle: 1000 * 60,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
      'focus'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false
  })

  activate()

  if (loading || !isAuthenticated) {
    return (
      <>
        <ToastContainer
          position='top-right'
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
          theme='colored'
        />
        <Loader></Loader>
      </>
    )
  }

  return (
    <div className='position-relative container-fluid app'>
      {props.loading ? <Loader></Loader> : null}
      <Header></Header>
      <ToastContainer
        position='top-right'
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme='colored'
      />
      {isAuthorized && (
        <Switch>
          <Route path='#/' exact>
            <Redirect to='/dashboard'></Redirect>
          </Route>
          <Route path='#/login' exact>
            <Redirect to='/dashboard'></Redirect>
          </Route>
          <ProtectedRoute
            exact
            path='/dashboard'
            component={Dashboard}
            authenticated={authenticated}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path='/donor/register'
            component={DonorRegister}
            authenticated={authenticated}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path='/donor/dis'
            component={DIS}
            authenticated={authenticated}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path='/admin/userManagement'
            component={UserManagement}
            authenticated={authenticated}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path='/casePacking/:caseno'
            component={CasePacking}
            authenticated={authenticated}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path='/advancedSearch'
            component={Search}
            authenticated={authenticated}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path='/donations'
            component={QADonationsPage}
            authenticated={authenticated}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path='/cases'
            component={CasePackReport}
            authenticated={authenticated}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path='/ucnLocationUpdate'
            component={CaseShipping}
            authenticated={authenticated}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path='/labOrderReport'
            component={LabOrderReport}
            authenticated={authenticated}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path='/labOrder'
            component={LabOrder}
            authenticated={authenticated}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path='/cdcsReport'
            component={CDCSLogs}
            authenticated={authenticated}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path='/uploadLabResults'
            component={LabReportUpload}
            authenticated={authenticated}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path='/scanUCN'
            component={ScanUCN}
            authenticated={authenticated}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path='/healthAssessments'
            component={HealthAssessments}
            authenticated={authenticated}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path='/labResultsReport'
            component={LabResultsReport}
            authenticated={authenticated}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path='/donorReport'
            component={DonorReport}
            authenticated={authenticated}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path='/donorEntry'
            component={DonorEntry}
            authenticated={authenticated}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path='/donorUpload'
            component={DonorUpload}
            authenticated={authenticated}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path='/shippingSummaryReport'
            component={ShippingSummary}
            authenticated={authenticated}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path='/shippingDetailsReport'
            component={ShippingDetails}
            authenticated={authenticated}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path='/kpiReport'
            component={KPIReport}
            authenticated={authenticated}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path='/donorCenterQueue'
            component={DonorCenterQueue}
            authenticated={authenticated}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path='/deviationForm'
            component={DeviationForm}
            authenticated={authenticated}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path='/deviationReports'
            component={DeviationReports}
            authenticated={authenticated}>
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path='/centerManagement'
            component={CenterManagement}
            authenticated={authenticated}
          ></ProtectedRoute>
          <Route path='*'>
            <Redirect to='/dashboard'></Redirect>
          </Route>
        </Switch>
      )
      }
      <Modal size='md' show={idlePopup} centered>
        <Modal.Header>
          <h4 className='text-secondary p-0 m-0'>Session Timeout</h4>
        </Modal.Header>
        <Modal.Body>
          <div className='row justify-content-center d-flex'>
            <div className='col-md-10'>
              <p>
                You are signed out due to inactivity. Please login again to
                continue.
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='row justify-content-end d-flex px-5'>
            <div className='col-md-6'>
              <button
                className='btn btn-primary'
                onClick={() => {
                  window.location.reload()
                  setIdlePopup(false)
                }}
              >
                Login
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal size='md' show={showUnAuthorizedPopUp.open} centered>
        <Modal.Header>
          <h4 className='text-secondary p-0 m-0'>! UnAuthorized</h4>
        </Modal.Header>
        <Modal.Body>
          <div className='row justify-content-center d-flex'>
            <div className='col-md-10'>
              <p>{showUnAuthorizedPopUp.message}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='row justify-content-end d-flex px-5'>
            <div className='col-md-6'>
              <button className='btn btn-primary' onClick={logout}>
                Logout
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal size='sm' show={showRoleSelectPopUp} centered>
        <Modal.Header>
          <h4 className='text-secondary p-0 m-0'>Please select your role</h4>
        </Modal.Header>
        <Modal.Body>
          <select
            className='form-select'
            onChange={e => setSelectedRole(e.target.value)}
          >
            <option value='0' selected disabled>
              Select
            </option>
            {userRoles.map(x => {
              return (
                <option value={x.role_id} key={x.role_id}>
                  {x.role_name}
                </option>
              )
            })}
          </select>
        </Modal.Body>
        <Modal.Footer>
          <div className='row w-100'>
            <button
              className='btn btn-primary'
              disabled={selectedRole == ''}
              onClick={handleRoleSelection}
            >
              Submit
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div >
  )
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}
const mapStateToProps = state => {
  return {
    loading: state.loading,
    roles: state.roles,
    roleId: state.roleId
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
