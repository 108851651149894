import { Button } from 'antd'
import React, { useState, useEffect } from 'react'

function PhysicalExamination (props) {
  const handleAnswersChange = e => {
    props.setAnswers({ ...props.answers, [e.target.name]: e.target.value })
    if (props.missedQuestions.includes(e.target.name)) {
      const newMissedQuestions = props.missedQuestions.filter(
        q => q != e.target.name
      )
      props.setMissedQuestions(newMissedQuestions)
    }
  }
  const handleNext = () => {
    if (props.assessmentType === 'Full Physical') {
      props.setAssessmentPage('2')
    } else {
      props.setAssessmentPage('4')
    }
  }

  return (
    <div
      className='mt-4'
      style={{ fontFamily: 'Font-Regular', color: '#000000' }}
    >
      <div className='row mb-2'>
        <div className='col-md-5 d-flex justify-content-between'>
          <p>Health Assessment Type : </p>
          <label htmlFor='full_physical'>
            Full Physical :{' '}
            <input
              type='radio'
              name='ASSESSMENT_TYPE'
              id='full_physical'
              value='Full Physical'
              checked={props.assessmentType === 'Full Physical'}
              onChange={e => {
                props.setAssessmentType(e.target.value)
              }}
              disabled={props.formDisabled}
            ></input>
          </label>
          <label htmlFor='counselling'>
            Counselling :{' '}
            <input
              type='radio'
              id='counselling'
              name='ASSESSMENT_TYPE'
              value='Counselling'
              checked={props.assessmentType === 'Counselling'}
              onChange={e => {
                props.setAssessmentType(e.target.value)
              }}
              disabled={props.formDisabled}
            ></input>
          </label>
        </div>
      </div>
      {props.assessmentType === 'Full Physical' ? (
        <>
          {props.answers && (
            <table className='table table-sm table-bordered'>
              <tbody>
                {props.questions.length &&
                  props.questions.map(question => {
                    return (
                      <tr
                        key={question.questionId}
                        className={
                          props.missedQuestions.includes(question.questionId)
                            ? 'table-danger'
                            : null
                        }
                      >
                        <td
                          className={
                            props.answers[question.questionId] &&
                            props.answers[question.questionId] !== 'N/A' &&
                            question.defaultAnswer !==
                              props.answers[question.questionId]
                              ? 'text-danger'
                              : null
                          }
                        >
                          {question.questionId} {question.questionHead}{' '}
                          {question.question}
                        </td>
                        {question.answerType === 'radio' ? (
                          question.options.map(option => {
                            return (
                              <td
                                className={
                                  props.answers[question.questionId] &&
                                  props.answers[question.questionId] !==
                                    'N/A' &&
                                  question.defaultAnswer !==
                                    props.answers[question.questionId]
                                    ? 'text-danger'
                                    : null
                                }
                              >
                                <input
                                  type='radio'
                                  value={option}
                                  name={question.questionId}
                                  id={`${question.questionId}+${option}`}
                                  onChange={handleAnswersChange}
                                  checked={
                                    props.answers[question.questionId] ===
                                    option
                                  }
                                  disabled={props.formDisabled}
                                ></input>
                                <label
                                  htmlFor={`${question.questionId}+${option}`}
                                  style={{ paddingLeft: '2px' }}
                                >
                                  {option}
                                </label>
                              </td>
                            )
                          })
                        ) : question.answerType === 'dropdown' ? (
                          <td
                            colSpan={2}
                            className={
                              props.answers[question.questionId] &&
                              props.answers[question.questionId] !== 'N/A' &&
                              question.defaultAnswer !==
                                props.answers[question.questionId]
                                ? 'text-danger'
                                : null
                            }
                          >
                            <select
                              name={question.questionId}
                              onChange={handleAnswersChange}
                              disabled={props.formDisabled}
                            >
                              <option disabled selected>
                                Select
                              </option>
                              {question.options.map(option => {
                                return (
                                  <option
                                    value={option}
                                    selected={
                                      props.answers[question.questionId] ===
                                      option
                                    }
                                    disabled={props.formDisabled}
                                  >
                                    {option}
                                  </option>
                                )
                              })}
                            </select>
                          </td>
                        ) : (
                          <td colSpan={2}></td>
                        )}
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          )}
        </>
      ) : (
        <>
          <div className='row'>
            <div className='col-md-8'>
              <h6>
                Please submit the Health Assessment in Health Assessment Result
                Tab
              </h6>
            </div>
          </div>
        </>
      )}
      {props.readOnly ? null : (
        <div className='row my-3'>
          <div className='col-md-4'>
            <Button type='primary' onClick={handleNext}>
              Next
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default PhysicalExamination
