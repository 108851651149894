import React, { useState, useRef } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import { Input, DatePicker, Space, Button } from 'antd'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import ReactToPrint from 'react-to-print'
import PrintLabOrder from './PrintLabOrder'
import { msalFetch } from '../utilities/msalFetch'
import { formatDate } from '../utilities/format'
import { Table, Tag } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'

const columns = [
  {
    title: 'UCN',
    dataIndex: 'UCN',
    key: 'UCN'
  },
  {
    title: 'Donation Date',
    dataIndex: 'DONATION_DATE',
    key: 'DONATION_DATE'
  },
  {
    title: 'Donor Type',
    dataIndex: 'DONOR_TYPE',
    key: 'DONOR_TYPE'
  },
  {
    title: 'NAT_VMT',
    dataIndex: 'NAT_VMT',
    key: 'NAT_VMT'
  },
  {
    title: 'ATYA',
    dataIndex: 'ATYA',
    key: 'ATYA'
  },
  {
    title: 'SPE_RPR',
    dataIndex: 'SPE_RPR',
    key: 'SPE_RPR'
  },
  {
    title: 'Plasma Processor',
    dataIndex: 'PLASMA_PROCESSOR',
    key: 'PLASMA_PROCESSOR'
  },
  {
    title: 'Lab Result',
    dataIndex: 'Lab_Result',
    key: 'Lab_Result',
    render: (_, { Lab_Result }) => {
      switch (Lab_Result) {
        case 'Partial':
          return <Tag color='geekblue'>{Lab_Result}</Tag>
        case 'Pass':
          return <Tag color='green'>{Lab_Result}</Tag>
        case 'Reactive':
          return <Tag color='red'>{Lab_Result}</Tag>
        case 'Abnormal SPE':
          return <Tag color='yellow'>{Lab_Result}</Tag>
        case 'RPR Positive':
          return <Tag color='yellow'>{Lab_Result}</Tag>
        case 'ATYA Positive':
          return <Tag color='red'>{Lab_Result}</Tag>
        case 'No Records':
          return <p>{Lab_Result}</p>
      }
    },
    filters: [
      {
        text: 'Partial',
        value: 'Partal'
      },
      {
        text: 'Pass',
        value: 'Pass'
      },
      {
        text: 'Reactive',
        value: 'Reactive'
      },
      {
        text: 'Abnormal SPE',
        value: 'Abnormal SPE'
      },
      {
        text: 'ATYA Positive',
        value: 'ATYA Positive'
      },
      {
        text: 'RPR Positive',
        value: 'RPR Positive'
      },
      {
        text: 'No Records',
        value: 'No Records'
      }
    ],
    onFilter: (value, record) => record.Lab_Result.indexOf(value) === 0
  }
]

const { RangePicker } = DatePicker

function LabOrderReport (props) {
  const [dateRange, setDateRange] = useState(undefined)
  const [ucn, setUCN] = useState('')
  const [labOrderReport, setLabOrderReport] = useState(undefined)

  let printRef = useRef(null)

  const handleDateRangeChange = (dates, dateStrings, info) => {
    const [fromdate, todate] = dateStrings
    if (fromdate != '' && todate != '') {
      setDateRange({
        fromDate: moment(fromdate).format('YYYY-MM-DD'),
        toDate: moment(todate).format('YYYY-MM-DD')
      })
    } else {
      setDateRange(undefined)
    }
  }

  const getLabOrderReport = () => {
    props.actions.setLoading()
    const body = {
      dateRange,
      ucn
    }
    const options = {
      method: 'post',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      },
      body: JSON.stringify(body)
    }
    msalFetch('/api/reports/getLabOrder', options)
      .then(async res => {
        props.actions.clearLoading()
        const data = await res.json()
        if (res.ok) {
          const transformedData = data.map(x => {
            x.DONATION_DATE = formatDate(x.DONATION_DATE)
            x.SPE_RPR = x.SPE_RPR ? 'Y' : ''
            x.ATYA = x.ATYA ? 'Y' : ''
            x.NAT_VMT = x.NAT_VMT ? 'Y' : ''
            x.Lab_Result=x.Lab_Result?x.Lab_Result:'No Records'
            return x
          })
          setLabOrderReport(transformedData)
        } else {
          toast.error('Failed to get lab order')
        }
      })
      .catch(err => {
        props.actions.clearLoading()
      })
  }

  return (
    <div className='row justify-content-center mt-2'>
      <div className='col-md-10'>
        <div className='row d-flex justify-content-center mt-4 mb-4'>
          <div className='col-sm-10'>
            <h3 style={{ color: '#292764' }}>Lab Order Report</h3>
            <div className='row'>
              <div className='col-md-6'>
                Donation Date :{' '}
                <RangePicker
                  style={{ marginRight: '24px' }}
                  format='DD-MMM-YYYY'
                  onCalendarChange={handleDateRangeChange}
                ></RangePicker>
              </div>
              <div className='col-md-6'>
                UCN :
                <Input
                  style={{ width: '40%' }}
                  onChange={e => setUCN(e.target.value)}
                ></Input>
              </div>
            </div>
            <div className='my-2'>
              <Button type='primary' onClick={getLabOrderReport}>
                Apply
              </Button>
            </div>
            <div className='row my-4'>
              <div className='col-md-10'>
                {labOrderReport ? (
                  <>
                    <div className='d-flex justify-content-end mb-2'>
                      <ReactToPrint
                        trigger={() => (
                          <Button icon={<DownloadOutlined />}>
                            PrintLabOrder
                          </Button>
                        )}
                        content={() => printRef}
                      />
                    </div>
                    <Table
                      columns={columns}
                      dataSource={labOrderReport}
                      size='small'
                    ></Table>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: 'none' }}>
        <PrintLabOrder
          ref={el => {
            printRef = el
          }}
          labOrderReport={labOrderReport}
          ucn={ucn}
          donationRange={dateRange}
        ></PrintLabOrder>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LabOrderReport)
